import { Box, Button, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, FormControl, FormLabel, Image, Input, Modal, ModalBody, ModalContent, ModalOverlay, Skeleton, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure, useToast } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import axiosInstance from "../../services/api";
import Empty from "./empty.svg";
import Location from '../catalog/location.svg';
import { ArrowBackIcon } from "@chakra-ui/icons";
import Header from "../../components/header/header";


const Offers = () => {
    const [loading, setLoading] = useState(true);
    const [loadingx, setLoadingx] = useState(true);
    const [loadingxx, setLoadingxx] = useState(false);
    const [loadingxxx, setLoadingxxx] = useState(false);
    const [offers, setOffers] = useState([]);
    const [offersx, setOffersx] = useState([]);
    const [items, setItems] = useState([]);

    const [idx, setIdx] = useState("");
    const [image, setImage] = useState("");
    const [namex, setNamex] = useState("");
    const [pricex, setPricex] = useState("");
    const [idxx, setIdxx] = useState("");
    const [sellView, setSellView] = useState("main");

    
    const [offerAmount, setOfferAmount] = useState("");
    const [offerId, setOfferId] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance.get("/sale-items/buyer-items-offer")
        // .then(res => console.log("vvvxx", res?.data?.data))
        .then(res => setOffers(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, [])

    useEffect(() => {
        axiosInstance.get("/sale-items/user")
        // .then(res => console.log("vvvxx", res?.data?.data))
        .then(res => setItems(res?.data?.data))
        .then(res => setLoadingx(false))
        .catch(error => console.log(error))
    }, [])

    const currentUser = useSelector(({userData})=>  userData?.currentUser);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

    const toast = useToast();


    const handleSellView = async(id, img, name, idi) => {
        setIdx(id)
        setImage(img)
        setNamex(name)
        setIdxx(idi)
        onOpen()
        try {
            const { data } = await axiosInstance.get(`/sale-items/${id}/seller-item-offers`)
            console.log("seller offers", data?.data)
            setOffersx(data?.data)
            setSellView("list")
        } catch(error) {
            console.log(error?.response?.data?.message)
        } finally {
            onClose()
        }
    }

    const handleDrawer = (price, ofid) => {
        setPricex(price)
        setOfferId(ofid)
        onOpen2()
    }

    const makeOffer = async () => {
        setLoadingxx(true)
        const datax = {
            price: offerAmount
        }
        try {
            const {data} = await axiosInstance.post(`/sale-items/${idxx}/send-buyer-offer/${offerId}`, datax)
            console.log(data)
            window.location.reload()
            onClose2()
            toast({
                // title: "An Error Occurred",
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } catch(error) {
            console.log(error?.response?.data?.message)
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoadingxx(false)
        }
    }

    const acceptOffer = async (id) => {
        setLoadingxxx(true)
        const datax = {
            price: offerAmount
        }
        try {
            const {data} = await axiosInstance.patch(`/sale-items/${idxx}/accept-buyer-offer/${id}`, datax)
            console.log(data)
            // window.location.reload()
            toast({
                // title: "An Error Occurred",
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } catch(error) {
            console.log(error?.response?.data?.message)
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoadingxxx(false)
        }
    }

    function Resolver() {
        if(offerAmount) {
            return <Button onClick={makeOffer} type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loadingxx ? <Spinner /> : "Send Offer" }</Button>
        } else {
            return <Button onClick={makeOffer} disabled type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loadingxx ? <Spinner /> : "Send Offer" }</Button>
        }
    }

    return (
        <Box>
            <Header />
            <Box px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={"1rem"} marginBottom="18rem">
                <Text color="#1A1A1A" marginLeft={"1rem"} fontWeight={"700"} fontSize="1.4rem" fontFamily={"Clash Display"}>Offers</Text>
                <br />
                <Tabs>
                    <TabList maxW="800px" margin={"0 auto"} borderBottom={"none"} display={"flex"} justifyContent="space-around">
                        <Tab _selected={{borderBottom: "2px solid #E66F15", color: "#1A1A1A"}} fontWeight="500" fontSize={"1.1rem"} color="#666666">Buying</Tab>
                        <Tab _selected={{borderBottom: "2px solid #E66F15", color: "#1A1A1A"}} fontWeight="500" fontSize={"1.1rem"} color="#666666">Selling</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel padding={"1rem 0"}>
                            <Box maxW={"400px"} margin="2rem auto 3rem auto" borderTop="1px solid #F6F6F6">
                                <Text borderRadius={"20px"} bg={"#F6FECC"} color="#2E1604" margin={"-1.2rem auto 0 auto"} width={"fit-content"} fontWeight={"500"} fontSize=".85rem" padding={".5rem 1.4rem"}>Recents</Text>
                            </Box>

                            <Box maxW={"800px"} margin="0 auto">
                                {loading ? 
                                <Flex marginTop={"7rem"} marginBottom={"7rem"} justifyContent={"center"}>
                                    <Spinner size={"lg"} color="#E66F15" />
                                </Flex>
                                :
                                offers?.length < 1 ? 
                                <Box textAlign={"center"} marginTop="2rem">
                                    <Image src={Empty} transform="scale(.6)" width={"auto"} margin="0 auto" />
                                    <Text transform={"translateY(-50px)"} color="#1A1A1A" fontSize={".9rem"} fontFamily={"Clash Display"} fontWeight="600">No offers available</Text>
                                </Box>
                                :
                                offers?.map(({_id, imageURLs, offers, name}) => {
                                    return (
                                        <Box key={_id}>
                                            <Flex justifyContent={"space-between"} cursor={"pointer"} mt={2} marginBottom=".8rem" padding={"1.5rem 1rem 1rem 1rem"} borderRadius={"12px"} border="1px solid #EAEAEA" bg="#FFF" gap="1rem" alignItems={"center"}>
                                                    <Flex gap=".8rem" width={"100%"} flexWrap="wrap" justifyContent="space-between">
                                                        <Flex alignItems={"center"}>
                                                            <Box mr={3} height={"60px"} width="60px" backgroundSize={"cover"} backgroundImage={imageURLs[0]} borderRadius="12px"></Box>
                                                            <Box>
                                                                {offers[offers?.length - 1]?.lastOfferSenderId !== currentUser?._id && offers[offers?.length - 1]?.status === "rejected" && <Text mb={3} width={"170px"} fontWeight="600" color="#4D2507" whiteSpace={"nowrap"} fontSize=".9rem">Seller sent you a counter offer</Text>}
                                                                {offers[offers?.length - 1]?.lastOfferSenderId !== currentUser?._id && offers[offers?.length - 1]?.status === "accepted" && <Text mb={3} width={"170px"} fontWeight="600" color="#4D2507" whiteSpace={"nowrap"} fontSize=".9rem">Seller accepted your offer</Text>}
                                                                {offers[offers?.length - 1]?.lastOfferSenderId === currentUser?._id && offers[offers?.length - 1]?.status === "pending" && <Text mb={3} width={"170px"} fontWeight="600" color="#4D2507" whiteSpace={"nowrap"} fontSize=".9rem">You sent an offer</Text>}
                                                                <Text width={"170px"} fontWeight="600" color="#666666" whiteSpace={"nowrap"} fontSize=".9rem">{name}</Text>
                                                                {/* <Text fontSize={".8rem"} color={"#8C8C8C"} width={"170px"}>{moment(createdAt).calendar()}</Text> */}
                                                            </Box>
                                                        </Flex>

                                                        <Text fontWeight={"600"} fontSize=".9rem">₦{offers[offers?.length - 1]?.listPrice?.toLocaleString()}</Text>

                                                        {offers[offers?.length - 1]?.lastOfferSenderId === currentUser?._id ? 

                                                        offers[offers?.length - 1]?.status === "rejected" ? 
                                                        <Text minW="205px" textAlign={"right"} color="#F04438" fontWeight={"600"} fontSize=".9rem">Offer Declined</Text>
                                                        :
                                                        offers[offers?.length - 1]?.status === "pending" ? 
                                                        <Text minW="205px" textAlign={"right"} color="#4D2507" fontWeight={"600"} fontSize=".9rem">Offer Sent</Text>
                                                        :
                                                        null
                                                        // <Flex gap=".5rem" marginTop={"-.2rem"}>
                                                        //     <Button padding={"1.1rem 2rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"100%"} color="#fff" fontSize=".9rem" fontWeight={"700"}>Pay</Button>
                                                        //     <Button padding={"1.1rem 2rem"} borderRadius="20px" border="1px solid #E66F15" bg="#FFF" width={"100%"} color="#E66F15" fontSize=".9rem" fontWeight={"700"}>Decline</Button>
                                                        // </Flex>
                                                        :

                                                        <Flex gap=".5rem" marginTop={[".7rem", ".7rem", "0", "0",]} justifyContent={"center"}>
                                                            <Button onClick={() => navigate(`/checkout/${_id}`)} padding={".85rem 2rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"100%"} color="#fff" fontSize=".9rem" fontWeight={"700"}>Pay</Button>
                                                            {/* <Button padding={".85rem 2rem"} borderRadius="20px" border="1px solid #E66F15" bg="#FFF" width={"100%"} color="#E66F15" fontSize=".9rem" fontWeight={"700"}>Decline</Button> */}
                                                        </Flex>
                                                        }
                                                    </Flex>
                                            </Flex>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </TabPanel>

                        <TabPanel padding={"0"}>
                            {loadingx ? 
                                <Stack mt={6}>
                                    <Skeleton height={"30px"} />
                                    <Skeleton height={"30px"} />
                                    <Skeleton height={"30px"} />
                                    <Skeleton height={"30px"} />
                                </Stack>
                                :
                                items?.length < 1 ? 
                                <Box textAlign={"center"} marginTop="2rem">
                                    <Image src={Empty} transform="scale(.6)" width={"auto"} margin="0 auto" />
                                    <Text transform={"translateY(-50px)"} color="#1A1A1A" fontSize={".9rem"} fontFamily={"Clash Display"} fontWeight="600">No offers available</Text>
                                </Box>
                                :
                                sellView === "main" ?
                                <Flex marginTop="4rem" wrap={"wrap"} gap="1rem" rowGap={"3rem"}>
                                    {items?.map(({imageURLs, name, listPrice, pickUpLocation, _id, status, offers}) => {
                                        return (
                                                <Box onClick={() => handleSellView(_id, imageURLs[0], name, _id)} cursor={"pointer"} overflow={"hidden"} minWidth={["150px", "250px" ]} flex={["1", "1", "0", "0"]} key={_id}>
                                                    <Box overflow={"hidden"} borderRadius="12px" height="230px" width={"100%"} backgroundImage={imageURLs[0]} backgroundSize="cover"></Box>
                                                    <Box padding={"0 .3rem"}>
                                                        <Text mt={2} fontWeight="600">{name}</Text>
                                                        <Flex mt={2} justifyContent={"space-between"}>
                                                            {/* <Flex alignItems={"center"}>
                                                                <Image mr={1} transform={"translateY(1.5px)"} width={"20%"} src={Location} />
                                                                <Text mt={1} whiteSpace="nowrap" color="#908888">{pickUpLocation?.landmarkArea}</Text>
                                                            </Flex> */}
                                                            <Text mt={1} fontSize=".95rem" fontWeight="600">₦{listPrice?.toLocaleString()}</Text>
                                                        </Flex>
                                                        <Text fontSize={".8rem"} fontWeight="600" color={offers?.length < 1 ? "#F04438" : "#12B76A"} mt={2}>{offers?.length} Offers</Text>
                                                    </Box>
                                                </Box>
                                        )
                                    })}
                                </Flex>
                                :
                                <Box maxW={"800px"} margin="2rem auto 0 auto">
                                    {offersx?.map(({_id, buyer, lastOfferSenderId, listPrice, sellerPrice, serviceCharge, status}) => {
                                        return (
                                            <Box key={_id}>
                                                <Flex justifyContent={"space-between"} cursor={"pointer"} mt={2} marginBottom=".8rem" padding={"1.5rem 1rem 1rem 1rem"} borderRadius={"12px"} border="1px solid #EAEAEA" bg="#FFF" gap="1rem" alignItems={"center"}>
                                                        <Flex gap=".8rem" width={"100%"} flexWrap="wrap" justifyContent="space-between">
                                                            <Flex alignItems={"center"}>
                                                                <Box mr={3} height={"60px"} width="60px" backgroundSize={"cover"} backgroundImage={image} borderRadius="12px"></Box>
                                                                <Box>
                                                                    {lastOfferSenderId === currentUser?._id && status === "rejected" && <Text mb={3} width={"170px"} fontWeight="600" color="#4D2507" whiteSpace={"nowrap"} fontSize=".9rem">You sent a counter offer</Text>}
                                                                    {lastOfferSenderId === currentUser?._id && status === "accepted" && <Text mb={3} width={"170px"} fontWeight="600" color="#4D2507" whiteSpace={"nowrap"} fontSize=".9rem">You received an offer</Text>}
                                                                    {lastOfferSenderId !== currentUser?._id && status === "accepted" && <Text mb={3} width={"170px"} fontWeight="600" color="#4D2507" whiteSpace={"nowrap"} fontSize=".9rem">You received an offer</Text>}
                                                                    {lastOfferSenderId !== currentUser?._id && status === "pending" && <Text mb={3} width={"170px"} fontWeight="600" color="#4D2507" whiteSpace={"nowrap"} fontSize=".9rem">You received an offer</Text>}
                                                                    <Text width={"170px"} fontWeight="600" color="#666666" whiteSpace={"nowrap"} fontSize=".9rem">{namex}</Text>
                                                                    {/* <Text fontSize={".8rem"} color={"#8C8C8C"} width={"170px"}>{moment(createdAt).calendar()}</Text> */}
                                                                </Box>
                                                            </Flex>

                                                            <Text fontWeight={"600"} fontSize=".9rem">₦{sellerPrice?.toLocaleString()}</Text>

                                                            {lastOfferSenderId === currentUser?._id ? 

                                                            status === "rejected" ? 
                                                            <Text minW="205px" textAlign={"right"} marginTop="-.2rem" color="#F04438" fontWeight={"600"} fontSize=".9rem">Counter Offer Sent</Text>
                                                            :
                                                            status === "accepted" ? 
                                                            <Text minW="205px" textAlign={"right"} color="#12B76A" fontWeight={"600"} fontSize=".9rem">Offer Accepted</Text>
                                                            :
                                                            null
                                                            :
                                                            status === "accepted" ? 
                                                            <Text minW="205px" textAlign={"right"} color="#12B76A" fontWeight={"600"} fontSize=".9rem">Offer Accepted</Text>
                                                            :
                                                            <Flex gap=".5rem" marginTop={[".7rem", ".7rem", "0", "0",]} justifyContent={"center"}>
                                                                <Button onClick={() => acceptOffer(buyer?._id)} padding={".85rem 2rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"100%"} color="#fff" fontSize=".9rem" fontWeight={"700"}>{loadingxxx ? <Spinner /> : "Accept"}</Button>
                                                                <Button onClick={() => handleDrawer(sellerPrice, buyer?._id)} padding={".85rem 2rem"} borderRadius="20px" border="1px solid #E66F15" bg="#FFF" width={"100%"} color="#E66F15" fontSize=".9rem" fontWeight={"700"}>Counter Offer</Button>
                                                            </Flex>
                                                            }
                                                        </Flex>
                                                </Flex>
                                            </Box>
                                        )
                                    })}
                                </Box>
                                }
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>

            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent margin={"0 .5rem"}>

                <ModalBody>
                    <Flex marginTop={"7rem"} justifyContent={"center"}>
                        <Spinner size={"lg"} color="#E66F15" />
                    </Flex>
                    <Text textAlign={"center"} mt={5} marginBottom={"7rem"} fontWeight="500">Fetching your offers.. Hang Tight</Text>
                </ModalBody>
                </ModalContent>
            </Modal>


            <Drawer
                isOpen={isOpen2}
                placement='right'
                onClose={onClose2}
                size="md"
            >
                <DrawerOverlay />
                <DrawerContent>
                <DrawerHeader>
                    <br />
                    <Text onClick={() => onClose2()} cursor={"pointer"} flex={"1"} fontWeight={"600"} color={"#1A1A1A"} fontSize=".9rem"><ArrowBackIcon transform={"translate(-4px,-1px)"} />Back</Text>
                </DrawerHeader>
                <DrawerBody>
                    <Text mt={3} fontFamily={"Clash Display"} fontWeight="700" fontSize={"1.2rem"}>Counter Offer</Text>
                    {/* <Text mt={5} fontWeight="400">Make sure to set a fair price for the seller, and move the item to your cart once the seller accepts.</Text> */}
                    <Flex mt={7} bg="#FAE2D0" borderRadius="12px" gap="1rem" alignItems={"center"} padding="1.5rem 1.2rem">
                        <Box height="60px" width="60px" backgroundImage={image} backgroundSize="cover" borderRadius="12px"></Box>
                        <Box>
                            <Text mb={2} fontWeight="500" color="#1A1A1A">{namex}</Text>
                            <Text fontWeight="500" color="#1A1A1A" fontSize={".9rem"}>Buyer's Offer: <strong>₦{pricex?.toLocaleString()}</strong></Text>
                        </Box>
                    </Flex>
                    <br />
                    <FormControl mt={3}>
                        <FormLabel transform={"translateY(2px)"} fontWeight="700" fontFamily='Creato Display' fontSize=".9rem">Amount you’re offering</FormLabel>
                        <Input type={"email"} onChange={e => setOfferAmount(parseInt(e.target.value))} padding={"1.3rem 1rem"} placeholder="Enter an Amount" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" borderRadius={"20px"} />
                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Resolver />
                </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Box>
    )
}

export default Offers;