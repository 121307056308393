import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react"
import { Link } from "react-router-dom";


const TextArrow = ({text, link}) => {
    return (
        <Link to={link}>
            <Flex gap={".7rem"} alignItems="center">
                <Text color="#1A1A1A" fontWeight={"700"} fontFamily="Clash Display" fontSize={"1.2rem"}>{text}</Text>
                <ArrowForwardIcon color={"#D1FB00"} />
            </Flex>
        </Link>
    )
}

export default TextArrow;