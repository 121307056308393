import { ArrowBackIcon, ArrowForwardIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Divider, Flex, HStack, Text } from "@chakra-ui/react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/header/header";
import Navbar from "../../components/navbar/navbar";

const issues = [
    "I received the wrong item, what should I do?",
    "The item I got doesn’t match what i ordered",
    "I received a damaged item, what should I do?",
    "Something else?"
]

const Dispute = () => {
    const { id } = useParams()
    const { state } = useLocation()
    const navigate = useNavigate()
    return (
        <Box>
            <Header />
            <Box px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={"2rem"}>
                <HStack alignItems={"center"} gap="2rem">
                    <Text onClick={() => navigate(-1)} cursor={"pointer"} fontWeight={"600"} color={"#1A1A1A"}><ArrowBackIcon transform={"translate(-4px,-1px)"} />Back</Text>
                    <Text mb={6} fontSize={"1.25rem"} fontWeight="700" fontFamily={"Clash Display"}>Dispute Order</Text>
                </HStack>
                <HStack mt={8} alignItems={"center"} gap="5.4rem">
                    <Text display={["none", "flex"]}></Text>
                    <Box w={"600px"}>
                        {issues?.map((i, index) => {
                            return (
                                // <Link style={{textDecoration: "none"}} to={`/orders/${id}/dispute/create/${i}`}>
                                    <Box mb={6} key={index} cursor="pointer" onClick={() => navigate(`/orders/${id}/dispute/create/${i}`, {state: state})}>
                                    <Flex margin={"1rem 0"} width={"100%"} justifyContent={"space-between"}>
                                        <Text fontWeight={"500"}>{i}</Text>
                                        <ChevronRightIcon fontSize={"1.3rem"} />
                                    </Flex>
                                    <Divider />
                                </Box>
                                // </Link>
                            )
                        })}
                    </Box>
                </HStack>
            </Box>
        </Box>
    )
}

export default Dispute