import { Box, Button, Flex, Image, Skeleton, Stack, Text } from "@chakra-ui/react";

import One from '../../assets/trending/one.svg';
import Two from '../../assets/trending/two.svg';
import Three from '../../assets/trending/three.svg';
import Four from '../../assets/trending/four.svg';
import Five from '../../assets/trending/five.svg';
import Six from '../../assets/trending/six.svg';
import Seven from '../../assets/trending/seven.svg';
import Eight from '../../assets/trending/eight.svg';
import Nine from '../../assets/trending/nine.svg';
import Ten from '../../assets/trending/ten.svg';

import Location from '../../assets/location.svg';
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import Navbar from "../../components/navbar/navbar";
import axiosInstance from "../../services/api";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SaveAction2 from "../../components/saveAction/saveAction2";
import SaveAction from "../../components/saveAction/saveAction";
import Header from "../../components/header/header";

const data = [
    {
        img: One,
        name: "Scanfrost Gas Cooker",
        location: "Maitama",
        price: "210000"
    },
    {
        img: Two,
        name: "Round Mirror Mini",
        location: "Egbeda",
        price: "12000"
    },
    {
        img: Three,
        name: "Cooking Pots (12 set)",
        location: "Ikorodu",
        price: "35000"
    },
    {
        img: Four,
        name: "iPhone X 64gb",
        location: "Maryland",
        price: "140000"
    },
    {
        img: Five,
        name: "Fuji Film X-T3 Camera",
        location: "Yaba",
        price: "200000"
    },
    {
        img: Six,
        name: "Bass Guitar",
        location: "Surulere",
        price: "60000"
    },
    {
        img: Seven,
        name: "Mini Bookshelf",
        location: "Ago-Palace",
        price: "55000"
    },
    {
        img: Eight,
        name: "Layered Cupboard",
        location: "Surulere",
        price: "35000"
    },
    {
        img: Nine,
        name: "Standing Mirror (7 Feet)",
        location: "Iwaya",
        price: "10000"
    },
    {
        img: Ten,
        name: "Round Wall Mirror",
        location: "Oyingbo",
        price: "20000"
    },
    {
        img: One,
        name: "Scanfrost Gas Cooker",
        location: "Maitama",
        price: "210000"
    },
    {
        img: Two,
        name: "Round Mirror Mini",
        location: "Egbeda",
        price: "12000"
    },
    {
        img: Three,
        name: "Cooking Pots (12 set)",
        location: "Ikorodu",
        price: "35000"
    },
    {
        img: Four,
        name: "iPhone X 64gb",
        location: "Maryland",
        price: "140000"
    },
    {
        img: Five,
        name: "Fuji Film X-T3 Camera",
        location: "Yaba",
        price: "200000"
    },
    {
        img: Six,
        name: "Bass Guitar",
        location: "Surulere",
        price: "60000"
    },
    {
        img: Seven,
        name: "Mini Bookshelf",
        location: "Ago-Palace",
        price: "55000"
    },
    {
        img: Eight,
        name: "Layered Cupboard",
        location: "Surulere",
        price: "35000"
    },
    {
        img: Nine,
        name: "Standing Mirror (7 Feet)",
        location: "Iwaya",
        price: "10000"
    },
    {
        img: Ten,
        name: "Round Wall Mirror",
        location: "Oyingbo",
        price: "20000"
    },
    {
        img: One,
        name: "Scanfrost Gas Cooker",
        location: "Maitama",
        price: "210000"
    },
    {
        img: Two,
        name: "Round Mirror Mini",
        location: "Egbeda",
        price: "12000"
    },
    {
        img: Three,
        name: "Cooking Pots (12 set)",
        location: "Ikorodu",
        price: "35000"
    },
    {
        img: Four,
        name: "iPhone X 64gb",
        location: "Maryland",
        price: "140000"
    },
    {
        img: Five,
        name: "Fuji Film X-T3 Camera",
        location: "Yaba",
        price: "200000"
    },
]

const StaffPicks = () => {
    const state = useLocation()
    // console.log("type", state?.search?.slice(6))

    const [savedItems, setSavedItems] = useState([]);
    const [loadingx, setLoadingx] = useState(true);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0,0)
    })

    const navigate = useNavigate()

    useEffect(() => {
        // axiosInstance.get("sale-items")
        axiosInstance.get("staff-picked-items")
        // .then(res => console.log("vvv", res?.data?.data))
        .then(res => setItems(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, []);

    // useEffect(() => {
    //     axiosInstance.get("/saved-items")
    //     // .then(res => console.log("vvv", res?.data?.data))
    //     .then(res => setSavedItems(res?.data?.data))
    //     .then(res => setLoadingx(false))
    //     .catch(error => console.log(error))
    // }, []);

    return (
        <>
        <Header />
        <Box px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={[".8rem", ".9rem", "1rem", "1rem"]}>
            <Text mb={6} mt={4} color="#1A1A1A" fontWeight={"700"} fontFamily="Clash Display" fontSize={"1.2rem"}>{state?.search?.slice(6) === "staff" ? "Staff Picks" : state?.search?.slice(6) === "relocation" ? "Garage Sales & Relocation" : ""}</Text>

            <Flex wrap={"wrap"} gap="1rem" rowGap={"3rem"}>
                {loading ? 
                 <>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton width="100%" height={"230"} borderRadius="12px" />
                     <Skeleton width="100%" height={"25px"} />
                     <Skeleton width="100%" height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton width="100%" height={"230"} borderRadius="12px" />
                     <Skeleton width="100%" height={"25px"} />
                     <Skeleton width="100%" height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 </>
                :
                items?.filter(i => i?.item?.isAvailable === true && i?.label === state?.search?.slice(6))?.map(({item}) => {
                        // const idx = savedItems.filter(i => i?.item?._id === _id)
                        return (
                            <Box onClick={() => navigate(`/item-details/${item?._id}`, {state: "paid"})} cursor={"pointer"} overflow={"hidden"} minWidth={["150px", "250px" ]} flex={["1", "1", "0", "1"]} key={item?._id}>
                                <Box position={"relative"} overflow={"hidden"} borderRadius="12px" height="230px" width={"100%"} backgroundImage={item?.imageURLs[0]} backgroundSize="cover">
                                    {/* {savedItems?.map(i => i?.item?._id).includes(item?._id) ? <SaveAction2 id={idx[0]} from="main" /> : <SaveAction id={item?._id} />} */}
                                </Box>
                                <Box padding={"0 .3rem"}>
                                    {/* <Text mt={1} fontWeight="600" fontSize={".9rem"}>{createdAt?.slice(0,10)}</Text> */}
                                    <Text textTransform={"capitalize"} mt={1} fontWeight="600" fontSize={".9rem"}>{item?.name}</Text>
                                    <Flex mt={2} justifyContent={"space-between"}>
                                        <Flex alignItems={"center"}>
                                            <Image mr={1} transform={"translateY(1.5px)"} width={"20%"} src={Location} />
                                            <Text mt={1} color="#908888" fontSize={".9rem"}>{item?.pickUpLocation?.landmarkArea}</Text>
                                        </Flex>
                                        <Text mt={1} fontSize=".9rem" fontWeight="600">₦{item?.listPrice?.toLocaleString()}</Text>
                                    </Flex>
                                    {/* {today && <Button mt={3} bg="#E66F15" color={"#fff"} width={"100%"} padding={"0 3rem"} height="38px" fontFamily={"Clash Display"} fontSize=".8rem" borderRadius="20px">Buy Now</Button>} */}
                                </Box>
                            </Box>
                        )
                    })
                    }
            </Flex> 

            <Flex marginBottom={"4rem"} justifyContent={"center"}>
                <Flex margin={"3rem auto 0 auto"} alignItems={"center"} gap=".6rem">
                    <Text cursor="pointer" color={"#1A1A1A"} padding=".1rem .7rem" borderRadius={"50%"} border="1px solid #D1FB00">1</Text>
                    <Text cursor="pointer" color={"#1A1A1A"} padding=".1rem .6rem" borderRadius={"50%"} border="1px solid #D1FB00">2</Text>
                    <Text cursor="pointer" color={"#1A1A1A"} padding=".1rem .6rem" borderRadius={"50%"} border="1px solid #D1FB00">3</Text>
                    <ArrowForwardIcon cursor="pointer" color={"#D1FB00"} />
                </Flex>
            </Flex>
        </Box>
        </>
    )
}

export default StaffPicks;