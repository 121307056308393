import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Spinner,
  useDisclosure,
  Checkbox,
  Divider,
  InputGroup,
  InputLeftElement,
  RadioGroup,
  Stack,
  Radio,
  InputRightElement,
  useToast,
  Skeleton,
  Badge,
} from "@chakra-ui/react";
import Header from "../../components/header/header";
import { Fragment, useEffect, useState } from "react";
import axiosInstance from "../../services/api";
import Empty from "./empty.svg";
import {
  AddIcon,
  ArrowBackIcon,
  ChevronDownIcon,
  DeleteIcon,
  EditIcon,
  MinusIcon,
  PlusSquareIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import states from "../../utils/states";
import capitalizeFirstLetter from "../../utils/capitalize";
import statesLgaJson from "../../utils/stateLGAs";
import { AiOutlineUpload } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const datay = [
  {
    name: "Brand New",
  },
  {
    name: "Almost New",
  },
  {
    name: "Fairly Used",
  },
  {
    name: "Used",
  },
];

const Clearance = () => {
  const [loadingc, setLoadingc] = useState(false);
  const [loadingd, setLoadingd] = useState(false);
  const [loadinge, setLoadinge] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clearance, setClearance] = useState([]);
  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState([]);
  const [itemIds, setItemIds] = useState([]);
  const [idxData, setIdxData] = useState(null);
  const [namex, setNamex] = useState("");
  const [imageUrlx, setImageUrlx] = useState("");
  const [itemIdsx, setItemIdsx] = useState([]);
  const [idx, setIdx] = useState("");
  

  const [items, setItems] = useState([]);
  const [loadingx, setLoadingx] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate()

  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();

  useEffect(() => {
    axiosInstance
      .get("/sale-items-clearance-groups/user")
    //   .then(res => console.log("vvvxx", res?.data?.data))
      .then((res) => setClearance(res?.data?.data))
      .then((res) => setLoading(false))
      .catch((error) => console.log(error));
  }, [loadingc, loadingd, loadinge]);

  const toast = useToast();

  useEffect(() => {
    axiosInstance
      .get("/sale-items/user")
      // .then(res => console.log("vvvxx", res?.data?.data))
      .then((res) => setItems(res?.data?.data))
      .then((res) => setLoadingx(false))
      .catch((error) => console.log(error));
  }, []);

  const createClearance = async () => {
    setLoadingc(true);

    try {
        const formData = new FormData()
        formData.append("file", imageUrl)
        formData.append("upload_preset", "jmrkiyu0")
        formData.append("cloud_name", "declutters")

        const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)

        const myObj = {};
        const datax = {
        name,
        imageURL: data?.url,
        itemIDs: itemIds,
        };

        for (const key in datax) {
        const value = datax[key];
        if (value !== "" && value !== undefined && value !== null) {
            myObj[key] = value;
        }
        }

        try {
            const { data } = await axiosInstance.post("/sale-items-clearance-groups", myObj);
            onClose();
            setName("")
            setImageUrl("")
            setItemIds([])
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            })
        }

    } catch(error) {
        toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
        })
    } finally {
        setLoadingc(false); 
    }
  };


  const editClearance = async () => {
    if(imageUrlx !== "") {
      setLoadinge(true);
      console.log(idxData)
      try {
        
          const formData = new FormData()
          formData.append("file", imageUrlx)
          formData.append("upload_preset", "jmrkiyu0")
          formData.append("cloud_name", "declutters")
  
          // if(imageUrlx) {
            const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
          // }
  
          const myObj = {};
  
          const datax = {
            name: namex || idxData?.name,
            imageURL: data?.url,
            itemIDs: itemIdsx.length >= 2 ? itemIdsx : idxData?.items?.map(i => i?._id),
          };
  
          for (const key in datax) {
          const value = datax[key];
          if (value !== "" && value !== undefined && value !== null) {
              myObj[key] = value;
          }
          }
  
          try {
              const { data } = await axiosInstance.patch(`/sale-items-clearance-groups/${idxData?._id}`, myObj);
              onClose3();
              setNamex("")
              setImageUrlx("")
              setItemIdsx([])
          } catch(error) {
              toast({
                  title: "An Error Occurred",
                  description: error?.response?.data?.message,
                  status: "error",
                  position: "top-right",
                  duration: 5000,
                  isClosable: true,
              })
          }
  
      } catch(error) {
          toast({
              title: "An Error Occurred",
              description: error?.response?.data?.message,
              status: "error",
              position: "top-right",
              duration: 5000,
              isClosable: true,
          })
      } finally {
          setLoadinge(false); 
      }
    } else {
      setLoadinge(true);
    console.log(idxData)
    try {
      

        const myObj = {};

        const datax = {
          name: namex || idxData?.name,
          imageURL: idxData?.imageURL,
          itemIDs: itemIdsx.length >= 2 ? itemIdsx : idxData?.items?.map(i => i?._id),
        };

        for (const key in datax) {
        const value = datax[key];
        if (value !== "" && value !== undefined && value !== null) {
            myObj[key] = value;
        }
        }

        try {
            const { data } = await axiosInstance.patch(`/sale-items-clearance-groups/${idxData?._id}`, myObj);
            onClose3();
            setNamex("")
            setImageUrlx("")
            setItemIdsx([])
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            })
        }

    } catch(error) {
        toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
        })
    } finally {
        setLoadinge(false); 
    }
    }
    
  };

  const deleteClearance = async () => {
    setLoadingd(true);

    try {
      const { data } = await axiosInstance.delete(`/sale-items-clearance-groups/${idx}`);
      console.log(data);
      onClose2();
      toast({
        description: "Clearance sale removed successfully",
        status: "success",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoadingd(false);
    }
  };

  const handleDelete = (id) => {
    setIdx(id);
    onOpen2();
  };

  const handleEdit = (i) => {
    setIdxData(i);
    
    onOpen3();
  };

  const handleItemsSelect = (id) => {
      // Add item to the array if it doesn't already exist
      if (!itemIds.includes(id)) {
        setItemIds([...itemIds, id]);
      } else {
      // Remove item from the array if it exists
      const filteredItems = itemIds.filter((item) => item !== id);
      setItemIds(filteredItems);
    }
  };

  const handleItemsSelectx = (id) => {
      // Add item to the array if it doesn't already exist
      if (!itemIdsx.includes(id)) {
        setItemIdsx([...itemIdsx, id]);
      } else {
      // Remove item from the array if it exists
      const filteredItems = itemIdsx.filter((item) => item !== id);
      setItemIdsx(filteredItems);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  return (
    <Box>
      <Header />
      <Box px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={"2rem"} height="77vh">
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Text
            flex={"6"}
            color="#1A1A1A"
            fontWeight={"700"}
            fontSize="1.25rem"
            fontFamily={"Clash Display"}
          >
            My Clearance Sales
          </Text>
          {clearance?.length > 0 && (
            <Button
              onClick={() => onOpen()}
              padding={".9rem 1.5rem"}
              fontFamily="Clash Display"
              borderRadius="20px"
              border="1px solid #EFEDED"
              bg="#E66F15"
              width={"fit-content"}
              color="#fff"
              fontSize={[".9rem", ".9rem"]}
              fontWeight={"600"}
            >
              <PlusSquareIcon mr={2} />
              Create new
            </Button>
          )}
        </Flex>
        {/* {alerts?.length > 0 && <Text>You will receive alerts if items that match these criteria are listed on the platform</Text>} */}
        <br />
        <br />
        {loading ? (
          <Flex marginTop={"20rem"} justifyContent={"center"}>
            <Spinner size={"lg"} color="#E66F15" />
          </Flex>
        ) : clearance?.length < 1 ? (
          <Box textAlign={"center"} marginTop="1rem">
            <Image
              src={Empty}
              transform="scale(.6) translateY(20px)"
              width={"auto"}
              margin="0 auto"
            />
            <Text color="#030303" fontFamily={"Clash Display"} fontWeight="600">
              Nothing to see here yet!
            </Text>
            <Text
              color="#666666"
              fontSize={".9rem"}
              maxW="500px"
              mx="auto"
              mt={2}
            >
              You can create groups of your listed items that you want to sell
              of quickly along with a name and a catchy image. This would be
              displayed on the homepage for prospective buyers
            </Text>
            <Flex justifyContent={"center"} mt="3rem">
              <Button
                onClick={() => onOpen()}
                padding={".9rem 1.5rem"}
                fontFamily="Clash Display"
                borderRadius="20px"
                border="1px solid #EFEDED"
                bg="#E66F15"
                width={"fit-content"}
                color="#fff"
                fontSize={[".9rem", ".9rem"]}
                fontWeight={"600"}
              >
                Create new
              </Button>
            </Flex>
          </Box>
        ) : (
          <Box maxW={"700px"} mx="auto">
            {clearance?.map(
              ({
                _id,
                user,
                name,
                imageURL,
                items,
              }) => {
                return (
                  <Flex
                    position={"relative"}
                    mb="1rem"
                    bg="#FAE2D0"
                    key={_id}
                    padding={".7rem"}
                    borderRadius={"8px"}
                    border="1px solid #E66F15"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Flex alignItems={"center"}>
                        <Box mr=".5rem" width={"100px"} height={"70px"} borderRadius={"8px"} backgroundImage={imageURL} backgroundSize={"cover"} backgroundPosition={"center"}></Box>
                        <Box>
                            <Text mb="1.5rem" fontSize={".9rem"} fontWeight={"500"} color="#333" textTransform={"capitalize"}>{name}</Text>
                            <Badge fontSize={".65rem"} colorScheme="green">{items?.length} items</Badge>
                        </Box>
                    </Flex>
                    {/* <Slider {...settings}> */}
                    <Flex width={"170px"} flexWrap={"wrap"} gap=".4rem">
                        {items?.map(({imageURLs, name, listPrice, pickUpLocation, _id, status}) => {
                            return (
                                <Box onClick={() => handleItemsSelect(_id)} cursor={"pointer"} overflow={"hidden"} borderRadius="0" width={"50px"} height={"40px"} key={_id}>
                                    <Box overflow={"hidden"} borderRadius="12px" height="100px" width={"100%"} backgroundImage={imageURLs[0]} backgroundSize="contain"></Box>
                                    <Box padding={"0 .3rem"}>
                                        <Text mt={1} fontWeight="600" fontSize={".8rem"}>{name}</Text>
                                    </Box>
                                </Box>
                            )
                        })}
                    </Flex>
                    {/* </Slider> */}
                    <Box position={"absolute"} right={"-4%"} top="0">
                      <DeleteIcon
                        onClick={() => handleDelete(_id)}
                        cursor={"pointer"}
                      />
                    </Box>
                    <Box position={"absolute"} right={"-4%"} top="30%">
                      <EditIcon
                        onClick={() => handleEdit({
                          _id,
                          user,
                          name,
                          imageURL,
                          items,
                        })}
                        cursor={"pointer"}
                      />
                    </Box>
                  </Flex>
                );
              }
            )}
          </Box>
        )}
      </Box>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Clearance Sale</ModalHeader>
          <ModalBody>
            <FormControl mt={3} mb="1rem">
              <Input
                type="text"
                outline={"none"}
                _focus={{
                  border: "1px solid #E66F15",
                  boxShadow: "1px solid #E66F15",
                  outline: "none",
                }}
                _hover={{
                  border: "1px solid #E66F15",
                  boxShadow: "1px solid #E66F15",
                }}
                border="1px solid #B0B4B0 !important"
                borderRadius={"12px"}
                padding={"1rem"}
                placeholder="Name"
                _placeholder={{ color: "#8C8C8C", fontSize: ".8rem" }}
                fontSize=".9rem"
                height={"45px"}
                onChange={e => setName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <InputGroup overflow={"hidden"}>
                <Input
                  type="text"
                  outline={"none"}
                  _focus={{
                    border: "1px solid #E66F15",
                    boxShadow: "1px solid #E66F15",
                    outline: "none",
                  }}
                  _hover={{
                    border: "1px solid #E66F15",
                    boxShadow: "1px solid #E66F15",
                  }}
                  border="1px solid #B0B4B0 !important"
                  borderRadius={"12px"}
                  padding={"1rem"}
                  placeholder="Image"
                  _placeholder={{ color: "#8C8C8C", fontSize: ".8rem" }}
                  fontSize=".9rem"
                  height={"45px"}
                  value={imageUrl?.name}
                />
                <InputRightElement
                  width={"120px"}
                  cursor={"pointer"}
                  children={
                    <Box position={"relative"} cursor={"pointer"}>
                      <Input
                        onChange={(e) => setImageUrl(e.target.files[0])}
                        cursor={"pointer"}
                        type="file"
                        opacity={"0"}
                        transform={"translate(-1.2rem, .6rem)"}
                        position={"absolute"}
                        mt="-1rem"
                        ml="1rem"
                        height={"50px"}
                      />
                      <Flex
                        cursor={"pointer"}
                        bg="lightgray"
                        mt=".25rem"
                        borderRadius={"8px"}
                        ml="-.38rem"
                        alignItems={"center"}
                        gap=".3rem"
                        padding={".5rem .6rem"}
                      >
                        <AiOutlineUpload />
                        <Text cursor={"pointer"} fontSize={".75rem"}>
                          Upload Image
                        </Text>
                      </Flex>
                    </Box>
                  }
                />
              </InputGroup>
            </FormControl>
            <Text mt="1rem" color="#666666" fontSize={".9rem"}>Click as many of your approved items that you want to add into this clearance sale</Text>
            <Box maxHeight={"240px"} overflowY={"scroll"}>
            {loadingx ? 
                                <Stack mt={6}>
                                    <Skeleton height={"40px"} />
                                    <Skeleton height={"40px"} />
                                    <Skeleton height={"40px"} />
                                    <Skeleton height={"40px"} />
                                </Stack>
                                :
                                items?.length < 1 ? 
                                <Box textAlign={"center"} marginTop="1rem">
                                    <Image src={Empty} transform="scale(.6) translateY(20px)" width={"auto"} margin="0 auto" />
                                    <Text color="#030303" fontFamily={"Clash Display"} fontWeight="600">Nothing to see here yet!</Text>
                                    <Text color="#666666" fontSize={".9rem"}>Click the sell button to list an item</Text>
                                </Box>
                                :
                                    <Flex mt={4} wrap={"wrap"} gap="1rem">
                                {items?.filter(i => i.status === "approved")?.map(({imageURLs, name, listPrice, pickUpLocation, _id, status}) => {
                                    return (
                                        // <Link to={`item-details/${_id}`}>
                                            <Box onClick={() => handleItemsSelect(_id)} cursor={"pointer"} overflow={"hidden"} border={itemIds?.includes(_id) ? "3px solid #E66F15" : "none"} borderRadius="12px" minWidth={"100px"} flex={["1", "1", "0", "0"]} key={_id}>
                                                <Box overflow={"hidden"} borderRadius="12px" height="100px" width={"100%"} backgroundImage={imageURLs[0]} backgroundSize="cover"></Box>
                                                <Box padding={"0 .3rem"}>
                                                    <Text mt={1} fontWeight="600" fontSize={".8rem"}>{name}</Text>
                                                    {/* <Flex mt={2} justifyContent={"space-between"}>
                                                        <Flex alignItems={"center"}>
                                                            <Text mt={1} whiteSpace="nowrap" color="#908888" fontSize={[".85rem", ".85rem", "1rem","1rem",]}>{pickUpLocation?.landmarkArea}</Text>
                                                        </Flex>
                                                        <Text mt={1} fontSize=".95rem" fontWeight="600">₦{listPrice?.toLocaleString()}</Text>
                                                    </Flex> */}
                                                    {/* <Text fontSize={".8rem"} fontWeight="600" color={status === "pending" ? "goldenrod" : status === "rejected" ? "#F04438" : "#12B76A"} mt={2}>{status.slice(0,1).toUpperCase() + status.slice(1)}</Text> */}
                                                </Box>
                                            </Box>
                                        // </Link>
                                    )
                                })}
                                </Flex>}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button mr={4} variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              bg="#E66F15"
              color={"#fff"}
              mr={3}
              onClick={createClearance}
            >
              {loadingc ? <Spinner /> : "Create"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>




      <Modal isCentered isOpen={isOpen3} onClose={onClose3}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Clearance Sale</ModalHeader>
          <ModalBody>
            <FormControl mt={3} mb="1rem">
              <Input
                type="text"
                outline={"none"}
                _focus={{
                  border: "1px solid #E66F15",
                  boxShadow: "1px solid #E66F15",
                  outline: "none",
                }}
                _hover={{
                  border: "1px solid #E66F15",
                  boxShadow: "1px solid #E66F15",
                }}
                border="1px solid #B0B4B0 !important"
                borderRadius={"12px"}
                padding={"1rem"}
                placeholder="Name"
                _placeholder={{ color: "#8C8C8C", fontSize: ".8rem" }}
                fontSize=".9rem"
                height={"45px"}
                defaultValue={idxData?.name}
                onChange={e => setNamex(e.target.value)}
              />
            </FormControl>
            <Flex alignItems={"center"}>
              <Box height={"40px"} width={"60px"} backgroundImage={idxData?.imageURL} backgroundSize={"contain"} borderRadius={"6px"} mr=".8rem">

              </Box>
            <FormControl>
              <InputGroup overflow={"hidden"}>
                <Input
                  type="text"
                  outline={"none"}
                  _focus={{
                    border: "1px solid #E66F15",
                    boxShadow: "1px solid #E66F15",
                    outline: "none",
                  }}
                  _hover={{
                    border: "1px solid #E66F15",
                    boxShadow: "1px solid #E66F15",
                  }}
                  border="1px solid #B0B4B0 !important"
                  borderRadius={"12px"}
                  padding={"1rem"}
                  placeholder="Image"
                  _placeholder={{ color: "#8C8C8C", fontSize: ".8rem" }}
                  fontSize=".9rem"
                  height={"45px"}
                  value={imageUrlx?.name}
                />
                <InputRightElement
                  width={"120px"}
                  cursor={"pointer"}
                  children={
                    <Box position={"relative"} cursor={"pointer"}>
                      <Input
                        onChange={(e) => setImageUrlx(e.target.files[0])}
                        cursor={"pointer"}
                        type="file"
                        opacity={"0"}
                        transform={"translate(-1.2rem, .6rem)"}
                        position={"absolute"}
                        mt="-1rem"
                        ml="1rem"
                        height={"50px"}
                      />
                      <Flex
                        cursor={"pointer"}
                        bg="lightgray"
                        mt=".25rem"
                        borderRadius={"8px"}
                        ml="-.38rem"
                        alignItems={"center"}
                        gap=".3rem"
                        padding={".5rem .6rem"}
                      >
                        <AiOutlineUpload />
                        <Text cursor={"pointer"} fontSize={".75rem"}>
                          Upload Image
                        </Text>
                      </Flex>
                    </Box>
                  }
                />
              </InputGroup>
            </FormControl>
            </Flex>
            <Text mt="1rem" color="#666666" fontSize={".9rem"}>Click as many of your approved items that you want to add into this clearance sale</Text>
            <Box maxHeight={"240px"} overflowY={"scroll"}>
            {loadingx ? 
                                <Stack mt={6}>
                                    <Skeleton height={"40px"} />
                                    <Skeleton height={"40px"} />
                                    <Skeleton height={"40px"} />
                                    <Skeleton height={"40px"} />
                                </Stack>
                                :
                                items?.length < 1 ? 
                                <Box textAlign={"center"} marginTop="1rem">
                                    <Image src={Empty} transform="scale(.6) translateY(20px)" width={"auto"} margin="0 auto" />
                                    <Text color="#030303" fontFamily={"Clash Display"} fontWeight="600">Nothing to see here yet!</Text>
                                    <Text color="#666666" fontSize={".9rem"}>Click the sell button to list an item</Text>
                                </Box>
                                :
                                    <Flex mt={4} wrap={"wrap"} gap="1rem">
                                {items?.filter(i => i.status === "approved")?.map(({imageURLs, name, listPrice, pickUpLocation, _id, status}) => {
                                    return (
                                        // <Link to={`item-details/${_id}`}>
                                            <Box onClick={() => handleItemsSelectx(_id)} cursor={"pointer"} overflow={"hidden"} border={idxData?.items.map(i => i._id).includes(_id) ? "3px solid #E66F15" : "none"} borderRadius="12px" minWidth={"100px"} flex={["1", "1", "0", "0"]} key={_id}>
                                                <Box overflow={"hidden"} borderRadius="12px" height="100px" width={"100%"} backgroundImage={imageURLs[0]} backgroundSize="cover"></Box>
                                                <Box padding={"0 .3rem"}>
                                                    <Text mt={1} fontWeight="600" fontSize={".8rem"}>{name}</Text>
                                                    {/* <Flex mt={2} justifyContent={"space-between"}>
                                                        <Flex alignItems={"center"}>
                                                            <Text mt={1} whiteSpace="nowrap" color="#908888" fontSize={[".85rem", ".85rem", "1rem","1rem",]}>{pickUpLocation?.landmarkArea}</Text>
                                                        </Flex>
                                                        <Text mt={1} fontSize=".95rem" fontWeight="600">₦{listPrice?.toLocaleString()}</Text>
                                                    </Flex> */}
                                                    {/* <Text fontSize={".8rem"} fontWeight="600" color={status === "pending" ? "goldenrod" : status === "rejected" ? "#F04438" : "#12B76A"} mt={2}>{status.slice(0,1).toUpperCase() + status.slice(1)}</Text> */}
                                                </Box>
                                            </Box>
                                        // </Link>
                                    )
                                })}
                                </Flex>}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button mr={4} variant="ghost" onClick={onClose3}>
              Cancel
            </Button>
            <Button
              bg="#E66F15"
              color={"#fff"}
              mr={3}
              onClick={editClearance}
            >
              {loadinge ? <Spinner /> : "Edit"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isCentered isOpen={isOpen2} onClose={onClose2}>
        <ModalOverlay />
        <ModalContent maxHeight={"600px"} overflow="scroll" padding={".8rem"}>
          <ModalHeader mb={6}>
            <Text
              cursor={"pointer"}
              fontSize="1.1rem"
              fontFamily="Clash Display"
              fontWeight={"700"}
              color={"#1A1A1A"}
            >
              Delete Clearance Sale?
            </Text>
            <Text mt="1rem" fontSize={".9rem"} color={"gray"}>The clearance sale group would be deleted but the individual items would still visibly exist on the platform and can be purchased by prospective buyers as normal.</Text>
          </ModalHeader>
          <ModalBody>
            <Flex gap="1.2rem" justifyContent={"flex-end"}>
              <Button
                onClick={onClose2}
                padding={".9rem 1.5rem"}
                fontFamily="Clash Display"
                borderRadius="8px"
                border="1px solid #E66F15"
                bg="#FFF"
                width={"fit-content"}
                color="#E66F15"
                fontSize={[".9rem", ".9rem"]}
                fontWeight={"600"}
              >
                Cancel
              </Button>
              <Button
                onClick={() => deleteClearance(idx)}
                padding={".9rem 1.5rem"}
                fontFamily="Clash Display"
                borderRadius="8px"
                border="1px solid #EFEDED"
                bg="#E66F15"
                width={"fit-content"}
                color="#fff"
                fontSize={[".9rem", ".9rem"]}
                fontWeight={"600"}
              >
                {loadingd ? <Spinner /> : "Delete"}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Clearance;
