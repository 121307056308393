import { Button, Flex, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, useDisclosure, useToast, Spinner, Text } from "@chakra-ui/react"
import { useState } from "react";
import axiosInstance from "../../../services/api";
import { DeleteIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const DeleteItem = ({id}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [idx, setIdx] = useState("");

    const toast = useToast()
    const navigate = useNavigate()

    const handleDelete = (id) => {
		setIdx(id)
		onOpen()
	}

    const deleteItem = async() => {
		setLoading(true)
		try {
			const {data} = await axiosInstance.delete(`/sale-items/${idx}`)
      navigate('/sell')
			toast({
                description: "Item removed successfully",
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
		} catch(error) {
			toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
		} finally {
			setLoading(false)
			onClose()
		}
	}

   return (
    <>
    <Button mb={3} onClick={() => handleDelete(id)} padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #E66F15" bg="#F04438" mt={2} width={"100%"} color="#FFF" fontSize=".9rem" fontWeight={"700"} _hover={{backgroundColor: "#FFF", color: "#F04438", borderColor: "#F04438"}}>Delist Item <DeleteIcon ml=".5rem" /></Button>

    <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg='blackAlpha.300'  />
        <ModalContent margin={"0 .5rem"} bg="#fff">
        <ModalHeader>Delete Item</ModalHeader>
        <ModalBody pt={"1rem"} pb="1.5rem">
            <Text>De-Listing this item removes it completely from our platform and would require you create again from scratch if you want it listed again.</Text>
            <Text mt=".8rem">Sure to proceed?</Text>
            <br />
            <Flex mt=".5rem" justifyContent='flex-end'>
                <Button onClick={deleteItem} colorScheme='orange'>{loading ? <Spinner /> : "Yes"}</Button>
            </Flex>
        </ModalBody>
        </ModalContent>
    </Modal>
    </>
   )
 }
 
 export default DeleteItem