import { ArrowBackIcon, ViewIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, Flex, FormControl, FormLabel, Image, Input, Skeleton, Spinner, Stack, Text, Textarea, useDisclosure, useToast } from "@chakra-ui/react"
import { useState } from "react";
import { useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import Newsletter from "../../components/newsletter/newletter";
import axiosInstance from "../../services/api";
import Heart from './hearticon.svg';
import Trending from "../forgotPassword/homeComponents/trending";
import Similar from "../../components/similar/similar";

import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
  import Illustration from './illustration.svg';
import { useSelector } from "react-redux";
import Header from "../../components/header/header";
import Congrats from "../../assets/congratulations.jpg";
import EditListing from "../listItem/editListing/editListing";
import DeleteItem from "./deleteItem/deleteItem";
import {AiOutlinePlayCircle, AiOutlineShareAlt} from 'react-icons/ai'


const ItemDetails = (props) => {
    const { id } = useParams()
    const { state, search } = useLocation()
    const query = new URLSearchParams(search);
    const type = query.get('type')

    const [updateTrigger, setUpdateTrigger] = useState(Math.random())

    const currentUser = useSelector(({userData})=>  userData?.currentUser);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
    const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure();

    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [item, setItem] = useState(null);
    const [items, setItems] = useState(null);
    const [view, setView] = useState("normal");
    const [reason, setReason] = useState("");
    const [offerAmount, setOfferAmount] = useState(null);
    const [selectedImage, setSelectedImage] = useState("");

    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        window.scrollTo(0,0)
    })

    useEffect(() => {
        axiosInstance.get(type === "free" ? `/free-items/${id}` : `/sale-items/${id}`)
        // .then(res => console.log("vvvxx", res?.data?.data))
        .then(res => setItem(res?.data?.data))
        .then(res => setSelectedImage(res?.data?.data?.imageURLs[0]))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, [updateTrigger]);

    useEffect(() => {
        // axiosInstance.get("sale-items")
        axiosInstance.get("sale-items?isAvailable=true&status=approved")
        // .then(res => console.log("vvv", res?.data?.data))
        .then(res => setItems(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, []);

    const makeOffer = async () => {
        setLoading2(true)
        const datax = {
            price: offerAmount
        }
        try {
            const {data} = await axiosInstance.post(`/sale-items/${id}/send-seller-offer`, datax)
            console.log(data)
            onOpen2()
            onClose()
        } catch(error) {
            // console.log(error?.response?.data?.message)
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoading2(false)
        }
    }

    const applyGiveaway = async () => {
        setLoading2(true)
        const datax = {
            reason
        }
        try {
            const {data} = await axiosInstance.post(`/free-items/${id}/apply`, datax)
            console.log(data)
            onOpen2()
            onClose()
        } catch(error) {
            // console.log(error?.response?.data?.message)
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoading2(false)
        }
    }

    const handleDrawer = (val) => {
        setView(val)
        onOpen()
    }

    function Resolver() {
        if(offerAmount) {
            return <Button onClick={makeOffer} type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading2 ? <Spinner /> : "Send Offer" }</Button>
        } else {
            return <Button onClick={makeOffer} disabled type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading2 ? <Spinner /> : "Send Offer" }</Button>
        }
    }
    function Resolverx() {
        if(reason) {
            return <Button onClick={applyGiveaway} type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading2 ? <Spinner /> : "Apply" }</Button>
        } else {
            return <Button disabled type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading2 ? <Spinner /> : "Apply" }</Button>
        }
    }

    const handleShare = (id) => {
        if (navigator.share) {
            navigator
              .share({
                title: "Item on Declutters App",
                url: `https://decluttersapp.com/item-details/${id}`,
              })
              .then(() => {
                // console.log("Thanks for sharing!");
              })
              .catch(console.error);
          } else {
            navigator.clipboard.writeText(`https://decluttersapp.com/item-details/${id}`)
            toast({
                description: "Item Link Copied",
                status: "info",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
          }
    }

    return (
        <Box>
            <Header />
            <Box px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={["1.5rem", "1.5rem", "2rem", "2rem"]}>
                {loading ? 
                // <Flex marginTop={"10rem"} marginBottom={"10rem"} justifyContent={"center"}>
                //     <Spinner size={"lg"} color="#E66F15" />
                // </Flex>
                // :
                <Flex height={"600px"} justifyContent={"space-between"} gap="2rem">
                    <Skeleton flex={"3"} borderRadius={"12px"} height="580px"></Skeleton>
                    <Stack flex={"2"}>
                        <Skeleton mt={2} height={"30px"} />
                        <br />
                        <Skeleton mt={3} height={"30px"} />
                        <Skeleton mt={3} height={"30px"} />
                        <br />
                        <Skeleton mt={3} height={"30px"} />
                        <br />
                        <br />
                        <br />
                        <Skeleton mt={3} height={"30px"} />
                        <Skeleton mt={3} height={"30px"} />
                        <Skeleton mt={3} height={"30px"} />
                        <br />
                        <br />
                        <br />
                        <Skeleton mt={3} height={"30px"} />
                        <Skeleton mt={3} height={"30px"} />
                        <Skeleton mt={3} height={"30px"} />
                    </Stack>
                </Flex>
                :
                <>
                {currentUser ?
                item?.winner?._id === currentUser?._id &&
                 <Box>
                    <Box>
                        <Box mb=".6rem" height={"140px"} bg="lightgray" backgroundImage={Congrats} backgroundSize={"cover"} backgroundPosition={"center"} textAlign={"center"} pt="2rem">
                            
                        </Box>
                        <Text>You have been selected as the winner for this item!🥳</Text>
                        <Divider my=".5rem" />
                    </Box>
                    {item?.isGiverAnonymous ? 
                    <>
                    <Text>The Giver has chosen to be anonymous and therefore our logistics partner <strong onClick={() => window.open('https://wehaul247.com', '_blank')} style={{color: "#E66F15", textDecoration: "underline", cursor: "pointer"}}>WEHAUL 247</strong> would be responsible for retrieving and delivering the item to you.</Text>
                    <a target="_blank" href={"https://wa.me/" + "2349160000687" + "?text=Hi, I was selected as winner of the giveaway item with ID " + `${id}` + " from Declutters and would like to claim and have it delivered"} data-action="share/whatsapp/share">
                    <Button padding={"1.3rem 1.5rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} color="#fff" fontSize=".9rem" fontWeight={"700"}>Claim Item</Button>
                    </a>
                    </>
                    :
                    <>
                        <Text mb=".1rem">You can contact and reach the giver with these details to claim the item.</Text>
                        <Text>Address: <strong>{item?.pickUpAddress}</strong></Text>
                        <Text>Phone: <strong>{item?.giver?.phoneNumber}</strong></Text>

                        <a target="_blank" href={"https://wa.me/" + "2349160000687" + "?text=Hi, I was selected as winner of the giveaway item with ID " + `${id}` + " from Declutters and would like to claim and have it delivered"} data-action="share/whatsapp/share">
                        <Text marginTop={"1rem"} width={"max-content"} fontSize="1rem" color="#666666" fontWeight={"500"}>Want this item delivered instead ? <strong style={{color: "#E66F15", cursor: "pointer"}}>Request Delivery</strong></Text>
                        </a>
                    </>
                    }
                    <br />
                    <br />
                </Box> : null}
                <Flex justifyContent={"space-between"} gap="2rem" flexWrap={"wrap"}>
                    <Box position={"relative"} flex={"3"} flexBasis="350px" height={["350px", "350px", "570px", "570px"]} bg="red" borderRadius={"12px"} backgroundColor="#D9D9D9" backgroundImage={selectedImage ? selectedImage : item?.imageURLs[0]} backgroundSize={"cover"}>
                        <Image cursor={"pointer"} position={"absolute"} top="6%" right={"5%"} padding={".7rem .6rem .5rem.6rem"} borderRadius="50%" transform={"scale(.85)"} background={"#999"} src={Heart} />
                        <Flex position={"absolute"} bottom="7%" left={"5%"} gap=".6rem" alignItems={"center"}>
                            {item?.imageURLs?.map((i, index) => {
                                return (
                                    <Box cursor={"pointer"} onClick={() => setSelectedImage(i)} key={index} height={i === item?.imageURLs[0] ? "55px" : "45px"} width={i === item?.imageURLs[0] ? "55px" : "45px"} opacity={i === item?.imageURLs[0] ? 1 : .7} borderRadius="50%" bg="gray" border="1px solid #fff" backgroundImage={i} backgroundSize="cover"></Box>
                                )
                            })}
                                {item?.videoURL !== "https://wallpaperaccess.com/full/676550.jpg" && 
                                <Box cursor={"pointer"} position={"relative"} overflow={"hidden"} onClick={onOpen3} height={"45px"} width={"45px"} opacity={.7} borderRadius="50%" bg="gray" border="1px solid #fff" backgroundImage={item?.imageURLs[0]} backgroundSize="cover">
                                    <Box position={"absolute"} top={"0"} bottom={"0"} left="0" right={"0"} bg="blackAlpha.700">
                                        <AiOutlinePlayCircle style={{marginTop: "14px", marginLeft: "14px", transform: "scale(1.3)", fill: "#FFF", stroke: "#FFF"}} />
                                    </Box>
                                </Box>}
                        </Flex>
                    </Box>
                    <Box flex={"2"} flexBasis="350px" textTransform={"capitalize"}>
                        <Flex alignItems={"center"} justifyContent={"space-between"}>
                            <Text fontFamily={"Clash Display"} fontWeight="700" fontSize={"1.2rem"}>{item?.name}</Text>
                            <Text onClick={() => handleShare(item?._id)} cursor={"pointer"} fontWeight={"500"}>Share Item <AiOutlineShareAlt style={{display: "inline", transform: "translateY(5px)", fontSize: "1.3rem"}} /></Text>
                        </Flex>
                        <Text mt={6} fontWeight="400">{item?.description}</Text>
                        <Flex mt={6} alignItems={"center"} justifyContent="space-between">
                            <Text fontSize={"1.1rem"} fontWeight="700">{item?.listPrice ? "₦" : null}{item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted").length > 0 ? item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted")[0].listPrice?.toLocaleString() : item?.listPrice ? item?.listPrice?.toLocaleString() : "Free"}</Text>
                            {item?.listPrice && <Text fontSize={"1.1rem"} fontWeight="700"><ViewIcon /> {item?.views}</Text>}
                        </Flex>
                        <br />
                        <br />
                        {
                        item?.seller?._id !== currentUser?._id ?
                        <>
                        {item?.listPrice ? <Link to={currentUser ? `/checkout/${id}` : "/login"}><Button mb={3} padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"100%"} color="#fff" fontSize=".9rem" fontWeight={"700"}>Buy Now</Button></Link> : <Button isDisabled={item?.entrants.filter(i => i.user === currentUser?._id).length > 0} onClick={() => handleDrawer("giveaway")} mb={3} padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"100%"} color="#fff" fontSize=".9rem" fontWeight={"700"}>{item?.entrants.filter(i => i.user === currentUser?._id).length > 0 ? "Applied" : "Apply for giveaway"}</Button>}
                        {item?.isNegotiable && <Button onClick={currentUser ? () => handleDrawer("normal") : () => navigate("/login")} mb={3} padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #E66F15" bg="#FFF" mt={2} width={"100%"} color="#E66F15" fontSize=".9rem" fontWeight={"700"}>Make me an offer</Button>}
                        {/* <Button mb={3} padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #E66F15" bg="#FFF" mt={2} width={"100%"} color="#E66F15" fontSize=".9rem" fontWeight={"700"}>Add to Cart</Button> */}
                        </>
                        :
                        <>
                        {currentUser && item?.seller?._id === currentUser?._id && <EditListing item={item} setUpdateTrigger={setUpdateTrigger} />}
                        {currentUser && item?.seller?._id === currentUser?._id && <DeleteItem id={id} />}
                        </>
                        }
                        <Box mt={7} fontWeight={"500"}>
                            <Text mb={1} color="#8C8C8C">Condition</Text>
                            <Text color="#1A1A1A">{item?.condition}</Text>
                        </Box>
                        <Box mt={5} fontWeight={"500"}>
                            <Text mb={1} color="#8C8C8C">Location</Text>
                            <Text color="#1A1A1A">{item?.pickUpLocation?.landmarkArea}</Text>
                        </Box>
                        <Box mt={5} fontWeight={"500"}>
                            <Text mb={1} color="#8C8C8C">Seller's Ratings</Text>
                            <Text color="#1A1A1A">No reviews for this seller yet</Text>
                        </Box>
                    </Box>
                </Flex>
                </>}
                <br />
                <br />
                {typeof(state) === "object" && <Similar data={state} category={"null"} id={id} />}
                <br />
                <Similar data={items} category={item?.category} id={id} />
            </Box>
            <Newsletter />


            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                size="md"
            >
                <DrawerOverlay />
                <DrawerContent>
                {/* <DrawerCloseButton /> */}
                <DrawerHeader>
                    <br />
                    <Text onClick={() => onClose()} cursor={"pointer"} flex={"1"} fontWeight={"600"} color={"#1A1A1A"} fontSize=".9rem"><ArrowBackIcon transform={"translate(-4px,-1px)"} />Back</Text>
                </DrawerHeader>
                <DrawerBody>
                    {view  === "normal" &&
                        <>
                    <Text mt={3} fontFamily={"Clash Display"} fontWeight="700" fontSize={"1.2rem"}>Make an Offer</Text>
                    <Text mt={5} fontWeight="400">Make sure to set a fair price for the seller, and move the item to your cart once the seller accepts. <br /> Also NOTE that you are restricted to two(2) total offers for an item and cannot re-offer if your pending offer has not been accepted or rejected.</Text>
                    <Flex mt={10} bg="#FAE2D0" borderRadius="12px" gap="1rem" alignItems={"center"} padding="1.5rem 1.2rem">
                        <Box height="60px" width="60px" backgroundImage={item?.imageURLs[0]} backgroundSize="cover" borderRadius="12px"></Box>
                        <Box>
                            <Text mb={2} fontWeight="500" color="#1A1A1A">{item?.name}</Text>
                            <Text fontWeight="700" color="#1A1A1A">₦{item?.listPrice?.toLocaleString()}</Text>
                        </Box>
                    </Flex>
                    <br />
                    <FormControl mt={3}>
                        <FormLabel transform={"translateY(2px)"} fontWeight="700" fontFamily='Creato Display' fontSize=".9rem">Amount you’re offering</FormLabel>
                        <Input type={"email"} onChange={e => setOfferAmount(parseInt(e.target.value))} padding={"1.3rem 1rem"} placeholder="Enter an Amount" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" borderRadius={"20px"} />
                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Resolver />
                    </>}
                    {view  === "giveaway" &&<>
                    <Text mt={3} fontFamily={"Clash Display"} fontWeight="700" fontSize={"1.2rem"}>Apply for Giveaway</Text>
                    <Text mt={5} fontWeight="400">Give the owner a reason to gift you this item, and we'll notify you if they do.</Text>
                    <Flex mt={10} bg="#FAE2D0" borderRadius="12px" gap="1rem" alignItems={"center"} padding="1.5rem 1.2rem">
                        <Box height="60px" width="60px" backgroundImage={item?.imageURLs[0]} backgroundSize="cover" borderRadius="12px"></Box>
                        <Box>
                            <Text mb={2} fontWeight="500" color="#1A1A1A">{item?.name}</Text>
                            <Text fontWeight="700" color="#1A1A1A">Free</Text>
                        </Box>
                    </Flex>
                    <br />
                    <FormControl mt={3}>
                        {/* <FormLabel transform={"translateY(2px)"} fontWeight="700" fontFamily='Creato Display' fontSize=".9rem">Amount you’re offering</FormLabel> */}
                        <Textarea type={"email"} onChange={e => setReason(e.target.value)} padding={"1.3rem 1rem"} rows="8" placeholder="Enter Reason" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" borderRadius={"10px"} />
                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Resolverx />
                    </>}
                </DrawerBody>
                </DrawerContent>
            </Drawer>

            <Modal isCentered isOpen={isOpen2} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                <ModalCloseButton onClick={() => onClose2()} />
                <ModalBody>
                    <Image width={"30%"} margin="0 auto" src={Illustration} />
                    <Text mt={3} fontFamily='Clash Display' color="#030303" fontWeight={"700"} fontSize="1.2rem" textAlign={"center"}>{item?.listPrice ? "Offer successfully sent!" : "Application successfully sent"}</Text>
                    <Text mt={2} color="#908888" fontFamily='Clash Display' textAlign={"center"}>{item?.listPrice ? "Your offer has been sent to the seller.  We’ll notify you if the seller accepts or declines your offer." : "Your application has been sent to the giver.  We’ll notify you if the giver accepts your request."}</Text>
                    <Flex mt={6} justifyContent={"center"}>
                        <Button onClick={() => onClose2()} fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width={400} color="#fff" fontSize=".9rem" fontWeight={"700"}>Continue</Button>
                    </Flex>
                    <br />
                </ModalBody>
                </ModalContent>
            </Modal>
            <Modal isCentered isOpen={isOpen3} onClose={onClose3}>
                <ModalOverlay />
                <ModalContent mx=".5rem" maxw="500px">
                <ModalCloseButton onClick={() => onClose3()} />
                <ModalBody>
                    <Text transform={"translateY(14px)"} fontWeight={"600"} fontSize={"1.2rem"}>Video Preview</Text>
                    <br />
                    <br />
                    <video width={"100%"} src={item?.videoURL} controls></video>
                    <br />
                </ModalBody>
                </ModalContent>
            </Modal>



            
            
        </Box>
    )
}

export default ItemDetails;