import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import Pay from './pay.svg';
import Cash from './cash.svg';
import { useSelector } from "react-redux";
import axiosInstance from "../../services/api";
import capitalizeFirstLetter from "../../utils/capitalize";
import moment from "moment";
import Header from "../../components/header/header";


const Wallet = () => {
    const [loading, setLoading] = useState(true);
    const [loadingxx, setLoadingxx] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [modalType, setModalType] = useState("");
    const [details, setDetails] = useState({});

    const currentUser = useSelector(({userData})=>  userData?.currentUser);

    const [orders, setOrders] = useState();


    useEffect(() => {
        axiosInstance.get("/transactions/user")
        // .then(res => console.log("vvvxx", res?.data?.data))
        .then(res => setOrders(res?.data?.data))
        .then(res => setLoadingxx(false))
        .catch(error => console.log(error))
    }, [])

    const handleModal = (status, details) => {
        setModalType(status)
        setDetails(details)
        onOpen()
    }

    const [payments, setPayments] = useState([]);
    const [loadingx, setLoadingx] = useState(true)

    useEffect(() => {
        axiosInstance.get("/payments/user")
        // .then(res => console.log("payments", res?.data?.data))
        .then(res => setPayments(res?.data?.data))
        .then(res => setLoadingx(false))
        .catch(error => console.log(error))
    }, []);


    return (
        <Box>
            <Header />
            <Box px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={["1rem", "1rem", "2rem", "2rem"]}>
                <Flex alignItems={"center"} whiteSpace="nowrap" mb={10}>
                    <Link to="/sell"><Text cursor={"pointer"} flex={"1"} fontWeight={"600"} color={"#1A1A1A"}><ArrowBackIcon transform={"translate(-4px,-1px)"} />Back</Text></Link>
                    <Text ml={10} color="#1A1A1A" fontWeight={"700"} fontSize="1.4rem" fontFamily={"Clash Display"}>Payments</Text>
                </Flex>
                <Box maxWidth="700px" margin="0 auto">
                    <Box textAlign={"center"} marginBottom="1rem">
                        <Text color={"#1A1A1A"} fontWeight="500">Amount Made</Text>
                        <Text fontWeight="400" fontSize={"1.6rem"} fontFamily="Clash Display">₦ <strong style={{fontFamily: "Clash Display"}}>{!loadingx && orders?.filter(i => i.status === "approved" && !i.isSellerCredited)?.reduce((accumulator, object) => { return accumulator + object.sellerPrice}, 0)?.toLocaleString()}</strong></Text>
                    </Box>
                    <br />
                    <Tabs>
                        <TabList borderBottom={"none"} display={"flex"} justifyContent="space-around">
                            <Tab _selected={{borderBottom: "2px solid #E66F15", color: "#1A1A1A"}} fontWeight="500" fontSize={"1.1rem"} color="#666666">Pending Payments</Tab>
                            <Tab _selected={{borderBottom: "2px solid #E66F15", color: "#1A1A1A"}} fontWeight="500" fontSize={"1.1rem"} color="#666666">Completed Payments</Tab>
                        </TabList>
                        <br />
                        {/* <Text mt={8} fontSize={".85rem"} color="#2E1604" fontWeight="500">Friday, 11th Nov 2022</Text> */}
                        <TabPanels>
                            <TabPanel padding={".8rem"}>
                                {orders?.filter(i => i.status === "approved" && !i.isSellerCredited).map(({status, sellerPrice, createdAt, type, item}, index) => {
                                    return (
                                        <Flex 
                                        // onClick={() => handleModal(status, {status, sellerPrice, createdAt, type, item})} 
                                        cursor={"pointer"} bg="#fff" mb={3} borderRadius={"12px"} border="1px solid #EAEAEA" padding={"1rem"} key={index} alignItems="center" justifyContent={"space-between"}>
                                            <Flex alignItems="center">
                                                <Image src={Pay} mr={3} />
                                                <Box>
                                                    <Text textTransform={"capitalize"} color={"#1A1A1A"} fontWeight="500" fontSize=".95rem" mb={1}>{item?.name}</Text>
                                                    <Text color={"#8C8C8C"} fontSize=".85rem">{moment(createdAt).calendar()}</Text>
                                                </Box>
                                            </Flex>
                                            <Text borderRadius={"12px"} bg={status === "approved" ? "goldenrod" :"#F04438"} color="#FFF" fontWeight={"600"} fontSize=".85rem" padding={".2rem .5rem"}>₦{sellerPrice?.toLocaleString()}</Text>
                                        </Flex>
                                    )
                                })}
                            </TabPanel>
                            <TabPanel padding={".8rem"}>
                            {payments?.map(({status, amount, createdAt, type, item}, index) => {
                                    return (
                                        <Flex onClick={() => handleModal(status, {status, amount, createdAt, type, item})} cursor={"pointer"} bg="#fff" mb={3} borderRadius={"12px"} border="1px solid #EAEAEA" padding={"1rem"} key={index} alignItems="center" justifyContent={"space-between"}>
                                            <Flex alignItems="center">
                                                <Image src={Pay} mr={3} />
                                                <Box>
                                                    <Text color={"#1A1A1A"} fontWeight="500" fontSize=".95rem" mb={1}>{capitalizeFirstLetter(type)} for {item?.name}</Text>
                                                    <Text color={"#8C8C8C"} fontSize=".85rem">{moment(createdAt).calendar()}</Text>
                                                </Box>
                                            </Flex>
                                            <Text borderRadius={"12px"} bg={status === "pending" ? "goldenrod" : status === "success" ? "#12B76A" :"#F04438"} color="#FFF" fontWeight={"600"} fontSize=".85rem" padding={".2rem .5rem"}>₦{amount?.toLocaleString()}</Text>
                                        </Flex>
                                    )
                                })}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </Box>
            <br />
            <br />
            <br />
            <br />
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius={"30px"} margin="0 .8rem">
                    <ModalCloseButton />
                    <ModalHeader></ModalHeader>
                    <ModalBody>
                        <Box textAlign={"center"} mb={6}>
                            <Image transform={"translateY(-10px)"} width={"auto"} margin="0 auto" src={Cash} />
                            <Text mt={1} color={"#1A1A1A"} fontFamily="Clash Display" fontWeight={"700"} fontSize="1.1rem">{modalType === "pending" ? "Payment Pending" : "Payment Successful"}</Text>
                            <Text color={"#8C8C8C"} fontSize=".9rem" fontWeight={"500"}><strong style={{color: "#1A1A1A"}}>₦{details?.amount?.toLocaleString()}</strong> {modalType === "pending" ? "will be paid into your Settlement account" : "has been sent to your settlement account"}</Text>
                        </Box>
                        <Box maxW={"320px"} margin="0 auto">
                            <Flex mb={2} alignItems={"center"} justifyContent="space-between">
                                <Text fontSize=".9rem" color={"#1A1A1A"} fontWeight="500">Payout Account</Text>
                                <Text color={"#1A1A1A"} fontWeight="600">******{currentUser?.bankAccount?.accountNumber?.slice(6)}</Text>
                            </Flex>
                            <Flex mb={2} alignItems={"center"} justifyContent="space-between">
                                <Text fontSize=".9rem" color={"#1A1A1A"} fontWeight="500">Amount</Text>
                                <Text color={"#1A1A1A"} fontWeight="600">₦{details?.amount?.toLocaleString()}</Text>
                            </Flex>
                            <Flex mb={6} alignItems={"center"} justifyContent="space-between">
                                <Text>Item</Text>
                                <Text color={"#1A1A1A"} fontWeight="600">{details?.item?.name}</Text>
                            </Flex>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default Wallet;