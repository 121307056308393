import './App.css';
import Navbar from './components/navbar/navbar';
import Home from './pages/home';
import {Routes, Route} from "react-router-dom"
import { useEffect, useState } from 'react';
import { loaduser } from './redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, useDisclosure } from '@chakra-ui/react';
import SignUp from './pages/signup/signUp';
import SuccessScreen from './components/successScreen/successScreen';
import Login from './pages/login/login';
import ForgotPassword from './pages/forgotPassword/forgotPassword';
import ForgotPasswordNew from './pages/forgotPassword/forgotPasswordNew';
import TrendingPage from './pages/trending/trending';
import Footer from './components/footer/footer';
import { userActionTypes } from './redux/constants/userActionTypes';
import Settings from './pages/settings/settings';
import Help from './pages/settings/components/help';
import ListItem from './pages/listItem/listItem';
import Rating from './pages/ratings/ratings';
import Faqs from './pages/Faqs/faqs';
import ItemDetails from './pages/itemDetails/itemDetails';
import Catalog from './pages/catalog/catalog';
import Checkout from './pages/checkout/checkout';
import Orders from './pages/orders/orders';
import OrderDetails from './pages/orders/orderDetails';
import Dispute from './pages/dispute/dispute';
import CreateDispute from './pages/dispute/createDispute/createDispute';
import CustomerSupport from './pages/customerSupport/customerSupport';
import SavedItems from './pages/savedItems/savedItems';
import Wallet from './pages/wallet/wallet';
import Offers from './pages/offers/offers';
import Search from './pages/search/search';
import DisputeThread from './pages/dispute/disputeThread';
import EmailVerification from './pages/emailVerification/emailVerification';
import NewlyListed from './pages/newlyListed/newlyListed';
import TodaysDeals from './pages/todaysDeals/todaysDeals';
import AllGiveawayItems from './pages/giveaways/allGiveawayItems';
import UserGiveaways from './pages/giveaways/userGiveaway';
import ListGiveawayItem from './pages/giveaways/listGiveawayItem/listItem';
import Explore from './pages/explore/explore';
import Wehaul from './pages/wehaul/wehaul';
import New from './pages/new/new';
import StaffPicks from './pages/staffPicks/staffPicks';
import AlertMe from './pages/alertMe/alertMe';
import PaySuccess from './pages/appPaymentSuccess/paySuccess';
import Clearance from './pages/clearance/clearance';
import ClearanceSales from './pages/clearanceSales/clearanceSales';
import ClearanceSaleDetails from './pages/clearanceSales/clearanceSaleDetails/clearanceSaleDetails';
import AllItems from './pages/alItems/allItems';
import Logo1 from './assets/logo1.png'
import Logo2 from './assets/prod.png'
import Google from '././components/footer/google.svg';
import Appstore from '././components/footer/appstore.svg';
// import WhatsAppWidget from 'react-whatsapp-chat-widget';
// import "react-whatsapp-chat-widget/index.css";



function App() {
  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const loading = useSelector(({userData})=>  userData?.isLoading);
  const currentUser = useSelector(({userData})=>  userData?.currentUser);
  // console.log("currentUser", loading)

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    // console.log(userAgent)
    setIsMobile(/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent));
  }, []);

  useEffect(() => {
    const lastModalDate = localStorage.getItem('lastModalDate');
    const now = new Date().getTime();
    const threeDaysInMilliseconds = 1 * 24 * 60 * 60 * 1000; // 3 days in milliseconds

    if ((!lastModalDate || now - lastModalDate >= threeDaysInMilliseconds)) {
      onOpen();
      localStorage.setItem('lastModalDate', now);
    }
  }, [onOpen]);

  useEffect(() => {
    if(localStorage.DCV1TK) {
      dispatch(loaduser())
    } else {
      dispatch({type: userActionTypes.LOADUSER_FAIL, payload: null})
    }
  }, [])

  return (
    <>
    {loading ? 
    <Flex marginTop={"20rem"} justifyContent={"center"}>
      <Spinner size={"lg"} color="#E66F15" />
    </Flex>
    :
    <div className="App">
      {/* <Navbar /> */}
      {/* <Box padding={"0rem 5rem"}> */}
        <Routes>
          <Route path='/'  element={currentUser?.isPasswordAutoGenerated ? <ForgotPasswordNew /> : <Home />} />
          <Route path='/explore/:category'  element={<Explore />} />
          <Route path='/trending'  element={<TrendingPage />} />
          <Route path='/recent'  element={<NewlyListed />} />
          <Route path='/all'  element={<AllItems />} />
          <Route path='/brand-new'  element={<New />} />
          <Route path='/todays-deals'  element={<TodaysDeals />} />
          <Route path='/admin-picks'  element={<StaffPicks />} />
          <Route path='/giveaway-items'  element={<AllGiveawayItems />} />
          <Route path='/clearance-sales'  element={<ClearanceSales />} />
          <Route path='/clearance-sales/:id'  element={<ClearanceSaleDetails />} />
          <Route path='/settings'  element={<Settings />} />
          <Route path='/orders'  element={<Orders />} />
          <Route path='/offers'  element={<Offers />} />
          <Route path='/giveaway'  element={<UserGiveaways />} />
          <Route path='/saved-items'  element={<SavedItems />} />
          <Route path='/orders/:id'  element={<OrderDetails />} />
          <Route path='/orders/:id/dispute'  element={<Dispute />} />
          <Route path='/orders/dispute-thread/:did'  element={<DisputeThread />} />
          <Route path='/orders/:id/dispute/create/:issue'  element={<CreateDispute />} />
          <Route path='/list-item'  element={<ListItem />} />
          <Route path='/list-giveaway-item'  element={<ListGiveawayItem />} />
          <Route path='/sell'  element={<Catalog />} />
          <Route path='/wallet'  element={<Wallet />} />
          <Route path='/item-details/:id'  element={<ItemDetails />} />
          <Route path='/checkout/:id'  element={<Checkout />} />
          <Route path='/ratings'  element={<Rating />} />
          <Route path='/faqs'  element={<Faqs />} />
          <Route path='/alert-me'  element={<AlertMe />} />
          <Route path='/clearance'  element={<Clearance />} />
          <Route path='/support'  element={<CustomerSupport />} />
          <Route path='/help'  element={<Help />} />
          <Route path='search/:keyword' element={<Search />} />
          {/* <Route path='/:id' element={<PickView />} /> */}
          <Route path='/signup' element={<SignUp />} />
          <Route path='/signup/verify/:email' element={<EmailVerification />} />
          <Route path='/login' element={<Login />} />
          <Route path='/reset-password' element={<ForgotPassword />} />
          <Route path='/reset-password/new' element={<ForgotPasswordNew />} />
          <Route path='/success' element={<SuccessScreen />} />
          <Route path='/wehaul/:id' element={<Wehaul />} />
          <Route path='/payment-success' element={<PaySuccess />} />
        </Routes>
        {/* <Button onClick={() => console.log(isMobile)}>test</Button> */}
        {/* <Button onClick={() => onOpen()}>test</Button> */}
        <Footer />
      {/* </Box> */}

      {/* {isMobile && ( */}
        <Modal size={"lg"} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton bg="#fff" />
            <ModalBody padding={"2.5rem 1.5rem"}>
              <Image src={Logo1} width={"12%"} />
              <Text textAlign={"center"} maxW={"350px"} mx="auto" fontFamily={"Clash Display"} lineHeight={"46px"} fontSize={"2.5rem"} fontWeight={"600"}>Get the Declutters App now!</Text>
              <Text mb=".5rem" textAlign={"center"} mt="1.2rem" maxW={"400px"} mx="auto" fontFamily={"Clash Display"} lineHeight={"30px"} fontSize={"1.3rem"} fontWeight={"400"}>Find that household item you're looking for quickly & easily</Text>
              <Image src={Logo2} />
              {/* <Flex justifyContent={"center"}> */}
                <Flex mt="1rem" alignItems={"center"} gap="1rem" justifyContent={"center"}>
                      <Image onClick={() => window.open('https://play.google.com/store/apps/details?id=com.declutters.decluttersmobileapp', "_blank") || window.location.replace('https://play.google.com/store/apps/details?id=com.declutters.decluttersmobileapp')} width={"28%"} cursor={"pointer"} _hover={{transform: "scale(.9)"}} transition="250ms ease" src={Google} />
                      <Image onClick={() => window.open('https://apps.apple.com/ng/app/declutters-app/id6448369072', "_blank") || window.location.replace('https://apps.apple.com/ng/app/declutters-app/id6448369072')} width={"28%"} cursor={"pointer"} _hover={{transform: "scale(.9)"}} transition="250ms ease" src={Appstore} />
                </Flex>
                <Text onClick={() => onClose()} textAlign={"center"} mt="1.3rem" fontSize={".9rem"} cursor={"pointer"} textDecoration={"underline"} color={"#E66F15"} fontWeight={"500"}>Skip for now</Text>
              {/* </Flex> */}
              {/* <Image src={Mobile} />
              <Box height={["500px", "600px"]} width={"100%"} backgroundImage={Mobile} backgroundSize={"contain"} backkgroundPosition="right" backgroundRepeat={"no-repeat"}>
              </Box>
              <a href="https://apps.apple.com/ng/app/declutters-app/id6448369072" target="_blank" rel="noopener noreferrer"><Box cursor={"pointer"} position={"absolute"} bottom={["13%", "7.5%"]} left={["26%", "31.5%"]} height={"60px"} width="90px" borderRadius={"4px"}></Box></a>
              <a href="https://play.google.com/store/apps/details?id=com.declutters.decluttersmobileapp" target="_blank" rel="noopener noreferrer"><Box cursor={"pointer"} position={"absolute"} bottom={["13%", "7.5%"]} right={["26%", "31.5%"]} height={"60px"} width="90px" borderRadius={"4px"}></Box></a> */}
            </ModalBody>
          </ModalContent>
        </Modal>
      {/* )} */}
    </div>
   }
    </>
  );
}

export default App;
