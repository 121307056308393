import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, FormControl, FormHelperText, FormLabel, Heading, Image, Input, InputGroup, InputLeftAddon, InputLeftElement, InputRightElement, Spinner, Text, useToast } from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Google from '../../assets/google.svg';
import Instagram from '../../assets/instagram.svg';
import Navbar2 from "../../components/navbar2/navbar2";
import baseUrl from "../../config";
import axiosInstance from "../../services/api";
import { GoogleLogin } from '@react-oauth/google';
import { useDispatch } from "react-redux";
import { userActionTypes } from "../../redux/constants/userActionTypes";

const SignUp = () => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [noFill, setNoFill] = useState("");

    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function Resolver() {
        if(firstName && lastName && email && password) {
            return <Button type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Sign Up" }</Button>
        } else {
            return <Button disabled type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Sign Up" }</Button>
        }
    }

    const datax = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phone.charAt(0) === "0" ? "+234" + phone.slice(1) : "+234" + phone,
        password: password
    }
    const datax2 = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password
    }

    const signUp = async(e) => {
        e.preventDefault();
        setLoading(true)
        if(noFill === "") {
            // console.log(datax)
            try {
                const {data} = await axiosInstance.post("auth/sign-up", phone ? datax : datax2)
                navigate(`verify/${email}`)
                toast({
                    description: data?.message,
                    status: "success",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                });
            } catch(error) {
                toast({
                    title: "An Error Occurred",
                    description: error?.response?.data?.message,
                    status: "error",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setLoading(false)
            }
        } else {
            toast({
                title: "An Error Occurred",
                description: "This platform cannot be accessed by a spam bot.",
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
            setLoading(false)
        }
    }

    const googleVerify = async(e) => {
        e.preventDefault();
        setLoading(true)
        const datax = {
            idToken: "eyJhbGciOiJSUzI1NiIsImtpZCI6ImY0MTk2YWVlMTE5ZmUyMTU5M2Q0OGJmY2ZiNWJmMDAxNzdkZDRhNGQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiOTIyMTE5MDY2MjE3LXVjczByZWZpaGswZThxYWMyZmRxMjRtaGtqdTQybzl1LmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiOTIyMTE5MDY2MjE3LXVjczByZWZpaGswZThxYWMyZmRxMjRtaGtqdTQybzl1LmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTE0ODc1MjUxNjQ1MjcwNjg2MTQzIiwiZW1haWwiOiJsaWFtYWNlMDA3QGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiNmNkbERFLWwtdmZfRWdMeERORVZIZyIsIm5hbWUiOiJMaWFtIE51c3NpZSIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQVRYQUp4TEZRdzd6QjRyQWhqZmM3WnVyNlpCTGJydTE2eTNPTV9KdHhIZj1zOTYtYyIsImdpdmVuX25hbWUiOiJMaWFtIiwiZmFtaWx5X25hbWUiOiJOdXNzaWUiLCJsb2NhbGUiOiJlbiIsImlhdCI6MTYzMzUzNjQ2MSwiZXhwIjoxNjMzNTQwMDYxLCJqdGkiOiIyZDE1NTZiMzZlYzYzNDc0Y2U4OTkyZDhlNDE5MDgyMzdmOWIwNTI3In0.hfETdK2ArwLzocNQ1VUoBQ5k273daQ8wRHd9PYACsZB2VwSTDYW1QGicIF9PXGpCupaDQiFv3RIfYQvkBGTnztrmOaJEY-1D0mwf2f2Dq17OJvmjV7Y446N6p3Ief7zSGofO0XEuPf0ovA64UV9e1A5iMpiuCmse0pqvkAT9Vbqd8wCmLyibgEHspRpTv9qFMZ-QsSbGIrS4YDeSH533cpe3tvL_vU97r-HescsKiCZmiaN9vMl4FvlDTIiqV1IezuqZ-Vca-uPpvcPyxgIFVcHwN3_gyyqvGRVeJbozkjGR1k5stxu_LEI7IoTtzY9yjB6uurxjAqVPh8PYbf_LvA"
        }
        try {
            const {data} = await axiosInstance.post("auth/google", datax)
            console.log("test",data)
            toast({
                // title: "An Error Occurred",
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } catch(error) {
            // console.log(error?.response?.data?.message)
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoading(false)
        }
    }


    const googleResponse = async (response) => {
        // console.log(response?.credential);
        const datax = {
          "idToken": response?.credential,
        }
        try {
          const {data} = await axiosInstance.post("auth/google", datax)
        //   console.log("test",data)
            localStorage.setItem("DCV1TK", data?.data?.token);
            dispatch({type: userActionTypes.SIGNIN_SUCCESS, payload: data?.data?.user})
            navigate("/")
        //   window.location.reload();
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
        //   console.log(error?.response?.data?.message)
        }
      }

 
    return (
        <Box>
            <Navbar2 />
            <Box maxWidth={"500px"} padding="3rem .8rem" margin="0 auto 20rem auto">
                <Heading fontWeight={700} fontSize="1.4rem" color="#030303" fontFamily='Clash Display'>Create an account</Heading>
                <Text mt={1} color={"#908888"} fontWeight="500" fontFamily='Creato Display'>Start buying and selling household items easily</Text>
                <br />
                <form onSubmit={signUp}>
                    <Flex gap={"1.2rem"}>
                        <FormControl>
                            <FormLabel transform={"translateY(3px)"} fontWeight="600" fontFamily='Creato Display' fontSize=".9rem">First Name</FormLabel>
                            <Input type="text" onChange={e => setFirstName(e.target.value)} padding={"1.3rem 1rem"} placeholder="First Name" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" />
                        </FormControl>
                        <FormControl>
                            <FormLabel transform={"translateY(3px)"} fontWeight="600" fontFamily='Creato Display' fontSize=".9rem">Last Name</FormLabel>
                            <Input type={"text"} onChange={e => setLastName(e.target.value)} padding={"1.3rem 1rem"} placeholder="Last Name" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" />
                        </FormControl>
                    </Flex>
                    <FormControl mt={2}>
                        <FormLabel transform={"translateY(3px)"} fontWeight="600" fontFamily='Creato Display' fontSize=".9rem">Email Address</FormLabel>
                        <Input type="email" onChange={e => setEmail(e.target.value)} padding={"1.3rem 1rem"} placeholder="Enter a valid email address" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" />
                    </FormControl>
                    <FormControl mt={2}>
                        <FormLabel transform={"translateY(3px)"} fontWeight="600" fontFamily='Creato Display' fontSize=".9rem">Password</FormLabel>
                        <InputGroup>
                            <Input onChange={e => setPassword(e.target.value)} type={showPassword ? "text" : "password"} padding={"1.3rem 1rem"} placeholder="Enter a password" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" />
                            <InputRightElement children={showPassword ? <ViewIcon cursor={"pointer"} onClick={() => setShowPassword(!showPassword)} /> : <ViewOffIcon cursor={"pointer"} onClick={() => setShowPassword(!showPassword)} />} />
                        </InputGroup>
                        <FormHelperText color="#8C8C8C">Must be a min of 8 characters including numbers and letters.</FormHelperText>
                    </FormControl>
                    <FormControl mt={2} mb={2}>
                        <FormLabel transform={"translateY(3px)"} fontWeight="600" fontFamily='Creato Display' fontSize=".9rem">Phone Number (Optional)</FormLabel>
                        <InputGroup>
                            <InputLeftAddon height={"44px"} children="+234" />
                            <Input type="tel" minLength={10} maxLength={11} onChange={e => setPhone(e.target.value)} padding={"1.3rem 1rem"} placeholder="Enter a valid phone number" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" />
                        </InputGroup>
                    </FormControl>
                    <Text mt={2} textAlign={"center"} color="#8C8C8C" fontSize={".9rem"} fontFamily='Clash Display'>By Creating an account, you agree to our <strong style={{color: "#E66F15", fontFamily: "Clash Display", fontWeight: "600", cursor: "pointer"}}>Terms & Conditions</strong> and <strong style={{color: "#E66F15", fontFamily: "Clash Display", fontWeight: "600", cursor: "pointer"}}>Privacy Policy</strong></Text>
                    <Resolver />
                    <Flex mt={1} mb={2} justifyContent={"center"}>
                        <Flex gap=".9rem" width={"60%"} alignItems="center">
                            <Box color={"#EFEDED"} borderTop="1px solid #EFEDED" width={"150px"}></Box>
                            <Text fontWeight={"500"}>or</Text>
                            <Box color={"#EFEDED"} borderTop="1px solid #EFEDED" width={"150px"}></Box>
                        </Flex>
                    </Flex>
                    <Flex justifyContent={"center"}>
                        <GoogleLogin
                            width="350px"
                            onSuccess={googleResponse}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                        />
                     </Flex>

                    {/* <Button mb={1} padding={"1.3rem 1rem"} border="1px solid #EFEDED" bg="#fff" mt={2} width="100%" color="#030303" fontSize=".9rem" fontWeight={"400"}><Image mr={2} src={Google} />Sign In with Google</Button> */}
                    {/* <Button padding={"1.3rem 1rem"} border="1px solid #EFEDED" bg="#fff" mt={2} width="100%" color="#030303" fontSize=".9rem" fontWeight={"400"}><Image mr={2} src={Instagram} />Sign In with Instagram</Button> */}
                    
                    <Link to="/login"><Text mt={3} textAlign={"center"} fontFamily='Clash Display'>Already a registered user? <strong style={{cursor: "pointer"}}>Sign in</strong></Text></Link>
                    <FormControl mt={2} visibility={"hidden"}>
                        <FormLabel transform={"translateY(3px)"} fontWeight="600" fontFamily='Creato Display' fontSize=".9rem">Email Address</FormLabel>
                        <Input type="text" onChange={e => setNoFill(e.target.value)} padding={"1.3rem 1rem"} placeholder="Fill if you're a bot" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" />
                    </FormControl>
                </form>



                {/* <GoogleLogin
                clientId="380862278482-50p2mlq8t17pikhsino7i53a2rsiglsq.apps.googleusercontent.com"
                render={renderProps => (
                    <Button onClick={renderProps.onClick} mt={5} width="100%" variant="outline" height={47}><Image src={Google} />Sign in with google</Button>
                    // <button className="google"  disabled={renderProps.disabled}>This is my custom Google button</button>
                    // <div onClick={renderProps.onClick} className="google"><Img style={{transform: 'translateY(7px)', marginRight: '8px'}} src={Google} alt="" /><span>Sign in with google</span></div>
                    )}
                buttonText="Login"
                onSuccess={googleResponse}
                onFailure={googleResponse}
                cookiePolicy={'single_host_origin'}
                prompt="consent"
                /> */}
            </Box>
        </Box>
    )
}

export default SignUp;

