import { Box, Divider, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import Logo from './wehaul_main_logo-all-white.png';
import Vans from './wehaul-vans.png';
import Wa from './whatsapp-logo.png';
import { useEffect, useState } from "react";
import axiosInstance from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import capitalizeFirstLetter from "../../utils/capitalize";
import { useSelector } from "react-redux";

const Wehaul = () => {
    const {id} = useParams();

    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState(null);
    const [selectedImage, setSelectedImage] = useState("");

    const navigate = useNavigate();
    const currentUser = useSelector(({userData})=>  userData?.currentUser);

    useEffect(() => {
        axiosInstance.get(`/sale-items/${id}`)
        // .then(res => console.log("vvvxx", res?.data?.data))
        .then(res => setItem(res?.data?.data))
        .then(res => setSelectedImage(res?.data?.data?.imageURLs[0]))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, [id]);
    return (
        <Box bg="#E66F15" height={"100vh"}>
            {loading ? 
            <Flex paddingTop={"20rem"} justifyContent={"center"}>
            <Spinner size={"lg"} color="#FFF" />
          </Flex>
          :
          <>
            <Box height={"28vh"} bg="#E66F15" maxW="400px" margin={"0 auto"}>
                <Flex justifyContent={"center"} width="100%">
                    <Flex paddingTop={"2rem"} width="fit-content" alignItems={"center"}>
                        <Text color="#fff" fontWeight={"600"} fontSize="1.2rem" fontFamily="Clash Display" mr={2}>Deliver with</Text>
                        <Box width={"50px"} height="40px" transform={"translateY(3px)"} backgroundImage={Logo} backgroundSize="contain" backgroundRepeat={"no-repeat"}></Box>
                        {/* <Image src={Logo} width="10%" /> */}
                    </Flex>
                </Flex>
                <Image transform={"translateY(-1rem) scale(1.2)"} src={Vans} />
            </Box>
            <Box height={"65vh"} bg="#FFF" borderRadius={"30px 30px 0 0"} maxW="450px" margin={"0 auto"} padding="2rem">
                <Text color="#333" fontWeight={"600"} fontFamily="Clash Display" fontSize="1.3rem">Our delivery services provide end-to-end solutions with a wide array of vehicles for all your moving needs.</Text>
                <Text mt={3} color="#333" fontWeight={"500"} fontSize=".9rem">Affordable Pricing, Full Customer Support, Tracking technology, Safe & Seamless Delivery, Ease of use from the comfort of your phones with our Apps</Text>
                <br />
                <Divider />
                <br />
                <Text mt={2} color="#333" fontWeight={"600"} fontFamily="Clash Display" fontSize="1.3rem" textAlign={"center"}>{capitalizeFirstLetter(item?.name)}</Text>
                <Text color="#333" fontSize=".85rem" textAlign={"center"}>Delivery Estimate for your item is between</Text>
                <Text mt={3} textAlign={"center"}><strong style={{color: "#E66F15", marginRight: "6px"}}>₦{item?.minDeliveryPrice?.toLocaleString()}</strong> to <strong style={{color: "#E66F15", marginLeft: "6px"}}>₦{item?.maxDeliveryPrice?.toLocaleString()}</strong></Text>

                <a target="_blank" href={"https://wa.me/" + "2349160000687" + "?text=Hi, I purchased this item " + `https://declutters-dashboard.onrender.com/item-details/${id}` + " from Declutters and would like to have it picked up at " + item?.pickUpAddress + ", and delivered to " + currentUser?.defaultDeliveryAddress?.address + ". Delivery contact is " + currentUser?.defaultDeliveryAddress?.phoneNumber + ". How much would this cost?"} data-action="share/whatsapp/share">
                <Flex mt={10} padding={".5rem 1rem"} border=".5px solid #E66F15" borderRadius={"24px"} alignItems="center" justifyContent={"space-between"}>
                    <Text color="#333" fontWeight={"600"} fontFamily="Clash Display" fontSize="1rem">Continue with Wehaul</Text>
                    <Image src={Wa} width="20%" />
                </Flex>
                </a>

                <Text onClick={() => navigate("/orders")} mt={5} color="#333" fontSize=".85rem" textAlign={"center"}>Not interested? <span style={{textDecoration: "underline", color: "#E66F15", cursor: "pointer"}}>Proceed to your order page</span></Text>
            </Box>
            </>}
        </Box>
    )
}

export default Wehaul;