import { Box, Button, Flex, Image, Skeleton, Stack, Text } from "@chakra-ui/react";

import One from '../../assets/trending/one.svg';
import Two from '../../assets/trending/two.svg';
import Three from '../../assets/trending/three.svg';
import Four from '../../assets/trending/four.svg';
import Five from '../../assets/trending/five.svg';
import Six from '../../assets/trending/six.svg';
import Seven from '../../assets/trending/seven.svg';
import Eight from '../../assets/trending/eight.svg';
import Nine from '../../assets/trending/nine.svg';
import Ten from '../../assets/trending/ten.svg';

import Location from '../../assets/location.svg';
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import Navbar from "../../components/navbar/navbar";
import axiosInstance from "../../services/api";
import { useNavigate } from "react-router-dom";
import SaveAction2 from "../../components/saveAction/saveAction2";
import SaveAction from "../../components/saveAction/saveAction";
import Header from "../../components/header/header";
import CustomPagination from "../../components/customPagination";


const AllItems = () => {

    const [savedItems, setSavedItems] = useState([]);
    const [loadingx, setLoadingx] = useState(true);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    

    useEffect(() => {
        window.scrollTo(0,0)
    })

    const navigate = useNavigate()

    const [page, setPage] = useState(0)

    const getitems = async() => {
        setLoading(true)
        try {
            const {data} = await axiosInstance.get(`sale-items?isAvailable=true&status=approved&page=${page}`)
            setItems(data?.data)
        } catch(error) {
            console.log(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
       getitems()
    }, [page]);

    useEffect(() => {
        axiosInstance.get("/saved-items")
        // .then(res => console.log("vvv", res?.data?.data))
        .then(res => setSavedItems(res?.data?.data))
        .then(res => setLoadingx(false))
        .catch(error => console.log(error))
    }, []);

    return (
        <>
        <Header />
        <Box  px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={[".8rem", ".9rem", "1rem", "1rem"]}>
            <Text mb={6} mt={4} color="#1A1A1A" fontWeight={"700"} fontFamily="Clash Display" fontSize={"1.2rem"}>All Items</Text>

            <Flex wrap={"wrap"} gap="1rem" rowGap={"3rem"}>
                {loading ? 
                 <>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton width="100%" height={"230"} borderRadius="12px" />
                     <Skeleton width="100%" height={"25px"} />
                     <Skeleton width="100%" height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton width="100%" height={"230"} borderRadius="12px" />
                     <Skeleton width="100%" height={"25px"} />
                     <Skeleton width="100%" height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 </>
                :
                items?.map(({imageURLs, name, listPrice, pickUpLocation, _id, createdAt}) => {
                        const idx = savedItems.filter(i => i?.item?._id === _id)
                        return (
                            // <Link to={`item-details/${_id}`} style={{flex: "0"}}>
                            <Box onClick={() => navigate(`/item-details/${_id}`, {state: "paid"})} cursor={"pointer"} overflow={"hidden"} minWidth={["150px", "250px" ]} flex="1" key={_id}>
                                <Box position={"relative"} overflow={"hidden"} borderRadius="12px" height="230px" width={"100%"} backgroundImage={imageURLs[0]} backgroundSize="cover">
                                    {savedItems.map(i => i?.item?._id).includes(_id) ? <SaveAction2 id={idx[0]} from="main" /> : <SaveAction id={_id} />}
                                </Box>
                                <Box padding={"0 .3rem"}>
                                    {/* <Text mt={1} fontWeight="600" fontSize={".9rem"}>{createdAt?.slice(0,10)}</Text> */}
                                    <Text textTransform={"capitalize"} mt={1} fontWeight="600" fontSize={".9rem"}>{name}</Text>
                                    <Flex mt={2} justifyContent={"space-between"}>
                                        <Flex alignItems={"center"}>
                                            <Image mr={1} transform={"translateY(1.5px)"} width={"20%"} src={Location} />
                                            <Text mt={1} color="#908888" fontSize={".9rem"}>{pickUpLocation?.landmarkArea}</Text>
                                        </Flex>
                                        <Text mt={1} fontSize=".9rem" fontWeight="600">₦{listPrice?.toLocaleString()}</Text>
                                    </Flex>
                                </Box>
                            </Box>
                            // </Link>
                        )
                    })
                    }
            </Flex> 

            <CustomPagination setPage={setPage} page={page} />
        </Box>
        </>
    )
}

export default AllItems;