import { Box, Button, Flex, FormControl, Image, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react";
import Header from "../../components/header/header";
import Navbar from "../../components/navbar/navbar";
import Gallery from './media.svg';
import Send from './send.svg';
import { useNavigate } from "react-router-dom";

const chats = [
    {
        sender: "me",
        message: "Hi, is it possible to return a purchased item if it doesn’t look like what i ordered?"
    },
    {
        sender: "admin",
        message: "Good morning, Welcome to Declutter’s Live Chat."
    },
    {
        sender: "admin",
        message: "Yes, you can. Kindly share a link to the purchased item and attach images showing what was delivered to you."
    }
]

const CustomerSupport = () => {
    const navigate = useNavigate();
    return (
        <Box>
            <Header />
            <Box px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={"2rem"} height="77vh">
                <Text flex={"6"} marginLeft="1rem" color="#1A1A1A" fontWeight={"700"} fontSize="1.25rem" fontFamily={"Clash Display"}>Contact Support</Text>
                <br />
                <Box maxW="500px" mx="auto" mt="2rem" padding={"1rem"}>
                    <Text>Welcome to our Contact Support page! We're here to help you with any questions or concerns you may have about our Declutters. Whether you're having trouble navigating our site, need help placing an order, or have a product inquiry, our dedicated team of customer support professionals is ready to assist you.</Text>
                    <br />
                    <Text>We strive to respond to all inquiries as quickly as possible and aim to provide excellent customer service. Additionally, you can also browse our comprehensive <strong onClick={() => navigate(`/faqs`)} style={{color: "#E66F15", cursor: "pointer"}}>FAQs</strong> page for answers to commonly asked questions. Thank you for choosing Declutters for all of your household item needs, and we look forward to hearing from you soon!</Text>
                </Box>
                <Flex justifyContent={"center"} mt="3rem">
                <a target="_blank" href={"https://wa.me/" + "2348108610699"} data-action="share/whatsapp/share">
                    <Button padding={".9rem 1.5rem"} fontFamily="Clash Display" borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"fit-content"} color="#fff" fontSize={[".9rem", ".9rem"]} fontWeight={"600"}>Talk to customer support</Button>
                </a>
                </Flex>
                {/* <Box maxW={"660px"} margin="0 auto">
                    <Text color="#1A1A1A" mb={3} fontWeight={"700"} textAlign="center" fontSize="1rem" fontFamily={"Clash Display"}>Admin</Text>
                    <Box padding={"1rem"} position={"relative"} height={"600px"} border="1px solid #EAEAEA" borderRadius={"0px 0px 20px 20px"}>
                        <Text color="#666666" mb={7} fontWeight={"500"} textAlign="center" fontSize=".85rem">Today, 9:21 AM</Text>

                        {chats?.map(({sender, message}, index) => {
                            return (
                                <Flex key={index} justifyContent={sender === "me" ? "flex-end" : "flex-start"}>
                                     <Text padding={"1rem .8rem"} fontSize={[".85rem", ".9rem"]} borderRadius={sender === "me" ? "24px 24px 0px 24px" : "0px 24px 24px 24px"} fontWeight={sender === "me" ?"500" : "600"} maxW={"280px"} mb={4} bg={sender === "me" ? "#FAE2D0" : "#E66F15"} color={sender === "me" ? "#1A1A1A" : "#fff"}>{message}</Text>
                                </Flex>
                            )
                        })}

                        <Box bg="#fff" position={"absolute"} width="95%" bottom={"3%"} borderRadius={"0px 0px 20px 20px"}>
                            <FormControl>
                                <InputGroup>
                                    <Input borderRadius={"24px"} padding="1.6rem 1rem" placeholder="Compose a message..." _placeholder={{color: "#8C8C8C", fontSize: ".85rem"}} />
                                    <InputRightElement
                                    children={
                                        <Flex transform={"translateY(6px)"} marginLeft={"-5rem"} alignItems={"center"} gap="2.5rem">
                                            <Image cursor={"pointer"} transform={"scale(1.3)"} src={Gallery} />
                                            <Image cursor={"pointer"} transform={"scale(1.3)"} src={Send} />
                                        </Flex>
                                    }
                                    />
                                </InputGroup>
                            </FormControl>
                        </Box>
                    </Box> 
                </Box> */}
                
            </Box>
        </Box>
    )
}

export default CustomerSupport;