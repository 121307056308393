import { AddIcon, CheckIcon, ChevronDownIcon, MinusIcon, PlusSquareIcon, SearchIcon } from "@chakra-ui/icons";
import { Box, Button, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex, FormControl, Image, Input, InputGroup, InputLeftElement, InputRightElement, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Radio, RadioGroup, Skeleton, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Navbar from "../../components/navbar/navbar";
import axiosInstance from "../../services/api";
import Categories2 from "../forgotPassword/homeComponents/categoriesTwo";
import Filtericon from './filtericon.svg';
import states from '../../utils/states'
import capitalizeFirstLetter from "../../utils/capitalize";
import Heart from './hearticon.svg';
import Location from './location.svg';
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/header/header";

const conditions = [
    "Brand New",
    "Almost New",
    "Fairly Used",
    "Used"
]
const prices = [
    "Lowest to Highest",
    "Highest to Lowest"
]


const Search = () => {
    const { keyword } = useParams()
    const {state} = useLocation()
    // console.log(state)
    const [sort, setSort] = useState("Recommended");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [searchResult, setSearchResult] = useState([])

    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);

    const [items, setItems] = useState([]);
    const [loadingx, setLoadingx] = useState(true);

    const [value1, setValue1] = useState("");
    const [value2, setValue2] = useState("");
    const [value3, setValue3] = useState("");

    const [showMore, setShowMore] = useState(false);
    const [locationSearch, setLocationSearch] = useState("");

    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedCondition, setSelectedCondition] = useState("");
    const [selectedPriceArr, setSelectedPriceArr] = useState("");
    const [selectedMinPrice, setSelectedMinPrice] = useState("");
    const [selectedMaxPrice, setSelectedMaxPrice] = useState("");
    const [selectedLocation, setSelectedLocation] = useState("");

    const navigate = useNavigate()

    const url = `sale-items?isAvailable=true&status=approved${state?.category.length > 0 ? `&category=${state?.category}` : ``}${state?.condition.length > 0 ? `&condition=${state?.condition}` : ``}${state?.location.length > 0 ? `&pickUpLocation.state=${state?.location}` : ``}${state?.min.length > 0 ? `&listPrice[lte]=${state?.min}` : ``}${state?.max.length > 0 ? `&listPrice[gte]=${state?.max}` : ``}`
    


    useEffect(() => {
        axiosInstance.get("/item-categories")
        // .then(res => console.log(res?.data))
        .then(res => setCategories(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, [])

    useEffect(() => {
        // axiosInstance.get("sale-items")
        axiosInstance.get(keyword === "null" ? url : `sale-items?search=${keyword}&isAvailable=true&status=approved`)
        // .then(res => console.log("vvv", res?.data?.data))
        .then(res => setSearchResult(res?.data?.data))
        .then(res => setLoadingx(false))
        .catch(error => console.log(error))
    }, []);



    return (
        <Box>
            <Header />
            <Categories2 />
            <Box marginTop="-1.7rem" px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={[".8rem", ".9rem", "1rem", "1rem"]}>
                <Box textAlign={"center"} marginTop="2rem">
                    {loading2 ? <Text fontSize={".85rem"} fontWeight="600">Currently searching..</Text> : <Text fontSize={".85rem"} fontWeight="600">{searchResult?.filter(i => i.name.toLowerCase().includes(keyword.toLowerCase())).length} results found for</Text>}
                    <Text mt={2} color="#2E1604" fontFamily="Clash Display" fontWeight="500">“{keyword}”</Text>
                </Box>

                <Flex marginTop={"1.5rem"} justifyContent="space-between" alignItems={"center"}>

                <Menu>
                  <MenuButton>
                    <Flex border="1px solid #4D2507" padding={".4rem .5rem"} borderRadius="24px">
                        <Text fontSize={".8rem"}>Sort By: <strong>{sort}</strong><ChevronDownIcon fontSize={"1.1rem"} /></Text>
                    </Flex>
                  </MenuButton>
                    <MenuList
                    bg="#FFF"
                    color={"#000"}
                    fontSize=".8rem"
                    fontFamily={"Clash Display"}
                    border="1px solid #EAEAEA"
                    padding=".5rem"
                    >
                    <MenuItem
                        _hover={{ background: "lightgray" }}
                        _focus={{ background: "lightgray" }}
                        fontWeight={"500"}
                        onClick={() => setSort("Recommended")}
                    >
                        Recommended
                        {sort === "Recommended" && <CheckIcon color={"#E66F15"} marginLeft={"4rem"} />}
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                        _hover={{ background: "lightgray" }}
                        _focus={{ background: "lightgray" }}
                        fontWeight={"500"}
                        onClick={() => setSort("Newly Listed")}
                    >
                        Newly Listed
                        {sort === "Newly Listed" && <CheckIcon color={"#E66F15"} marginLeft={"5rem"} />}
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                        _hover={{ background: "lightgray" }}
                        _focus={{ background: "lightgray" }}
                        fontWeight={"500"}
                        onClick={() => setSort("Deals & Promos")}
                    >
                        Deals & Promos
                        {sort === "Deals & Promos" && <CheckIcon color={"#E66F15"} marginLeft={"4rem"} />}
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                        _hover={{ background: "lightgray" }}
                        _focus={{ background: "lightgray" }}
                        fontWeight={"500"}
                        onClick={() => setSort("Trending Items")}
                    >
                        Trending Items
                        {sort === "Trending Items" && <CheckIcon color={"#E66F15"} marginLeft={"4rem"} />}
                    </MenuItem>
                    </MenuList>
              </Menu>

                    {/* <Flex cursor={"pointer"} onClick={() => onOpen()} border="1px solid #4D2507" padding={".4rem .5rem"} borderRadius="24px">
                        <Image mr={1.5} src={Filtericon} />
                        <Text fontSize={".8rem"} fontWeight="500">Filters</Text>
                    </Flex> */}
                </Flex>


                <Flex mt={6} marginBottom="4rem" wrap={"wrap"} gap="1rem" rowGap={"3rem"}>
                    {loading ?
                    <>
                    <Stack minWidth="250px" flex="1">
                        <Skeleton height={"230"} borderRadius="12px" />
                        <Skeleton height={"25px"} />
                        <Skeleton height={"25px"} />
                    </Stack>
                    <Stack minWidth="250px" flex="1">
                        <Skeleton width="100%" height={"230"} borderRadius="12px" />
                        <Skeleton width="100%" height={"25px"} />
                        <Skeleton width="100%" height={"25px"} />
                    </Stack>
                    <Stack minWidth="250px" flex="1">
                        <Skeleton height={"230"} borderRadius="12px" />
                        <Skeleton height={"25px"} />
                        <Skeleton height={"25px"} />
                    </Stack>
                    <Stack minWidth="250px" flex="1">
                        <Skeleton height={"230"} borderRadius="12px" />
                        <Skeleton height={"25px"} />
                        <Skeleton height={"25px"} />
                    </Stack>
                    <Stack minWidth="250px" flex="1">
                        <Skeleton height={"230"} borderRadius="12px" />
                        <Skeleton height={"25px"} />
                        <Skeleton height={"25px"} />
                    </Stack>
                    </>
                    :
                    searchResult?.length == 0 ?
                    <Text mt="3rem" width={"100%"} textAlign={"center"}>No items matched your search</Text>
                    :
                    searchResult?.map(({imageURLs, name, listPrice, pickUpLocation, _id}) => {
                        return (
                            // <Link to={`item-details`} style={{flex: "0", flexBasis: "250px"}}>
                                <Box onClick={() => navigate(`/item-details/${_id}`)} cursor={"pointer"} overflow={"hidden"} minWidth={["150px", "250px" ]} flex={["1", "1", "0", "0"]} key={name}>
                                    <Box position={"relative"} overflow={"hidden"} borderRadius="12px" height="230px" width={"100%"} backgroundImage={imageURLs[0]} backgroundSize="cover">
                                        <Image src={Heart} position="absolute" right={"4%"} top="5%" />
                                    </Box>
                                    <Box padding={"0 .3rem"}>
                                        <Text mt={1} fontWeight="600">{name}</Text>
                                        <Flex mt={2} justifyContent={"space-between"}>
                                            <Flex alignItems={"center"}>
                                                <Image mr={1} transform={"translateY(1.5px)"} width={"20%"} src={Location} />
                                                <Text mt={1} color="#908888">{pickUpLocation?.landmarkArea}</Text>
                                            </Flex>
                                            <Text mt={1} fontSize=".95rem" fontWeight="600">₦{parseInt(listPrice)?.toLocaleString()}</Text>
                                        </Flex>
                                    </Box>
                                </Box>
                            // </Link>
                        )
                    })}
                </Flex>
            </Box>


            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
            >
                <DrawerOverlay />
                <DrawerContent>
                <DrawerBody>
                    <Text margin={"1rem 0"} fontFamily={"Clash Display"} fontWeight="700" fontSize={"1rem"}>Filters</Text>
                    <Text fontWeight={"600"} fontSize=".9rem">By Category</Text>
                    <RadioGroup margin={"1rem 0"} onChange={setValue1} value={value1}>
                        <Stack direction='column' lineHeight={"30px"}>
                        {categories?.map(({name}, index) => {
                            return (
                                <Radio size={"sm"} colorScheme="orange" fontSize={".8rem"} key={index} value={name}>{name}</Radio>
                            )
                        })}
                        </Stack>
                    </RadioGroup>
                    <Text fontWeight={"600"} fontSize=".9rem">By Item Condition</Text>
                    <RadioGroup margin={"1rem 0"} onChange={setValue2} value={value2}>
                        <Stack direction='column' lineHeight={"30px"}>
                        {conditions?.map((i, index) => {
                            return (
                                <Radio size={"sm"} colorScheme="orange" fontSize={".8rem"} key={index} value={i}>{i}</Radio>
                            )
                        })}
                        </Stack>
                    </RadioGroup>
                    <Text fontWeight={"600"} fontSize=".9rem">By Price</Text>
                    <RadioGroup margin={"1rem 0 0 0"} onChange={setValue3} value={value3}>
                        <Stack direction='column' lineHeight={"30px"}>
                        {prices?.map((i, index) => {
                            return (
                                <Radio size={"sm"} colorScheme="orange" fontSize={".8rem"} key={index} value={i}>{i}</Radio>
                            )
                        })}
                        </Stack>
                    </RadioGroup>
                    <Text fontWeight={"600"} mt={5} mb={3} fontSize=".9rem">By Price Range</Text>
                    <Flex mb={8} gap=".7rem" maxW={"80%"}>
                        <FormControl>
                            <InputGroup>
                                <Input type="tel" outline={"none"} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15", outline: "none"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"10px"} padding={".7rem .4rem"} placeholder="0" _placeholder={{color: "#8C8C8C"}} fontSize=".9rem" />
                                <InputRightElement children={<Text transform={"translateY(1px)"} color="#B3B3B3" fontSize={".75rem"} marginLeft="-1rem">Min (₦)</Text>} />
                            </InputGroup>
                        </FormControl>
                        <FormControl>
                            <InputGroup>
                                <Input type="tel" outline={"none"} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15", outline: "none"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"10px"} padding={".7rem .4rem"} placeholder="0" _placeholder={{color: "#8C8C8C"}} fontSize=".9rem" />
                                <InputRightElement children={<Text transform={"translateY(1px)"} color="#B3B3B3" fontSize={".75rem"} marginLeft="-1rem">Max (₦)</Text>} />
                            </InputGroup>
                        </FormControl>
                    </Flex>

                    <Text fontWeight={"600"} fontSize=".9rem">By Item Location</Text>
                    <FormControl mt={3}>
                            <InputGroup>
                                <InputLeftElement children={<SearchIcon color={"gray"} />} />
                                <Input onChange={e => setLocationSearch(e.target.value)} type="text" outline={"none"} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15", outline: "none"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"20px"} padding={".7rem .4rem .7rem 2rem"} placeholder="Search Location" _placeholder={{color: "#8C8C8C", fontSize: ".8rem"}} fontSize=".9rem" />
                            </InputGroup>
                        </FormControl>
                    <RadioGroup margin={"1rem 0 0 0"} onChange={setValue3} value={value3}>
                        <Stack direction='column' lineHeight={"30px"}>
                        <Radio size={"sm"} colorScheme="orange" fontSize={".8rem"} value={"Anywhere"}>Anywhere</Radio>
                        {states?.filter(i => i.includes(locationSearch)).slice(0, showMore == true ? states.length : 5)?.map((i, index) => {
                            return (
                                <Radio size={"sm"} colorScheme="orange" fontSize={".8rem"} key={index} value={i}>{capitalizeFirstLetter(i)}</Radio>
                            )
                        })}
                        </Stack>
                        <Flex cursor={"pointer"} mt={3} alignItems={"center"}>
                            {!showMore ?
                            <AddIcon marginRight={".4rem"} fontSize=".8rem" />
                            :
                            <MinusIcon marginRight={".4rem"} fontSize=".8rem" />
                            }
                            <Text onClick={() => setShowMore(!showMore)} fontSize={".85rem"} fontWeight="600">Show {showMore ? "less" : "more"}</Text>
                        </Flex>
                    </RadioGroup>

                    <Button mb={1} mt={6} padding={"1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"100%"} color="#fff" fontSize=".9rem" fontWeight={"700"}>Apply</Button>
                    <Button mb={8} padding={".8rem 1rem"} borderRadius="20px" border="1px solid #E66F15" bg="#FFF" mt={2} width={"100%"} color="#1A1A1A" fontSize=".9rem" fontWeight={"700"}>Reset</Button>

                </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Box>
    )
}

export default Search;