import { Box, Flex, Skeleton, Stack, Text } from "@chakra-ui/react"
import TextArrow from "../../../components/textArrow";

import One from '../../../assets/categories/one.svg';
import Two from '../../../assets/categories/two.svg';
import Three from '../../../assets/categories/three.svg';
import Four from '../../../assets/categories/four.svg';
import Five from '../../../assets/categories/five.svg';
import Six from '../../../assets/categories/six.svg';
import Seven from '../../../assets/categories/seven.svg';
import Eight from '../../../assets/categories/eight.svg';
import Nine from '../../../assets/categories/nine.svg';
import Ten from '../../../assets/categories/ten.svg';
import Eleven from '../../../assets/categories/eleven.svg';
import { useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import axiosInstance from "../../../services/api";
import { useNavigate } from "react-router-dom";

const data = [
    {
        img: One,
        name: "Living & Dining Room",
        egs: ["Sofas", "Center Tables", "Dining Table", "TV Stand"]
    },
    {
        img: Two,
        name: "Bedroom",
        egs: ["Bed Frame", "Mattress", "Night Stand", "Dresser"]
    },
    {
        img: Three,
        name: "Bathroom",
        egs: ["Sofas", "Center Tables", "Dining Table", "TV Stand"]
    },
    {
        img: Four,
        name: "Cookware",
        egs: ["Pots", "Oven", "Gas Cooker", "Cutleries", "Dishwasher"]
    },
    {
        img: Five,
        name: "Home Decor",
        egs: ["Lamp", "Mirrors", "Lights", "Vases", "Curtains"]
    },
    {
        img: Six,
        name: "Kids items",
        egs: ["Sofas", "Center Tables", "Dining Table", "TV Stand"]
    },
    {
        img: Seven,
        name: "Appliances",
        egs: ["Sofas", "Center Tables", "Dining Table", "TV Stand"]
    },
    {
        img: Eight,
        name: "Gadgets & Electronics",
        egs: ["Sofas", "Center Tables", "Dining Table", "TV Stand"]
    },
    {
        img: Nine,
        name: "Office",
        egs: ["Sofas", "Center Tables", "Dining Table", "TV Stand"]
    },
    {
        img: Ten,
        name: "External Areas",
        egs: ["Sofas", "Center Tables", "Dining Table", "TV Stand"]
    },
    {
        img: Eleven,
        name: "Others",
        egs: ["Sofas", "Center Tables", "Dining Table", "TV Stand"]
    }
]


const Categories = () => {

    const [showMore, setShowMore] = useState(false);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()

    useEffect(() => {
        axiosInstance.get("/item-categories")
        // .then(res => console.log(res?.data))
        .then(res => setCategories(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, [])


    return (
        <Box mt={10} px={[".8rem", "1.5rem", "2.5rem", "3rem"]} pt={[".7rem", "1rem", "1rem", "1rem"]}>
            <Flex alignItems={"center"} justifyContent="space-between">
                <TextArrow text="Explore Categories" link="/categories" />
                <Flex onClick={() => setShowMore(!showMore)} cursor={"pointer"} alignItems={"center"}>
                    <Text mr={1} fontSize=".9rem" fontWeight="500">See More</Text>
                    {showMore ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </Flex>
            </Flex>
            <br />
            {loading ? 
            <Stack mt={4} textAlign="center">
                <Skeleton height={"30px"} width="100%" />
                <Skeleton height={"30px"} width="100%" />
                <Skeleton height={"30px"} width="100%" />
            </Stack>
                    :
            <Flex maxW={["100%", "83%"]} margin="0 auto" justifyContent={"space-between"} gap={[".4rem", "2rem"]}>
                    {categories?.slice(0,6).map(({imageURL, name}) => {
                        return (
                            <Box cursor={"pointer"} onClick={() => navigate(`/explore/${name}`)} _hover={{transform: "scale(.9)"}} transition={"250ms ease"} width="300px" key={name}>
                                <Box margin={"0 auto"} width={["55px", "120px"]} height={["55px", "120px"]} borderRadius={"50%"} backgroundImage={imageURL} backgroundSize="cover"></Box>
                                <Text mt={2} textAlign="center" fontFamily={"Clash Display"} fontWeight="500" fontSize={[".7rem", "1rem"]}>{name}</Text>
                            </Box>
                        )
                    })}
                </Flex> }
                <br />
                {showMore && 
                <Flex maxW={["90%", "73%"]} transition="250ms ease" margin="0 auto" justifyContent={"space-between"} gap={[".4rem", "2rem"]}>
                    {categories?.slice(6,10).map(({imageURL, name}) => {
                        return (
                            <Box cursor={"pointer"} onClick={() => navigate(`/explore/${name}`)} _hover={{transform: "scale(.9)"}}  transition={"250ms ease"} width="300px" key={name}>
                                <Box margin={"0 auto"} width={["55px", "120px"]} height={["55px", "120px"]} borderRadius={"50%"} backgroundImage={imageURL} backgroundSize="cover"></Box>
                                <Text mt={2} textAlign="center" fontFamily={"Clash Display"} fontWeight="500" fontSize={[".7rem", "1rem"]}>{name}</Text>
                            </Box>
                        )
                    })}
                    <Box cursor={"pointer"} _hover={{transform: "scale(.9)"}}  transition={"250ms ease"} width="300px">
                        <Box margin={"0 auto"} width={["55px", "120px"]} height={["55px", "120px"]} borderRadius={"50%"} backgroundImage={categories?.find(i => i.name === "Others")?.imageURL} backgroundSize="cover"></Box>
                        <Text mt={2} textAlign="center" fontFamily={"Clash Display"} fontWeight="500" fontSize={[".7rem", "1rem"]}>{data[10]?.name}</Text>
                    </Box>
                </Flex> }
        </Box>
    )
}

export default Categories;