import { Box, Button, Flex, Heading, Image, Skeleton, Stack, Text } from "@chakra-ui/react";
import Navbar from "../../components/navbar/navbar";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import One from './one.svg';
import Two from './two.svg';
import Three from './three.svg';
import Four from './four.svg';
import Five from './five.svg';
import Location from './location.svg';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axiosInstance from "../../services/api";
import { useState } from "react";
import Empty from './empty.svg';
import Header from "../../components/header/header";
import { useSelector } from "react-redux";

const Catalog = () => {
    const [loading, setLoading] = useState(true);
    const [loadingx, setLoadingx] = useState(true);
    const [items, setItems] = useState([]);
    const [itemsx, setItemsx] = useState([]);

    const navigate = useNavigate()

    const [orders, setOrders] = useState([]);

    const currentUser = useSelector(({userData})=>  userData?.currentUser);

    useEffect(() => {
        axiosInstance.get("/transactions/user")
        // .then(res => console.log("vvvxx", res?.data?.data))
        .then(res => setOrders(res?.data?.data))
        .then(res => setLoadingx(false))
        .catch(error => console.log(error))
    }, [])

    useEffect(() => {
        axiosInstance.get("/sale-items/user")
        // .then(res => console.log("vvvxx", res?.data?.data))
        .then(res => setItems(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, [])
   
    return (
        <Box>
            <Header />
            <Box px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={"2rem"}>
                <Box maxWidth={"1100px"} margin="0 auto">
                     {/* <Text mb={6} fontSize={"1.25rem"} fontWeight="700" fontFamily={"Clash Display"}>Sell</Text> */}
                    <Flex gap={["1rem", "1rem", "3rem", "3rem"]}>
                        <Box textAlign={"center"}  flex={"1"} bg="#1A1A1A" color="#fff" height={"150px"} boxSizing="border-box" padding={".3rem"} borderRadius="8px">
                            <Text mt={3} fontWeight="500">Sales Offer</Text>
                            <Text mt={1} fontWeight="700" fontSize={"1.6rem"} fontFamily="Clash Display">{(items?.filter(i => i.offers.length > 0)).length}</Text>
                            <Button onClick={() => navigate("/offers")} type="submit" padding={"1.3rem 1rem"} borderRadius="20px" bg="#E66F15" mt={2} width={170} color="#fff" fontSize=".9rem" fontWeight={"700"}>View Offers</Button>
                        </Box>
                        <Box textAlign={"center"}  flex={"1"} bg="#1A1A1A" color="#fff" height={"150px"} boxSizing="border-box" padding={".3rem"} borderRadius="8px">
                            <Text mt={3} fontWeight="500">Amount Made</Text>
                            <Text mt={1} fontWeight="700" fontSize={"1.6rem"} fontFamily="Clash Display">₦ <strong style={{fontFamily: "Clash Display"}}>{!loadingx && orders?.filter(i => i.status === "approved" && !i.isSellerCredited && i.seller === currentUser?._id)?.reduce((accumulator, object) => { return accumulator + object.sellerPrice}, 0)?.toLocaleString()}</strong></Text>
                            <Button onClick={() => navigate("/wallet")} type="submit" padding={"1.3rem 1rem"} borderRadius="20px" bg="#E66F15" mt={2} width={170} color="#fff" fontSize=".9rem" fontWeight={"700"}>Payment History</Button>
                        </Box>
                    </Flex>
                    <br />
                    <br />
                    <Tabs>
                        <TabList borderBottom={"none"} display={"flex"} justifyContent="space-around">
                            <Tab _selected={{borderBottom: "2px solid #E66F15", color: "#1A1A1A"}} fontWeight="500" fontSize={"1.1rem"} color="#666666">Listings</Tab>
                            <Tab _selected={{borderBottom: "2px solid #E66F15", color: "#1A1A1A"}} fontWeight="500" fontSize={"1.1rem"} color="#666666">Items Paid For</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel width="100%" padding={"0"}>
                                {loading ? 
                                <Stack mt={6}>
                                    <Skeleton height={"40px"} />
                                    <Skeleton height={"40px"} />
                                    <Skeleton height={"40px"} />
                                    <Skeleton height={"40px"} />
                                </Stack>
                                :
                                items?.length < 1 ? 
                                <Box textAlign={"center"} marginTop="1rem">
                                    <Image src={Empty} transform="scale(.6) translateY(20px)" width={"auto"} margin="0 auto" />
                                    <Text color="#030303" fontFamily={"Clash Display"} fontWeight="600">Nothing to see here yet!</Text>
                                    <Text color="#666666" fontSize={".9rem"}>Click the sell button to list an item</Text>
                                </Box>
                                :
                                    <Flex mt={4} wrap={"wrap"} gap="1rem" rowGap={"3rem"}>
                                {items?.map(({imageURLs, name, listPrice, pickUpLocation, _id, status}) => {
                                    return (
                                        // <Link to={`item-details/${_id}`}>
                                            <Box onClick={() => navigate(`/item-details/${_id}`)} cursor={"pointer"} overflow={"hidden"} minWidth={["150px", "250px" ]} flex={["1", "1", "0", "0"]} key={_id}>
                                                <Box overflow={"hidden"} borderRadius="12px" height="230px" width={"100%"} backgroundImage={imageURLs[0]} backgroundSize="cover"></Box>
                                                <Box padding={"0 .3rem"}>
                                                    <Text mt={1} fontWeight="600">{name}</Text>
                                                    <Flex mt={2} justifyContent={"space-between"}>
                                                        <Flex alignItems={"center"}>
                                                            <Image mr={1} transform={"translateY(1.5px)"} width={"20%"} src={Location} />
                                                            <Text mt={1} whiteSpace="nowrap" color="#908888" fontSize={[".85rem", ".85rem", "1rem","1rem",]}>{pickUpLocation?.landmarkArea}</Text>
                                                        </Flex>
                                                        <Text mt={1} fontSize=".95rem" fontWeight="600">₦{listPrice?.toLocaleString()}</Text>
                                                    </Flex>
                                                    <Text fontSize={".8rem"} fontWeight="600" color={status === "pending" ? "goldenrod" : status === "rejected" ? "#F04438" : "#12B76A"} mt={2}>{status.slice(0,1).toUpperCase() + status.slice(1)}</Text>
                                                </Box>
                                            </Box>
                                        // </Link>
                                    )
                                })}
                                </Flex>}
                            </TabPanel>
                            <TabPanel padding={"0"}>
                                <Flex mt={4} wrap={"wrap"} gap="1rem" rowGap={"3rem"}>
                                {items?.filter(i => i.buyer).map(({imageURLs, name, listPrice, pickUpLocation, _id, status}) => {
                                        return (
                                            <Box onClick={() => navigate(`/item-details/${_id}`)} cursor={"pointer"} overflow={"hidden"} minWidth={["150px", "250px" ]} flex={["1", "1", "0", "0"]} key={_id}>
                                                <Box overflow={"hidden"} borderRadius="12px" height="230px" width={"100%"} backgroundImage={imageURLs[0]} backgroundSize="cover"></Box>
                                                <Box padding={"0 .3rem"}>
                                                    <Text mt={1} fontWeight="600">{name}</Text>
                                                    <Flex mt={2} justifyContent={"space-between"}>
                                                        <Flex alignItems={"center"}>
                                                            <Image mr={1} transform={"translateY(1.5px)"} width={"20%"} src={Location} />
                                                            <Text mt={1} whiteSpace="nowrap" color="#908888" fontSize={[".85rem", ".85rem", "1rem","1rem",]}>{pickUpLocation?.landmarkArea}</Text>
                                                        </Flex>
                                                        <Text mt={1} fontSize=".95rem" fontWeight="600">₦{listPrice?.toLocaleString()}</Text>
                                                    </Flex>
                                                    <Text fontSize={".8rem"} fontWeight="600" color={status === "pending" ? "#F04438" : "#12B76A"} mt={2}>Sold</Text>
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </Flex>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </Box>
            </Box>
        </Box>
    )
}

export default Catalog;