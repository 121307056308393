import { Box, Button, Divider, Flex, FormControl, HStack, Image, Img, Input, ListItem, Spinner, Text, UnorderedList } from "@chakra-ui/react"
import Logo from '../../assets/Declutterslogo.png';
import Google from './google.svg';
import Appstore from './appstore.svg';
import Ig from './imstagram.svg';
import Tw from './twitter.svg';
import Fb from './facebook.svg';
import Tk from './tiktok.svg';

const Footer = () => {
   
    return (
        <Box px={[".8rem","3rem"]}
        py="1.5rem" 
        // position={"fixed"}
        // bottom={"0"}
        // width={"100%"}
        // bg="linear-gradient(117.97deg, rgba(209, 251, 0, 0.24) 2.73%, rgba(209, 251, 0, 0.35) 36.57%, rgba(223, 159, 14, 0.574257) 61.68%, rgba(230, 111, 21, 0.69) 82.22%)"
        bg="#F6FECC"
        >
            <Flex alignItems="center" flexWrap="wrap" flexDirection={["row-reverse","row-reverse", "row", "row"]} justifyContent={"space-between"} gap="1rem">
                <Box flex="1 0 350px" textAlign={["center", "left"]}>
                    {/* <Img src={Logo} width="50%" /> */}
                    <Text fontWeight={"600"} color="#1A1A1A">© Declutters 2022. All Rights Reserved</Text>
                    <Text fontWeight={"600"} color="#1A1A1A">Declutters app is owned by Connal Solutions LLC</Text>
                </Box>
                {/* <Box flex="2" minW={{base: "100%", md: "470px"}}>
                
                    <Flex lineHeight="50px" marginTop="3rem" gap="7rem" color="#fff">
                        <UnorderedList color="#1A1A1A" listStyleType="none">
                            <ListItem mb={2} fontWeight={"700"} fontSize="1.1rem">Company</ListItem>
                            <ListItem cursor="pointer" _hover={{color: "#8EB65F"}} transition="250ms ease">About</ListItem>
                            <ListItem cursor="pointer" _hover={{color: "#8EB65F"}} transition="250ms ease">Location</ListItem>
                            <ListItem cursor="pointer" _hover={{color: "#8EB65F"}} transition="250ms ease">Contact Support</ListItem>
                            <ListItem cursor="pointer" _hover={{color: "#8EB65F"}} transition="250ms ease">Careers</ListItem>
                        </UnorderedList>
                        <UnorderedList color="#1A1A1A" listStyleType="none">
                            <ListItem mb={2} fontWeight={"700"} fontSize="1.1rem">Help</ListItem>
                            <ListItem cursor="pointer" _hover={{color: "#8EB65F"}} transition="250ms ease">FAQs</ListItem>
                            <ListItem cursor="pointer" _hover={{color: "#8EB65F"}} transition="250ms ease">Delivery</ListItem>
                            <ListItem cursor="pointer" _hover={{color: "#8EB65F"}} transition="250ms ease">Return Policy</ListItem>
                            <ListItem cursor="pointer" whiteSpace={"nowrap"} _hover={{color: "#8EB65F"}} transition="250ms ease">Terms of Service</ListItem>
                            <ListItem cursor="pointer" _hover={{color: "#8EB65F"}} transition="250ms ease">Privacy Policy</ListItem>
                        </UnorderedList>
                        <UnorderedList color="#1A1A1A" listStyleType="none">
                            <ListItem mb={2} fontWeight={"700"} fontSize="1.1rem" whiteSpace={"nowrap"}>Follow Us</ListItem>
                            <ListItem cursor="pointer" _hover={{color: "#8EB65F"}} transition="250ms ease">Instagram</ListItem>
                            <ListItem cursor="pointer" _hover={{color: "#8EB65F"}} transition="250ms ease">Twitter</ListItem>
                            <ListItem cursor="pointer" _hover={{color: "#8EB65F"}} transition="250ms ease">Facebook</ListItem>
                        </UnorderedList>
                        <UnorderedList listStyleType="none">
                            <ListItem mb={2} color="#1A1A1A" fontWeight={"700"} fontSize="1.1rem">Download</ListItem>
                            <Image width={"75%"} mb={5} cursor={"pointer"} _hover={{transform: "scale(.9)"}} transition="250ms ease" src={Google} />
                            <Image width={"75%"} cursor={"pointer"} _hover={{transform: "scale(.9)"}} transition="250ms ease" src={Appstore} />
                        </UnorderedList>
                    </Flex>
                </Box> */}
                <Box flex={"1 0 350px"}>
                    <Flex alignItems={"center"} gap="2rem" justifyContent={"center"}>
                        <a href="https://www.instagram.com/declutters_app" target="_blank" rel="noopener noreferrer"><Image cursor={"pointer"} _hover={{transform: "scale(.9)"}} transition="250ms ease" src={Ig} /></a>
                        <a href="https://twitter.com/DecluttersApp" target="_blank" rel="noopener noreferrer"><Image cursor={"pointer"} _hover={{transform: "scale(.9)"}} transition="250ms ease" src={Tw} /></a>
                        <a href="https://www.facebook.com/profile.php?id=100093030413070" target="_blank" rel="noopener noreferrer"><Image cursor={"pointer"} _hover={{transform: "scale(.9)"}} transition="250ms ease" src={Fb} /></a>
                        <a href="https://www.tiktok.com/@decluttersapp?lang=en" target="_blank" rel="noopener noreferrer"><Image cursor={"pointer"} _hover={{transform: "scale(.9)"}} transition="250ms ease" src={Tk} /></a>
                    </Flex>
                    <a href="mailto:contact@decluttersapp.com"><Text textAlign={"center"} textDecoration={"underline"} cursor={"pointer"}>contact@decluttersapp.com</Text></a>
                </Box>
                <Flex flex={"1 0 350px"} alignItems={"center"} gap="1rem" justifyContent={["center", "flex-end"]}>
                    <Image onClick={() => window.open('https://play.google.com/store/apps/details?id=com.declutters.decluttersmobileapp', "_blank") || window.location.replace('https://play.google.com/store/apps/details?id=com.declutters.decluttersmobileapp')} width={"28%"} cursor={"pointer"} _hover={{transform: "scale(.9)"}} transition="250ms ease" src={Google} />
                    <Image onClick={() => window.open('https://apps.apple.com/ng/app/declutters-app/id6448369072', "_blank") || window.location.replace('https://apps.apple.com/ng/app/declutters-app/id6448369072')} width={"28%"} cursor={"pointer"} _hover={{transform: "scale(.9)"}} transition="250ms ease" src={Appstore} />
                </Flex>

            </Flex>
            {/* <Flex marginTop={"6rem"} justifyContent={"center"}>
                <Text fontWeight={"600"} color="#1A1A1A">© Declutters 2022. All Rights Reserved</Text>
            </Flex> */}
            

            
        </Box>
    )
}

export default Footer;