import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

const CustomPagination = ({page, setPage}) => {
  return (
    <Flex marginBottom={"4rem"} justifyContent={"center"}>
        <Flex margin={"3rem auto 0 auto"} alignItems={"center"} gap=".6rem">
            <ArrowBackIcon onClick={page === 0 ? null : () => setPage(page - 1)} cursor="pointer" color={"#D1FB00"} />
            <Text color={"#1A1A1A"} padding=".1rem .7rem" borderRadius={"50%"} border="1px solid #D1FB00">{page + 1}</Text>
            <ArrowForwardIcon onClick={() => setPage(page + 1)} cursor="pointer" color={"#D1FB00"} />
        </Flex>
    </Flex>
  )
}

export default CustomPagination;