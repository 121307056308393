import { Box, Text } from "@chakra-ui/react"


const PrivacyPolicy = () => {
    return (
        <Box>
            <Text mb={4} fontSize={"1.3rem"} color="#1A1A1A" fontFamily={"Clash Display"} fontWeight="700">Privacy Policy</Text>
            <Text color="#2E1604">At Declutters, we take your privacy seriously and are committed to protecting your personal information. This privacy policy explains how we collect, use, and share your information when you use our app.</Text>
            <br />
            <Text mb={4} fontWeight="500" color="#1A1A1A">Information We Collect</Text>
            <Text color="#2E1604">We collect information that you provide to us when you use the app, such as your name, email address, and any other personal information you choose to share.</Text>
            <br />
            <Text mb={4} fontWeight="500" color="#1A1A1A">How We Use Your Information</Text>
            <Text color="#2E1604">We use the information we collect to provide and improve the app's services, such as by personalizing your experience or facilitating transactions between users.We may also use your information for research and development purposes, such as to conduct surveys and studies to improve the app.</Text>
            <br />
            <Text mb={4} fontWeight="500" color="#1A1A1A">Sharing Your Information</Text>
            <Text color="#2E1604">We do not share your personal information with third parties for their marketing purposes. We may share your information with third-party service providers who assist us in providing the app's services.</Text>
            <br />
            <Text mb={4} fontWeight="500" color="#1A1A1A">Your Choices and Access to Your Information</Text>
            <Text color="#2E1604">We do not share your personal information with third parties for their marketing purposes. We may share your information with third-party service providers who assist us in providing the app's services.</Text>
        </Box>
    )
}

export default PrivacyPolicy;