import { Box, Text } from "@chakra-ui/react"


const Terms = () => {
    return (
        <Box>
            <Text mb={4} fontSize={"1.3rem"} color="#1A1A1A" fontFamily={"Clash Display"} fontWeight="700">Terms of Services</Text>
            <Text color="#2E1604">Welcome to Declutters! By using our app, you are agreeing to be bound by the terms and conditions set forth in this terms of service agreement (the "Agreement")</Text>
            <br />
            <Text mb={4} fontWeight="500" color="#1A1A1A">Access and Use of the App</Text>
            <Text color="#2E1604">Subject to your compliance with this Agreement, we grant you a limited, non-exclusive, non-transferable, revocable license to access and use the app for your personal, non-commercial use. You may not use the app for any illegal or unauthorized purpose, and you may not violate any laws in your jurisdiction when using the app.</Text>
            <br />
            <Text mb={4} fontWeight="500" color="#1A1A1A">Prohibited Conduct</Text>
            <Text color="#2E1604">You may not use the app for any purpose that is unlawful or prohibited by this Agreement. You may not use the app in any manner that could damage, disable, overburden, or impair the app or interfere with any other party's use of the app. You may not attempt to gain unauthorized access to the app or its systems or networks.</Text>
            <br />
            <Text mb={4} fontWeight="500" color="#1A1A1A">Content</Text>
            <Text color="#2E1604">The app allows users to post, upload, or otherwise make available content (including, but not limited to, text, images, audio, and video) ("User Content"). You are solely responsible for your User Content, and you assume all risks associated with your User Content, including, but not limited to, any reliance on its accuracy, completeness, or usefulness by others.
            <br /> <br />  We do not endorse or control any User Content, and we are not responsible or liable for any User Content. We reserve the right, but do not have the obligation, to remove or edit any User Content for any reason.
            <br /> <br />  By posting, uploading, or otherwise making available your User Content, you grant us a non-exclusive, worldwide, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display your User Content in any media or platform.</Text>
            <br />
            <Text mb={4} fontWeight="500" color="#1A1A1A">Privacy</Text>
            <Text color="#2E1604">Our collection, use, and disclosure of your personal information is governed by our privacy policy, which is incorporated into this Agreement by reference.</Text>
        </Box>
    )
}

export default Terms;