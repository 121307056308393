import { Box, Button, Flex, Image, Text } from "@chakra-ui/react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../header/header";
import Navbar2 from "../navbar2/navbar2";
import Illustration from './illustration.svg';

const SuccessScreen = () => {
    const { state } = useLocation();
    const navigate = useNavigate()

    return (
        <Box marginBottom={"20rem"} px={[".8rem", "1.5rem", "2.5rem", "3rem"]}>
            <Header />
            <Image width={"30%"} margin="0 auto" src={Illustration} />
            <Text mt={3} fontFamily='Clash Display' color="#030303" fontWeight={"700"} fontSize="1.2rem" textAlign={"center"}>{state?.text}</Text>
            <Text mt={2} color="#908888" fontFamily='Clash Display' textAlign={"center"}>{state?.para}</Text>
            <Flex mt={6} justifyContent={"center"}>
            <Button onClick={() => window.location.href = state?.link} fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width={400} color="#fff" fontSize=".9rem" fontWeight={"700"}>Continue</Button>
            </Flex>
        </Box>
    )
}

export default SuccessScreen;