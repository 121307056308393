import { ArrowBackIcon } from "@chakra-ui/icons";
import { Badge, Box, Flex, FormControl, HStack, Image, Input, InputGroup, InputRightElement, Spinner, Text, useToast } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/header/header";
import Navbar from "../../components/navbar/navbar";
import axiosInstance from "../../services/api";
import capitalizeFirstLetter from "../../utils/capitalize";
import Gallery from '../customerSupport/media.svg';
import Send from '../customerSupport/send.svg';
import axios from "axios";

const chats = [
    {
        sender: "me",
        message: "Hi, is it possible to return a purchased item if it doesn’t look like what i ordered?"
    },
    {
        sender: "admin",
        message: "Good morning, Welcome to Declutter’s Live Chat."
    },
    {
        sender: "admin",
        message: "Yes, you can. Kindly share a link to the purchased item and attach images showing what was delivered to you."
    }
]

const DisputeThread = () => {
    const navigate = useNavigate();
    const {did} = useParams();

    const currentUser = useSelector(({userData})=>  userData?.currentUser);
    const toast = useToast()

    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingr, setLoadingr] = useState(false);
    const [response, setResponse] = useState("");


    const [media, setMedia] = useState("")

    useEffect(() => {
        axiosInstance.get(`/disputes/${did}`)
        // .then(res => console.log("dispute thread", res?.data?.data))
        .then(res => setDetails(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, [loadingr]);

    const respond = async (e) => {
        e.preventDefault()
        setLoadingr(true)

        if(typeof(response) === "object") {
            try {
                const formData = new FormData()
                formData.append("file", response)
                formData.append("upload_preset", "jmrkiyu0")
                formData.append("cloud_name", "declutters")

                const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)

                const datax = {
                    message: data?.url
                }

                try {
                    const {data} = await axiosInstance.post(`/disputes/${did}/respond`, datax)
                    // console.log(data)
                    setResponse("")
                toast({
                    // title: "An Error Occurred",
                    description: data?.message,
                    status: "success",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                  });
                } catch(error) {
                    toast({
                        title: "An Error Occurred",
                        description: error?.response?.data?.message,
                        status: "error",
                        position: "top-right",
                        duration: 5000,
                        isClosable: true,
                      });
                } finally {
                    setLoadingr(false)
                }

            } catch(error) {
                toast({
                    title: "An Error Occurred",
                    description: error?.response?.data?.message,
                    status: "error",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                  });
            }
        } else {
            const datax = {
                message: response
            }
    
            try {
                const {data} = await axiosInstance.post(`/disputes/${did}/respond`, datax)
                // console.log(data)
                setResponse("")
            toast({
                // title: "An Error Occurred",
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
            } catch(error) {
                toast({
                    title: "An Error Occurred",
                    description: error?.response?.data?.message,
                    status: "error",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                  });
            } finally {
                setLoadingr(false)
            }
        }
        
    }

    return (
        <Box overflowX={"hidden"}>
            <Header />
            <Box px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={"2rem"}>
            {loading ? 
                <Flex marginTop={"7rem"} marginBottom={"7rem"} justifyContent={"center"}>
                <Spinner size={"lg"} color="#E66F15" />
                </Flex>
                :
                <>
                <Text onClick={() => navigate(-1)} cursor={"pointer"} fontWeight={"600"} color={"#1A1A1A"}><ArrowBackIcon transform={"translate(-4px,-1px)"} />Back</Text>
                <HStack flexWrap={"wrap"} mt={5} alignItems={"center"} gap="1rem">
                    <Text fontSize={[".95rem", "1.25rem"]} fontWeight="700" fontFamily={"Clash Display"}>{details?.title}</Text>
                    {!details?.isResolved && <Badge color={details?.isResolved ? "#FFF" : "#AED100"} bg={details?.isResolved ? "green" : "#F6FECC"} p="4px" transform={"translateY(-7px)"} fontWeight="600">{details?.isResolved ? "RESOLVED" : "UNRESOLVED"}</Badge>}
                </HStack>
                <br />
                <Box maxW={"660px"} margin="0 auto">
                    <Text color="#1A1A1A" mb={3} fontWeight={"700"} textAlign="center" fontSize="1rem" fontFamily={"Clash Display"}>Dispute Thread</Text>
                    <Box padding={"1rem"} overflowY="scroll" position={"relative"} height={"600px"} paddingBottom="6rem" border="1px solid #EAEAEA" borderRadius={"0px 0px 20px 20px"}>
                        <Text color="#666666" mb={7} fontWeight={"500"} textAlign="center" fontSize=".85rem">{moment(details?.messages[0]?.sentAt).calendar()}</Text>

                        

                        {details?.messages?.map(({senderId, text, sentBy, sentAt, _id}) => {
                            return (
                                <Flex zIndex={"2"} key={_id} justifyContent={senderId === currentUser?._id ? "flex-end" : sentBy === "admin" ? "center" : "flex-start"}>
                                    <Box minW={"280px"} position={"relative"} padding={".75rem .8rem 1.3rem .8rem"} borderRadius={senderId === currentUser?._id ? "24px 24px 0px 24px" : sentBy === "admin" ? "0px" : "0px 24px 24px 24px"} fontWeight={sentBy === "admin" ? "600" : "500"} maxW={"280px"} mb={4} bg={senderId === currentUser?._id ? "#FAE2D0" : sentBy === "admin" ? "#E66F15" : "#F6FECC"} color={sentBy === "admin" ? "#fff" : "#1A1A1A"}>
                                        <Text fontSize={".85rem"} textDecoration={sentBy === "admin" ? "underline" : null} color={sentBy === "admin" ? null : "#9c9c9c"}>{senderId === currentUser?._id ? null : sentBy === "admin" ? "Admin" : capitalizeFirstLetter(sentBy) }</Text>
                                        {text?.slice(0, 25) === "http://res.cloudinary.com" ?
                                            <a target="_blank" href={text}><Box mb={3} h="200px" backgroundImage={text} backgroundSize={"cover"} borderRadius={"12px"}></Box></a>
                                            :
                                            <Text>{text}</Text>
                                        }
                                        <Text fontSize={".7rem"} color={sentBy === "admin" ? "#fff" : "#9c9c9c"} right={senderId !== currentUser?._id && sentBy !== "admin" ? "6%" : "2%"} bottom="3%" position={"absolute"}>{moment(sentAt).calendar()}</Text>
                                     </Box>
                                </Flex>
                            )
                        })}

                       
                    </Box>

                    <Box width="100%" bg="#fff" margin={"-4.5rem auto 0 auto"} padding=".6rem .6rem" bottom={"3%"} borderRadius={"0px 0px 20px 20px"}>
                        <InputGroup width={"100%"}>
                            <Input onChange={e => setResponse(e.target.value)} value={response || response ? response?.name : ""} bg="#fff" borderRadius={"24px"} padding="1.6rem 1rem" placeholder="Compose a message..." _placeholder={{color: "#8C8C8C", fontSize: ".85rem"}} />
                            <InputRightElement
                            children={
                                <Flex transform={"translateY(6px)"} marginLeft={"-7rem"} alignItems={"center"} gap="2.5rem">
                                    <Box position={"relative"} maxW={"30px"} overflowX={"hidden"}>
                                        <Input type="file" zIndex={"3"} onChange={(e) =>setResponse(e.target.files[0])} opacity={"0"} cursor={"pointer"} />
                                        <Image position={"absolute"} left={"0"} top="25%" cursor={"pointer"} transform={"scale(1.3)"} src={Gallery} />
                                    </Box>
                                    {loadingr ? <Spinner color="orange" size={"sm"} /> : <Image onClick={respond} cursor={"pointer"} transform={"scale(1.3)"} src={Send} />}
                                </Flex>
                            }
                            />
                        </InputGroup>
                        <Box position={"absolute"} h="8px" width={"640px"} zIndex="10" bg="#fff" borderRadius={"0 0 4px 4px"}></Box>
                    </Box>
                    
                    

                </Box>
                </>}
            </Box>
        </Box>
    )
}

export default DisputeThread;