import { Box, Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Spinner, Button, Image, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Address from "./components/address";
import Category from "./components/category";
import Condition from "./components/condition";
import Description from "./components/description";
import Location from "./components/location";
import Media from "./components/media";
import Name from "./components/name";
import Price from "./components/price";
import Illustration from '../itemDetails/illustration.svg'
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../services/api";
import { useSelector } from "react-redux";


const ListingForm = () => {
    const [step, setStep] = useState("one");

    const [category, setCategory] = useState("");
    const [subcategory, setSubCategory] = useState("");
    const [minDPrice, setMinDPrice] = useState("");
    const [maxDPrice, setMaxDPrice] = useState("");
    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [condition, setCondition] = useState("");
    const [location, setLocation] = useState("");
    const [defect, setDefect] = useState("")
    const [address, setAddress] = useState("");
    const [price, setPrice] = useState("");
    const [negotiable, setNegotiable] = useState("");
    const [free, setFree] = useState("");
    const [media, setMedia] = useState([]);
    const [tags, setTags] = useState([]);
    
    const [logger, setLogger] = useState(false);
    const [loading, setLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
    const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure();

    const currentUser = useSelector(({userData})=>  userData?.currentUser);

    const navigate = useNavigate()

    useEffect(() => {
        if(!Object.keys(currentUser).includes('bankAccount') && currentUser?.phoneNumber?.length < 1) {
            onOpen3()
        }
    }, [])

    const datax = {
        name: name,
        category: category?.name,
        subcategory: subcategory?.name,
        minDeliveryPrice: subcategory?.minDeliveryPrice,
        maxDeliveryPrice: subcategory?.maxDeliveryPrice,
        description: desc,
        condition: condition,
        defectDesc: defect,
        pickUpAddress: address,
        pickUpLocation: {
            state: location.split(" ")[1], 
            landmarkArea: location.split(" ")[0]
        },
        sellerPrice: price,
        isNegotiable: negotiable,
        tags: tags,
        imageURLs: media,
        "videoURL": "https://wallpaperaccess.com/full/676550.jpg"
    }

    const listItem = async () => {
        setLoading(true)

        try {
            const {data} = await axiosInstance.post("/sale-items", datax)
            // console.log(data)
            onOpen2()
        } catch (error) {
            console.log(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    const handleNewList = () => {
        window.location.href = "/list-item"
    }

    const handleFinish = () => {
        window.location.href = "/"
    }

    return (
        <Box px={[".8rem", "1.5rem", "2.5rem", "3rem"]}>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Text fontSize={"1.25rem"} fontWeight="700" fontFamily={"Clash Display"}>List an Item</Text>
                <Text onClick={() => navigate("/list-giveaway-item")} fontSize={"1rem"} fontWeight="600" fontFamily={"Clash Display"} color={"#E66F15"} textDecoration={"underline"} cursor={"pointer"}>List an Item for free?</Text>
            </Flex>
            
            <br />
            <Box maxW={"450px"} margin="0 auto">
                {step === "one" && <Category setStep={setStep} setCategory={setCategory} setName={setName} setDesc={setDesc} setSubCategory={setSubCategory} />}
                {/* {step === "two" && <Name setStep={setStep} setName={setName} />}
                {step === "three" && <Description setStep={setStep} setDesc={setDesc} />}
                {step === "four" && <Condition setStep={setStep} setCondition={setCondition} />} */}
                {step === "five" && <Location setStep={setStep} setLocation={setLocation} setCondition={setCondition} setAddress={setAddress} setDefect={setDefect} />}
                {/* {step === "six" && <Address setStep={setStep} setAddress={setAddress} />}
                {step === "seven" && <Price setStep={setStep} setPrice={setPrice} setNegotiable={setNegotiable} setFree={setFree} />} */}
                {step === "eight" && <Media setLogger={setLogger} setMedia={setMedia} setPrice={setPrice} setNegotiable={setNegotiable} setFree={setFree} setTags={setTags} category={category} onOpen={onOpen} />}
            </Box>


            <Modal isCentered size={"xxl"} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent mx={[".5rem", "15rem"]} overflow="scroll" padding={".8rem"}>
                <ModalCloseButton />
                <ModalHeader fontFamily="Clash Display">Item Listing Preview</ModalHeader>
                <ModalBody lineHeight={"40px"}>

                    <Flex justifyContent={"space-between"} gap="1.5rem" flexWrap={"wrap"}>
                        <Box minWidth={"350px"} backgroundImage={media[0]} flex="1" height={["200px", "350px"]} backgroundSize={["contain", "cover"]} backgroundRepeat="no-repeat" borderRadius={"10px"}></Box>
                        <Box flex="1">
                            <Text fontWeight={"500"} color="#8C8C8C">Description</Text>
                            <Text fontWeight={"500"} color="#1A1A1A">{desc}</Text>
                            <Text fontWeight={"500"} color="#8C8C8C">Condition</Text>
                            <Text fontWeight={"500"} color="#1A1A1A">{condition}</Text>
                            <Text fontWeight={"500"} color="#8C8C8C">Location</Text>
                            <Text fontWeight={"500"} color="#1A1A1A">{location}</Text>
                            <Text fontWeight={"500"} color="#8C8C8C">Category</Text>
                            <Text fontWeight={"500"} color="#1A1A1A">{category?.name}</Text>
                        </Box>
                    </Flex>
                    <Text mt={3} fontFamily={"Clash Display"} fontWeight="700" fontSize={"1.3rem"}>{name}</Text>
                    <Text mt={1} fontSize="1.1rem" fontWeight="600">₦{parseInt(price)?.toLocaleString()}</Text>

                    <Button mb={3} type="submit" onClick={listItem} fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={6} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Submit Listing"}</Button>
                    <br />
                </ModalBody>
                </ModalContent>
            </Modal>

            <Modal isCentered isOpen={isOpen2} onClose={onClose}>
                <ModalOverlay />
                <ModalContent margin={"0 .5rem"}>
                <ModalCloseButton onClick={() => onClose2()} />
                <ModalBody>
                    <Image width={"30%"} margin="0 auto" src={Illustration} />
                    <Text mt={3} fontFamily='Clash Display' color="#030303" fontWeight={"700"} fontSize="1.2rem" textAlign={"center"}>Item Listed successfully</Text>
                    <Text mt={2} color="#908888" fontFamily='Clash Display' textAlign={"center"}>Your item has been listed on declutters.  We’ll notify you if a potential buyer shows interest.</Text>
                    <Flex mt={6} justifyContent={"center"} gap="2rem">
                    <Button onClick={handleNewList} fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #E66F15" bg="#fff" mt={2} width={400} color="#E66F15" fontSize=".9rem" fontWeight={"700"}>List another item</Button>
                    <Button onClick={handleFinish} fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width={400} color="#fff" fontSize=".9rem" fontWeight={"700"}>Finish</Button>
                    </Flex>
                    <br />
                </ModalBody>
                </ModalContent>
            </Modal>

            <Modal closeOnOverlayClick={false} isCentered isOpen={isOpen3} onClose={onClose3}>
                <ModalOverlay />
                <ModalContent margin={"0 .5rem"}>
                {/* <ModalCloseButton onClick={() => onClose3()} /> */}
                <ModalBody>
                    <Text mt={3} fontFamily='Clash Display' color="#030303" fontWeight={"700"} fontSize="1.2rem" textAlign={"center"}>Complete your profile</Text>
                    <Text mt={2} color="#908888" fontFamily='Clash Display' textAlign={"center"}>Please complete your profile information by adding your <strong>phone number</strong>, <strong>pickup address,</strong> and <strong>bank account</strong> to list items.</Text>
                    <Flex mt={6} justifyContent={"center"} gap="2rem">
                    <Button onClick={() => navigate("/")} fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #E66F15" bg="#fff" mt={2} width={400} color="#E66F15" fontSize=".9rem" fontWeight={"700"}>Back to Home</Button>
                    <Button onClick={() => navigate("/settings")} fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width={400} color="#fff" fontSize=".9rem" fontWeight={"700"}>Complete Profile</Button>
                    </Flex>
                    <br />
                </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default ListingForm;