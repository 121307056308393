import { AddIcon, ArrowBackIcon, ArrowRightIcon, ChevronDownIcon, HamburgerIcon, MinusIcon, SearchIcon } from "@chakra-ui/icons";
import { Avatar, Box, Button, Divider, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex, FormControl, Image, Input, InputGroup, InputLeftAddon, InputLeftElement, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Radio, RadioGroup, Spinner, Stack, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Logo from "./../../assets/Declutterslogo.png";
import One from "./icons/one.svg";
import Two from "./icons/two.svg";
import Three from "./icons/three.svg";
import Four from "./icons/four.svg";
import Five from "./icons/five.svg";
import Six from "./icons/six.svg";
import Seven from "./icons/seven.svg";
import Eight from "./icons/eight.svg";
import Nine from "./icons/nine.svg";
import Ten from "./icons/ten.svg";
import Usericon from './usericon.svg';
import Carticon from './carticon.svg';
import hearticon from './hearticon.svg';
import Filtericon from './filtericon.svg';
import Filtericon2 from './filtericon2.svg';
import Bellicon from './bellicon.svg';
import { Fragment, useEffect, useState } from "react";
import Receive from './icons/notifIcons/receive.svg';
import Review from './icons/notifIcons/review.svg';
import Listing from './icons/notifIcons/listing.svg';
import Transaction from './icons/notifIcons/transaction.svg';
import axiosInstance, { baseURL } from "../../services/api";
import moment from "moment";
import io from 'socket.io-client';
import { baseUrl2 } from "../../config";
import states from "../../utils/states";
import capitalizeFirstLetter from "../../utils/capitalize";

const conditions = [
    "Brand New",
    "Almost New",
    "Fairly Used",
    "Used"
]
const prices = [
    "Lowest to Highest",
    "Highest to Lowest"
]



  const dataxy = [
    {
      reason: "I’ll really like the hand dryer because i own couple of wigs and hand dryers are really expensive to own these days lorem ipsum I’ll really like the hand dryer because i own couple of wigs and hand dryers are really expensive to own these days lorem ipsum ."
    },
    {
      reason: "I’ll really like the hand dryer because i own couple of wigs and hand dryers are really expensive to own these days lorem ipsum I’ll really like the hand dryer because i own couple of wigs and hand dryers are really expensive to own these days lorem ipsum ."
    },
    {
      reason: "I’ll really like the hand dryer because i own couple of wigs and hand dryers are really expensive to own these days lorem ipsum I’ll really like the hand dryer because i own couple of wigs and hand dryers are really expensive to own these days lorem ipsum ."
    },
    {
      reason: "I’ll really like the hand dryer because i own couple of wigs and hand dryers are really expensive to own these days lorem ipsum I’ll really like the hand dryer because i own couple of wigs and hand dryers are really expensive to own these days lorem ipsum ."
    }
  ]


const Header = () => {
    const [view, setView] = useState("main");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { pathname } = useLocation();
    const currentUser = useSelector(({userData})=>  userData?.currentUser);
    const [savedItems, setSavedItems] = useState([]);
    const [loadings, setLoadings] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingm, setLoadingm] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [giftee, setGiftee] = useState("");
    const [keyword, setKeyword] = useState("");
    const [trigger, setTrigger] = useState(Math.random());

    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
    const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure();

    const [value1, setValue1] = useState("");
    const [value2, setValue2] = useState("");
    const [value3, setValue3] = useState("");
    const [value4, setValue4] = useState("");
    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState("");


    const [categories, setCategories] = useState([]);
    const [loadingc, setLoadingc] = useState(true);
    const [loading2, setLoading2] = useState(true);

    const [showMore, setShowMore] = useState(false);
    const [locationSearch, setLocationSearch] = useState("");

    const navigate = useNavigate();
    const toast = useToast()
    const socket = io(baseUrl2, {
        transports: ["websocket"]
        })

    useEffect(() => {
        socket.on("connect", () => {
            socket.emit("identify", currentUser?._id)
            // console.log(socket.connected);
        });
    }, []);

    useEffect(() => {
        socket.on("newNotification", (msg) => {
            setTrigger(Math.random())
            // toast({
            //     // title: "An Error Occurred",
            //     description: msg,
            //     status: "success",
            //     position: "top-right",
            //     duration: 5000,
            //     isClosable: true,
            // });
        })
    }, [])

    const getNotifications = async () => {
        try {
            const {data} = await axiosInstance.get("/notifications/in-app")
            // console.log('notifications', data?.data)
            setNotifications(data?.data)
        } catch (error) {
            console.log(error?.response)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if(currentUser) {
        getNotifications()
        }
     }, [loading, loadingm, trigger])

     useEffect(() => {
        axiosInstance.get("/item-categories")
        // .then(res => console.log(res?.data))
        .then(res => setCategories(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, [])


    useEffect(() => {
        if(currentUser) {
        axiosInstance.get("/saved-items")
        // .then(res => console.log("vvv", res?.data?.data))
        .then(res => setSavedItems(res?.data?.data))
        .then(res => setLoadings(false))
        .catch(error => console.log(error))
        }
    }, []);

    const handleMenuClicks = () => {
        onClose()
    }

    const markAll = async() => {
        setLoadingm(true)
        try {
            const {data} = await axiosInstance.patch("/notifications/in-app/mark-all-as-opened")
            // console.log(data)
        } catch(error) {
            console.log(error?.response?.data?.message)
        } finally {
            setLoadingm(false)
        }
    }

    const handleSearch = (e) => {
        e.preventDefault()
        navigate(`/search/${keyword}`)
      }

    const handleNotifClick = (type, entityId, id) => {
        switch (type) {
            case 'Dispute':
                navigate(`/orders/dispute-thread/${entityId}`);
            break;
            case 'Free Item':
                navigate(`/item-details/${entityId}`, {state: "free"})
            break;
            case 'Sale Item':
                navigate(`/item-details/${entityId}`)
            break;
            case 'Transaction':
                navigate(`/orders/${entityId}`)
            break;
            case 'Sale Item Offer':
                navigate(`/offers`)
            break;
            case 'Free Item Entrant':
                navigate(`/giveaway`)
            break;
            default:
            break;
        }
        // type === "Sale Item Offer" ? navigate(`/offers`) : type === "Free Item Entrant" ? navigate(`/giveaway`) :  null
    }

    const logout = async () => {
        await localStorage.removeItem("DCV1TK")
        window.location.href = "/"
    }

    return (
        <Box borderBottom={"1px solid #EAEAEA"} px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={["1rem", "1.2rem", "1.35rem", "1.5rem"]}>
            <Flex alignItems={"center"} justifyContent="space-between">
                <Flex flex={"1"} alignItems={"center"}>
                    <HamburgerIcon onClick={() => onOpen()} mr={["1rem", "1.7rem"]} cursor={"pointer"} fontSize={"1.3rem"} />
                    <Image width={["50%", "17%", "19%"]} cursor={"pointer"} onClick={() => navigate("/")} src={Logo} alt="declutters_logo" />

                    <form onSubmit={handleSearch}>
                    <FormControl 
                        marginLeft="3rem"
                        width={"330px"}
                        display={["none", "none", "none", "block"]}>
                        <InputGroup>
                            {/* <Popover>
                                <PopoverTrigger>
                                    <InputLeftAddon cursor={"pointer"} border={"1.5px solid #1A1A1A !important"}  bg="linear-gradient(117.97deg, rgba(209, 251, 0, 0.24) 2.73%, rgba(209, 251, 0, 0.35) 36.57%, rgba(223, 159, 14, 0.574257) 61.68%, rgba(230, 111, 21, 0.69) 82.22%)" children={<Text cursor={"pointer"} color="#333" fontWeight="600" fontSize={".8rem"} padding=".4rem 0" borderRadius={"21px"}>Shop by Category <ChevronDownIcon fontSize={"1rem"} /></Text>} />
                                </PopoverTrigger>
                                <PopoverContent marginLeft={"18.4rem"} width={"700px"} cursor="pointer" _focus={{boxShadow: "none"}}>
                                    <PopoverArrow marginLeft={"-9rem"} />
                                    <PopoverBody cursor={"default"} padding={"1.5rem"}>
                                        <Flex gap={"1.5rem"} justifyContent={"space-between"}>
                                        <Box flex="1">
                                            <Text mb={2} fontFamily={"Clash Display"} fontSize=".85rem" fontWeight="700" whiteSpace={"nowrap"}>Living & Dining Rooms</Text>
                                            <Text _hover={{color: "#E66F15"}} transition="250ms ease" mb={2} cursor={"pointer"} fontSize=".8rem">Sofas</Text>
                                            <Text _hover={{color: "#E66F15"}} transition="250ms ease" mb={2} cursor={"pointer"} fontSize=".8rem">Center Tables</Text>
                                            <Text _hover={{color: "#E66F15"}} transition="250ms ease" mb={2} cursor={"pointer"} fontSize=".8rem">Dining Tables</Text>
                                            <Text _hover={{color: "#E66F15"}} transition="250ms ease" mb={2} cursor={"pointer"} fontSize=".8rem">TV Stand</Text>
                                            <Text _hover={{color: "#E66F15"}} transition="250ms ease" mb={2} cursor={"pointer"} fontSize=".8rem">Wall Shelf</Text>
                                            <Text _hover={{color: "#E66F15"}} transition="250ms ease" mb={2} cursor={"pointer"} fontSize=".8rem">Chair</Text>
                                            <Text _hover={{color: "#E66F15"}} transition="250ms ease" mb={2} cursor={"pointer"} fontSize=".8rem">Bar Stool</Text>
                                            <Text _hover={{color: "#E66F15"}} transition="250ms ease" mb={2} cursor={"pointer"} fontSize=".8rem">Others</Text>
                                        </Box>
                                        
                                        </Flex>
                                        <Flex mt={3} gap={"1.5rem"} justifyContent={"space-between"}>
                                        <Box flex="1">
                                            <Text mb={2} fontFamily={"Clash Display"} fontSize=".85rem" fontWeight="700" whiteSpace={"nowrap"}>Gadgets & Elecronics</Text>
                                            <Text _hover={{color: "#E66F15"}} transition="250ms ease" mb={2} cursor={"pointer"} fontSize=".8rem">Television</Text>
                                            <Text _hover={{color: "#E66F15"}} transition="250ms ease" mb={2} cursor={"pointer"} fontSize=".8rem">Phone</Text>
                                            <Text _hover={{color: "#E66F15"}} transition="250ms ease" mb={2} cursor={"pointer"} fontSize=".8rem">Laptop</Text>
                                            <Text _hover={{color: "#E66F15"}} transition="250ms ease" mb={2} cursor={"pointer"} fontSize=".8rem">Computer</Text>
                                            <Text _hover={{color: "#E66F15"}} transition="250ms ease" mb={2} cursor={"pointer"} fontSize=".8rem">Speakers</Text>
                                            <Text _hover={{color: "#E66F15"}} transition="250ms ease" mb={2} cursor={"pointer"} fontSize=".8rem">Camera</Text>
                                            <Text _hover={{color: "#E66F15"}} transition="250ms ease" mb={2} cursor={"pointer"} fontSize=".8rem">Others</Text>
                                        </Box>
                                        
                                        </Flex>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover> */}
                            <Input onChange={e => setKeyword(e.target.value)} placeholder="Search Items" outline={"none"} focusBorderColor="none" _placeholder={{fontSize: ".8rem", fontFamily: "Creato Display"}} border={"1.5px solid #1A1A1A !important"} borderRadius="20px" />
                            <InputRightElement
                                children={
                                <Flex gap="1rem" marginLeft={"-2rem"}>
                                    {/* <Image onClick={() => onOpen3()} cursor={"pointer"} opacity="1" transform={"scale(1)"} src={Filtericon} /> */}
                                    <Button type="submit" isDisabled={keyword.length < 3} onClick={() => navigate(`/search/${keyword}`)} bg="#E66F15" fontSize={".7rem"} padding=".2rem .9rem" height={"32px"} borderRadius="21px" color="#fff">Search</Button>
                                </Flex>
                                }
                            />
                        </InputGroup>
                    </FormControl>
                </form>

                <Flex display={["none", "none", "flex", "flex"]} alignItems={"center"} transform={"translateX(1rem)"}>
                    <Text fontSize={".8rem"} fontWeight={"500"} whiteSpace={"nowrap"}>Advanced Search:</Text>
                    <Box onClick={() => onOpen3()} borderRadius={"6px"} cursor={"pointer"} display={"flex"} padding={".2rem"} justifyContent={"center"} textAlign={"center"} height={"20px"} width="24px" bg="#212121">
                        <Image src={Filtericon2} />
                    </Box>
                    
                </Flex>
                
                </Flex>

                

                <Flex flex={"1"} alignItems={"center"} gap="1rem" justifyContent={"flex-end"}>
                    {!currentUser && 
                        <>
                        <Flex display={["none", "flex"]} cursor={"pointer"} whiteSpace="nowrap" gap=".2rem" justifyContent={"flex-end"}>
                        <Link to="/login"><Text fontWeight={"600"} fontSize=".85rem" fontFamily="Clash Display" textDecoration={"underline"}>Sign In</Text></Link>
                            <Text>/</Text>
                            <Link to="/signup"><Text fontWeight={"600"} fontSize=".85rem" fontFamily="Clash Display" textDecoration={"underline"}>Create Account</Text></Link>
                        </Flex>
                        </>}

                    <Flex  alignItems={"center"} gap="1rem" justifyContent={"flex-end"}>
                        <Text whiteSpace={"nowrap"} fontSize={".9rem"} fontWeight="500" _hover={{color: "#E66F15"}} display={["none", "none", "flex", "flex",]} onClick={currentUser ? () => navigate("/orders") : () => navigate("/login")} cursor={"pointer"}>My Orders</Text>
                        <Image onClick={() => onOpen2()} cursor={"pointer"} display={["block", "block", "none", "none"]} src={Carticon} />



                        <Image display={["none", "none", "flex", "flex",]} transform={"scale(.85)"} onClick={currentUser ? () => navigate("/saved-items") : () => navigate("/login")} cursor={"pointer"} src={hearticon} />
                        {currentUser && <Image display={["none", "none", "flex", "flex",]} transform={"scale(.85)"} onClick={() => navigate("/settings")} cursor={"pointer"} src={Usericon} />}
                        <Popover size="xl">
                            <PopoverTrigger>
                                <Box position={"relative"}>
                                     <Image transform={"scale(.95)"} cursor={"pointer"} src={Bellicon} />
                                     {notifications.filter(i => !i?.isOpened).length > 0 && <Box position={"absolute"} top="0" right={"12%"} height={"8px"} width="8px" borderRadius={"50%"} backgroundColor="#E66F15"></Box>}
                                </Box>
                            </PopoverTrigger>
                            <PopoverContent maxH="500px" py={".6rem"} _focus={{ounline: "none", boxShadow: "none"}}>
                            <PopoverArrow />
                            {/* <PopoverCloseButton /> */}
                            <PopoverHeader fontFamily={"Clash Display"} fontWeight="700" color="#1A1A1A">Notifications</PopoverHeader>
                            <PopoverBody maxH="450px" overflowY={"scroll"}>
                                {currentUser ?
                                view === "main" ?
                                currentUser ?
                                <>
                                {notifications.filter(i => moment(i?.createdAt).calendar().toLowerCase().includes("today")).length > 0 &&
                                    <Box maxW={"90%"} margin="2.2rem auto 1.5rem auto" borderTop="1px solid #F6F6F6">
                                    <Text borderRadius={"20px"} bg={"#F6FECC"} color="#2E1604" margin={"-1.2rem auto 0 auto"} width={"fit-content"} fontWeight={"500"} fontSize=".75rem" padding={".4rem 1.2rem"}>Latest</Text>
                                </Box>}
                                {notifications.filter(i => moment(i?.createdAt).calendar().toLowerCase().includes("today")).map(({description, isOpened, entityId, createdAt, type, _id}, index) => {
                                return (
                                    <Fragment key={index}>
                                        <Flex 
                                        onClick={() => handleNotifClick(type, entityId, _id)}
                                        // onClick={type === "Dispute" ? () => navigate(`/orders/dispute-thread/${entityId}`) : type === "Free Item" ? () => navigate(`/item-details/${entityId}`, {state: "free"}) : type === "Sale Item" ? () => navigate(`/item-details/${entityId}`) : type === "Transaction" ? () => navigate(`/orders/${entityId}`) :type === "Sale Item" ? () => navigate(`/item-details/${entityId}`) : type === "Transaction" ? () => navigate(`/orders/${entityId}`) : type === "Sale Item Offer" ? () => navigate(`/offers`) : type === "Free Item Entrant" ? () => navigate(`/giveaway`) :  null} 
                                        cursor={"pointer"} py=".7rem" gap=".5rem" alignItems={"flex-start"}>
                                            {type === "Payment" && <Image src={Receive} />}
                                            {type === "Free Item" && <Image src={Receive} />}
                                            {type === "Sale Item" && <Image src={Listing} />}
                                            {type === "Sale Item Offer" && <Image src={Listing} />}
                                            {type === "Dispute" && <Image src={Review} />}
                                            {type === "Transaction" && <Image src={Transaction} />}
                                            <Box>
                                            <Text color="#2E1604" fontSize={".85rem"} fontWeight="600">{description}</Text>
                                            <Text mt={1} color="#8C8C8C" fontSize={".75rem"} fontWeight="600">{moment(createdAt).calendar()}</Text>
                                            </Box>
                                        </Flex>
                                        {index + 1 < notifications.filter(i => moment(i?.createdAt).calendar().toLowerCase().includes("today")).length && <Divider />}
                                    </Fragment>
                                )
                                })}

                                {notifications.filter(i => moment(i?.createdAt).calendar().toLowerCase().includes("today")).length > 0 &&
                                    <Box maxW={"90%"} margin="2.2rem auto 1.5rem auto" borderTop="1px solid #F6F6F6">
                                    <Text borderRadius={"20px"} bg={"#F6FECC"} color="#2E1604" margin={"-1.2rem auto 0 auto"} width={"fit-content"} fontWeight={"500"} fontSize=".75rem" padding={".4rem 1.2rem"}>Older</Text>
                                </Box>}
                                {notifications.filter(i => !moment(i?.createdAt).calendar().toLowerCase().includes("today")).map(({description, isOpened, entityId, createdAt, type, _id}, index) => {
                                return (
                                    <Fragment key={index}>
                                        <Flex
                                        onClick={() => handleNotifClick(type, entityId, _id)}
                                        //  onClick={type === "Dispute" ? () => navigate(`/orders/dispute-thread/${entityId}`) : type === "Free Item" ? () => navigate(`/item-details/${entityId}`, {state: "free"}) : type === "Sale Item" ? () => navigate(`/item-details/${entityId}`) : type === "Transaction" ? () => navigate(`/orders/${entityId}`) :type === "Sale Item" ? () => navigate(`/item-details/${entityId}`) : type === "Transaction" ? () => navigate(`/orders/${entityId}`) : type === "Sale Item Offer" ? () => navigate(`/offers`) : type === "Free Item Entrant" ? () => navigate(`/giveaway`) :  null} 
                                         cursor={"pointer"} py=".7rem" gap=".5rem" alignItems={"flex-start"}>
                                            {type === "Payment" && <Image src={Receive} />}
                                            {type === "Free Item" && <Image src={Receive} />}
                                            {type === "Sale Item" && <Image src={Listing} />}
                                            {type === "Sale Item Offer" && <Image src={Listing} />}
                                            {type === "Dispute" && <Image src={Review} />}
                                            {type === "Transaction" && <Image src={Transaction} />}
                                            <Box>
                                            <Text color="#2E1604" fontSize={".85rem"} fontWeight="600">{description}</Text>
                                            <Text mt={1} color="#8C8C8C" fontSize={".75rem"} fontWeight="600">{moment(createdAt).calendar()}</Text>
                                            </Box>
                                        </Flex>
                                        {index + 1 < notifications.filter(i => moment(i?.createdAt).calendar().toLowerCase().includes("today")).length && <Divider />}
                                    </Fragment>
                                )
                                })}
                                </>
                                :
                                <Text textAlign={"center"} mb="2rem">Sign In to see notifications</Text>
                                :
                                view === "submissions" ?
                                <Box>
                                <Text mt={3} onClick={() => setView("giveaway")} cursor={"pointer"} flex={"1"} fontWeight={"600"} color={"#1A1A1A"} fontFamily="Clash Display"><ArrowBackIcon mr={6} transform={"translate(-4px,-1px)"} />Giveaway Items</Text>

                                {dataxy?.map(({reason}, index) => {
                                    return (
                                    <Fragment key={index}>
                                        <Box py={5} display={"flex"} alignItems="start" gap=".7rem"> 
                                        <Radio onChange={e => setGiftee(e.target.value)} transform={"translateY(5px)"} colorScheme={"orange"} value={index}></Radio>
                                        <Text fontSize={".85rem"} fontWeight="500" color="#666666">{reason}</Text>
                                        </Box>
                                        <Divider />
                                    </Fragment>
                                    )
                                })}
                                <Flex mt={4} justifyContent={"center"}>
                                    <Button isDisabled={giftee === ""} padding={"1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"80%"} color="#fff" fontSize=".9rem" fontWeight={"700"}>Choose Selected</Button>
                                </Flex>
                                </Box>
                                :
                                <Box>
                                <Text mt={3} onClick={() => setView("main")} cursor={"pointer"} flex={"1"} fontWeight={"600"} color={"#1A1A1A"} fontFamily="Clash Display"><ArrowBackIcon mr={6} transform={"translate(-4px,-1px)"} />Giveaway Items</Text>
                                <Flex mt={4} marginBottom="4rem" wrap={"wrap"} gap=".3rem" rowGap={"1rem"} justifyContent="space-between">
                                {savedItems?.map(({item, _id}) => {
                                    return (
                                        // <Link to={`/item-details/${item?._id}`} style={{flex: "0", flexBasis: "140px", flexGrow: "0"}} key={_id}>
                                            <Box onClick={() => setView("submissions")} cursor={"pointer"} overflow={"hidden"} minWidth="140px" maxW={"145px"} key={_id} flexGrow="0">
                                                <Box position={"relative"} overflow={"hidden"} borderRadius="4px" height="180px" width={"100%"} backgroundImage={item?.imageURLs[0]} backgroundSize="cover">
                                                </Box>
                                                <Box padding={"0 .3rem"}>
                                                    <Text mt={1} fontWeight="600" fontSize={".85rem"}>{item?.name}</Text>
                                                    <Text fontWeight="500" fontSize={".8rem"} color="#2E1604">7 Entries</Text>
                                                </Box>
                                            </Box>
                                        // </Link>
                                    )
                                })}
                                </Flex>
                                </Box>
                                :
                                <Text mt={4} textAlign={"center"}> <strong style={{color: "#E66F15", cursor: "pointer", textDecoration: "underline"}}>Sign in</strong> to see your notifications</Text>
                                }
                            </PopoverBody>
                            <PopoverFooter height={"70px"}>
                                <Text onClick={markAll} cursor={"pointer"} transform={"translateY(3px)"} fontWeight="600" fontSize={".85rem"} color={"gray"} textAlign={"center"}>{loadingm ? <Spinner size={"xs"} /> : "Mark all as read"}</Text>
                            </PopoverFooter>
                            </PopoverContent>
                            
                        </Popover>
                    </Flex>
                    <Button onClick={() => window.location.href = currentUser ? "/list-item" : "login"} padding={".9rem 1.5rem"} fontFamily="Clash Display" borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"fit-content"} color="#fff" fontSize={[".7rem", ".9rem"]} fontWeight={"600"}>Sell an Item</Button>
                </Flex>
            </Flex>


            <Modal isOpen={isOpen2} onClose={onClose2}>
                <ModalOverlay />
                <ModalContent margin="5rem .5rem 0 .5rem">
                {/* <ModalCloseButton onClick={() => onClose2()} /> */}
                <ModalBody pb={4}>
                    <form onSubmit={handleSearch}>
                        <FormControl mt={4}>
                            <InputGroup>
                                <Input variant="flushed" onChange={e => setKeyword(e.target.value)} type="text" borderRadius={"8px"} padding={"1.3rem 1rem"} placeholder="Search.." _placeholder={{color: "#8C8C8C"}} fontSize=".9rem" />
                                <InputRightElement children={
                                    <Flex ml="-5rem">
                                        <Text fontSize={".85rem"} fontWeight={"500"} whiteSpace={"nowrap"}>Search By:</Text>
                                        <Box padding={".2rem .4rem"} bg={"#212121"} ml=".3rem" borderRadius={"6px"}>
                                            <Image onClick={() => onOpen3()} cursor={"pointer"} opacity="1" transform={"scale(.9)"} src={Filtericon2} />
                                        </Box>
                                    </Flex>
                                // <Image onClick={() => onOpen3()} cursor={"pointer"} opacity="1" transform={"scale(1)"} src={Filtericon} />
                                } />
                            </InputGroup>
                        </FormControl>
                    </form>
                </ModalBody>
                </ModalContent>
            </Modal>




            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
            >
                <DrawerOverlay />
                <DrawerContent>
                <DrawerBody>
                    <Flex marginTop="3rem" justifyContent={"center"}>
                    {currentUser ?
                    <Flex alignItems={"center"}>
                        <Avatar size={"md"} transform="scale(.9)" mr={2} src={currentUser?.profilePicture} name={currentUser?.firstName + " " + currentUser?.lastName} />
                        <Box>
                        <Text fontFamily={"Clash Display"} color="#1A1A1A" fontWeight={"700"}>{currentUser?.firstName + " " + currentUser?.lastName}</Text>
                        <Text color="#4A4949" fontSize={".9rem"}>{currentUser?.email}</Text>
                        </Box>
                    </Flex>
                    :
                    <>
                        <Flex display={["none", "flex"]} cursor={"pointer"} whiteSpace="nowrap" gap=".2rem" justifyContent={"flex-end"}>
                        <Link to="/login"><Text fontWeight={"600"} fontSize=".85rem" fontFamily="Clash Display" textDecoration={"underline"}>Sign In</Text></Link>
                            <Text>/</Text>
                            <Link to="/signup"><Text fontWeight={"600"} fontSize=".85rem" fontFamily="Clash Display" textDecoration={"underline"}>Create Account</Text></Link>
                        </Flex>
                        </>}
                    </Flex>

                    <div className="nav" style={{justifyContent: "left", width: "100%", marginTop: "2rem", overflowY: "scroll"}}>
                        <NavLink onClick={handleMenuClicks} activeClassName='activex' className='list-item' exact to={!currentUser ? '/login' : '/saved-items'}><img style={{marginRight: ".7rem"}} src={One} alt="" /><span className='label'>Saved Items</span><ArrowRightIcon className="hov" marginLeft={"6rem"} fontSize={".5rem"} transform="translateY(1px)" color={"orange"} /></NavLink>
                        <NavLink onClick={handleMenuClicks} isActive={() => ['/dashboard/request/make-request', '/dashboard/request/drivers', '/dashboard/wallet'].includes(pathname)} activeClassName='activex' className='list-item' exact to={!currentUser ? '/login' : '/sell'}><img style={{marginRight: ".7rem"}} src={Two} alt="" /><span className='label'>Sell</span><ArrowRightIcon className="hov" marginLeft={"9.5rem"} fontSize={".5rem"} transform="translateY(1px)" color={"orange"} /></NavLink>
                        <NavLink onClick={handleMenuClicks} activeClassName='activex' className='list-item' exact to={!currentUser ? '/login' : '/orders'}><img style={{marginRight: ".7rem"}} src={Three} alt="" /><span className='label'>My Orders</span><ArrowRightIcon className="hov" marginLeft={"3.7rem"} fontSize={".5rem"} transform="translateY(1px)" color={"orange"} /></NavLink>
                        <Box width={"87%"} margin="1.5rem 0" border="0.5px solid #D9D9D9"></Box>
                        <NavLink onClick={handleMenuClicks} activeClassName='activex' className='list-item' exact to={!currentUser ? '/login' : '/wallet'}><img style={{marginRight: ".7rem"}} src={Three} alt="" /><span className='label'>Payments</span><ArrowRightIcon className="hov" marginLeft={"8.4rem"} fontSize={".5rem"} transform="translateY(1px)" color={"orange"} /></NavLink>
                        <NavLink onClick={handleMenuClicks} activeClassName='activex' className='list-item' exact to={!currentUser ? '/login' : '/offers'}><img style={{marginRight: ".7rem"}} src={Four} alt="" /><span className='label'>Offers</span><ArrowRightIcon className="hov" marginLeft={"8.4rem"} fontSize={".5rem"} transform="translateY(1px)" color={"orange"} /></NavLink>
                        <NavLink onClick={handleMenuClicks} activeClassName='activex' className='list-item' exact to={!currentUser ? '/login' : '/ratings'}><img style={{marginRight: ".7rem"}} src={Five} alt="" /><span className='label'>Ratings & Reviews</span><ArrowRightIcon className="hov" marginLeft={"3.3rem"} fontSize={".5rem"} transform="translateY(1px)" color={"orange"} /></NavLink>
                        <NavLink onClick={handleMenuClicks} activeClassName='activex' className='list-item' exact to={!currentUser ? '/login' : '/settings'}><img style={{marginRight: ".7rem"}} src={Six} alt="" /><span className='label'>Settings</span><ArrowRightIcon className="hov" marginLeft={"7.5rem"} fontSize={".5rem"} transform="translateY(1px)" color={"orange"} /></NavLink>
                        <Box width={"87%"} margin="1.5rem 0" border="0.5px solid #D9D9D9"></Box>
                        <NavLink onClick={handleMenuClicks} activeClassName='activex' className='list-item' exact to={!currentUser ? '/login' : '/giveaway'}><img style={{marginRight: ".7rem"}} src={Ten} alt="" /><span className='label'>Giveaways</span><ArrowRightIcon className="hov" marginLeft={"5.9rem"} fontSize={".5rem"} transform="translateY(1px)" color={"orange"} /></NavLink>
                        <NavLink onClick={handleMenuClicks} activeClassName='activex' className='list-item' exact to={!currentUser ? '/login' : '/alert-me'}><img style={{marginRight: ".7rem"}} src={Four} alt="" /><span className='label'>Alert Me</span><ArrowRightIcon className="hov" marginLeft={"8.4rem"} fontSize={".5rem"} transform="translateY(1px)" color={"orange"} /></NavLink>
                        <NavLink onClick={handleMenuClicks} activeClassName='activex' className='list-item' exact to={!currentUser ? '/login' : '/clearance'}><img style={{marginRight: ".7rem"}} src={Four} alt="" /><span className='label'>Clearance Sales</span><ArrowRightIcon className="hov" marginLeft={"3.4rem"} fontSize={".5rem"} transform="translateY(1px)" color={"orange"} /></NavLink>
                        <NavLink onClick={handleMenuClicks} activeClassName='activex' className='list-item' exact to={!currentUser ? '/login' : '/support'}><img style={{marginRight: ".7rem"}} src={Seven} alt="" /><span className='label'>Customer Support</span><ArrowRightIcon className="hov" marginLeft={"3.2rem"} fontSize={".5rem"} transform="translateY(1px)" color={"orange"} /></NavLink>
                        <NavLink onClick={handleMenuClicks} activeClassName='activex' className='list-item' exact to='/faqs'><img style={{marginRight: ".7rem"}} src={Eight} alt="" /><span className='label'>FAQs</span><ArrowRightIcon className="hov" marginLeft={"8.7rem"} fontSize={".5rem"} transform="translateY(1px)" color={"orange"} /></NavLink>
                        {/* <NavLink onClick={handleMenuClicks} activeClassName='activex' className='list-item' exact to="https://blog.decluttersapp.com"><img style={{marginRight: ".7rem"}} src={Four} alt="" /><span className='label'>Blog</span><ArrowRightIcon className="hov" marginLeft={"8.4rem"} fontSize={".5rem"} transform="translateY(1px)" color={"orange"} /></NavLink> */}
                        <br />
                        {currentUser && <div onClick={logout} style={{cursor: "pointer", background: "#F0FEAA", width: "70%", alignSelf: "left", marginLeft: ".9rem", marginTop: "1rem"}} activeClassName='activex' className='list-item lg'><img style={{marginRight: ".7rem"}} src={Nine} alt="" /><span className='label'>Log Out</span></div>}
                    </div>
                </DrawerBody>
                </DrawerContent>
            </Drawer>


            <Drawer
                isOpen={isOpen3}
                placement='right'
                onClose={onClose3}
            >
                <DrawerOverlay />
                <DrawerContent>
                <DrawerBody overflowY={"scroll"}>
                    <Text margin={"1rem 0"} fontFamily={"Clash Display"} fontWeight="700" fontSize={"1rem"}>Filters</Text>
                    <Text fontWeight={"600"} fontSize=".9rem">By Category</Text>
                    <RadioGroup margin={"1rem 0"} onChange={setValue1} value={value1}>
                        <Stack direction='column' lineHeight={"30px"}>
                        {categories?.map(({name}, index) => {
                            return (
                                <Radio size={"sm"} colorScheme="orange" fontSize={".8rem"} key={index} value={name}>{name}</Radio>
                            )
                        })}
                        </Stack>
                    </RadioGroup>
                    <Text fontWeight={"600"} fontSize=".9rem">By Item Condition</Text>
                    <RadioGroup margin={"1rem 0"} onChange={setValue2} value={value2}>
                        <Stack direction='column' lineHeight={"30px"}>
                        {conditions?.map((i, index) => {
                            return (
                                <Radio size={"sm"} colorScheme="orange" fontSize={".8rem"} key={index} value={i}>{i}</Radio>
                            )
                        })}
                        </Stack>
                    </RadioGroup>
                    {/* <Text fontWeight={"600"} fontSize=".9rem">By Price</Text>
                    <RadioGroup margin={"1rem 0 0 0"} onChange={setValue3} value={value3}>
                        <Stack direction='column' lineHeight={"30px"}>
                        {prices?.map((i, index) => {
                            return (
                                <Radio size={"sm"} colorScheme="orange" fontSize={".8rem"} key={index} value={i}>{i}</Radio>
                            )
                        })}
                        </Stack>
                    </RadioGroup> */}
                    <Text fontWeight={"600"} mt={5} mb={3} fontSize=".9rem">By Price Range</Text>
                    <Flex mb={8} gap=".7rem" maxW={"80%"}>
                        <FormControl>
                            <InputGroup>
                                <Input onChange={e => setMinPrice(e.target.value)} type="tel" outline={"none"} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15", outline: "none"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"10px"} padding={".7rem .4rem"} placeholder="0" _placeholder={{color: "#8C8C8C"}} fontSize=".9rem" />
                                <InputRightElement children={<Text transform={"translateY(1px)"} color="#B3B3B3" fontSize={".75rem"} marginLeft="-1rem">Min (₦)</Text>} />
                            </InputGroup>
                        </FormControl>
                        <FormControl>
                            <InputGroup>
                                <Input onChange={e => setMaxPrice(e.target.value)} type="tel" outline={"none"} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15", outline: "none"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"10px"} padding={".7rem .4rem"} placeholder="0" _placeholder={{color: "#8C8C8C"}} fontSize=".9rem" />
                                <InputRightElement children={<Text transform={"translateY(1px)"} color="#B3B3B3" fontSize={".75rem"} marginLeft="-1rem">Max (₦)</Text>} />
                            </InputGroup>
                        </FormControl>
                    </Flex>

                    <Text fontWeight={"600"} fontSize=".9rem">By Item Location</Text>
                    <FormControl mt={3}>
                            <InputGroup>
                                <InputLeftElement children={<SearchIcon color={"gray"} />} />
                                <Input onChange={e => setLocationSearch(e.target.value)} type="text" outline={"none"} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15", outline: "none"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"20px"} padding={".7rem .4rem .7rem 2rem"} placeholder="Search Location" _placeholder={{color: "#8C8C8C", fontSize: ".8rem"}} fontSize=".9rem" />
                            </InputGroup>
                        </FormControl>
                    <RadioGroup margin={"1rem 0 0 0"} onChange={setValue4} value={value4}>
                        <Stack direction='column' lineHeight={"30px"}>
                        <Radio size={"sm"} colorScheme="orange" fontSize={".8rem"} value={"Anywhere"}>Anywhere</Radio>
                        {states?.filter(i => i.includes(locationSearch)).slice(0, showMore == true ? states.length : 5)?.map((i, index) => {
                            return (
                                <Radio size={"sm"} colorScheme="orange" fontSize={".8rem"} key={index} value={i}>{capitalizeFirstLetter(i)}</Radio>
                            )
                        })}
                        </Stack>
                        <Flex cursor={"pointer"} mt={3} alignItems={"center"}>
                            {!showMore ?
                            <AddIcon marginRight={".4rem"} fontSize=".8rem" />
                            :
                            <MinusIcon marginRight={".4rem"} fontSize=".8rem" />
                            }
                            <Text onClick={() => setShowMore(!showMore)} fontSize={".85rem"} fontWeight="600">Show {showMore ? "less" : "more"}</Text>
                        </Flex>
                    </RadioGroup>

                    <Button onClick={() => navigate("/search/null", {state: {category: value1, condition: value2, min: minPrice, max: maxPrice, location: value4}})} mb={1} mt={6} padding={"1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"100%"} color="#fff" fontSize=".9rem" fontWeight={"700"}>Apply</Button>
                    {/* <Button mb={8} padding={".8rem 1rem"} borderRadius="20px" border="1px solid #E66F15" bg="#FFF" mt={2} width={"100%"} color="#1A1A1A" fontSize=".9rem" fontWeight={"700"}>Reset</Button> */}

                </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Box>
    )
}

export default Header;