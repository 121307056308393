import { ArrowBackIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Divider, Checkbox, FormControl, FormLabel, Input, InputGroup, InputRightElement, Image, Spinner, Progress, ProgressLabel, Radio } from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import Upload from '../assets/media.svg';

const Media = ({setLogger, setMedia, setPrice, setNegotiable, setFree, setTags, onOpen, category}) => {
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const [loading5, setLoading5] = useState(false);
    const [loading6, setLoading6] = useState(false);
    const [loading7, setLoading7] = useState(false);
    const [loading8, setLoading8] = useState(false);
    const [chunkArray, setChunkArray] = useState([]);
    const [tagsx, setTagsx] = useState([]);

    const [progressValue, setProgressValue] = useState(0)

    const [price, setPricex] = useState("");
    const [negotiable, setNegotiablex] = useState(true);
    const [free, setFreex] = useState(false);

 

    function Resolver() {
        if(price && selectedImages.length >= 2) {
            return <Button type="submit" onClick={uploadImages} fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Continue"}</Button>
        } else {
            return <Button disabled type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>Continue</Button>
        }
    }

    const handleSelectImages = e => {
        if(e.target.files) {
          const chunk = Array.from(e.target.files).map(file => file)
          setChunkArray(chunk)
          const fileArray = Array.from(e.target.files).map(file => URL.createObjectURL(file))
        //   console.log(fileArray)
  
          setSelectedImages(prevImages => prevImages.concat(fileArray))
          Array.from(e.target.files).map(
            (file) => URL.revokeObjectURL(file)
          )
        }
        console.log(chunkArray)
    }

    const renderPhotos = source => {
        return source.map((photo, index) => {
          return <Box key={photo} height="93px" borderRadius={"12px"} flex="1" backgroundImage={photo} backgroundSize="cover"></Box>
        //     <Text>{`loading${index + 1}` == true ? "done" : null}</Text>
        //   </>
        })
    }

    const handleContinue = () => {
        setPrice(price)
        setNegotiable(negotiable)
        setFree(free)
        setMedia(selectedImages)
        setLogger(true)
        onOpen()
    }

    const uploadImages = async () => {
        setPrice(price)
        setNegotiable(negotiable)
        setFree(free)
        setMedia(uploadedImages)
        setTags(tagsx)
        setLogger(true)


        const formData = new FormData()
        formData.append("file", chunkArray[0])
        formData.append("upload_preset", "jmrkiyu0")
        formData.append("cloud_name", "declutters")
        

        if(!uploadedImages?.length) {
        setProgressValue(1)
        setLoading(true)
        try {
            const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
            // console.log(data)
            uploadedImages.push(data.url)
            setProgressValue(25)
            setLoading1(true)

            try {
                const formData = new FormData()
                formData.append("file", chunkArray[1])
                formData.append("upload_preset", "jmrkiyu0")
                formData.append("cloud_name", "declutters")

                const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
                // console.log(data)
                uploadedImages.push(data.url)
                setProgressValue(50)
                onOpen()
                setLoading2(true)

                if(chunkArray.length === 2) {
               
                try {
                    const formData = new FormData()
                    formData.append("file", chunkArray[2])
                    formData.append("upload_preset", "jmrkiyu0")
                    formData.append("cloud_name", "declutters")
    
                    const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
                    // console.log(data)
                    uploadedImages.push(data.url)
                    setProgressValue(75)
                    onOpen()
                    setLoading3(true)

                    if(chunkArray.length === 3) {

                    try {
                        const formData = new FormData()
                        formData.append("file", chunkArray[3])
                        formData.append("upload_preset", "jmrkiyu0")
                        formData.append("cloud_name", "declutters")
        
                        const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
                        // console.log(data)
                        uploadedImages.push(data.url)
                        setProgressValue(90)
                        // console.log("final", uploadedImages)
                        onOpen()
                        setLoading4(true)

                        if(chunkArray.length === 4) {

                            try {
                                const formData = new FormData()
                                formData.append("file", chunkArray[4])
                                formData.append("upload_preset", "jmrkiyu0")
                                formData.append("cloud_name", "declutters")
                
                                const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
                                // console.log(data)
                                uploadedImages.push(data.url)
                                setProgressValue(90)
                                // console.log("final", uploadedImages)
                                onOpen()
                                setLoading5(true)

                                if(chunkArray.length === 5) {

                                    try {
                                        const formData = new FormData()
                                        formData.append("file", chunkArray[5])
                                        formData.append("upload_preset", "jmrkiyu0")
                                        formData.append("cloud_name", "declutters")
                        
                                        const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
                                        // console.log(data)
                                        uploadedImages.push(data.url)
                                        setProgressValue(90)
                                        // console.log("final", uploadedImages)
                                        onOpen()
                                        setLoading6(true)

                                        
                                        if(chunkArray.length === 6) {

                                            try {
                                                const formData = new FormData()
                                                formData.append("file", chunkArray[6])
                                                formData.append("upload_preset", "jmrkiyu0")
                                                formData.append("cloud_name", "declutters")
                                
                                                const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
                                                // console.log(data)
                                                uploadedImages.push(data.url)
                                                setProgressValue(90)
                                                // console.log("final", uploadedImages)
                                                onOpen()
                                                setLoading7(true)
                                                
                                                if(chunkArray.length === 7) {

                                                    try {
                                                        const formData = new FormData()
                                                        formData.append("file", chunkArray[7])
                                                        formData.append("upload_preset", "jmrkiyu0")
                                                        formData.append("cloud_name", "declutters")
                                        
                                                        const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
                                                        // console.log(data)
                                                        uploadedImages.push(data.url)
                                                        setProgressValue(90)
                                                        // console.log("final", uploadedImages)
                                                        onOpen()
                                                        setLoading8(true)
                                                        
                                            
                                                        
                                                    } catch(error) {
                                                        console.log(error?.response?.data?.message)
                                                        setLoading8(false)
                                                    }
                                                }
                                    
                                                
                                            } catch(error) {
                                                console.log(error?.response?.data?.message)
                                                setLoading7(false)
                                            }
                                        }
                            
                                        
                                    } catch(error) {
                                        console.log(error?.response?.data?.message)
                                        setLoading6(false)
                                    }
                                }
                                
                    
                                
                            } catch(error) {
                                console.log(error?.response?.data?.message)
                                setLoading5(false)
                            }
                        }
                        
            
                        
                    } catch(error) {
                        console.log(error?.response?.data?.message)
                        setLoading4(false)
                    }
                }
                    
                } catch(error) {
                    console.log(error?.response?.data?.message)
                    setLoading3(false)
                }
            }
                
            } catch(error) {
                console.log(error?.response?.data?.message)
                setLoading2(false)
            }
        } catch(error) {
            console.log(error?.response?.data?.message)
            setLoading1(false)
        } finally {
            setLoading(false)
        }
     } else {
        onOpen()
     }
    }

    const addTag = (item) => {
        if(!tagsx.includes(item)) {
            tagsx.push(item)
        } else {
            // const newArr = tagsx.filter(i => i !== item)
            setTagsx(tagsx.filter(i => i !== item))
        }
        console.log(tagsx)
    }


    return (
        <Box>
            <Text fontSize={["1.1rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>Enter a price</Text>
            <Text mt={2} fontWeight={"600"} color="#667085" fontSize={".9rem"}>You will get 100% of your listed price here as a seller. Declutters will add a 10% charge to the buyer</Text>

            <FormControl mt={4} mb={3}>
                <InputGroup>
                    <Input onChange={e => setPricex(parseInt(e.target.value))} type="tel" outline={"none"} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15", outline: "none"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"10px"} padding={"1.3rem 1rem"} placeholder="0" _placeholder={{color: "#8C8C8C"}} fontSize=".9rem" />
                    <InputRightElement children={<Text transform={"translateY(1px)"} color="#B3B3B3" fontSize={".9rem"} marginLeft="-3rem">Amount (₦)</Text>} />
                </InputGroup>
            </FormControl>
            <Checkbox colorScheme={"orange"} defaultChecked={negotiable} onChange={e => setNegotiablex(e.target.checked)} fontWeight={"500"} fontSize=".9rem" mb={2}>Price is Negotiable?</Checkbox><br />
            {/* <Checkbox colorScheme={"orange"} defaultChecked={free} onChange={e => setFreex(e.target.checked)} fontWeight={"500"} fontSize=".9rem">Give away for free</Checkbox> */}
            <br /><br /><br />
            <Text fontSize={["1.1rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>Add Tags (Optional)</Text>
            <Text mt={2} fontWeight={"600"} color="#667085" fontSize={".9rem"}>Add tags to allow your item be easily found by potential buyers.</Text>
            <Flex mt={3} flexWrap="wrap" gap=".5rem">
                {category?.tags?.map((i, index) => {
                    return (
                        <Radio size={"md"} onChange={() => addTag(i)} colorScheme="orange" fontSize={".8rem"} borderRadius=".7rem" border="1px solid #E66F15" key={index} value={i}>{i}</Radio>
                        // <Text fontSize={".9rem"} onClick={() => addTag(i)} cursor={"pointer"} bg={tagsx.includes(i) ? "#E66F15" : "#fff"} color={tagsx.includes(i) ? "#fff" : "#000"} padding={".5rem .7rem"} transition="250ms ease" borderRadius=".7rem" border="1px solid #E66F15" key={index}>{i}</Text>
                    )
                })}
            </Flex>
            <br /><br /><br />
            <Text fontSize={["1.1rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>Add images</Text>
            <Text mt={2} fontWeight={"600"} color="#667085" fontSize={".9rem"}>Upload {category?.name === "Bulk Sales" ? "a minimun of 2 up to 8" : "a minimun of 2 and up to 4"} high-quality images of the exact item you are listing. Must be the same item buyer will get.</Text>
            <Flex mt={5} alignItems={"center"} gap="1rem">
                <Box display={category?.name === "Bulk Sales" ? selectedImages.length == 8 ? "none" : null : selectedImages.length == 4 ? "none" : null} bg="#fff" overflow={"hidden"} position={"relative"} cursor={"pointer"} height="93px" borderRadius={"12px"} flex="1" border={"1px dashed #8C8C8C"} textAlign="center">
                    <Image src={Upload} margin="1.5rem auto 0 auto" />
                    <Text fontSize={".8rem"} fontFamily={"Clash Display"} color="#667085">Add image</Text>
                    <Input bg="red" width="100%" marginTop="-68%" cursor={"pointer"} opacity="0" marginLeft="-50%" height="100%" position={"absolute"} 
                    type="file" multiple maxLength={4} 
                    // accept="image/*" capture="environment"
                    onChange={handleSelectImages} />
                </Box>
                {renderPhotos(selectedImages)}
                {selectedImages.length == 0 &&
                <>
                <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                </>}
                {selectedImages.length == 1 &&
                <>
                <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                </>}
                {selectedImages.length == 2 &&
                <>
                <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                </>}
            </Flex>
            {selectedImages?.length !== 0 && selectedImages?.length < 2 ? <Text mt="1rem" color="crimson">Please add a minimum of two(2) images.</Text> : null}
            <br />
            {loading &&
            <Progress colorScheme={"orange"} hasStripe value={progressValue} transition="250ms ease" borderRadius="6px" isAnimated>
                <ProgressLabel>Uploading Media ({progressValue}%)</ProgressLabel>
            </Progress>}
            <br />
            <Resolver />

            
        </Box>
    )
}

export default Media;