import { Box, Button, Flex, FormControl, FormHelperText, FormLabel, Heading, Image, Input, Spinner, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Google from '../../assets/google.svg';
import Instagram from '../../assets/instagram.svg';
import Navbar2 from "../../components/navbar2/navbar2";
import axiosInstance from "../../services/api";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const toast = useToast()

    const navigate = useNavigate();

    const datax = {
        email
    }

    const requestCode = async(e) => {
        e.preventDefault()
        setLoading(true)

        try {
            const {data} = await axiosInstance.post("auth/reset-password", datax)
            navigate("/login")
            toast({
                // title: "An Error Occurred",
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } catch(error) {
            toast({
                // title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box>
            <Navbar2 />
            <Box maxWidth={"500px"} margin="0 auto 20rem auto">
                <Heading marginTop={"6rem"} fontWeight={700} fontSize="1.4rem" color="#030303" fontFamily='Clash Display'>Forgot Password</Heading>
                <Text mt={2} color={"#908888"} fontWeight="500" fontFamily='Creato Display'>We will send a new password to your email address</Text>
                <br />
                <form onSubmit={requestCode}>
                    <FormControl mt={3}>
                        <FormLabel transform={"translateY(3px)"} fontWeight="600" fontFamily='Creato Display' fontSize=".9rem">Email Address</FormLabel>
                        <Input onChange={e => setEmail(e.target.value)} required padding={"1.3rem 1rem"} placeholder="Enter a valid email address" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" />
                    </FormControl>

                    <Button type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={8} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Forgot Password"}</Button>
                    
                </form>
            </Box>
        </Box>
    )
}

export default ForgotPassword;

