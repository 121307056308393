import "./home.scss";
import { useEffect, useState } from "react";
// import Modal from "./shareModal/modal";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { ArrowForwardIcon, ChevronDownIcon } from "@chakra-ui/icons";
import Banner from "../components/banner";
import Categories from "./forgotPassword/homeComponents/categories";
import Trending from "./forgotPassword/homeComponents/trending";
import Newsletter from "../components/newsletter/newletter";
import Categories2 from "./forgotPassword/homeComponents/categoriesTwo";
import Header from "../components/header/header";
import Give from '../assets/one.jpg';
import Recent from '../assets/three.jpg';
import New from '../assets/newcx.jpg';
import Staff from '../assets/four.jpg';
import axiosInstance from "../services/api";

const Home = () => {

  const currentUser = useSelector(({ userData }) => userData?.currentUser);

  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const [clearanceItems, setClearanceItems] = useState([]);
    const [loadingxxx, setLoadingxxx] = useState(true);

  useEffect(() => {
    // axiosInstance.get("sale-items")
    axiosInstance.get("deals")
    // .then(res => console.log("vvv", res?.data?.data))
    .then(res => setItems(res?.data?.data))
    .then(res => setLoading(false))
    .catch(error => console.log(error))
}, []);

useEffect(() => {
  // axiosInstance.get("sale-items")
  axiosInstance.get("/sale-items-clearance-groups")
  // .then(res => console.log("vvv", res?.data?.data))
  .then(res => setClearanceItems(res?.data?.data))
  .then(res => setLoadingxxx(false))
  .catch(error => console.log(error))
}, [loadingxxx]);

  return (
    <Box className="home">
      {/* <Navbar /> */}
      <Header />
      <Categories2 />
      <Banner />
      <Categories />
      <Trending today={false} filter="dd" title={"Newly Listed"} link="/recent" />
      <Trending today={false} filter="hh" title="Trending Items" link="/trending" />
      {clearanceItems?.length > 0 && <Trending today={false} filter="clearance" title="Clearance Sales" link="/clearance-sales" />}
      {items?.filter(i => i.item?.isAvailable === true)?.length > 0 && <Trending today={true} filter="today" title={"Today's Deals"} link="/todays-deals" />}
      <Trending stFilter={"relocation"} today={false} filter="st" title="Garage Sales & Relocation" link="/admin-picks?type=relocation" />
      <Trending stFilter={"staff"} today={false} filter="st" title="Staff Picks" link="/admin-picks?type=staff" />
      <Trending today={false} filter="dd" title={"All Items"} link="/all" />
      
     
      {/* <Trending today={false} filter="free" title={"Giveaway Items"} link="/giveaway-items" /> */}
      <Flex mt="3rem" bg="rgba(229, 111, 21, .2)" px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={["1rem", "1rem", "2rem", "2rem"]} gap="1.2rem" justifyContent={"space-between"} flexWrap={"wrap"}>
          <Box onClick={() => navigate(`/giveaway-items`)} cursor={"pointer"} bg="#FFF" overflow={"hidden"} flex="1 0 300px" height={"400px"} padding={"1rem"}>
            <Text fontSize={"1.25rem"} fontWeight="700" fontFamily={"Clash Display"} mb={3}>Giveaway Items</Text>
            <Box height={"80%"} bg="lightgray" backgroundImage={Give} backgroundSize="cover" backgroundPosition={"center"}></Box>
            <Flex mt={3} alignItems={"center"} justifyContent={"space-between"}>
              <Text fontWeight="500">View All</Text>
              <ArrowForwardIcon color={"#D1FB00"} />
            </Flex>
            
          </Box>

          <Box onClick={() => navigate(`/brand-new`)} cursor={"pointer"} bg="#FFF" overflow={"hidden"} flex="1 0 300px" height={"400px"} padding={"1rem"}>
            <Text fontSize={"1.25rem"} fontWeight="700" fontFamily={"Clash Display"} mb={3}>Brand New Items</Text>
            <Box height={"80%"} bg="lightgray" backgroundImage={New} backgroundSize="cover" backgroundPosition={"center"}></Box>
            <Flex mt={3} alignItems={"center"} justifyContent={"space-between"}>
              <Text fontWeight="500">View All</Text>
              <ArrowForwardIcon color={"#D1FB00"} />
            </Flex>
            
          </Box>
    
          <Box overflow={"hidden"} flex="1 0 300px" height={"400px"}>

            <Box onClick={() => navigate(`/recent`)} height={"48%"} bg="#FFF" mb="5%" padding={"1rem"} cursor={"pointer"}>
              <Text fontSize={"1.25rem"} fontWeight="700" fontFamily={"Clash Display"} mb={3}>Newly Listed</Text>
              <Box height={"57%"} bg="lightgray" backgroundImage={Recent} backgroundSize="cover" backgroundPosition={"center"}></Box>
              <Flex mt={2} alignItems={"center"} justifyContent={"space-between"}>
                <Text fontWeight="500">View All</Text>
                <ArrowForwardIcon color={"#D1FB00"} />
              </Flex>
            </Box>

            <Box onClick={() => navigate(`/staff-picks`)} cursor={"pointer"} height={"48%"} bg="#FFF" padding={"1rem"}>
              <Text fontSize={"1.25rem"} fontWeight="700" fontFamily={"Clash Display"} mb={3}>Staff Picks</Text>
              <Box height={"57%"} bg="lightgray" backgroundImage={Staff} backgroundSize="cover" backgroundPosition={"center"}></Box>
              <Flex mt={2} alignItems={"center"} justifyContent={"space-between"}>
                <Text fontWeight="500">View All</Text>
                <ArrowForwardIcon color={"#D1FB00"} />
              </Flex>
            </Box>

          </Box>

          <Box cursor={"pointer"} bg="#FFF" overflow={"hidden"} flex={["1 0 350px","0 0 350px","0 0 350px","0 0 350px"]} height={"400px"} padding={"1rem"}>
            <Text fontSize={"1.8rem"} fontWeight="700" color={"orange.500"} fontFamily={"Clash Display"} mb={3}>Get Offers for your items</Text>
            <Text fontWeight="500" mt={4} fontFamily={"Clash Display"}>Seamlessly sell your used household items on Declutters</Text>
            <Text fontWeight="500" mt={4} fontFamily={"Clash Display"}>We'll handle everything from promoting, to payment, deleviery and everything in between.</Text>
            <br />
            <br />
            <br />
            <Button textAlign={"left"} onClick={() => navigate(currentUser ? "/list-item" : "login")} padding={".9rem 1.5rem"} fontFamily="Clash Display" borderRadius="20px" border="1px solid #EFEDED" bg="orangered" width={"100%"} color="#F5F5F5" fontSize={[".7rem", ".9rem"]} fontWeight={"600"}>Sell an Item <ArrowForwardIcon ml={7} color={"#D1FB00"} /></Button>
          </Box>
      </Flex>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Newsletter />
    </Box>
  );
};

export default Home;
