import { Badge, Box, Button, Divider, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, FormControl, FormLabel, Image, Input, Modal, ModalBody, ModalContent, ModalOverlay, Radio, RadioGroup, Skeleton, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure, useToast } from "@chakra-ui/react";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import axiosInstance from "../../services/api";
import Empty from "./empty.svg";
import Location from '../catalog/location.svg';
import { ArrowBackIcon, CheckCircleIcon } from "@chakra-ui/icons";
import Header from "../../components/header/header";




const UserGiveaways = () => {
    const [loading, setLoading] = useState(true);
    const [loadingx, setLoadingx] = useState(false);
    const [loadingy, setLoadingy] = useState(false);
    // const [loadingxx, setLoadingxx] = useState(false);
    // const [loadingxxx, setLoadingxxx] = useState(false);
    const [createdGiveaways, setCreatedGiveaways] = useState([]);
    const [appliedGiveaways, setAppliedGiveaways] = useState([]);
    // const [offersx, setOffersx] = useState([]);
    // const [items, setItems] = useState([]);

    const [idx, setIdx] = useState("");
    const [image, setImage] = useState("");
    const [namex, setNamex] = useState("");
    const [pricex, setPricex] = useState("");
    const [idxx, setIdxx] = useState("");
    const [entrantsx, setEntrantsx] = useState("");
    const [giftee, setGiftee] = useState("")
    const [sellView, setSellView] = useState("main");

    
    const [offerAmount, setOfferAmount] = useState("");
    const [offerId, setOfferId] = useState("");

    const navigate = useNavigate();


    useEffect(() => {
        axiosInstance.get("/free-items/user")
        // .then(res => console.log("vvvxx", res?.data?.data))
        .then(res => setCreatedGiveaways(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, [])

    useEffect(() => {
        axiosInstance.get("/free-items/entrant")
        // .then(res => console.log("vvvxx", res?.data?.data))
        .then(res => setAppliedGiveaways(res?.data?.data))
        .then(res => setLoadingy(false))
        .catch(error => console.log(error))
    }, [])

    const currentUser = useSelector(({userData})=>  userData?.currentUser);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

    const toast = useToast();


    const handleSellView = async(id, img, name, idi, entrants) => {
        setIdx(id)
        setImage(img)
        setNamex(name)
        setIdxx(idi)
        setEntrantsx(entrants)
        setSellView("list")

        // onOpen()

        // try {
        //     const { data } = await axiosInstance.get(`/sale-items/${id}/seller-item-offers`)
        //     console.log("seller offers", data?.data)
        //     setOffersx(data?.data)
        //     setSellView("list")
        // } catch(error) {
        //     console.log(error?.response?.data?.message)
        // } finally {
        //     onClose()
        // }
    }

    // const handleDrawer = (price, ofid) => {
    //     setPricex(price)
    //     setOfferId(ofid)
    //     onOpen2()
    // }

    const selectWinner = async () => {
        // console.log(JSON.parse(giftee))
        setLoadingx(true)
        const datax = {
            reason: JSON.parse(giftee)?.reason
        }
        try {
            const {data} = await axiosInstance.patch(`/free-items/${idx}/select-winner/${JSON.parse(giftee)?.user}`, datax)
            console.log(data)
            // window.location.reload()
            onClose2()
            toast({
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } catch(error) {
            console.log(error?.response?.data?.message)
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoadingx(false)
        }
    }


    function Resolver() {
        if(giftee) {
            return <Button onClick={selectWinner} isDisabled={entrantsx?.filter(i => i.isSelected).length > 0} type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} color="#fff" fontSize=".9rem" fontWeight={"700"}>{loadingx ? <Spinner /> : "Choose selected application" }</Button>
        } else {
            return <Button disabled type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} color="#fff" fontSize=".9rem" fontWeight={"700"}>{loadingx ? <Spinner /> : "Choose selected application" }</Button>
        }
    }

    return (
        <Box>
            <Header />
            <Box px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={"2rem"} marginBottom="18rem">
                <Flex justifyContent={"space-between"} alignItems="center">
                    <Text color="#1A1A1A" fontWeight={"700"} fontSize="1.4rem" fontFamily={"Clash Display"}>Giveaways</Text>
                    <Button onClick={() => navigate(currentUser ? "/list-giveaway-item" : "login")} padding={"1.3rem 1.5rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} color="#fff" fontSize=".9rem" fontWeight={"700"}>List Item for Giveaway</Button>
                </Flex>
                <br />
                <br />
                <Tabs>
                    <TabList maxW="800px" margin={"0 auto"} borderBottom={"none"} display={"flex"} justifyContent="space-around">
                        <Tab _selected={{borderBottom: "2px solid #E66F15", color: "#1A1A1A"}} fontWeight="500" fontSize={"1rem"} color="#666666">Created Giveaways</Tab>
                        <Tab _selected={{borderBottom: "2px solid #E66F15", color: "#1A1A1A"}} fontWeight="500" fontSize={"1rem"} color="#666666">Applied</Tab>
                    </TabList>
                    <TabPanels>
                        
                        <TabPanel>
                            {loading ? 
                                <Flex marginTop="4rem" wrap={"wrap"} gap="1rem" rowGap={"3rem"}>
                                    <Stack minWidth="250px" flex="0">
                                        <Skeleton height={"230"} borderRadius="12px" />
                                        <Skeleton height={"25px"} />
                                        <Skeleton height={"25px"} />
                                    </Stack>
                                    <Stack minWidth="250px" flex="0">
                                        <Skeleton width="100%" height={"230"} borderRadius="12px" />
                                        <Skeleton width="100%" height={"25px"} />
                                        <Skeleton width="100%" height={"25px"} />
                                    </Stack>
                                    <Stack minWidth="250px" flex="0">
                                        <Skeleton height={"230"} borderRadius="12px" />
                                        <Skeleton height={"25px"} />
                                        <Skeleton height={"25px"} />
                                    </Stack>
                                    <Stack minWidth="250px" flex="0">
                                        <Skeleton height={"230"} borderRadius="12px" />
                                        <Skeleton height={"25px"} />
                                        <Skeleton height={"25px"} />
                                    </Stack>
                                    <Stack minWidth="250px" flex="0">
                                        <Skeleton height={"230"} borderRadius="12px" />
                                        <Skeleton height={"25px"} />
                                        <Skeleton height={"25px"} />
                                    </Stack>
                                </Flex>
                                :
                                createdGiveaways?.length < 1 ? 
                                <Box textAlign={"center"} marginTop="2rem">
                                    <Image src={Empty} transform="scale(.6)" width={"auto"} margin="0 auto" />
                                    <Text transform={"translateY(-50px)"} color="#1A1A1A" fontSize={".9rem"} fontFamily={"Clash Display"} fontWeight="600">No Giveaways Created</Text>
                                </Box>
                                :
                                sellView === "main" ?
                                <Flex marginTop="4rem" wrap={"wrap"} gap="1rem" rowGap={"3rem"}>
                                    {createdGiveaways?.map(({imageURLs, name, pickUpLocation, _id, entrants}) => {
                                        return (
                                                <Box onClick={() => handleSellView(_id, imageURLs[0], name, _id, entrants)} cursor={"pointer"} overflow={"hidden"} minWidth={["150px", "250px" ]} flex={["1", "1", "0", "0"]} key={_id}>
                                                    <Box overflow={"hidden"} borderRadius="12px" height="230px" width={"100%"} backgroundImage={imageURLs[0]} backgroundSize="cover"></Box>
                                                    <Box padding={"0 .3rem"}>
                                                        <Text mt={2} fontWeight="600" textTransform={"capitalize"}>{name}</Text>
                                                        <Flex mt={2} justifyContent={"space-between"}>
                                                            <Flex alignItems={"center"}>
                                                                <Image mr={1} transform={"translateY(1.5px)"} width={"20%"} src={Location} />
                                                                <Text mt={1} whiteSpace="nowrap" color="#908888">{pickUpLocation?.landmarkArea}</Text>
                                                            </Flex>
                                                            {/* <Text mt={1} fontSize=".95rem" fontWeight="600">₦{listPrice?.toLocaleString()}</Text> */}
                                                        </Flex>
                                                        <Text fontSize={".8rem"} fontWeight="600" color={entrants?.length < 1 ? "#F04438" : "#12B76A"} mt={2}>{entrants?.length} Applications</Text>
                                                        {/* <Text fontSize={".8rem"} fontWeight="600" color={offers?.length < 1 ? "#F04438" : "#12B76A"} mt={2}>{offers?.length} Offers</Text> */}
                                                    </Box>
                                                </Box>
                                        )
                                    })}
                                </Flex>
                                :
                                <Box maxW={"800px"} margin="2rem auto 0 auto">
                                    <Flex my={5}  bg="#FAE2D0" borderRadius="12px" gap="1rem" alignItems={"center"} padding="1rem 1.2rem">
                                        <Box height="60px" width="60px" backgroundImage={image} backgroundSize="cover" borderRadius="12px"></Box>
                                        <Box>
                                            <Text mb={2} fontWeight="500" color="#1A1A1A">{namex}</Text>
                                            <Text fontWeight="700" color="#1A1A1A">Free</Text>
                                        </Box>
                                    </Flex>
                                    <RadioGroup onChange={setGiftee} value={giftee}>
                                        {entrantsx?.map((i, index) => {
                                            return (
                                            <Fragment key={index}>
                                                <Flex justifyContent={"space-between"} alignItems="center">
                                                    <Box py={5} display={"flex"} alignItems="start" gap=".7rem" cursor={"pointer"}> 
                                                        <Radio transform={"translateY(5px)"} colorScheme={"orange"} value={JSON.stringify(i)}></Radio>
                                                        <Text fontSize={".85rem"} fontWeight="500" color="#666666">{i?.reason}</Text>
                                                    </Box>
                                                    {i?.isSelected && <Badge borderRadius={"4px"} colorScheme={"orange"} color="orange">Selected</Badge>}
                                                </Flex>
                                                <Divider />
                                            </Fragment>
                                            )
                                        })}
                                    </RadioGroup>

                                    <Flex mt={7} justifyContent={"center"}>
                                        <Resolver />
                                    </Flex>
                                </Box>
                                }
                        </TabPanel>


















                        <TabPanel padding={"2.5rem 0"}>
                            {appliedGiveaways?.map(({entrants, category, condition, pickUpLocation, _id, name, imageURLs}, index) => {
                                return (
                                    <Flex cursor={"pointer"} onClick={() => navigate(`/item-details/${_id}`, {state: "free"})} position={"relative"} mb="2rem" bg="#FAE2D0" key={_id} padding={"1.2rem"} borderRadius={"8px"} border="1px solid #E66F15" justifyContent={"space-between"}>
                                        <Flex gap=".8rem" alignItems={"center"}>
                                            <Box height={"80px"} width={"80px"} borderRadius={"6px"} backgroundImage={imageURLs[0]} backgroundSize={"cover"}></Box>
                                            <Box>
                                                <Text mb="1rem" textTransform={"capitalize"}><strong>{name}</strong></Text>
                                                <Text><strong>{category}</strong></Text>
                                                {/* <Text>Price range: <strong>₦{minPrice?.toLocaleString()}</strong> - <strong>₦{maxPrice?.toLocaleString()}</strong></Text> */}
                                            </Box>
                                        </Flex>
                                        <Box mt=".7rem" textAlign={"right"}>
                                            <Text mb="1rem"><strong>{condition}</strong></Text>
                                            <Text textAlign={"right"}>Location: <strong style={{textTransform: "capitalize"}}>{pickUpLocation?.landmarkArea}, {pickUpLocation?.state}</strong></Text>
                                        </Box>
                                        <Box position={"absolute"} right={"-4%"} top="0">
                                            {/* <DeleteIcon onClick={() => handleDelete(_id)} cursor={"pointer"} /> */}
                                        </Box>
                                        {entrants.filter(i => i.user === currentUser?._id)[0].isSelected && 
                                        <Text position={"absolute"} top="-20%" right={"0"} fontWeight={"500"} bg="#E66F15" borderRadius={"26px"} padding={".5rem 2rem"} border="3px solid #FFF" color="#FFF">Won <CheckCircleIcon /></Text>
                                        }
                                    </Flex>
                                )
                            })}
                            {/* <Box maxW={"400px"} margin="2rem auto 3rem auto" borderTop="1px solid #F6F6F6">
                                <Text borderRadius={"20px"} bg={"#F6FECC"} color="#2E1604" margin={"-1.2rem auto 0 auto"} width={"fit-content"} fontWeight={"500"} fontSize=".85rem" padding={".5rem 1.4rem"}>Recents</Text>
                            </Box>

                            <Box maxW={"800px"} margin="0 auto">
                                {loading ? 
                                <Flex marginTop={"7rem"} marginBottom={"7rem"} justifyContent={"center"}>
                                    <Spinner size={"lg"} color="#E66F15" />
                                </Flex>
                                :
                                createdGiveaways?.length < 1 ? 
                                <Box textAlign={"center"} marginTop="2rem">
                                    <Image src={Empty} transform="scale(.6)" width={"auto"} margin="0 auto" />
                                    <Text transform={"translateY(-50px)"} color="#1A1A1A" fontSize={".9rem"} fontFamily={"Clash Display"} fontWeight="600">No Giveaways Created</Text>
                                </Box>
                                :
                                createdGiveaways?.map(({_id, imageURLs, offers, name}) => {
                                    return (
                                        <Box key={_id}>
                                            <Flex justifyContent={"space-between"} cursor={"pointer"} mt={5} marginBottom="2.5rem" bg="#FFF" gap="1rem" alignItems={"center"}>
                                                    <Flex gap=".8rem" width={"100%"} justifyContent="space-between">
                                                        <Flex alignItems={"center"}>
                                                            <Box mr={3} height={"60px"} width="60px" backgroundSize={"cover"} backgroundImage={imageURLs[0]} borderRadius="12px"></Box>
                                                            <Box>
                                                                {offers[offers?.length - 1]?.lastOfferSenderId !== currentUser?._id && offers[offers?.length - 1]?.status === "rejected" && <Text mb={3} width={"170px"} fontWeight="600" color="#4D2507" whiteSpace={"nowrap"} fontSize=".9rem">Seller sent you a counter offer</Text>}
                                                                {offers[offers?.length - 1]?.lastOfferSenderId !== currentUser?._id && offers[offers?.length - 1]?.status === "accepted" && <Text mb={3} width={"170px"} fontWeight="600" color="#4D2507" whiteSpace={"nowrap"} fontSize=".9rem">Seller accepted your offer</Text>}
                                                                {offers[offers?.length - 1]?.lastOfferSenderId === currentUser?._id && offers[offers?.length - 1]?.status === "pending" && <Text mb={3} width={"170px"} fontWeight="600" color="#4D2507" whiteSpace={"nowrap"} fontSize=".9rem">You sent an offer</Text>}
                                                                <Text width={"170px"} fontWeight="600" color="#666666" whiteSpace={"nowrap"} fontSize=".9rem">{name}</Text>
                                                            </Box>
                                                        </Flex>

                                                        <Text fontWeight={"600"} fontSize=".9rem">₦{offers[offers?.length - 1]?.listPrice?.toLocaleString()}</Text>

                                                        {offers[offers?.length - 1]?.lastOfferSenderId === currentUser?._id ? 

                                                        offers[offers?.length - 1]?.status === "rejected" ? 
                                                        <Text minW="205px" textAlign={"right"} color="#F04438" fontWeight={"600"} fontSize=".9rem">Offer Declined</Text>
                                                        :
                                                        offers[offers?.length - 1]?.status === "pending" ? 
                                                        <Text minW="205px" textAlign={"right"} color="#4D2507" fontWeight={"600"} fontSize=".9rem">Offer Sent</Text>
                                                        :
                                                        null
                                                        :

                                                        <Flex gap=".5rem" marginTop={"-.2rem"}>
                                                            <Button onClick={() => navigate(`/checkout/${_id}`)} padding={".85rem 2rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"100%"} color="#fff" fontSize=".9rem" fontWeight={"700"}>Pay</Button>
                                                            <Button padding={".85rem 2rem"} borderRadius="20px" border="1px solid #E66F15" bg="#FFF" width={"100%"} color="#E66F15" fontSize=".9rem" fontWeight={"700"}>Decline</Button>
                                                        </Flex>
                                                        }
                                                    </Flex>
                                            </Flex>
                                        </Box>
                                    )
                                })}
                            </Box> */}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>

            {/* <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>

                <ModalBody>
                    <Flex marginTop={"7rem"} justifyContent={"center"}>
                        <Spinner size={"lg"} color="#E66F15" />
                    </Flex>
                    <Text textAlign={"center"} mt={5} marginBottom={"7rem"} fontWeight="500">Fetching your offers.. Hang Tight</Text>
                </ModalBody>
                </ModalContent>
            </Modal> */}


            {/* <Drawer
                isOpen={isOpen2}
                placement='right'
                onClose={onClose2}
                size="md"
            >
                <DrawerOverlay />
                <DrawerContent>
                <DrawerHeader>
                    <br />
                    <Text onClick={() => onClose2()} cursor={"pointer"} flex={"1"} fontWeight={"600"} color={"#1A1A1A"} fontSize=".9rem"><ArrowBackIcon transform={"translate(-4px,-1px)"} />Back</Text>
                </DrawerHeader>
                <DrawerBody>
                    <Text mt={3} fontFamily={"Clash Display"} fontWeight="700" fontSize={"1.2rem"}>Counter Offer</Text>
                    <Text mt={5} fontWeight="400">Make sure to set a fair price for the seller, and move the item to your cart once the seller accepts.</Text>
                    <Flex mt={7} bg="#FAE2D0" borderRadius="12px" gap="1rem" alignItems={"center"} padding="1.5rem 1.2rem">
                        <Box height="60px" width="60px" backgroundImage={image} backgroundSize="cover" borderRadius="12px"></Box>
                        <Box>
                            <Text mb={2} fontWeight="500" color="#1A1A1A">{namex}</Text>
                            <Text fontWeight="500" color="#1A1A1A" fontSize={".9rem"}>Buyer's Offer: <strong>₦{pricex?.toLocaleString()}</strong></Text>
                        </Box>
                    </Flex>
                    <br />
                    <FormControl mt={3}>
                        <FormLabel transform={"translateY(2px)"} fontWeight="700" fontFamily='Creato Display' fontSize=".9rem">Amount you’re offering</FormLabel>
                        <Input type={"email"} onChange={e => setOfferAmount(parseInt(e.target.value))} padding={"1.3rem 1rem"} placeholder="Enter an Amount" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" borderRadius={"20px"} />
                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Resolver />
                </DrawerBody>
                </DrawerContent>
            </Drawer> */}
        </Box>
    )
}

export default UserGiveaways;