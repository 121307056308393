import { userActionTypes } from '../constants/userActionTypes'
import axios from 'axios';
// import { toast } from 'react-toastify'
import baseUrl from '../../config';
import axiosInstance from '../../services/api';

// export const signup = (formData, history) => 
//    async(dispatch) => {
//      try {
//            const { data } = await axios.post(baseUrl + '/auth/sign-up', formData);
//            console.log(data)
//            dispatch({ type: userActionTypes.SIGNUP_SUCCESS, data });
           
//            history.push('/login');
//          } catch (error) {
//            console.log(error?.response?.data?.message)
//          }
// }

// export const signin = (formData, history) => 
//    async(dispatch) => {
//      try {
//         const { data } = await axios.post(`${baseUrl}/auth/customer/login`, formData);
//         console.log(data, data.token)
//         localStorage.setItem("HBET_TK", data.token);
//         dispatch({ type: userActionTypes.SIGNIN_SUCCESS, payload: data });
//         window.location.reload();
//      } catch (error) {
//         console.log(error?.response?.data?.message)
//         toast.error(error?.response?.data?.message, {
//            position: toast.POSITION.TOP_CENTER
//         })
//      }
// }

// export const logout = (history) => async(dispatch) => {
//    try {
//       localStorage.removeItem('HBET_TK');
//       await dispatch({ type: userActionTypes.LOGOUT_SUCCESS })
//    } catch (error) {
//       if(error.response){
//          console.log(error?.response?.data)
//       }
//    }
// }


export const loaduser = (history)=> async dispatch=> {
   try {
      if(localStorage.DCV1TK){
         const {data} = await axiosInstance.get("/users/profile");
            // console.log('line 55bbbb', data)
            dispatch({type: userActionTypes.LOADUSER_SUCCESS, payload: data?.data});

      }else{
         // throw Error('token is absent')
         localStorage.removeItem('DCV1TK')
         window.location.href = '/'
      }
       
   } catch (error) {
      dispatch({type: userActionTypes.LOADUSER_FAIL, payload: null})
      localStorage.removeItem('DCV1TK')
      window.location.href = '/'
      console.log('heloooooo')
      if(error.response){
         console.log(error.response.data)
         localStorage.removeItem('DCV1TK')
         window.location.href = '/'
      }
   }
}
   