import { Box, Flex, Skeleton, Stack, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../services/api";



const Categories2 = () => {

    const [showMore, setShowMore] = useState(false);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance.get("/item-categories")
        // .then(res => console.log(res?.data))
        .then(res => setCategories(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, [])


    return (
        <Box borderTop={"1px solid #EAEAEA"} px={[".8rem", "1.5rem", "2.5rem", "3rem"]} pt={[".7rem", "1rem", "1rem", "1rem"]}>
            
            {loading ? 
            <Stack mt={4} textAlign="center">
                <Skeleton height={"30px"} width="100%" />
            </Stack>
                    :
            <Flex alignItems={"center"} justifyContent={"space-between"} gap="1.2rem" overflowX={"scroll"} sx={
                { 
               '::-webkit-scrollbar':{
                      display:'none'
                  }
               }
             }>
                    {categories?.map(({imageURL, name}) => {
                        return (
                                <Text cursor={"pointer"} onClick={() => navigate(`/explore/${name}`)} _hover={{color: "#E66F15", fontWeight: "500"}} transition="250ms ease" whiteSpace={"nowrap"} mt={2} key={name} textAlign="center" fontWeight="500" fontSize={".9rem"}>{name}</Text>
                        )
                    })}
                </Flex> }
                <br />
        </Box>
    )
}

export default Categories2;