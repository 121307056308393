import { Box, Flex, Switch, Text } from "@chakra-ui/react";


const PushNotifications = () => {
    return (
        <Box marginTop={".5rem"}>
            <Flex marginBottom={"2.2rem"} justifyContent={"space-between"} alignItems="center">
                <Text>Trends & Updates</Text>
                <Switch colorScheme={"orange"} />
            </Flex>
            <Flex marginBottom={"2.2rem"} justifyContent={"space-between"} alignItems="center">
                <Text>Messages</Text>
                <Switch colorScheme={"orange"} defaultChecked />
            </Flex>
            <Flex marginBottom={"2.2rem"} justifyContent={"space-between"} alignItems="center">
                <Text>My Orders</Text>
                <Switch colorScheme={"orange"} defaultChecked />
            </Flex>
            <Flex marginBottom={"2.2rem"} justifyContent={"space-between"} alignItems="center">
                <Text>Recommendations</Text>
                <Switch colorScheme={"orange"} />
            </Flex>
            <Flex marginBottom={"2.2rem"} justifyContent={"space-between"} alignItems="center">
                <Text>Deals & Promos</Text>
                <Switch colorScheme={"orange"} />
            </Flex>
            <Flex justifyContent={"space-between"} alignItems="center">
                <Text>Offer Updates</Text>
                <Switch colorScheme={"orange"} />
            </Flex>
        </Box>
    )
}

export default PushNotifications;