import { ArrowForwardIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { Box, Flex, FormControl, IconButton, Image, Input, InputGroup, InputLeftAddon, InputRightElement, Text } from "@chakra-ui/react";
import Lstar from './stars.svg';
import Rstar from './starsx.svg';
import Lwave from './wave.svg';
import Rwave from './wavex.svg';


const Newsletter = () => {
    return (
        <Box position={"relative"} height={"350px"} borderTop="1px solid #fff" px={[".7rem", "1rem", "1rem", "1rem"]}>
            <Image src={Lstar} position="absolute" top={"5%"} left={"7%"} />
            <Image src={Rstar} position="absolute" top={"5%"} right={"2%"} />
            <Image src={Lwave} position="absolute" bottom={0} left={0} />
            <Image src={Rwave} position="absolute" bottom={0} right={0} />

            <Box position={"absolute"} top="50%" left="50%" transform={"translate(-50%, -50%)"}>
                <Text fontWeight={"700"} lineHeight="55px" minW={"350px"} maxWidth={"950px"}
                // margin="4rem auto 0 auto" 
                textAlign={"center"} fontSize={["1.2rem", "2.7rem"]} fontFamily="Clash Display">Your 1-stop shop for buying and selling  household items.</Text>
                <Text fontSize={[".85rem", "1rem"]} fontWeight={"500"} lineHeight={["40px", "55px"]} maxWidth={["200px","950px"]} 
                margin="0 auto" 
                textAlign={"center"}>Get recommendations, promos and special offers from us.</Text>
                <FormControl 
                margin={"1rem auto 0 auto"} 
                maxWidth={"310px"}>
                    <InputGroup>
                        <Input placeholder="Enter your email address" padding={"1.4rem 1rem"} _placeholder={{fontSize: ".85rem", fontFamily: "Creato Display", color: "#666666"}} border={"1.5px solid #1A1A1A !important"} borderRadius="32px" />
                        <InputRightElement
                            children={<ArrowForwardIcon cursor={"pointer"} _hover={{background: "#ab4e07"}} transition="250ms ease" mt={2} bg="#E66F15" fontSize={"1.9rem"} borderRadius="50%" padding=".3rem" color="#FFF" />}
                        />
                    </InputGroup>
                </FormControl>
            </Box>
        </Box>
    )
}

export default Newsletter;