import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/header";
import Navbar from "../../components/navbar/navbar";
import DeliveryAddress from "./components/deliveryAddress";
import PayoutInformation from "./components/payoutInformation";
import PersonalInfo from "./components/personalInfo";
import Privacy from "./components/privacy";
import PushNotifications from "./components/pushNotifications";

const Settings = () => {
    const [page, setPage] = useState("one");
    return (
        <Box>
            <Header />
            <Box marginBottom={"20rem"} px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={"2rem"}>
                <Flex alignItems={"center"}>
                    <Link style={{display: "flex", flex: "1"}} to="/"><Text cursor={"pointer"} flex={"1"} fontWeight={"600"} color={"#1A1A1A"}><ArrowBackIcon transform={"translate(-4px,-1px)"} />Back</Text></Link>
                    <Text flex={"4"} color="#1A1A1A" fontWeight={"700"} fontSize="1.4rem" fontFamily={"Clash Display"}>Settings</Text>
                </Flex>
                <Flex display={["flex", "none"]} width={"100%"} margin="2rem auto 0 auto" alignItems={"center"} gap="1rem" flexWrap={"wrap"} justifyContent="center">
                    <Text onClick={() => setPage("one")} cursor="pointer" color={page === "one" ? "#1A1A1A" : null} fontWeight={page === "one" ? "700" : null} transition="250ms ease">Your Info</Text>
                    <Text onClick={() => setPage("two")} cursor="pointer" color={page === "two" ? "#1A1A1A" : null} fontWeight={page === "two" ? "700" : null} transition="250ms ease">Delivery Address</Text>
                    <Text onClick={() => setPage("three")} cursor="pointer" color={page === "three" ? "#1A1A1A" : null} fontWeight={page === "three" ? "700" : null} transition="250ms ease">Payout Information</Text>
                    <Text onClick={() => setPage("four")} cursor="pointer" color={page === "four" ? "#1A1A1A" : null} fontWeight={page === "four" ? "700" : null} transition="250ms ease">Privacy</Text>
                    <Text onClick={() => setPage("five")} cursor="pointer" color={page === "five" ? "#1A1A1A" : null} fontWeight={page === "five" ? "700" : null} transition="250ms ease">Push Notifications</Text>
                    <Link to="/help"><Text cursor="pointer" >Help</Text></Link>
                </Flex>
                <Flex marginTop="4rem">
                    <Text display={["none", "block"]} flex={"1"}></Text>
                    <Flex flex={"4"} gap="10rem">
                        <Box display={["none", "block"]} color="#666666">
                            <Text onClick={() => setPage("one")} cursor="pointer" mb={10} color={page === "one" ? "#1A1A1A" : null} fontWeight={page === "one" ? "500" : null} transition="250ms ease">Your Info</Text>
                            <Text onClick={() => setPage("two")} cursor="pointer" mb={10} color={page === "two" ? "#1A1A1A" : null} fontWeight={page === "two" ? "500" : null} transition="250ms ease">Delivery Address</Text>
                            <Text onClick={() => setPage("three")} cursor="pointer" mb={10} color={page === "three" ? "#1A1A1A" : null} fontWeight={page === "three" ? "500" : null} transition="250ms ease">Payout Information</Text>
                            <Text onClick={() => setPage("four")} cursor="pointer" mb={10} color={page === "four" ? "#1A1A1A" : null} fontWeight={page === "four" ? "500" : null} transition="250ms ease">Privacy</Text>
                            <Text onClick={() => setPage("five")} cursor="pointer" mb={10} color={page === "five" ? "#1A1A1A" : null} fontWeight={page === "five" ? "500" : null} transition="250ms ease">Push Notifications</Text>
                            <Link to="/help"><Text cursor="pointer" mb={8}>Help</Text></Link>
                        </Box>
                        <Box width={["100%", "500px"]} height="600px" padding={"0 .5rem"}>
                            {page === "one" && <PersonalInfo />}
                            {page === "two" && <DeliveryAddress />}
                            {page === "three" && <PayoutInformation />}
                            {page === "four" && <Privacy />}
                            {page === "five" && <PushNotifications />}
                        </Box>
                    </Flex>
                </Flex>
            </Box>
        </Box>
    )
}

export default Settings;