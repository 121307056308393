import { Badge, Box, Button, Flex, HStack, Image, Skeleton, Stack, Text } from "@chakra-ui/react"
import TextArrow from "../../../components/textArrow";

import One from '../../../assets/trending/one.svg';
import Two from '../../../assets/trending/two.svg';
import Three from '../../../assets/trending/three.svg';
import Four from '../../../assets/trending/four.svg';
import Five from '../../../assets/trending/five.svg';
import Six from '../../../assets/trending/six.svg';
import Seven from '../../../assets/trending/seven.svg';
import Eight from '../../../assets/trending/eight.svg';
import Nine from '../../../assets/trending/nine.svg';
import Ten from '../../../assets/trending/ten.svg';

import Location from '../../../assets/location.svg';
import { useEffect } from "react";
import axiosInstance from "../../../services/api";
import { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import SaveAction from "../../../components/saveAction/saveAction";
import SaveAction2 from "../../../components/saveAction/saveAction2";
import moment from "moment";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";




const Trending = ({title, link, today, slice, filter, stFilter}) => {
    const [items, setItems] = useState([]);
    const [todays, setTodays] = useState([]);
    const [freeItems, setFreeItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingxx, setLoadingxx] = useState(true);
    const [clearanceItems, setClearanceItems] = useState([]);
    const [staffItems, setStaffItems] = useState([]);
    const [loadingxxx, setLoadingxxx] = useState(true);
    const [loadingxxxx, setLoadingxxxx] = useState(true);

    const currentUser = useSelector(({userData})=>  userData?.currentUser);

    useEffect(() => {
        // axiosInstance.get("sale-items")
        axiosInstance.get("sale-items?isAvailable=true&status=approved")
        // .then(res => console.log("vvv", res?.data?.data))
        .then(res => setItems(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, []);

    useEffect(() => {
        // axiosInstance.get("sale-items")
        axiosInstance.get("free-items?isAvailable=true")
        // .then(res => console.log("vvv", res?.data?.data))
        .then(res => setFreeItems(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, []);

    useEffect(() => {
        // axiosInstance.get("sale-items")
        axiosInstance.get("/deals")
        // .then(res => console.log("vvv", res?.data?.data))
        .then(res => setTodays(res?.data?.data))
        .then(res => setLoadingxx(false))
        .catch(error => console.log(error))
    }, [loadingxx]);

    useEffect(() => {
        // axiosInstance.get("sale-items")
        axiosInstance.get("/sale-items-clearance-groups")
        // .then(res => console.log("vvv", res?.data?.data))
        .then(res => setClearanceItems(res?.data?.data))
        .then(res => setLoadingxxx(false))
        .catch(error => console.log(error))
    }, [loadingxxx]);

    const [savedItems, setSavedItems] = useState([]);
    const [loadingx, setLoadingx] = useState(true)

    useEffect(() => {
        if(currentUser) {
        axiosInstance.get("/saved-items")
        // .then(res => console.log("vvv", res?.data?.data))
        .then(res => setSavedItems(res?.data?.data))
        .then(res => setLoadingx(false))
        .catch(error => console.log(error))
        }
    }, []);

    useEffect(() => {
        // axiosInstance.get("sale-items")
        axiosInstance.get("staff-picked-items")
        // .then(res => console.log("vvv", res?.data?.data))
        .then(res => setStaffItems(res?.data?.data))
        .then(res => setLoadingxxxx(false))
        .catch(error => console.log(error))
    }, []);

    const navigate = useNavigate()

    let todayx = new Date();
    let mmx;
    let dd = todayx.getDate();
    let mm = todayx.getMonth();
    let vv = todayx.getFullYear();
    
    todayx = dd;
    if(mm == "0") {
        mm = "01"
    }
    mmx = (mm)
    const final = vv + "-" + mmx + "-" + todayx

    // console.log(final)

    return (
        <Box mt={10} px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={[".8rem", ".9rem", "1rem", "1rem"]}>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
                <TextArrow text={title} link={link} />
                <Link to={link}><Text cursor={"pointer"} mr={1} fontSize=".9rem" fontWeight="500" color="#E66F15">See More <ArrowForwardIcon color={"#E66F15"} /></Text></Link>
            </Flex>
            
            <br />
            <Flex wrap={"wrap"} gap="1rem" rowGap={"3rem"}>
                    {loading ? 
                    <>
                    <Stack minWidth="250px" flex="1">
                        <Skeleton height={"230"} borderRadius="12px" />
                        <Skeleton height={"25px"} />
                        <Skeleton height={"25px"} />
                    </Stack>
                    <Stack minWidth="250px" flex="1">
                        <Skeleton width="100%" height={"230"} borderRadius="12px" />
                        <Skeleton width="100%" height={"25px"} />
                        <Skeleton width="100%" height={"25px"} />
                    </Stack>
                    <Stack minWidth="250px" flex="1">
                        <Skeleton height={"230"} borderRadius="12px" />
                        <Skeleton height={"25px"} />
                        <Skeleton height={"25px"} />
                    </Stack>
                    <Stack minWidth="250px" flex="1">
                        <Skeleton height={"230"} borderRadius="12px" />
                        <Skeleton height={"25px"} />
                        <Skeleton height={"25px"} />
                    </Stack>
                    <Stack minWidth="250px" flex="1">
                        <Skeleton height={"230"} borderRadius="12px" />
                        <Skeleton height={"25px"} />
                        <Skeleton height={"25px"} />
                    </Stack>
                    </>
                    :
                    filter === "today" ?
                    todays?.filter(i => i.item?.isAvailable == true)?.slice(0,10).map(({item}) => {
                        const idx = savedItems.filter(i => i?.item?._id === item?._id)
                        return (
                            // <Link to={`checkout/${item?._id}`} style={{flex: "1", flexGrow: "0"}}>
                                <Box onClick={() => navigate(`item-details/${item?._id}`, {state: "paid"})} cursor={"pointer"} overflow={"hidden"} minWidth={["150px", "250px" ]} flex={["1", "1", "0", "1"]} key={item?._id}>
                                    <Box overflow={"hidden"} borderRadius="12px" height="250px" width={"100%"} backgroundImage={item?.imageURLs[0]} backgroundSize="cover">
                                        {/* {savedItems.map(i => i?.item?._id).includes(item?._id) ? <SaveAction2 id={idx[0]} from="main" /> : <SaveAction id={item?._id} />} */}
                                    </Box>
                                    <Box padding={"0 .3rem"}>
                                        {/* <Text mt={1} fontWeight="600" fontSize={".9rem"}>{createdAt?.slice(0,10)}</Text> */}
                                        <Text textTransform={"capitalize"} mt={1} fontWeight="600" fontSize={".9rem"}>{item?.name}</Text>
                                        <Flex mt={2} justifyContent={"space-between"}>
                                            <Flex alignItems={"center"}>
                                                <Image mr={1} transform={"translateY(1.5px)"} width={"20%"} src={Location} />
                                                <Text mt={1} whiteSpace="nowrap" color="#908888" fontSize={".9rem"}>{item?.pickUpLocation?.landmarkArea}</Text>
                                            </Flex>
                                            <Text mt={1} fontSize=".9rem" fontWeight="600">₦{item?.listPrice?.toLocaleString()}</Text>
                                        </Flex>
                                        {today && <Button onClick={() => navigate(`/checkout/${item?._id}`)} mt={3} bg="#E66F15" color={"#fff"} width={"100%"} padding={"0 3rem"} height="38px" fontFamily={"Clash Display"} fontSize=".8rem" borderRadius="20px">Buy Now</Button>}
                                    </Box>
                                </Box>
                            // </Link>
                        )
                    })
                    :
                    filter === "free" ? 
                    freeItems?.slice(0,10).map(({imageURLs, name, listPrice, pickUpLocation, _id, createdAt}) => {
                        const idx = savedItems.filter(i => i?.item?._id === _id)
                        return (
                            // <Link to={{pathname: `item-details/${_id}`, state: {type: "free"}}} style={{flex: "0"}}>
                            <Box onClick={() => navigate(`item-details/${_id}?type=free`)} cursor={"pointer"} overflow={"hidden"} minWidth={["150px", "250px" ]} flex={["1", "1", "0", "1"]} key={_id}>
                                <Box position={"relative"} overflow={"hidden"} borderRadius="12px" height="230px" width={"100%"} backgroundImage={imageURLs[0]} backgroundSize="cover">
                                    {/* {savedItems.map(i => i?.item?._id).includes(_id) ? <SaveAction2 id={idx[0]} from="main" /> : <SaveAction id={_id} />} */}
                                </Box>
                                <Box padding={"0 .3rem"}>
                                    {/* <Text mt={1} fontWeight="600" fontSize={".9rem"}>{createdAt?.slice(0,10)}</Text> */}
                                    <Text textTransform={"capitalize"} mt={1} fontWeight="600" fontSize={".9rem"}>{name}</Text>
                                    <Flex mt={2} justifyContent={"space-between"}>
                                        <Flex alignItems={"center"}>
                                            <Image mr={1} transform={"translateY(1.5px)"} width={"20%"} src={Location} />
                                            <Text mt={1} color="#908888" fontSize={".9rem"}>{pickUpLocation?.landmarkArea}</Text>
                                        </Flex>
                                        <Text mt={1} fontSize=".9rem" fontWeight="600">Free</Text>
                                    </Flex>
                                    {today && <Button mt={3} bg="#E66F15" color={"#fff"} width={"100%"} padding={"0 3rem"} height="38px" fontFamily={"Clash Display"} fontSize=".8rem" borderRadius="20px">Buy Now</Button>}
                                </Box>
                            </Box>
                            // </Link>
                        )
                    })
                    :
                    filter === "dd" ?
                    items?.slice(0,10).map(({imageURLs, name, listPrice, pickUpLocation, _id, createdAt}) => {
                        const idx = savedItems?.filter(i => i?.item?._id === _id)
                        return (
                            // <Link to={`item-details/${_id}`} style={{flex: "0"}}>
                            <Box onClick={() => navigate(`item-details/${_id}`, {state: "paid"})} cursor={"pointer"} overflow={"hidden"} minWidth={["150px", "250px" ]} flex={["1", "1", "0", "1"]} key={_id}>
                                <Box position={"relative"} overflow={"hidden"} borderRadius="12px" height="230px" width={"100%"} backgroundImage={imageURLs[0]} backgroundSize="cover">
                                    {savedItems?.map(i => i?.item?._id).includes(_id) ? <SaveAction2 id={idx[0]} from="main" /> : <SaveAction id={_id} />}
                                </Box>
                                <Box padding={"0 .3rem"}>
                                    {/* <Text mt={1} fontWeight="600" fontSize={".9rem"}>{createdAt?.slice(0,10)}</Text> */}
                                    <Text textTransform={"capitalize"} mt={1} fontWeight="600" fontSize={".9rem"}>{name}</Text>
                                    <Flex mt={2} justifyContent={"space-between"}>
                                        <Flex alignItems={"center"}>
                                            <Image mr={1} transform={"translateY(1.5px)"} width={"20%"} src={Location} />
                                            <Text mt={1} color="#908888" fontSize={".9rem"}>{pickUpLocation?.landmarkArea}</Text>
                                        </Flex>
                                        <Text mt={1} fontSize=".9rem" fontWeight="600">₦{listPrice?.toLocaleString()}</Text>
                                    </Flex>
                                    {today && <Button mt={3} bg="#E66F15" color={"#fff"} width={"100%"} padding={"0 3rem"} height="38px" fontFamily={"Clash Display"} fontSize=".8rem" borderRadius="20px">Buy Now</Button>}
                                </Box>
                            </Box>
                            // </Link>
                        )
                    })
                    :
                    filter === "st" ?
                    staffItems?.filter(i => i?.label === stFilter)?.slice(0,5).map(({item, createdAt}) => {
                        const idx = savedItems?.filter(i => i?.item?._id === item?._id)
                        return (
                            // <Link to={`item-details/${_id}`} style={{flex: "0"}}>
                            <Box onClick={() => navigate(`item-details/${item?._id}`, {state: "paid"})} cursor={"pointer"} overflow={"hidden"} minWidth={["150px", "250px" ]} flex={["1", "1", "0", "1"]} key={item?._id}>
                                <Box position={"relative"} overflow={"hidden"} borderRadius="12px" height="230px" width={"100%"} backgroundImage={item?.imageURLs[0]} backgroundSize="cover">
                                    {savedItems?.map(i => i?.item?._id).includes(item?._id) ? <SaveAction2 id={idx[0]} from="main" /> : <SaveAction id={item?._id} />}
                                </Box>
                                <Box padding={"0 .3rem"}>
                                    {/* <Text mt={1} fontWeight="600" fontSize={".9rem"}>{createdAt?.slice(0,10)}</Text> */}
                                    <Text textTransform={"capitalize"} mt={1} fontWeight="600" fontSize={".9rem"}>{item?.name}</Text>
                                    <Flex mt={2} justifyContent={"space-between"}>
                                        <Flex alignItems={"center"}>
                                            <Image mr={1} transform={"translateY(1.5px)"} width={"20%"} src={Location} />
                                            <Text mt={1} color="#908888" fontSize={".9rem"}>{item?.pickUpLocation?.landmarkArea}</Text>
                                        </Flex>
                                        <Text mt={1} fontSize=".9rem" fontWeight="600">₦{item?.listPrice?.toLocaleString()}</Text>
                                    </Flex>
                                    {today && <Button mt={3} bg="#E66F15" color={"#fff"} width={"100%"} padding={"0 3rem"} height="38px" fontFamily={"Clash Display"} fontSize=".8rem" borderRadius="20px">Buy Now</Button>}
                                </Box>
                            </Box>
                            // </Link>
                        )
                    })
                    :
                    filter === "clearance" ?
                    clearanceItems?.filter(i => i?.items?.length > 0)?.slice(0,10).map(({imageURL, name, items, _id}) => {
                        return (
                            // <Link to={`item-details/${_id}`} style={{flex: "0"}}>
                            <Box onClick={() => navigate(`clearance-sales/${_id}`, {state: "paid"})} cursor={"pointer"} overflow={"hidden"} minWidth={["150px", "250px" ]} flex={["1", "1", "0", "1"]} key={_id}>
                                <Box position={"relative"} overflow={"hidden"} borderRadius="12px" height="230px" width={"100%"} backgroundImage={imageURL} backgroundSize="cover">
                                    {/* {savedItems.map(i => i?.item?._id).includes(_id) ? <SaveAction2 id={idx[0]} from="main" /> : <SaveAction id={_id} />} */}
                                </Box>
                                <Box padding={"0 .3rem"}>
                                    {/* <Text mt={1} fontWeight="600" fontSize={".9rem"}>{createdAt?.slice(0,10)}</Text> */}
                                    <Flex mt={1} alignItems={"center"} justifyContent={"space-between"}>
                                        <Text textTransform={"capitalize"} fontWeight="600" fontSize={".9rem"}>{name}</Text>
                                        <Badge fontSize={".65rem"} colorScheme="green">{items?.length} items</Badge>
                                    </Flex>
                                    
                                    {/* <Flex mt={2} justifyContent={"space-between"}>
                                        <Flex alignItems={"center"}>
                                            <Image mr={1} transform={"translateY(1.5px)"} width={"20%"} src={Location} />
                                            <Text mt={1} color="#908888" fontSize={".9rem"}>{pickUpLocation?.landmarkArea}</Text>
                                        </Flex>
                                        <Text mt={1} fontSize=".9rem" fontWeight="600">₦{listPrice?.toLocaleString()}</Text>
                                    </Flex> */}
                                    {today && <Button mt={3} bg="#E66F15" color={"#fff"} width={"100%"} padding={"0 3rem"} height="38px" fontFamily={"Clash Display"} fontSize=".8rem" borderRadius="20px">Buy Now</Button>}
                                </Box>
                            </Box>
                            // </Link>
                        )
                    })
                    :
                    items?.sort((a, b) => b.views - a.views)?.slice(0,10).map(({imageURLs, name, listPrice, pickUpLocation, _id, createdAt}) => {
                        const idx = savedItems.filter(i => i?.item?._id === _id)
                        return (
                            // <Link to={`item-details/${_id}`} style={{flex: "0"}}>
                            <Box onClick={() => navigate(`item-details/${_id}`, {state: "paid"})} cursor={"pointer"} overflow={"hidden"} minWidth={["150px", "250px" ]} flex={["1", "1", "0", "1"]} key={_id}>
                                <Box position={"relative"} overflow={"hidden"} borderRadius="12px" height="230px" width={"100%"} backgroundImage={imageURLs[0]} backgroundSize="cover">
                                    {savedItems.map(i => i?.item?._id).includes(_id) ? <SaveAction2 id={idx[0]} from="main" /> : <SaveAction id={_id} />}
                                </Box>
                                <Box padding={"0 .3rem"}>
                                    {/* <Text mt={1} fontWeight="600" fontSize={".9rem"}>{createdAt?.slice(0,10)}</Text> */}
                                    <Text textTransform={"capitalize"} mt={1} fontWeight="600" fontSize={".9rem"}>{name}</Text>
                                    <Flex mt={2} justifyContent={"space-between"}>
                                        <Flex alignItems={"center"}>
                                            <Image mr={1} transform={"translateY(1.5px)"} width={"20%"} src={Location} />
                                            <Text mt={1} color="#908888" fontSize={".9rem"}>{pickUpLocation?.landmarkArea}</Text>
                                        </Flex>
                                        <Text mt={1} fontSize=".9rem" fontWeight="600">₦{listPrice?.toLocaleString()}</Text>
                                    </Flex>
                                    {today && <Button mt={3} bg="#E66F15" color={"#fff"} width={"100%"} padding={"0 3rem"} height="38px" fontFamily={"Clash Display"} fontSize=".8rem" borderRadius="20px">Buy Now</Button>}
                                </Box>
                            </Box>
                            // </Link>
                        )
                    })
                    }
            </Flex> 
        </Box>
    )
}

export default Trending;