import { Box, Button, Flex, Heading, Spinner, Text, useToast } from "@chakra-ui/react"
import { useState } from "react";
import Navbar2 from '../../components/navbar2/navbar2';
import OtpInput from "react-otp-input";
import axiosInstance from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";

const EmailVerification = () => {
    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingr, setLoadingr] = useState(false);

    const {email} = useParams()

    const handleChange = (otp) => {
        setOtp(otp);
    };

    const toast = useToast();
    const navigate = useNavigate();

    const verifyOtp = async (e) => {
        e.preventDefault()
        setLoading(true)

        const datax = {
            code: otp
        }

        try {
            const {data} = await axiosInstance.post("/auth/verify-email", datax)
            navigate("/success", {state: {text: "Awesome!", para: "You have successfully created and verified your account", link: "/login"}})
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false)
        }
    }
    const resendOtp = async (e) => {
        e.preventDefault()
        setLoadingr(true)

        const datax = {
            email
        }

        try {
            const {data} = await axiosInstance.post("/auth/resend-email-verification-code", datax)
            toast({
                // title: "An Error Occurred",
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoadingr(false)
        }
    }

    return (
        <Box marginBottom={"24rem"}>
            <Navbar2 />
            <Box maxW={"500px"} margin="3rem auto" padding={"0 1rem"}>
                <Heading fontWeight={700} fontSize="1.4rem" color="#030303" fontFamily='Clash Display'>Verify Email</Heading>
                <Text mt={1} color={"#908888"} fontWeight="500" fontSize={".9rem"} fontFamily='Creato Display'>We just sent a verification code to your email address</Text>
                <br />
                <br />
                <form onSubmit={verifyOtp}>
                <Box margin="0 auto">
                    <OtpInput
                        containerStyle={{
                            display: "flex",
                            gap: ".7rem",
                            margin: "0 auto",
                            justifyContent: "space-between",
                        }}
                        inputStyle={{
                            // border: "1px solid #4C4C4C",
                            height: "60px",
                            width: "100%",
                            background: "#D9D9D9",
                            color: "#0A0A0B",
                            fontSize: "1.1rem",
                            borderRadius: "8px"
                        }}
                        focusStyle={{
                            boxShadow: "none",
                            // border: "1px solid #1D2117",
                            outline: "none",
                        }}
                        value={otp}
                        onChange={handleChange}
                        numInputs={6}
                        isInputNum={true}
                        shouldAutoFocus={true}
                    />
                </Box>
                <Text onClick={resendOtp} mt={5} textAlign="center" color={"#030303"} fontWeight="500" fontSize=".8rem" fontFamily='Creato Display'>Didn’t receive a code? {loadingr ? <Spinner size={"xs"} color="#E66F15" /> : <strong style={{color: "#E66F15", cursor: "pointer"}}>Click to resend</strong>}</Text>
                <Flex mt={10} justifyContent={"center"}>
                    <Button type="submit" padding={"1.1rem 1rem"} isDisabled={otp.length < 6} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"90%"} color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Verify Email" }</Button>
                </Flex>
                </form>
            </Box>
        </Box>
    )
}

export default EmailVerification;