import { Box, Button, Flex, FormControl, FormHelperText, FormLabel, Heading, Image, Input, Spinner, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Google from '../../assets/google.svg';
import Instagram from '../../assets/instagram.svg';
import Navbar2 from "../../components/navbar2/navbar2";
import axiosInstance from "../../services/api";

const ForgotPasswordNew = () => {
    const [cPassword, setCPassword] = useState("");
    const [nPassword, setNPassword] = useState("");
    const [cnPassword, setcNPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const toast = useToast()

    const navigate = useNavigate();

    const datax = {
        currentPassword: cPassword,
        newPassword: nPassword
    }

    const updatePassword = async(e) => {
        e.preventDefault();

        if(nPassword === cnPassword) {
            setLoading(true);
            try {
                const {data} = await axiosInstance.patch("auth/update-password", datax)
                navigate("/success", {state: {para: "You can now log in subsequently with your new password", text: "Password successfully changed!", link: "/"}})
                
                toast({
                    // title: "An Error Occurred",
                    description: data?.message,
                    status: "success",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                });
            } catch(error) {
                toast({
                    // title: "An Error Occurred",
                    description: error?.response?.data?.message,
                    status: "error",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setLoading(false)
            }
        } else {
            toast({
                // title: "An Error Occurred",
                description: "Password Mismatch, Try Again.",
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        }
    }


    return (
        <Box>
            <Navbar2 />
            <Box maxWidth={"500px"} margin="0 auto 20rem auto">
                <Heading marginTop="4rem" fontWeight={700} fontSize="1.4rem" color="#030303" fontFamily='Clash Display'>New Password</Heading>
                <Text mt={2} color={"#908888"} fontWeight="500" fontFamily='Creato Display'>Set up a new password</Text>
                <br />
                <form onSubmit={updatePassword}>
                    <FormControl mt={4}>
                        <FormLabel transform={"translateY(3px)"} fontWeight="600" fontFamily='Creato Display' fontSize=".9rem">Old Password</FormLabel>
                        <Input onChange={e => setCPassword(e.target.value)} padding={"1.3rem 1rem"} placeholder="Enter old password" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel transform={"translateY(3px)"} fontWeight="600" fontFamily='Creato Display' fontSize=".9rem">New Password</FormLabel>
                        <Input onChange={e => setNPassword(e.target.value)} padding={"1.3rem 1rem"} placeholder="Enter new password" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" />
                        <FormHelperText color="#8C8C8C">Must be a min of 8 characters including numbers and letters.</FormHelperText>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel transform={"translateY(3px)"} fontWeight="600" fontFamily='Creato Display' fontSize=".9rem">Confirm Password</FormLabel>
                        <Input onChange={e => setcNPassword(e.target.value)} padding={"1.3rem 1rem"} placeholder="Confirm password" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" />
                    </FormControl>

                    <Button type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={6} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Set up password"}</Button>
                    
                </form>
            </Box>
        </Box>
    )
}

export default ForgotPasswordNew;

