import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Select,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import Location from "../assets/wallet.svg";
import Empty from "../assets/empty.svg";
import axiosInstance from "../../../services/api";

const PayoutInformation = () => {
  const [loading, setLoading] = useState(true);
  const [loadingx, setLoadingx] = useState(false);
  const [loadingy, setLoadingy] = useState(false);
  const [bank, setBank] = useState("");
  const [account, setAccount] = useState("");
  const [resolved, setResolved] = useState(null);
  const [resolvedData, setResolvedData] = useState(null);
  const [errorText, setErrorText] = useState(null);

  const toast = useToast();

  const [view, setView] = useState("two");
  const [data, setData] = useState(null);
  const [items, setItems] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  useEffect(() => {
    axiosInstance
      .get("/users/bank-account")
      // .then(res => console.log("yyy", res?.data?.data))
      .then((res) => setData(res?.data?.data))
      .then((res) => setLoading(false))
      .catch((error) => console.log(error));
  }, [loadingy]);

  useEffect(() => {
    axiosInstance
      .get("paystack/retrieve-banks")
      // .then(res => console.log("vvv", res?.data?.data))
      .then((res) => setItems(res?.data?.data))
      .catch((error) => console.log(error));
  }, []);

  function Resolver() {
    if (resolved === true) {
      return (
        <Button
          onClick={saveAccount}
          type="submit"
          fontFamily="Clash Display"
          padding={"1.3rem 1rem"}
          borderRadius="16px"
          border="1px solid #EFEDED"
          bg="#E66F15"
          mt={8}
          width="100%"
          color="#fff"
          fontSize=".9rem"
          fontWeight={"700"}
        >
          {loadingy ? <Spinner /> : "Save account details"}
        </Button>
      );
    } else {
      return (
        <Button
          disabled
          type="submit"
          fontFamily="Clash Display"
          padding={"1.3rem 1rem"}
          borderRadius="16px"
          border="1px solid #EFEDED"
          bg="#E66F15"
          mt={8}
          width="100%"
          color="#fff"
          fontSize=".9rem"
          fontWeight={"700"}
        >
          {loadingy ? <Spinner /> : "Save account details"}
        </Button>
      );
    }
  }

  const resolveAccount = async () => {
    setLoadingx(true);
    const datax = {
      bankCode: JSON.parse(bank)?.code,
      accountNumber: account,
    };
    try {
      const res = await axiosInstance.post(
        "/paystack/resolve-account-number",
        datax
      );
      console.log(res?.data?.data);
      setResolvedData(res?.data?.data);
      setResolved(true);
    } catch (error) {
      console.log(error?.response?.data?.message);
      setErrorText(error?.response?.data?.message);
      setResolved(false);
    } finally {
      setLoadingx(false);
    }
  };

  if (account.length == 10) {
    resolveAccount();
    setAccount("");
  }

  const saveAccount = async () => {
    setLoadingy(true);
    const datay = {
      bankCode: JSON.parse(bank)?.code,
      bankName: JSON.parse(bank)?.name,
      accountNumber: resolvedData?.account_number,
      accountName: resolvedData?.account_name,
    };
    try {
      const res = await axiosInstance.post("/users/link-bank-account", datay);
      console.log(res?.data);
      setView("two")
      toast({
        description: res?.data?.message,
        status: "success",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error?.response?.data?.message);
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoadingy(false);
    }
  };

  // const deleteAccount = async(id) => {
  //     setLoading(true)
  //     try {
  //         const res = await axiosInstance.delete(`/users/delivery-addresses/${id}`, datax)
  //         console.log(res)
  //         onclose()
  //         toast({
  //             description: "Address deleted succesfully",
  //             status: "success",
  //             position: "top-right",
  //             duration: 5000,
  //             isClosable: true,
  //         });
  //     } catch(error) {
  //         toast({
  //             title: "An Error Occurred",
  //             description: error?.response?.data?.message,
  //             status: "error",
  //             position: "top-right",
  //             duration: 5000,
  //             isClosable: true,
  //           });
  //     } finally {
  //         setLoading(false)
  //     }
  // }

  return (
    <>
      {loading ? (
        <Flex marginTop={"7rem"} justifyContent={"center"}>
          <Spinner size={"lg"} color="#E66F15" />
        </Flex>
      ) : !Object.keys(data)?.length && view === "three" ? (
        <Box textAlign={"center"}>
          <Image margin="0 auto" src={Empty} />
          <Text
            fontFamily={"Clash Display"}
            fontWeight="700"
            fontSize="1.2rem"
            color="#030303"
          >
            Nothing to see here
          </Text>
          <Text color={"#666666"}>
            Accounts will only appear after you’ve added one
          </Text>
          <Button
            onClick={() => setView("one")}
            type="submit"
            fontFamily="Clash Display"
            padding={"1.3rem 1rem"}
            borderRadius="16px"
            border="1px solid #EFEDED"
            bg="#E66F15"
            mt={8}
            width="100%"
            color="#fff"
            fontSize=".9rem"
            fontWeight={"700"}
          >
            Add a Settlement Account
          </Button>
        </Box>
      ) : (
        <Box>
          {view === "one" && (
            <form>
              <Text fontFamily={"Clash Display"} fontWeight="700">
                Add a Settlement account
              </Text>
              <br />
              <FormControl mb={5}>
                <FormLabel
                  transform={"translate(7px,3px)"}
                  color="#030303"
                  fontWeight="600"
                  fontFamily="Creato Display"
                  fontSize=".85rem"
                >
                  Bank
                </FormLabel>
                <Select
                  onChange={(e) => setBank(e.target.value)}
                  cursor={"pointer"}
                  _focus={{
                    border: "1px solid #E66F15",
                    boxShadow: "1px solid #E66F15",
                  }}
                  _hover={{
                    border: "1px solid #E66F15",
                    boxShadow: "1px solid #E66F15",
                  }}
                  border="1px solid #B0B4B0 !important"
                  borderRadius={"18px"}
                  height="43.59px"
                  fontSize=".9rem"
                >
                  <option selected disabled>
                    Select Bank
                  </option>
                  {items?.map((i) => {
                    return (
                      <option key={i?.id} value={JSON.stringify(i)}>
                        {i?.name}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl mb={2}>
                <FormLabel
                  transform={"translate(7px,3px)"}
                  color="#030303"
                  fontWeight="600"
                  fontFamily="Creato Display"
                  fontSize=".85rem"
                >
                  Account Number
                </FormLabel>
                <Input
                  onChange={(e) => setAccount(e.target.value)}
                  type="tel"
                  _focus={{
                    border: "1px solid #E66F15",
                    boxShadow: "1px solid #E66F15",
                  }}
                  _hover={{
                    border: "1px solid #E66F15",
                    boxShadow: "1px solid #E66F15",
                  }}
                  border="1px solid #B0B4B0 !important"
                  borderRadius={"18px"}
                  padding={"1.3rem 1rem"}
                  placeholder="09090939300"
                  fontSize=".9rem"
                  minLength={10}
                  maxLength={10}
                />
              </FormControl>
              <br />
              {loadingx ? (
                <Flex justifyContent={"center"}>
                  <Spinner size={"md"} color="lightgray" />
                </Flex>
              ) : resolved === true ? (
                <Box
                  bg={resolved ? "#FAE2D0" : "#FFF"}
                  padding={".8rem 1rem"}
                  borderRadius="6px"
                >
                  <Text>{resolvedData?.account_name}</Text>
                </Box>
              ) : (
                <Box
                  bg={resolved === false ? "#FAE2D0" : "#FFF"}
                  padding={".8rem 1rem"}
                  borderRadius="6px"
                >
                  <Text>{errorText}</Text>
                </Box>
              )}
              {/* <Checkbox colorScheme={"green"} mt={3}>Save as default account</Checkbox> */}
              <Resolver />
            </form>
          )}
          {view === "two" && (
            <>
              {Object.keys(data)?.length > 0 && (
                <Flex
                  padding={".7rem"}
                  borderRadius="12px"
                  border="1px solid #F0F0F0"
                  position={"relative"}
                >
                  <Image
                    mr={3}
                    height={"fit-content"}
                    padding={".8rem"}
                    borderRadius="50%"
                    border="1px solid #EAEAEA"
                    src={Location}
                  />
                  <Box>
                    <Text color="#030303">{data?.accountName}</Text>
                    <Text color="#404040">{data?.accountNumber}</Text>
                    <Text mt={2} color="#8C8C8C" fontSize={".9rem"}>
                      {data?.bankName}
                    </Text>
                  </Box>

                  {/* <DeleteIcon cursor={"pointer"} onClick={onOpen} position="absolute" right="3%" top="14%" color="gray" />

                                <AlertDialog
                                    isOpen={isOpen}
                                    leastDestructiveRef={cancelRef}
                                    onClose={onClose}
                                >
                                    <AlertDialogOverlay>
                                    <AlertDialogContent>
                                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                        Delete Address
                                        </AlertDialogHeader>

                                        <AlertDialogBody>
                                        Are you sure? You can't undo this action afterwards.
                                        </AlertDialogBody>

                                        <AlertDialogFooter>
                                        <Button ref={cancelRef} onClick={onClose}>
                                            Cancel
                                        </Button>
                                        <Button colorScheme='red' onClick={() => deleteAccount(i?._id)} ml={3}>
                                            {loading ? <Spinner /> : "Delete"}
                                        </Button>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                    </AlertDialogOverlay>
                                </AlertDialog> */}
                </Flex>
              )}
              {Object.keys(data)?.length == 0 && (
                <Box textAlign={"center"}>
                  <Image margin="0 auto" src={Empty} />
                  <Text
                    fontFamily={"Clash Display"}
                    fontWeight="700"
                    fontSize="1.2rem"
                    color="#030303"
                  >
                    Nothing to see here
                  </Text>
                  <Text color={"#666666"}>
                    Accounts will only appear after you’ve added one
                  </Text>
                  <Button
                    onClick={() => setView("one")}
                    type="submit"
                    fontFamily="Clash Display"
                    padding={"1.3rem 1rem"}
                    borderRadius="16px"
                    border="1px solid #EFEDED"
                    bg="#E66F15"
                    mt={8}
                    width="100%"
                    color="#fff"
                    fontSize=".9rem"
                    fontWeight={"700"}
                  >
                    Add a Settlement Account
                  </Button>
                </Box>
              )}
              <Text onClick={() => setView("one")} mt="2rem" color={"#E66F15"} fontWeight={"600"} textAlign={"center"} cursor={"pointer"} textDecoration={"underline"}>Change Settlement Account</Text>
            </>
          )}
          {view === "three" && (
            <Box textAlign={"center"}>
              <Image margin="0 auto" src={Empty} />
              <Text
                fontFamily={"Clash Display"}
                fontWeight="700"
                fontSize="1.2rem"
                color="#030303"
              >
                Nothing to see here
              </Text>
              <Text color={"#666666"}>
                Accounts will only appear after you’ve added one
              </Text>
              <Button
                onClick={() => setView("one")}
                type="submit"
                fontFamily="Clash Display"
                padding={"1.3rem 1rem"}
                borderRadius="16px"
                border="1px solid #EFEDED"
                bg="#E66F15"
                mt={8}
                width="100%"
                color="#fff"
                fontSize=".9rem"
                fontWeight={"700"}
              >
                Add a Settlement Account
              </Button>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default PayoutInformation;
