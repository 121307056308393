import { Box, Image, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../services/api";
import TrueHeart from './heart.svg';
import FalseHeart from './hearticon.svg';

const SaveAction = (id) => {
    const [action, setAction] = useState("");

    const toast = useToast();

    const currentUser = useSelector(({userData})=>  userData?.currentUser);

    const saveItem = async () => {
        // console.log(id)
        if(currentUser) {
        try {
            const {data} = await axiosInstance.post(`/sale-items/${id.id}/saved-items`)
            // console.log(data)
            setAction("like")
            toast({
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } catch(error) {
            // console.log(error?.response?.data?.message)
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        }
    } else {
        toast({
            // title: "An Error Occurred",
            description: "You must first login or signup to save items",
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
    }
    }

    const deleteSaveItem = async () => {
        console.log(id.id)
        try {
            const {data} = await axiosInstance.post(`/saved-items/:savedItemId`)
            console.log(data)
            setAction("")
            toast({
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        }
    }

    return (
        <Box onClick={saveItem} position={"absolute"} right="5%" top="5%">
            {action === "like" ?
                <Image src={TrueHeart} />
            :
                <Image src={FalseHeart} />
            }
        </Box>
    )
}

export default SaveAction;