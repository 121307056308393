import { ArrowBackIcon, PhoneIcon, WarningIcon } from "@chakra-ui/icons";
import { Box, Flex, HStack, Text, Spinner, FormControl, InputGroup, Input, InputRightElement, FormLabel, Image, RadioGroup, Stack, Radio, Divider, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, useDisclosure, Textarea, ModalCloseButton, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import axiosInstance from "../../services/api";
import Paystack from './paystack.svg';
import Location from '../settings/assets/location.svg';
import { useSelector } from "react-redux";
import AutoComplete from "../../components/googlePlacesAutocomplete";
import PayStack from "../../components/paystack/paystack";
import { RatingStar } from "rating-star";
import Header from "../../components/header/header";


const Checkout = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [coupon, setCoupon] = useState("");
    const [delivery, setDelivery] = useState("");
    

    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
    const [addressx, setAddressx] = useState("");

    const [newAddress, setNewAddress] = useState(false);

    const [addOns, setAddons] = useState(0);
    const [addOnsx, setAddonsx] = useState(0)

    const [trig, setTrig] = useState("")

    const toast = useToast()

    

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
    const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure();
    const currentUser = useSelector(({userData})=>  userData?.currentUser);

    useEffect(() => {
        axiosInstance.get(`/sale-items/${id}`)
        // .then(res => console.log("vvvxx", res?.data?.data))
        .then(res => setItem(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, [trig])

    const trigger = () => {
        if(delivery) {
            setLoading(true)
            setTrig(Math.random())
            if(item?.isAvailable) {
                onOpen3()
            } else {
                toast({
                    // title: "An Error Occurred",
                    description: "Oops! Item is no longer available to purchase",
                    status: "info",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                    });
            }
            
        } else {
            toast({
            // title: "An Error Occurred",
            description: "Please select or add delivery address before proceeding",
            status: "info",
            position: "top-right",
            duration: 5000,
            isClosable: true,
            });
        }
        
    }

    function Resolver() {
        if(delivery !== "") {
            return <Button mb={3} padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"90%"} color="#fff" fontSize=".9rem" fontWeight={"700"}>₦{item?.listPrice?.toLocaleString()}</Button>
        } else {
            return <Button disabled mb={3} padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"90%"} color="#fff" fontSize=".9rem" fontWeight={"700"}>₦{item?.listPrice?.toLocaleString()}</Button>
        }
    }

    function Resolverx() {
        if(addressx && city && phone) {
            return <Button onClick={handleNewAddress} mb={4} type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={8} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Save Address" }</Button>
        } else {
            return <Button mb={4} disabled type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={8} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Save Address" }</Button>
        }
    }

    const handleNewAddress = () => {
        setNewAddress(true)
        onClose()
    }
    const handleChangeAddress = () => {
        if(!currentUser?.defaultDeliveryAddress) {
            onOpen()
        }
        setDelivery("delivery")
    }

    const dAd = newAddress ? 
    {
        address: addressx,
        landmarkArea: city,
        phoneNumber: phone
    }
    :
    currentUser?.defaultDeliveryAddress

    

    return (
        <Box>
            <Header />
            {loading ? 
            <Flex marginTop={"7rem"} marginBottom={"7rem"} justifyContent={"center"}>
                <Spinner size={"lg"} color="#E66F15" />
            </Flex>
            :
            <Box px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={"2rem"}>
                <HStack alignItems={"center"} gap="2rem">
                    <Text onClick={() => navigate(-1)} cursor={"pointer"} fontWeight={"600"} color={"#1A1A1A"}><ArrowBackIcon transform={"translate(-4px,-1px)"} />Back</Text>
                    <Text mb={6} fontSize={"1.25rem"} fontWeight="700" fontFamily={"Clash Display"}>Checkout</Text>
                </HStack>
                <Flex gap={["2rem", "4rem", "6rem", "10rem"]} maxWidth="1200px" flexWrap="wrap" margin={"2rem auto 0 auto"}>
                    <Box flex="1" padding={["0", "0", "1.5rem", "1.5rem"]} minW="300px">
                        <Text color="#666666" fontSize={".95rem"} fontWeight="700">Order Summary</Text>
                        <Flex mt={5} bg="#FFF" borderRadius="12px" gap="1rem" alignItems={"center"} border="1px solid #EAEAEA" padding="1.5rem 1.2rem">
                            <Box height="60px" width="60px" backgroundImage={item?.imageURLs[0]} backgroundSize="cover" borderRadius="12px"></Box>
                            <Box>
                                <Text mb={2} fontWeight="500" color="#1A1A1A">{item?.name}</Text>
                                <Text fontWeight="700" color="#1A1A1A">₦{item?.listPrice?.toLocaleString()}</Text>
                            </Box>
                        </Flex>
                        <FormControl mt={10} mb={3}>
                            <FormLabel transform={"translateY(2px)"} fontSize=".9rem" fontWeight={"700"} color={"#666666"}>Coupon</FormLabel>
                            <InputGroup>
                                <Input onChange={e => setCoupon(e.target.value)} type="tel" outline={"none"} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15", outline: "none"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"24px"} padding={"1.3rem 1rem"} placeholder="Enter coupon code" _placeholder={{color: "#8C8C8C"}} fontSize=".9rem" />
                                <InputRightElement children={<Text cursor={"pointer"} transform={"translateY(1px)"} color="#8C8C8C" fontSize={".85rem"} marginLeft="-1rem">Apply</Text>} />
                            </InputGroup>
                        </FormControl>
                        <Text mt={5} color="#666666" fontSize={".95rem"} fontWeight="700">Payment Method</Text>
                        <Flex mt={2} bg="#FFF" borderRadius="12px" gap="1rem" alignItems={"center"} border="1px solid #EAEAEA" padding="1rem">
                            <Image src={Paystack} />
                            <Text mb={2} fontWeight="500" color="#1A1A1A" fontSize={".95rem"}>Pay using Paystack</Text>
                        </Flex>
                        <Text mt={5} color="#666666" fontSize={".95rem"} fontWeight="700">Delivery</Text>
                        <RadioGroup onChange={setDelivery}>
                            <Stack direction='column'>
                                {/* <Flex onClick={handleChangeAddress} mt={2} mb={1} bg="#FFF" borderRadius="12px" alignItems={"center"} border={delivery === "delivery" ? "1.5px solid #E66F15" : "1px solid #EAEAEA"} padding="1rem">
                                    <Radio colorScheme='orange' value="delivery">Delivery using WeHaul</Radio>
                                </Flex> */}
                                <Flex onClick={handleChangeAddress} mt={2} mb={1} bg="#FFF" borderRadius="12px" alignItems={"center"} border={delivery === "delivery" ? "1.5px solid #E66F15" : "1px solid #EAEAEA"} padding="1rem">
                                    <Radio colorScheme='orange' value="delivery">Set Delivery Address</Radio>
                                </Flex>
                                <Text marginTop={"1.5rem"} fontSize={[".85rem", ".92rem"]} color="#666666" fontWeight={"400"}><WarningIcon mr={1} color="#E66F15" /> Delivery address must be added before purchase can be made.</Text>
                                {delivery === "delivery" && 
                                currentUser?.defaultDeliveryAddress &&
                                !newAddress ? 
                                    <Flex mb={4} padding={".7rem"} borderRadius="12px" border="1px solid #F0F0F0" position={"relative"}>
                                        <Image mr={3} height={"fit-content"} padding={".8rem"} borderRadius="50%" border="1px solid #EAEAEA" src={Location} />
                                        <Box>
                                            <Text color="#030303">{currentUser?.defaultDeliveryAddress?.address}</Text>
                                            <Text color="#404040">{currentUser?.defaultDeliveryAddress?.landmarkArea}</Text>
                                            <Text mt={2} color="#8C8C8C" fontSize={".9rem"}>{currentUser?.defaultDeliveryAddress?.phoneNumber}</Text>
                                        </Box>
                                        <Text onClick={() => onOpen()} color="#8BA700" fontSize={".8rem"} fontWeight="700" cursor={"pointer"} position="absolute" right="3%" bottom="10%">Change</Text>
                                    </Flex> :
                                null}
                                {delivery === "delivery" && 
                                newAddress &&
                                    <Flex mb={4} padding={".7rem"} borderRadius="12px" border="1px solid #F0F0F0" position={"relative"}>
                                        <Image mr={3} height={"fit-content"} padding={".8rem"} borderRadius="50%" border="1px solid #EAEAEA" src={Location} />
                                        <Box>
                                            <Text color="#030303">{addressx}</Text>
                                            <Text color="#404040">{city}</Text>
                                            <Text mt={2} color="#8C8C8C" fontSize={".9rem"}>{phone}</Text>
                                        </Box>
                                        <Text onClick={() => onOpen()} color="#8BA700" fontSize={".8rem"} fontWeight="700" cursor={"pointer"} position="absolute" right="3%" bottom="10%">Change</Text>
                                    </Flex>
                                }
                                {/* <Flex mt={2} bg="#FFF" borderRadius="12px" alignItems={"center"} border={delivery === "pickup" ? "1.5px solid #E66F15" : "1px solid #EAEAEA"} padding="1rem">
                                    <Radio colorScheme='orange' value="pickup">Self Pick up</Radio>
                                </Flex> */}
                            </Stack>
                        </RadioGroup>
                       
                    </Box>
                    <Box flex="1" padding={["0", "0", "1.5rem", "1.5rem"]} minW="300px">
                        <Box borderRadius="12px" border="1px solid #EAEAEA" padding={"1.2rem"}>
                            <Flex mb={6} alignItems={"center"} justifyContent="space-between">
                                <Text>Sub Total</Text>
                                <Text fontSize={"1.1rem"} fontWeight="700">₦{item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted").length > 0 ? item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted")[0].listPrice?.toLocaleString() : item?.listPrice?.toLocaleString()}</Text>
                            </Flex>
                            <Flex mb={6} alignItems={"center"} justifyContent="space-between">
                                <Text>Processing Fee</Text>
                                <Text fontWeight={"500"}>₦{item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted").length > 0 ? (item?.processingFeePercent / 100) * item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted")[0].listPrice : (item?.processingFeePercent / 100) * item?.listPrice}</Text>
                            </Flex>
                            <Flex mb={6} alignItems={"center"} justifyContent="space-between">
                                <Text>Tax</Text>
                                <Text fontWeight={"500"}>₦{item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted").length > 0 ? (addOnsx / 100) * item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted")[0].listPrice : (addOnsx / 100) * item?.listPrice}</Text>
                            </Flex>
                            <Divider />
                            <Flex mt={6} alignItems={"center"} justifyContent="space-between">
                                <Text>Total (Excluding Delivery)</Text>
                                <Text fontSize={"1.1rem"} fontWeight="700">₦{(item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted").length > 0 ? item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted")[0].listPrice + ((addOns / 100) * item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted")[0].listPrice) + ((addOnsx / 100) * item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted")[0].listPrice) : item?.listPrice + ((addOnsx / 100) * item?.listPrice) + ((addOns / 100) * item?.listPrice)) + (item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted").length > 0 ? (item?.processingFeePercent / 100) * item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted")[0].listPrice : (item?.processingFeePercent / 100) * item?.listPrice)}</Text>
                            </Flex>
                        </Box>
                        <Text marginTop={"1.5rem"} textAlign={"center"} fontSize={[".85rem", ".92rem"]} color="#666666" fontWeight={"500"}><WarningIcon mr={1} color="#E66F15" /> Estimates from our delivery partners - <strong>₦{item?.minDeliveryPrice?.toLocaleString()} - ₦{item?.maxDeliveryPrice?.toLocaleString()}</strong></Text>
                        {/* <Flex mt={10} justifyContent="center">
                            <Resolver />
                        </Flex> */}
                        <Flex mt={8} justifyContent="center" 
                        > 
                           <Button onClick={trigger} padding={".9rem 1.5rem"} fontFamily="Clash Display" borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"80%"} color="#fff" fontSize={[".7rem", ".9rem"]} fontWeight={"600"}>Checkout</Button>
                        </Flex>
                        
                        {/* <Button onClick={() => onOpen2()}>Go</Button>
                        <Button onClick={() => console.log(currentUser?.defaultDeliveryAddress)}>console</Button> */}
                    </Box>
                </Flex>
            </Box>}


            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent margin={"0 .5rem"}>
                <ModalHeader>Delivery Address</ModalHeader>
                <ModalBody>
                    <Text fontFamily={"Clash Display"} fontWeight="700">Add a valid address</Text>
                    <br />
                    <FormControl mb={2}>
                        <AutoComplete setAddress={setAddressx} address={addressx} />
                        {/* <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">Address</FormLabel>
                        <Input onChange={e => setAddress(e.target.value)} type="text" _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} placeholder="e.g 99, Declutters street" fontSize=".9rem" /> */}
                    </FormControl>
                    <FormControl mb={5}>
                        <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">City/State</FormLabel>
                        <Input onChange={e => setCity(e.target.value)} type="text" _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} placeholder="e.g Yaba, Abuja" fontSize=".9rem" />
                    </FormControl>
                    <FormControl>
                        <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">Phone Number</FormLabel>
                        <Input onChange={e => setPhone(e.target.value)} type="tel" minLength={11} maxLength={11} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} placeholder="e.g 09090939300" fontSize=".9rem" />
                    </FormControl>
                    {/* <Checkbox onChange={e => setDef(e.target.checked)} defaultChecked={def} colorScheme={"green"} mt={3}>Save as default address</Checkbox> */}
                    <br />
                    <Resolverx />
                </ModalBody>
                </ModalContent>
            </Modal>

            <Modal isCentered isOpen={isOpen2} onClose={onClose2}>
                <ModalOverlay />
                <ModalContent margin={"0 .5rem"}>
                <ModalCloseButton />
                <ModalHeader pt={8} fontFamily="Clash Display">Chat with our Delivery Partner</ModalHeader>
                <ModalBody>
                    <Text>Wehaul lets you book, negotiate, and track your deliveries.</Text>
                    <br />
                    <Flex mb={4} padding={".7rem"} borderRadius="12px" border="1px solid #F0F0F0" position={"relative"}>
                        <Image mr={3} height={"fit-content"} padding={".8rem"} borderRadius="50%" border="1px solid #EAEAEA" src={Location} />
                        <Box>
                            <Text color="#030303">Wehaul247 Delivery Services</Text>
                            <Text color="#404040">Yaba, Lagos</Text>
                            <Text mt={2} color="#8C8C8C" fontSize={".9rem"}>09160000687</Text>
                        </Box>
                        <PhoneIcon onClick={() => onOpen()} color="#AED100" cursor={"pointer"} position="absolute" right="3%" bottom="10%" />
                    </Flex>
                    
                    <br />
                    <Text marginTop={"1rem"} color="#666666" fontWeight={"500"}><WarningIcon mr={1} color="#E66F15" /> You will be redirected to our Whatsapp contact</Text>
                    <a target="_blank" href={"https://wa.me/" + "2349160000687" + "?text=Hi, I purchased this item " + `https://declutters-dashboard.onrender.com/item-details/${id}` + " from Declutters and would like to have it picked up at " + item?.pickUpAddress + ", and delivered to " + currentUser?.defaultDeliveryAddress?.address + ". Delivery contact is " + currentUser?.defaultDeliveryAddress?.phoneNumber + ". How much would this cost?"} data-action="share/whatsapp/share">
                        <Button onClick={handleNewAddress} mb={4} type="submit" padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" mt={3} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>Chat with us</Button>
                    </a>
                </ModalBody>
                </ModalContent>
            </Modal>

            <Modal isCentered isOpen={isOpen3} onClose={onClose3}>
                    <ModalOverlay />
                    <ModalContent margin={"0 .5rem"}>

                    <ModalBody py="2rem">
                       {loading ?
                        <>
                        <Flex marginTop={"7rem"} justifyContent={"center"}>
                            <Spinner size={"lg"} color="#E66F15" />
                        </Flex>
                        <Text textAlign={"center"} mt={5} marginBottom={"7rem"} fontWeight="500">Processing Checkout.. Hold On.</Text>
                        </>
                        :
                        <>
                         <Text textAlign={"center"} mt={5} marginBottom={"2rem"} fontWeight="500">Proceed to make payment.</Text>
                         <Flex mt={8} justifyContent="center">
                         <PayStack 
                            depositAmount={(item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted").length > 0 ? item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted")[0].listPrice + ((addOns / 100) * item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted")[0].listPrice) + ((addOnsx / 100) * item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted")[0].listPrice) : item?.listPrice + ((addOnsx / 100) * item?.listPrice) + ((addOns / 100) * item?.listPrice)) + (item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted").length > 0 ? (item?.processingFeePercent / 100) * item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted")[0].listPrice : (item?.processingFeePercent / 100) * item?.listPrice)} 
                            // depositAmount={item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted").length > 0 ? item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted")[0].listPrice : item?.listPrice} 
                            userEmail={currentUser?.email} 
                            userId={currentUser?._id} 
                            listPrice={item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted").length > 0 ? item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted")[0].listPrice : item?.listPrice} 
                            sellerPrice={item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted").length > 0 ? item?.offers?.filter(i => i.buyer === currentUser?._id && i.status === "accepted")[0].sellerPrice : item?.sellerPrice}
                            discountAmount={0}
                            processingFee={item?.processingFeePercent}
                            taxAmount={0}
                            deliveryAd={dAd}
                            itemId={item?._id}
                            delivery={delivery}
                            isAvailable={item?.isAvailable}
                            setModal={onOpen2}
                            closePrev={onClose3}
                            buyerId={currentUser?._id}
                         />
                         </Flex>
                        </>
                        }
                    </ModalBody>
                    </ModalContent>
            </Modal>

            
        </Box>
    )
}

export default Checkout;