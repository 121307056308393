import { Box, Button, Flex, Image, Text } from "@chakra-ui/react"

import Six from '../../assets/trending/six.svg';
import Seven from '../../assets/trending/seven.svg';
import Eight from '../../assets/trending/eight.svg';
import Nine from '../../assets/trending/nine.svg';
import Ten from '../../assets/trending/ten.svg';
import Location from '../../assets/location.svg';
import { Link, useNavigate } from "react-router-dom";
import TextArrow from "../textArrow";


const Similar = ({data, category, id}) => {
    const navigate = useNavigate();

    const handleNav = (id) => {
        navigate(`/item-details/${id}`, {state: "paid"})
        window.location.reload()
    }

    return (
        <Box>
            <TextArrow text={category === "null" ? "More items in the clearance sale" : "More like this"} link={category === "null" ? "#" : "/"} /><br />
            <Flex gap="1rem" rowGap={"3rem"} overflowX="scroll" sx={
                { 
               '::-webkit-scrollbar':{
                      display:'none'
                  }
               }
             }>
            {category === "null" ?
            data?.filter(i => i._id !== id)?.slice(0,5).map(({imageURLs, name, listPrice, pickUpLocation, _id}) => {
                return (
                    <Box onClick={() => handleNav(_id)} cursor={"pointer"} overflow={"hidden"} minWidth="250px" flex="0" key={_id}>
                        <Box position={"relative"} overflow={"hidden"} borderRadius="12px" height="230px" width={"100%"} backgroundImage={imageURLs[0]} backgroundSize="cover">
                            {/* {savedItems.map(i => i?.item?._id).includes(_id) ? <SaveAction2 id={idx[0]} from="main" /> : <SaveAction id={_id} />} */}
                        </Box>
                        <Box padding={"0 .3rem"}>
                            {/* <Text mt={1} fontWeight="600" fontSize={".9rem"}>{createdAt?.slice(0,10)}</Text> */}
                            <Text mt={1} fontWeight="600" fontSize={".9rem"}>{name}</Text>
                            <Flex mt={2} justifyContent={"space-between"}>
                                <Flex alignItems={"center"}>
                                    <Image mr={1} transform={"translateY(1.5px)"} width={"20%"} src={Location} />
                                    <Text mt={1} color="#908888" fontSize={".9rem"}>{pickUpLocation?.landmarkArea}</Text>
                                </Flex>
                                <Text mt={1} fontSize=".9rem" fontWeight="600">₦{listPrice?.toLocaleString()}</Text>
                            </Flex>
                        </Box>
                    </Box>
                )
            })
            :
            data?.filter(i => i.category === category).slice(0,5).map(({imageURLs, name, listPrice, pickUpLocation, _id}) => {
                return (
                    <Box onClick={() => handleNav(_id)} cursor={"pointer"} overflow={"hidden"} minWidth="250px" flex="0" key={_id}>
                        <Box position={"relative"} overflow={"hidden"} borderRadius="12px" height="230px" width={"100%"} backgroundImage={imageURLs[0]} backgroundSize="cover">
                            {/* {savedItems.map(i => i?.item?._id).includes(_id) ? <SaveAction2 id={idx[0]} from="main" /> : <SaveAction id={_id} />} */}
                        </Box>
                        <Box padding={"0 .3rem"}>
                            {/* <Text mt={1} fontWeight="600" fontSize={".9rem"}>{createdAt?.slice(0,10)}</Text> */}
                            <Text mt={1} fontWeight="600" fontSize={".9rem"}>{name}</Text>
                            <Flex mt={2} justifyContent={"space-between"}>
                                <Flex alignItems={"center"}>
                                    <Image mr={1} transform={"translateY(1.5px)"} width={"20%"} src={Location} />
                                    <Text mt={1} color="#908888" fontSize={".9rem"}>{pickUpLocation?.landmarkArea}</Text>
                                </Flex>
                                <Text mt={1} fontSize=".9rem" fontWeight="600">₦{listPrice?.toLocaleString()}</Text>
                            </Flex>
                        </Box>
                    </Box>
                )
            })}
            </Flex>
        </Box>
    )
}

export default Similar