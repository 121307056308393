import { Box, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import Eight from '../../assets/trending/eight.svg';
import Nine from '../../assets/trending/nine.svg';
import Ten from '../../assets/trending/ten.svg';
import Location from '../../assets/location.svg'
import Heart from './heart.svg';
import { useEffect, useState } from "react";
import axiosInstance from "../../services/api";
import SaveAction2 from "../../components/saveAction/saveAction2";
import Header from "../../components/header/header";

const data = [
    {
        img: Eight,
        name: "Layered Cupboard",
        location: "Surulere",
        price: "35000"
    },
    {
        img: Nine,
        name: "Standing Mirror (7 Feet)",
        location: "Iwaya",
        price: "10000"
    },
    {
        img: Ten,
        name: "Round Wall Mirror",
        location: "Oyingbo",
        price: "20000"
    }
]


const SavedItems = () => {
    const [savedItems, setSavedItems] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axiosInstance.get("/saved-items")
        // .then(res => console.log("vvv", res?.data?.data))
        .then(res => setSavedItems(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, []);

    const navigate = useNavigate()

    return (
        <Box>
            <Header />
            <Box px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={["1.2rem", "1.5rem", "2rem", "2rem"]}>
                <Text fontSize={"1.25rem"} fontWeight="700" fontFamily={"Clash Display"}>Saved Items</Text>
                {loading ? 
                    <Flex margin={"7rem 0"} justifyContent={"center"}>
                        <Spinner size={"lg"} color="#E66F15" />
                    </Flex>
                    :
                <Flex mt={4} marginBottom="4rem" wrap={"wrap"} gap="1rem" rowGap={"3rem"}>
                    {savedItems?.map(({item, _id}) => {
                        return (
                            // <Link to={`/item-details/${item?._id}`} style={{flex: "0", flexBasis: "250px"}} key={_id}>
                                <Box onClick={() => navigate(`/item-details/${item?._id}`)} cursor={"pointer"} overflow={"hidden"} minWidth={["150px", "250px" ]} flex="1" key={_id}>
                                    <Box position={"relative"} overflow={"hidden"} borderRadius="12px" height="230px" width={"100%"} backgroundImage={item?.imageURLs[0]} backgroundSize="cover">
                                        {/* <Image src={Heart} position="absolute" right={"4%"} top="5%" /> */}
                                        <SaveAction2 id={_id} />
                                    </Box>
                                    <Box padding={"0 .3rem"}>
                                        <Text mt={1} fontWeight="600">{item?.name}</Text>
                                        <Flex mt={2} justifyContent={"space-between"}>
                                            <Flex alignItems={"center"}>
                                                <Image mr={1} transform={"translateY(1.5px)"} width={"20%"} src={Location} />
                                                <Text mt={1} color="#908888">{item?.pickUpLocation?.landmarkArea}</Text>
                                            </Flex>
                                            <Text mt={1} fontSize=".95rem" fontWeight="600">₦{parseInt(item?.listPrice)?.toLocaleString()}</Text>
                                        </Flex>
                                    </Box>
                                </Box>
                            // </Link>
                        )
                    })}
                </Flex>}
            </Box>
        </Box>
    )
}

export default SavedItems;