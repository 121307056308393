import { ArrowBackIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Divider, Checkbox, FormControl, Input, Textarea } from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import axiosInstance from "../../../../services/api";




const Category = ({setStep, setCategory, setName, setDesc}) => {
    const [selected, setSelected] = useState("");
    const [name, setNamex] = useState("");
    const [desc, setDescx] = useState("");
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        axiosInstance.get("/item-categories")
        // .then(res => console.log("xxxxx", res?.data?.data))
        .then(res => setCategories(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, [])
    

    function Resolver() {
        if(selected && name && desc) {
            return <Button type="submit" onClick={handleContinue} fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={6} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>Continue</Button>
        } else {
            return <Button disabled type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={6} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>Continue</Button>
        }
    }

    const handleContinue = () => {
        setCategory(selected)
        setName(name)
        setDesc(desc)
        setStep("five")
    }

    const handleSelect = (obj) => {
        setSelected(JSON.parse(obj))
        onClose()
    }

    return (
        <Box>
            <Text fontSize={["1.05rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>Which category would you like to list this item under?</Text>
            <Text mt={3} fontWeight={"600"} fontSize={".9rem"} color="#667085">Select the category that best describes it.</Text>

            <Flex onClick={onOpen} cursor={"pointer"} mt={4} alignItems={"center"} justifyContent="space-between" padding={".7rem 1rem"} borderRadius="24px" border="1px solid #B0B4B0">
                <Text color={selected ? "#1A1A1A" : "#B0B4B0"} fontSize={".9rem"}>{selected ? selected?.name : "Select a category"}</Text>
                <ChevronDownIcon color="#B0B4B0" fontSize={"1.2rem"} />
            </Flex>
            <br />
            <Text fontSize={["1.05rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>What is the name of the item you’re listing?</Text>
            <Text mt={3} fontWeight={"600"} fontSize={".9rem"} color="#667085">Name it in a way that makes it easy to find.</Text>

            <FormControl mt={4}>
                <Input onChange={e => setNamex(e.target.value)} type="text" _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} placeholder="Enter item name" _placeholder={{color: "#8C8C8C"}} fontSize=".9rem" />
            </FormControl>
            <br />
            <Text fontSize={["1.05rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>How would you describe this item?</Text>
            <Text mt={3} fontWeight={"600"} fontSize={".9rem"} color="#667085">Provide your buyer with information on the features and appearance of this item.</Text>

            <FormControl mt={4}>
                <Textarea onChange={e => setDescx(e.target.value)} type="text" _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"8px"} rows="6" padding={"1rem"} placeholder="Enter full description" _placeholder={{color: "#8C8C8C"}} fontSize=".9rem" />
            </FormControl>

            <Resolver />

            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxHeight={"600px"} overflow="scroll" padding={".8rem"}>
                <ModalHeader mb={6}>
                <Text cursor={"pointer"} onClick={onClose} fontSize="1.1rem" fontFamily="Clash Display" fontWeight={"700"} color={"#1A1A1A"}><ArrowBackIcon transform={"translate(-6px,-1px)"} />Categories</Text>
                </ModalHeader>
                <ModalBody>
                    {categories?.map((i, index) => {
                        return (
                            <Fragment key={i?._id}>
                            <Flex onClick={() => handleSelect(JSON.stringify(i))} position={"relative"} pb={4} alignItems={"center"} cursor={"pointer"}>
                                <Box mr={2} width={"34px"} height="34px" border="2px solid #F7CFB1" borderRadius={"50%"} backgroundImage={i?.imageURL} backgroundSize="cover"></Box>
                                <Box>
                                    <Text fontFamily={"Clash Display"} fontWeight="500">{i?.name}</Text>
                                    {/* <Flex maxW="260px" overflow={"hidden"} alignItems={"center"}>
                                        {tags.map((i, index) => ( */}
                                            <Text whiteSpace={"nowrap"} mt={1} maxWidth="270px" overflow={"hidden"} fontWeight="500" color="#8C8C8C">{i?.description}</Text>
                                        {/* ))}
                                    </Flex> */}
                                </Box>
                                <Checkbox size={"lg"} colorScheme={"orange"} position="absolute" right="3%" top="8%" />
                            </Flex>
                            <Divider />
                            <br />
                            </Fragment>
                        )
                    })}
                </ModalBody>

                
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default Category