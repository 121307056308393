import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Checkbox, Flex, FormControl, FormLabel, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Skeleton, Spinner, Stack, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useState } from "react";
import Location from '../assets/location.svg';
import Empty from '../assets/empty.svg'
import axiosInstance from "../../../services/api";
import { useEffect } from "react";
import { useRef } from "react";

const DeliveryAddress = () => {
    const [loading, setLoading] = useState(false);
    const [loadingr, setLoadingr] = useState(true);
    const [loadingu, setLoadingu] = useState(false);
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
    const [def, setDef] = useState(false);

    const [addresses, setAddresses] = useState([])

    const [view, setView] = useState("");
    const [data, setData] = useState(["a"]);
    const [tempData, setTempData] = useState(null);

    const [addressx, setAddressx] = useState("");
    const [cityx, setCityx] = useState("");
    const [phonex, setPhonex] = useState("");
    const [defx, setDefx] = useState(false);

    const toast = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
    const cancelRef = useRef()

    useEffect(() => {
        axiosInstance.get("/users/delivery-addresses")
        .then(res => setAddresses(res?.data?.data))
        .then(res => setLoadingr(false))
        .catch(error => console.log(error))
    }, [loading, loadingu])

    const datax = {
        address: address,
        landmarkArea: city,
        phoneNumber: phone,
        saveAsDefault: def
    }
    

    const addAddress = async(e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const {data} = await axiosInstance.post("/users/delivery-addresses", datax)
            setView("")
            toast({
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoading(false)
        }
    }

    const deleteAddress = async(id) => {
        setLoading(true)
        try {
            const res = await axiosInstance.delete(`/users/delivery-addresses/${id}`, datax)
            console.log(res)
            onclose()
            toast({
                description: "Address deleted succesfully",
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoading(false)
        }
    }

    const handleUpdate = async (i) => {
        await setTempData(JSON.parse(i))
        onOpen2()
    }

    const updateAddress = async() => {
        setLoadingu(true);
        const dataxy = {
            address: addressx || tempData?.address,
            landmarkArea: cityx || tempData?.landmarkArea,
            phoneNumber: phonex || tempData?.phoneNumber,
            saveAsDefault: false
        }
        try {
            const res = await axiosInstance.patch(`/users/delivery-addresses/${tempData?._id}`, dataxy)
            console.log(res?.data)
            onClose2()
            toast({
                description: "Address updated succesfully",
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoadingu(false)
        }
    }

    function Resolver() {
        if(address && city && phone) {
            return <Button type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={8} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Save Address" }</Button>
        } else {
            return <Button disabled type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={8} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Save Address" }</Button>
        }
    }
    return (
        <Box>
            {view === "form" && <form onSubmit={addAddress}>
                <Text fontFamily={"Clash Display"} fontWeight="700">Add an Address</Text>
                <br />
                <FormControl mb={5}>
                    <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">Address</FormLabel>
                    <Input onChange={e => setAddress(e.target.value)} type="text" _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} placeholder="99, Declutters street" fontSize=".9rem" />
                </FormControl>
                <FormControl mb={5}>
                    <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">City/State</FormLabel>
                    <Input onChange={e => setCity(e.target.value)} type="text" _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} placeholder="Yaba, Abuja" fontSize=".9rem" />
                </FormControl>
                <FormControl>
                    <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">Phone Number</FormLabel>
                    <Input onChange={e => setPhone(e.target.value)} type="tel" minLength={11} maxLength={11} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} placeholder="09090939300" fontSize=".9rem" />
                </FormControl>
                <Checkbox onChange={e => setDef(e.target.checked)} defaultChecked={def} colorScheme={"green"} mt={3}>Save as default address</Checkbox>
                <br />
                <Resolver />
            </form>}
            {loadingr ? 
                <Stack>
                    <Skeleton height={"40px"} width="100%" />
                    <Skeleton height={"40px"} width="100%" />
                    <Skeleton height={"40px"} width="100%" />
                    <Skeleton height={"40px"} width="100%" />
                    <Skeleton height={"40px"} width="100%" />
                </Stack>
                :
                addresses.length ?
                <>
                {view !== "form" &&
                addresses?.map((i) => {
                    return (
                        <Flex key={i?._id} mb={4} padding={".7rem"} borderRadius="12px" border="1px solid #F0F0F0" position={"relative"}>
                            <Image mr={3} height={"fit-content"} padding={".8rem"} borderRadius="50%" border="1px solid #EAEAEA" src={Location} />
                            <Box>
                                <Text color="#030303">{i?.address}</Text>
                                <Text color="#404040">{i?.landmarkArea}</Text>
                                <Text mt={2} color="#8C8C8C" fontSize={".9rem"}>{i?.phoneNumber}</Text>
                            </Box>
                            <Text onClick={() => handleUpdate(JSON.stringify(i))} color="#8BA700" fontSize={".8rem"} fontWeight="700" cursor={"pointer"} position="absolute" right="3%" bottom="10%">Edit</Text>
                            <DeleteIcon cursor={"pointer"} onClick={onOpen} position="absolute" right="3%" top="14%" color="gray" />

                                <AlertDialog
                                    isOpen={isOpen}
                                    leastDestructiveRef={cancelRef}
                                    onClose={onClose}
                                >
                                    <AlertDialogOverlay>
                                    <AlertDialogContent>
                                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                        Delete Address
                                        </AlertDialogHeader>

                                        <AlertDialogBody>
                                        Are you sure? You can't undo this action afterwards.
                                        </AlertDialogBody>

                                        <AlertDialogFooter>
                                        <Button ref={cancelRef} onClick={onClose}>
                                            Cancel
                                        </Button>
                                        <Button colorScheme='red' onClick={() => deleteAddress(i?._id)} ml={3}>
                                            {loading ? <Spinner /> : "Delete"}
                                        </Button>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                    </AlertDialogOverlay>
                                </AlertDialog>
                        </Flex>
                    )
                })}
                {view !== "form" && addAddress?.length !== 0 && <Text onClick={() => setView("form")} cursor={"pointer"} mt={6} textAlign={"center"} fontWeight={"600"} color={"#8BA700"}><AddIcon transform={"translate(-8px,-1px)"} />Add new delivery address</Text>}
                </>
                :
                view !== "form" &&
               <Box textAlign={"center"}>
                    <Image margin="0 auto" src={Empty} />
                    <Text fontFamily={"Clash Display"} fontWeight="700" fontSize="1.2rem" color="#030303">Nothing to see here</Text>
                    <Text color={"#666666"}>Addresses will only appear after you’ve added one</Text>
                    <Button onClick={() => setView("form")} type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={8} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>Add an Address</Button>
                </Box>
            }
                

            <Modal isOpen={isOpen2} onClose={onClose2}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Edit Address</ModalHeader>
                <ModalBody>
                    <FormControl mb={5}>
                        <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">Address</FormLabel>
                        <Input onChange={e => setAddressx(e.target.value)} type="text" _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} placeholder={tempData?.address} _placeholder={{color: "#1A1A1A"}} fontSize=".9rem" />
                    </FormControl>
                    <FormControl mb={5}>
                        <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">City/State</FormLabel>
                        <Input onChange={e => setCityx(e.target.value)} type="text" _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} placeholder={tempData?.landmarkArea} _placeholder={{color: "#1A1A1A"}} fontSize=".9rem" />
                    </FormControl>
                    <FormControl>
                        <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">Phone Number</FormLabel>
                        <Input onChange={e => setPhonex(e.target.value)} type="tel" minLength={11} maxLength={11} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} placeholder={tempData?.phoneNumber} _placeholder={{color: "#1A1A1A"}} fontSize=".9rem" />
                    </FormControl>
                    {/* <Checkbox onChange={e => setDefx(e.target.checked)} defaultChecked={tempData?.saveAsDefault} colorScheme={"green"} mt={3}>Save as default address</Checkbox> */}
                    <br />
                    <br />
                </ModalBody>

                <ModalFooter>
                    <Button mr={4} variant='ghost' onClick={onClose2}>Cancel</Button>
                    <Button bg="#E66F15" color={"#fff"} mr={3} onClick={updateAddress}>
                    {loadingu ? <Spinner /> : "Update"}
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default DeliveryAddress;