import { Box, Text } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import Header from "../../components/header/header";
import Navbar from "../../components/navbar/navbar";

const Faqs = () => {
  return (
    <>
    <Header />
    <Box marginBottom={"10rem"} borderTop={"1px solid #EAEAEA"} px={[".8rem", "1.5rem", "2.5rem", "3rem"]}>
        <Box maxW={"700px"} margin="3rem auto 0 auto">
        <Text mb={9} fontSize={"1.25rem"} fontWeight="700" fontFamily={"Clash Display"}>Frequently Asked Questions (FAQs)</Text>
        <Text mb={3} color={"#666666"} fontSize=".85rem" fontWeight={"700"}>Buying</Text>
      <Accordion defaultIndex={[0]} allowMultiple allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton padding={".6rem 0"}>
              <Box color={"#1A1A1A"} fontWeight="500" padding={".6rem 0"} as="span" flex="1" textAlign="left" borderTop={"none"}>
              How do I know the status of my order
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color={"#2E1604"} fontSize=".9rem" pb={4} padding={".6rem 0"}>
          Using the app, you can track your order status. To view updates on your order, please go to Purchase history - you’ll see all you orders with the delivery status beside them, click on your order to see full details.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton padding={".6rem 0"}>
              <Box color={"#1A1A1A"} fontWeight="500" padding={".6rem 0"} as="span" flex="1" textAlign="left">
              How to track my order
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color={"#2E1604"} fontSize=".9rem" pb={4} padding={".6rem 0"}>
          Using the app, you can track your order status. To view updates on your order, please go to Purchase history - you’ll see all you orders with the delivery status beside them, click on your order to see full details.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton padding={".6rem 0"}>
              <Box color={"#1A1A1A"} fontWeight="500" padding={".6rem 0"} as="span" flex="1" textAlign="left">
              Is it possible to return an item?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color={"#2E1604"} fontSize=".9rem" pb={4} padding={".6rem 0"}>
          You can contact our chat support if you encounter any problems (damaged items or items not as described). Send us images demonstrating these issues - if they qualify, you'll receive a full refund.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton padding={".6rem 0"}>
              <Box color={"#1A1A1A"} fontWeight="500" padding={".6rem 0"} as="span" flex="1" textAlign="left">
              What is the accepted payment method?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color={"#2E1604"} fontSize=".9rem" pb={4} padding={".6rem 0"}>
          Declutters only accepts payment via Paystack, you'll be redirected to a secure page and then back to our app to confirm your order.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <br />
        <Text mb={3} color={"#666666"} fontSize=".85rem" fontWeight={"700"}>Selling</Text>
      <Accordion defaultIndex={[0]} allowMultiple allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton padding={".6rem 0"}>
              <Box color={"#1A1A1A"} fontWeight="500" padding={".6rem 0"} as="span" flex="1" textAlign="left" borderTop={"none"}>
              How do I list an item?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color={"#2E1604"} fontSize=".9rem" pb={4} padding={".6rem 0"}>
          You can make your first listing by clicking Sell or tapping the Sell button (in the webapp) and filling in all the necessary information. A fixed commission is placed on each sold item.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton padding={".6rem 0"}>
              <Box color={"#1A1A1A"} fontWeight="500" padding={".6rem 0"} as="span" flex="1" textAlign="left">
              How do i withdraw my earnings?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color={"#2E1604"} fontSize=".9rem" pb={4} padding={".6rem 0"}>
          Upon making a sale, your funds will be held in reserve until the buyer confirms the purchase is in good condition - after which you will receive the funds in the bank account you provided. Go to Settings to add a settlement account.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton padding={".6rem 0"}>
              <Box color={"#1A1A1A"} fontWeight="500" padding={".6rem 0"} as="span" flex="1" textAlign="left">
              How do I resolve a return case?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color={"#2E1604"} fontSize=".9rem" pb={4} padding={".6rem 0"}>
          Your payments will be held until you either replace the purchased item or decide to refund the money if a buyer reports it damaged or not as described.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton padding={".6rem 0"}>
              <Box color={"#1A1A1A"} fontWeight="500" padding={".6rem 0"} as="span" flex="1" textAlign="left">
              How to deliver my listed items
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color={"#2E1604"} fontSize=".9rem" pb={4} padding={".6rem 0"}>
          If you're listing an item, please include your full address in order for our delivery service to know where to deliver it when it's picked up.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      </Box>
    </Box>
    </>
  );
};

export default Faqs;
