import { Box, Flex, Text, Spinner, Image } from "@chakra-ui/react";
import moment from "moment";
import { RatingStar } from "rating-star";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../components/header/header";
import Navbar from "../../components/navbar/navbar";
import axiosInstance from "../../services/api";
import Empty from './empty.svg'


const Rating = () => {
    const [reviews, setReviews] = useState();
    const [loading, setLoading] = useState(true);

    const currentUser = useSelector(({userData})=>  userData?.currentUser);

    const sum = reviews?.reduce((accumulator, object) => {
        return accumulator + object.rating;
      }, 0);

    useEffect(() => {
        axiosInstance.get(`/reviews/seller/${currentUser?._id}`)
        // .then(res => console.log("vvvxx", res?.data?.data))
        .then(res => setReviews(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, [])

    return (
        <>
        <Header />
        <Box mt={8} px={[".8rem", "1.5rem", "2.5rem", "3rem"]}>
            <Text fontSize={"1.25rem"} fontWeight="700" fontFamily={"Clash Display"}>Reviews & Ratings</Text>
            {loading ?
                <Flex marginTop={"10rem"} justifyContent={"center"}>
                    <Spinner size={"lg"} color="#E66F15" />
                </Flex>
                :
                reviews?.length < 1 ? 
                <Box textAlign={"center"} marginTop="5rem">
                    <Image src={Empty} transform="scale(.6) translateY(20px)" width={"auto"} margin="0 auto" />
                    <Text color="#030303" fontFamily={"Clash Display"} fontWeight="600">No Reviews yet</Text>
                    <Text color="#666666" fontSize={".9rem"}>Reviews will appear here once you start selling</Text>
                </Box>
                :
                <>
                <Flex mb={5} marginTop="2rem" alignItems={"center"}>
                    <Text mr={4} fontSize={"2rem"} color="#1A1A1A" fontWeight={"500"} fontFamily={"Clash Display"}>{(sum / reviews?.length).toFixed(1)}</Text>
                    <Box mb={1}>
                        <Flex gap=".3rem">
                            <Box marginLeft={"-.8rem"}>
                                <RatingStar
                                    // clickable
                                    maxScore={5}
                                    id="123"
                                    rating={sum / reviews?.length}
                                    colors={{ mask: "#F0BB00" }}
                                    // onRatingChange={onRatingChange}
                                />
                            </Box>
                        </Flex>
                        <Text fontSize={".9rem"} color="#666666" fontWeight={"500"}>{reviews?.length} product {`rating${reviews?.length > 1 ? "s" : ""}`}</Text>
                    </Box>
                </Flex>
                {/* <Divider /> */}
                <Flex alignItems={"center"} gap="1rem" flexWrap={"wrap"}>
                    {reviews?.map(({item, createdAt, comment, rating}, index) => {
                        return (
                            <Box key={index} border="1px solid #EAEAEA" borderRadius={"12px"} padding="1rem" flex={["1", "1", "0", "0"]} minW={"330px"} >
                                <Flex mb={3} alignItems="center" justifyContent={"space-between"}>
                                    <RatingStar
                                        maxScore={5}
                                        id={index}
                                        rating={rating}
                                        colors={{ mask: "#F0BB00" }}
                                    />
                                    <Text fontSize={".85rem"} color="#666666" fontWeight={"500"}>{moment(createdAt).format('MMMM Do YYYY')}</Text>
                                </Flex>
                                <Text color="#1A1A1A" fontWeight={"600"}>{item?.name}</Text>
                                <Text mt={2} color="#666666" fontSize={".95rem"} fontWeight={"500"}>{comment}</Text>
                            </Box>
                        )
                    })}
                </Flex>
            </>}
        </Box>
        <br /><br /><br /><br /><br />
        </>
    )
}

export default Rating;