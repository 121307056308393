import { ArrowBackIcon, ChevronDownIcon, ChevronUpIcon, WarningIcon } from "@chakra-ui/icons";
import { Box, Flex, HStack, Text, Spinner, FormControl, InputGroup, Input, InputRightElement, FormLabel, Image, RadioGroup, Stack, Radio, Divider, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, useDisclosure, Badge, ModalCloseButton, Textarea, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import axiosInstance from "../../services/api";
// import Paystack from './paystack.svg';
import Location from '../settings/assets/location.svg';
import { useSelector } from "react-redux";
import AutoComplete from "../../components/googlePlacesAutocomplete";
import PayStack from "../../components/paystack/paystack";
import moment from "moment/moment";
import { RatingStar } from "rating-star";
import Header from "../../components/header/header";
import Utils from "../../utils";


const OrderDetails = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [coupon, setCoupon] = useState("");
    const [delivery, setDelivery] = useState("");

    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
    const [addressx, setAddressx] = useState("");

    const [newAddress, setNewAddress] = useState(false);

    const [revLoader, setRevLoader] = useState(false)

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
    const currentUser = useSelector(({userData})=>  userData?.currentUser);

    const [rating, setRating] = useState("");
    const [comment, setComment] = useState("");
    const onRatingChange = (score) => {
        setRating(score);
      };

    const [aloading, setAloading] = useState(false);
    const [cloading, setCloading] = useState(false);

    const [reason, setReason] = useState("");
    const [pick, setPick] = useState(false)

    useEffect(() => {
        axiosInstance.get(`/transactions/${id}`)
        // .then(res => console.log("vvvxx", res?.data?.data))
        .then(res => setItem(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, []);

    const [dispute, setDispute] = useState([]);
    const [loadingd, setLoadingd] = useState([]);

    useEffect(() => {
        axiosInstance.get("/disputes/user")
        // .then(res => console.log("vvv", res?.data?.data))
        .then(res => setDispute(res?.data?.data))
        .then(res => setLoadingd(false))
        .catch(error => console.log(error))
    }, []);

    const toast = useToast()

    function Resolver() {
        if(delivery) {
            return <Button mb={3} padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"90%"} color="#fff" fontSize=".9rem" fontWeight={"700"}>Pay ₦91,000</Button>
        } else {
            return <Button disabled mb={3} padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"90%"} color="#fff" fontSize=".9rem" fontWeight={"700"}>Pay ₦91,000</Button>
        }
    }

    function Resolverx() {
        if(addressx && city && phone) {
            return <Button onClick={handleNewAddress} mb={4} type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={8} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Save Address" }</Button>
        } else {
            return <Button mb={4} disabled type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={8} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Save Address" }</Button>
        }
    }

    const handleNewAddress = () => {
        setNewAddress(true)
        onClose()
    }
    const handleChangeAddress = () => {
        if(!currentUser?.defaultDeliveryAddress) {
            onOpen()
        }
        setDelivery("delivery")
    }



    const datax = {
        rating,
        comment
    }

    const submitReview = async (e) => {
        e.preventDefault()
        setRevLoader(true)

        try {
            const {data} = await axiosInstance.post(`/sale-items/${item?.item?._id}/reviews`, datax)
            console.log(data)
            onClose2()
        toast({
            // title: "An Error Occurred",
            description: data?.message,
            status: "success",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
          window.location.reload()
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setRevLoader(false)
        }
    }

    const [type, setType] = useState("")

    const handleResolutions = (type) => {
        setType(type)
        onOpen()
    }


    const cancelPurchase = async () => {
        setCloading(true)

        const datax = {
            reason
        }

        try {
            const {data} = await axiosInstance.patch(`/transactions/${id}/cancel`, datax)
            console.log(data)
            toast({
                // title: "An Error Occurred",
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
              window.location.reload()
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setCloading(false)
        }
    }


    const approvePurchase = async () => {
        setCloading(true)
        try {
            const {data} = await axiosInstance.patch(`/transactions/${id}/approve`)
            toast({
                // title: "An Error Occurred",
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
            onOpen2()
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setCloading(false)
        }
    }

    // Calculate the date 7 days ago from the current date
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);






    return (
        <Box>
            <Header />
            {loading ? 
            <Flex marginTop={"7rem"} marginBottom={"7rem"} justifyContent={"center"}>
                <Spinner size={"lg"} color="#E66F15" />
            </Flex>
            :
            <Box px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={"1.5rem"}>
                <HStack alignItems={"center"} gap="2rem">
                    <Text onClick={() => navigate(-1)} cursor={"pointer"} fontWeight={"600"} color={"#1A1A1A"}><ArrowBackIcon transform={"translate(-4px,-1px)"} />Back</Text>
                    <Text mb={6} fontSize={"1.25rem"} fontWeight="700" fontFamily={"Clash Display"}>Order Summary</Text>
                </HStack>
                <Flex gap={"2rem"} maxWidth="1200px" flexWrap="wrap-reverse" margin={"2rem auto 0 auto"}>
                    <Box flex="1" padding={["0", "0", "1.5rem", "1.5rem"]} minW="300px">
                        <Flex alignItems={"center"} justifyContent="space-between">
                            <Box>
                                <Text onClick={() => Utils.copyValue(item?.orderNum)} mb={2} color="#1A1A1A" fontSize={".95rem"} fontWeight="700">Order {item?.orderNum} <span style={{color: "#E66F15", fontSize: ".65rem", marginLeft: ".2rem", cursor: "pointer"}}>Copy</span></Text>
                                <Text color="#1A1A1A" fontSize={".9rem"} fontWeight="500">{moment(item?.createdAt).format('MMMM Do YYYY')}</Text>
                            </Box>
                            <Text borderRadius={"20px"} bg={item?.status === "pending" ? "#EE9F63" : item?.status === "cancelled" ? "#F04438" : item?.status === "disputed" ? "purple" : "#12B76A"} color="#FFF" fontWeight={"600"} fontSize=".85rem" padding={".5rem .8rem"}>{item?.status === "pending" ? "Awaiting Delivery" : item?.status === "cancelled" ? "Cancelled" : item?.status === "disputed" ? "Under Dispute" : "Delivered"}</Text>
                        </Flex>
                        
                        <Flex mt={5} bg="#FFF" borderRadius="12px" gap="1rem" alignItems={"center"} border="1px solid #EAEAEA" padding="1.5rem 1.2rem">
                             <Box height="60px" width="60px" backgroundImage={item?.item?.imageURLs[0]} backgroundSize="cover" borderRadius="12px"></Box>
                            <Box>
                                <Text mb={2} fontWeight="500" color="#1A1A1A">{item?.item?.name?.slice(0,1).toUpperCase() + item?.item?.name?.slice(1)}</Text>
                                <Text fontWeight="700" color="#1A1A1A">₦{item?.listPrice?.toLocaleString()}</Text>
                            </Box>
                            
                        </Flex>
                        
                        <Flex mt={7} alignItems={"center"} justifyContent="space-between">
                            <Text color="#666666" fontSize={".95rem"} fontWeight="700">Pick Up Information</Text>
                            {!pick ? <ChevronDownIcon onClick={() => setPick(!pick)} cursor={"pointer"} fontSize={"1.2rem"} color="#666666" /> : <ChevronUpIcon onClick={() => setPick(!pick)} cursor={"pointer"} fontSize={"1.2rem"} color="#666666" />}
                        </Flex>
                        
                        
                        {pick && item?.deliveryInfo && 
                            <Flex mb={4} mt={2} padding={".7rem"} borderRadius="12px" border="1px solid #F0F0F0" position={"relative"}>
                                <Image mr={3} height={"fit-content"} padding={".8rem"} borderRadius="50%" border="1px solid #EAEAEA" src={Location} />
                                <Box>
                                    <Text color="#030303" fontSize={".9rem"}>{item?.item?.pickUpAddress}</Text>
                                    <Text color="#404040" fontSize={".9rem"}>{item?.item?.pickUpLocation?.landmarkArea}, {item?.item?.pickUpLocation?.state?.slice(0,1).toUpperCase() + item?.item?.pickUpLocation?.state?.slice(1)}</Text>
                                    <Text mt={2} color="#8C8C8C" fontSize={".85rem"}>{item?.deliveryInfo?.phoneNumber}</Text>
                                </Box>
                                {/* <Text onClick={() => onOpen()} color="#8BA700" fontSize={".8rem"} fontWeight="700" cursor={"pointer"} position="absolute" right="3%" bottom="10%">Change</Text> */}
                            </Flex>
                        }

                    {item?.seller?._id !== currentUser?._id &&
                    <>
                    <a target="_blank" href={"https://wa.me/" + "2349160000687" + "?text=Hi, I purchased this item " + `https://declutters-dashboard.onrender.com/item-details/${id}` + " from Declutters and would like to have it picked up at " + item?.pickUpAddress + ", and delivered to " + currentUser?.defaultDeliveryAddress?.address + ". Delivery contact is " + currentUser?.defaultDeliveryAddress?.phoneNumber + ". How much would this cost?"} data-action="share/whatsapp/share">
                    <Text marginTop={"1rem"} fontSize=".9rem" textAlign={"center"} color="#666666" fontWeight={"500"}>Want this item delivered instead ? <strong style={{color: "#E66F15", cursor: "pointer"}}>Request Delivery</strong></Text>
                    </a>
                        
                        {item?.status === "pending" &&
                            <Flex margin={"3rem auto 2rem auto"} justifyContent={"center"} alignItems="center" gap="3rem">
                                {cloading ? <Spinner color="#E66F15" />
                                :
                                <>
                                <Text onClick={() => handleResolutions("cancel")} cursor="pointer" whiteSpace="nowrap" color="#E66F15" fontSize=".9rem" fontWeight={"700"}>Cancel Purchase</Text>
                                <Button onClick={() => handleResolutions("approve")} padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" color="#fff" fontSize=".9rem" fontWeight={"700"}>{aloading ? <Spinner /> : "Confirm Pick Up"}</Button>
                                </>}
                            </Flex>}
                    </>
                    }

                    {item?.seller?._id === currentUser?._id && item?.status === "pending" && new Date(item?.createdAt) < sevenDaysAgo ?
                    <Flex margin={"3rem auto 2rem auto"} justifyContent={"center"} alignItems="center" gap="3rem">
                        {cloading ? <Spinner color="#E66F15" />
                        :
                        <>
                        <Text onClick={() => handleResolutions("cancel")} cursor="pointer" whiteSpace="nowrap" color="#E66F15" fontSize=".9rem" fontWeight={"700"}>Cancel Order</Text>
                        {/* <Button onClick={() => handleResolutions("approve")} padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" color="#fff" fontSize=".9rem" fontWeight={"700"}>{aloading ? <Spinner /> : "Confirm Pick Up"}</Button> */}
                        </>}
                    </Flex>
                    :
                    null
                    }


                    </Box>
                    <Box flex="1" padding={["0", "0", "1.5rem", "1.5rem"]}>
                        <Box borderRadius="12px" border="1px solid #EAEAEA" padding={"1.2rem"}>
                            <Flex mb={6} alignItems={"center"} justifyContent="space-between">
                                <Text>Sub Total</Text>
                                <Text fontWeight={"500"}>₦{item?.listPrice?.toLocaleString()}</Text>
                            </Flex>
                            <Flex mb={6} alignItems={"center"} justifyContent="space-between">
                                <Text>Processing Fee</Text>
                                <Text fontWeight={"500"}>₦{item?.processingFee?.toLocaleString()}</Text>
                            </Flex>
                            <Flex mb={6} alignItems={"center"} justifyContent="space-between">
                                <Text>Tax</Text>
                                <Text fontWeight={"500"}>₦{item?.taxAmount?.toLocaleString()}</Text>
                            </Flex>
                            <Divider />
                            <Flex mt={6} alignItems={"center"} justifyContent="space-between">
                                <Text>Total</Text>
                                <Text fontWeight={"500"}>₦{item?.totalAmount?.toLocaleString()}</Text>
                            </Flex>
                        </Box>

                        {dispute?.filter(i => i?.item?._id === item?.item?._id ).length < 1 ?
                        <Text marginTop={"2rem"} fontSize=".9rem" textAlign={"center"} color="#666666" fontWeight={"500"}>Need help with this order? <strong onClick={() => navigate(`/orders/${id}/dispute`, {state: item?.status})} style={{color: "#E66F15", cursor: "pointer"}}>Dispute Issue</strong></Text>
                        :
                        <Text marginTop={"2rem"} fontSize=".9rem" textAlign={"center"} color="#666666" fontWeight={"500"}>Follow up on dispute conversation? <strong onClick={() => navigate(`/orders/dispute-thread/${dispute?.filter(i => i?.item?._id === item?.item?._id )[0]._id}`)} style={{color: "#E66F15", cursor: "pointer"}}>Dispute Thread</strong></Text>
                        }
                        
                    </Box>
                </Flex>
            </Box>}


            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent margin={"0 .5rem"}>
                <ModalHeader>{type === "cancel" ? "Cancel purchase" : "Confirm Pick Up"}</ModalHeader>
                    <ModalBody>
                        <ModalCloseButton />
                        
                        <Text fontFamily={"Clash Display"} fontWeight="500">{type === "cancel" ? "Are you sure you want to cancel this purchase?" : "You are agreeing that the item is in excellent condition and the seller can get paid for it."}</Text>
                        {type === "cancel" && <Text mt={2} fontSize={".85rem"}>Please note that cancellation attracts a 5% fee. However, if cancellation is entirely due to the seller not meeting their obligations, please go ahead and <strong onClick={() => navigate(`/orders/${id}/dispute`)} style={{color: "#E66F15", cursor: "pointer", textDecoration: "underline"}}>DISPUTE</strong> the transaction instead. Please note that dispute resolution can take up to 2 - 4 weeks to resolve</Text>}
                        <br />
                       {type === "cancel" &&
                       <>
                        <FormControl>
                            <FormLabel transform={"translate(7px,3px)"} color="#475467" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">Enter a reason</FormLabel>
                            <Textarea onChange={e => setReason(e.target.value)} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"18px"} padding={"1rem"} rows="6" placeholder="Provide a reason for cancelling this purchase" fontSize=".9rem" />
                        </FormControl>
                        <br />
                        </>
                        }
                        <Button mb={4} onClick={type === "cancel" ? () => cancelPurchase() : () => approvePurchase()} type="submit" padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={8} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{cloading ? <Spinner /> : "Yes, Confirm"}</Button>
                        <Button mb={4} onClick={() => onClose()} type="submit" padding={"1rem 1rem"} borderRadius="16px" border="1px solid #E66F15" bg="#FFF" width="100%" color="#000" fontSize=".9rem" fontWeight={"700"}>No</Button>
                        <Text mb={4} textAlign={"center"} fontSize={".85rem"}><strong onClick={() => navigate(`/orders/${id}/dispute`)} style={{color: "#E66F15", cursor: "pointer", textDecoration: "underline"}}>Dispute Instead ?</strong></Text>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal isCentered isOpen={isOpen2} onClose={onClose2}>
                <ModalOverlay />
                <ModalContent>
                <ModalCloseButton />
                <ModalHeader textAlign={"center"} pt={8} fontFamily="Clash Display">Leave a  Review</ModalHeader>
                <ModalBody>
                    <Flex bg="#FAE2D0" borderRadius="12px" gap="1rem" alignItems={"center"} padding="1rem 1.2rem">
                        <Box height="60px" width="60px" backgroundImage={item?.item?.imageURLs[0]} backgroundSize="cover" borderRadius="12px"></Box>
                        <Box>
                            <Text mb={2} fontWeight="500" color="#1A1A1A" fontSize={".9rem"}>{item?.item?.name}</Text>
                            <Text fontWeight="700" color="#1A1A1A" fontSize={".9rem"}>₦{item?.listPrice?.toLocaleString()}</Text>
                        </Box>
                    </Flex>
                    <br />
                    <form onSubmit={submitReview}>
                        <Box textAlign={"center"}>
                            <Text fontSize={".9rem"} color="#666666" fontWeight="600">Rate this seller</Text>
                            <RatingStar
                                clickable
                                maxScore={5}
                                id={123456}
                                rating={rating}
                                colors={{ mask: "#F0BB00" }}
                                onRatingChange={onRatingChange}
                                size={48}
                            />
                        </Box>
                        <br />
                        <FormControl>
                            <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">Leave a Review</FormLabel>
                            <Textarea onChange={e => setComment(e.target.value)} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"18px"} padding={"1rem"} rows="6" placeholder="Provide your thoughts on the item(s) you ordered...." fontSize=".9rem" />
                        </FormControl>
                        <br />
                        <Button mb={4} type="submit" padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={8} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{revLoader ? <Spinner /> : "Submit Review" }</Button>
                    </form>
                </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default OrderDetails;




{/* <Modal isCentered isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
    <ModalHeader>Delivery Address</ModalHeader>
    <ModalBody>
<Text fontFamily={"Clash Display"} fontWeight="700">Add a valid address</Text>
    <br />
    <FormControl mb={2}>
        <AutoComplete setAddress={setAddressx} address={addressx} />
    </FormControl>
    <FormControl mb={5}>
        <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">City/State</FormLabel>
        <Input onChange={e => setCity(e.target.value)} type="text" _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} placeholder="e.g Yaba, Abuja" fontSize=".9rem" />
    </FormControl>
    <FormControl>
        <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">Phone Number</FormLabel>
        <Input onChange={e => setPhone(e.target.value)} type="tel" minLength={11} maxLength={11} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} placeholder="e.g 09090939300" fontSize=".9rem" />
    </FormControl>
    
    <Resolverx />
    </ModalBody>
    </ModalContent>
</Modal> */}