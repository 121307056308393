import { AddIcon } from "@chakra-ui/icons";
import { Avatar, AvatarGroup, Box, FormControl, FormLabel, Input, Text, Spinner, Button, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../../services/api";


const PersonalInfo = () => {
    const { profilePicture, firstName, lastName, email, phoneNumber } = useSelector(({userData})=>  userData?.currentUser);
    const [loading, setLoading] = useState(false);
    const [dp, setDp] = useState(null);
    const [dpx, setDpx] = useState("");
    const [phoneNumberx, setPhoneNumberx] = useState(phoneNumber);

    const toast = useToast()

    const handleImg = async(e) => {
        await setDp(e.target.files[0])
        console.log(e.target.files[0])
    
        const blob = URL.createObjectURL(e.target.files[0])
        setDpx(blob)
    }

    const datax = new FormData();
    datax.append("image", dp)

    const updateProfilePicture = async() => {
        // e.preventDefault()
        setLoading(true)
        try {
            const {data} = await axiosInstance.patch("/users/profile-picture", datax)
            toast({
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoading(false)
        }
    }
    const updateProfile = async() => {
        // e.preventDefault()
        setLoading(true)
        const datax = {
            phoneNumber: phoneNumberx.charAt(0) === "0" ? "+234" + phoneNumberx.slice(1) : "+234" + phoneNumberx
        }
        try {
            const {data} = await axiosInstance.patch("/users/profile", datax)
            toast({
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoading(false)
        }
    }

    const handleUpdate = () => {
        if(dp !== null && phoneNumberx === "") {
            updateProfilePicture()
        } else if(dp === null && phoneNumber !== "") {
            updateProfile()
        } else {
            updateProfilePicture()
            updateProfile()
        }
    }

    return ( 
        <Box width={"100%"}>
            <Box mb={2} textAlign={"center"}>
                <AvatarGroup cursor="pointer" width={"62px"} margin={"0 auto"} position={"relative"}>
                    <Avatar cursor={"pointer"} size={"lg"} border="1px solid #EE9F63" src={dpx || profilePicture} />
                    <AddIcon cursor={"pointer"} right={"8%"} bottom={"5%"} position={"absolute"} bg="#E66F15" color="#fff" zIndex={"4"} fontSize="1.4rem" padding=".4rem" borderRadius={"50%"} />
                    <Input onChange={handleImg} cursor={"pointer"} type="file" bg="red" position={"absolute"} zIndex={"4"} opacity="0" height="100%" />
                </AvatarGroup>
                <Text mt={2} color="#C05D12" fontWeight={"600"}>Update your profile picture</Text>
            </Box>
            <br />
            {/* <form> */}
                <FormControl>
                    <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">First Name</FormLabel>
                    <Input _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #E66F15 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} value={firstName} fontSize=".9rem" />
                </FormControl>
                <FormControl mt={5}>
                    <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">Last Name</FormLabel>
                    <Input _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #E66F15 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} value={lastName} fontSize=".9rem" />
                </FormControl>
                <FormControl mt={5}>
                    <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">Phone Number</FormLabel>
                    <Input onChange={e => setPhoneNumberx(e.target.value)} minLength={11} maxLength={11} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #E66F15 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} value={phoneNumberx} fontSize=".9rem" placeholder="Add your 11 digit phone number (without the country code)" />
                </FormControl>
                <FormControl mt={5}>
                    <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">Email Address</FormLabel>
                    <Input _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #E66F15 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} value={email} fontSize=".9rem" />
                </FormControl>
                <Button onClick={handleUpdate} fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" mt={9} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Save Changes" }</Button>
            {/* </form> */}
        </Box>
    )
}

export default PersonalInfo;