import { ArrowBackIcon, ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Divider, Checkbox, FormControl, FormLabel, Textarea, Input, InputRightElement, InputGroup, Image } from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import AutoComplete from "../../../../components/googlePlacesAutocomplete";
import statesLgaJson from "../../../../utils/stateLGAs";
import axiosInstance from "../../../../services/api";
import baseUrl from "../../../../config";
import Loc from '../../../../assets/location.svg'

const datay = [
    {
        name: "Brand New",
    },
    {
        name: "Almost New",
    },
    {
        name: "Fairly Used",
    },
    {
        name: "Used",
    }
]


const Location = ({setStep, setLocation, setCondition, setAddress, setDefect}) => {
    const [selected, setSelected] = useState("");
    const [selectedx, setSelectedx] = useState("");
    const [selectedy, setSelectedy] = useState("");
    const [temp, setTemp] = useState("");
    const [data, setData] = useState([]);
    const [datax, setDatax] = useState([]);
    const [loading, setLoading] = useState(true);
    const [address, setAddressx] = useState("");

    const [search, setSearch] = useState("")

    const [stateView, setStateView] = useState(true)
    
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

    useEffect(() => {
        axiosInstance.get(`${baseUrl}/state-areas`)
        // .then(res => console.log(res?.data?.data))
        .then(res => setData(res?.data?.data))
        .then(() => setLoading(false))
        .catch(error => console.log(error))
    }, [])

    function Resolver() {
        if(selected && selectedy && address) {
            return <Button type="submit" onClick={handleContinue} fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={6} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>Continue</Button>
        } else {
            return <Button disabled type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={6} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>Continue</Button>
        }
    }

    // const getRegions = async (alias) => {
    //     try {
    //         const {data} = await axios.get(`https://locus.fkkas.com/api/regions/${alias}`)
    //         setDatax(data?.data)
    //         // console.log(data?.data)
    //     } catch(error) {
    //         console.log(error)
    //     }
    // }

    const handleSelect = (name) => {
        setSelectedy(name)
        onClose2()
    }

    const handleStateSelect = (name) => {
        setSelected(name)
        // setTemp(alias)
        // getRegions(alias)
        setStateView(false)
    }

    const handleStateSelectx = (name) => {
        setSelectedx(name)
        onClose()
    }

    const handleContinue = () => {
        setCondition(selectedy)
        setLocation(selectedx + ", " + selected)
        setAddress(address)
        setStep("eight")
    }

    return (
        <Box>
            <Text fontSize={["1.05rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>What is the condition of this item?</Text>
            <Text mt={3} fontWeight={"600"} fontSize={".9rem"} color="#667085">What is the length of time this item has been used for?</Text>

            <Flex onClick={onOpen2} cursor={"pointer"} mt={4} alignItems={"center"} justifyContent="space-between" padding={".7rem 1rem"} borderRadius="20px" border="1px solid #B0B4B0">
                <Text color={selectedy ? "#1A1A1A" : "#B0B4B0"} fontSize={".9rem"}>{selectedy ? selectedy : "Select a condition"}</Text>
                <ChevronDownIcon color="#B0B4B0" fontSize={"1.2rem"} />
            </Flex>

            <br />
            <Text fontSize={["1.05rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>Select a pick up location</Text>
            <Text mt={3} fontWeight={"600"} color="#667085" fontSize={".9rem"}>Provide your buyer with as much information as possible about the item.</Text>

            <Flex onClick={onOpen} cursor={"pointer"} mt={4} alignItems={"center"} justifyContent="space-between" padding={".7rem 1rem"} borderRadius="20px" border="1px solid #B0B4B0">
                <Text color={selected ? "#1A1A1A" : "#B0B4B0"} fontSize={".9rem"} textTransform={"capitalize"}>{selected ? selectedx + ", " + selected : "Select location"}</Text>
                <ChevronDownIcon color="#B0B4B0" fontSize={"1.2rem"} />
            </Flex>
            <br />
            <Text fontSize={"1.25rem"} fontWeight="500" fontFamily={"Clash Display"}>Enter your full pick up address</Text>
            <Text mt={3} fontWeight={"600"} color="#667085" fontSize={".9rem"}>Please provide us with the exact address where we can pick up the item, or choose the location on the map.</Text>

            <FormControl mt={4}>
                {/* <AutoComplete setAddress={setAddressx} address={address} /> */}
                <InputGroup>
                    <Input onChange={e => setAddressx(e.target.value)} type="text" _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} placeholder="Enter address" _placeholder={{color: "#8C8C8C"}} fontSize=".9rem" />
                    <InputRightElement children={<Image src={Loc} />} />
                </InputGroup>
            </FormControl>
            <Resolver />

            <Modal isCentered isOpen={isOpen2} onClose={onClose2}>
                <ModalOverlay />
                <ModalContent maxHeight={"600px"} overflow="scroll" padding={".8rem"}>
                <ModalHeader mb={6}>
                <Text cursor={"pointer"} onClick={onClose2} fontSize="1.1rem" fontFamily="Clash Display" fontWeight={"700"} color={"#1A1A1A"}><ArrowBackIcon transform={"translate(-6px,-1px)"} />Condition</Text>
                </ModalHeader>
                <ModalBody>
                    {datay?.map(({img, name, egs}, index) => {
                        return (
                            <>
                            <Flex onClick={() => handleSelect(name)} position={"relative"} pb={4} alignItems={"center"} cursor={"pointer"} key={index}>
                                <Text fontFamily={"Clash Display"} fontWeight="500">{name}</Text>
                                <Checkbox size={"lg"} colorScheme={"orange"} position="absolute" right="3%" top="8%" />
                            </Flex>
                            <Divider />
                            <br />
                            </>
                        )
                    })}
                </ModalBody>

                
                </ModalContent>
            </Modal>

            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxHeight={"600px"} overflowY="scroll" padding={".8rem"}>
                <ModalHeader mb={6}>
                <Text cursor={"pointer"} onClick={() => setStateView(true)} fontSize="1.1rem" fontFamily="Clash Display" fontWeight={"700"} color={"#1A1A1A"}><ArrowBackIcon transform={"translate(-6px,-1px)"} />{stateView ? "State" : "Local Government"}</Text>
                </ModalHeader>
                <ModalBody>
                    {stateView ?
                    <>
                        {data.sort((a, b) => a.state?.localeCompare(b.state))?.map(({_id, state, areas}, index) => {
                            return (
                                <Box key={index}>
                                <Flex onClick={() => handleStateSelect(state)} position={"relative"} pb={4} justifyContent="space-between" alignItems={"center"} cursor={"pointer"}>
                                    <Text fontFamily={"Clash Display"} fontWeight="500" textTransform={"capitalize"}>{state}</Text>
                                    <ChevronRightIcon fontSize={"1.3rem"} color="#D1FB00" />
                                    {/* <Checkbox size={"lg"} colorScheme={"orange"} position="absolute" right="3%" top="8%" /> */}
                                </Flex>
                                <Divider />
                                <br />
                                </Box>
                            )
                        })}
                    </>
                    :
                    <>
                    <FormControl mb="1.2rem">
                        <Input onChange={e => setSearch(e.target.value)} type="text" _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} placeholder="Search Area" _placeholder={{color: "#8C8C8C"}} fontSize=".9rem" />
                    </FormControl>
                        {data?.find(i => i.state === selected)?.areas?.filter(i => i.toLowerCase().includes(search.toLowerCase()))?.map((i, index) => {
                            return (
                                <Box key={index}>
                                <Flex onClick={() => handleStateSelectx(i)} position={"relative"} pb={4} justifyContent="space-between" alignItems={"center"} cursor={"pointer"}>
                                    <Text fontFamily={"Clash Display"} fontWeight="500" textTransform={"capitalize"}>{i}</Text>
                                    {/* <ChevronRightIcon fontSize={"1.2rem"} color="#D1FB00" /> */}
                                    <Checkbox size={"lg"} colorScheme={"orange"} position="absolute" right="3%" top="8%" />
                                </Flex>
                                <Divider />
                                <br />
                                </Box>
                            )
                        })}
                    </>
                    }
                </ModalBody>

                
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default Location;