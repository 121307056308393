import { Box, Image } from "@chakra-ui/react"
import Slider from "react-slick";
import Bannerx from '../assets/banner.svg';
import Banner1 from '../assets/banner/web-banner-1.jpg';
import Banner2 from '../assets/banner/web-banner-2.jpg';
import Banner3 from '../assets/banner/web-banner-3.jpg';
import Banner4 from '../assets/banner/web-banner-4.jpg';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../services/api";
import axios from "axios";

const Banner = () => {
    const [banners, setBanners] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        // axiosInstance.get("sale-items")
        axiosInstance.get("banners")
        // .then(res => console.log("vvv", res?.data))
        .then(res => setBanners(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, []);

    const navigate = useNavigate()
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
      };
    return (
        <Slider {...settings}>
            {/* <Box height={"150px"} backgroundImage={Banner1} backgroundSize="cover"></Box> */}
            {/* <Image cursor={"pointer"} onClick={() => navigate('/explore/Gadgets & Electronics')} width={"100%"} src={Banner1} />
            <Image cursor={"pointer"} onClick={() => navigate('/explore/Living & Dining Rooms')} width={"100%"} src={Banner2} />
            <Image cursor={"pointer"} onClick={() => navigate('/explore/Living & Dining Rooms')} width={"100%"} src={Banner3} />
            <Image cursor={"pointer"} onClick={() => navigate('/todays-deals')} width={"100%"} src={Banner4} /> */}
            {!loading &&
             banners?.map(({web, routeLink}) => {
                return (
                    // <Box key={banner} cursor={"pointer"} onClick={() => navigate(`/${routeLink}`)} height={"350px"} bg="lightgray" backgroundImage={banner} backgroundSize={"cover"}>

                    // </Box>
                    <Image key={web} cursor={"pointer"} onClick={() => navigate(`/${routeLink}`)} width={"100%"} src={web} />
                )
            })}
        </Slider>
    )
}

export default Banner;