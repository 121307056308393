import { Box, Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Spinner, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { PaystackButton, usePaystackPayment } from "react-paystack";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../services/api";
import './paystack.css';


const PayStack = ({userEmail, depositAmount, itemId, listPrice, sellerPrice, discountAmount, processingFee, taxAmount, deliveryAd, delivery, setModal, disabled, isAvailable, closePrev, buyerId}) => {
    const config = {
        reference: new Date().getTime().toString(),
        email: userEmail,
        amount: depositAmount * 100,
        metadata: {
          itemId,
          buyerId,
          deliveryInfo: deliveryAd,
          listPrice,
          sellerPrice,
          processingFee,
          taxAmount,
          totalAmount: depositAmount
      },
        publicKey: "pk_live_3d3f3ca79e0e04a3329cf81bc311b4297d256464",
        // publicKey: "pk_live_188c18c736ea5f25357fe4414665d6e8fe16896e",
      };

      const initializePayment = usePaystackPayment(config);

    
      const currentUser = useSelector(({ userData }) => userData.currentUser);
      const toast = useToast();
      const navigate = useNavigate();
      const { isOpen, onOpen, onClose: onClosex } = useDisclosure();
    
      const onSuccess = async (reference) => {
        console.log("transaction successful", reference);
        onOpen()
        const datax = {
            paymentMethod: "paystack",
            transRef: reference.reference,
            deliveryInfo: deliveryAd,
            listPrice: listPrice,
            sellerPrice,
            discountAmount,
            processingFee,
            taxAmount,
            totalAmount: depositAmount
        }

        try {
            const { data } = await axiosInstance.post(`/sale-items/${itemId}/purchase`, datax)
            console.log(data)
            if(delivery === "delivery") {
               toast({
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
              // setModal()
              navigate(`/wehaul/${itemId}`)
            } else {
            navigate("/success", {state: {text: "Payment successful!", para: "You have successfully paid for this item.", link: "/orders"}})
            }
          onClosex()
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
              onClosex()
        }
      };
    
      const onClose = () => {
        toast({
            title: "An Error Occurred",
            description: "Transaction not completed",
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
      };

      
    
      const componentProps = {
        ...config,
        text: `Pay ₦${depositAmount?.toLocaleString()}`,
        className: "button",
        onSuccess: (reference) => onSuccess(reference),
        onClose: onClose,
        onClick: () => console.log("hello")
      };

      const checkAvailability = () => {
        // if(isAvailable === true) {
        //     toast({
        //         description: "Oops!, Item is no longer available",
        //         status: "error",
        //         position: "top-right",
        //         duration: 5000,
        //         isClosable: true,
        //       });
        // } else {
           initializePayment(onSuccess, onClose)
        // }
    }
    return (
            <Box width={"90%"} onClick={() => closePrev()}>
                {delivery && <PaystackButton {...componentProps} />}
                {/* {delivery && 
                   <button onClick={() => {
                    initializePayment(onSuccess, onClose)
                }}>{`Pay ₦${depositAmount?.toLocaleString()}`}</button>
                } */}

                <Modal isCentered isOpen={isOpen} onClose={onClosex}>
                    <ModalOverlay />
                    <ModalContent margin={"0 .5rem"}>

                    <ModalBody>
                        <Flex marginTop={"7rem"} justifyContent={"center"}>
                            <Spinner size={"lg"} color="#E66F15" />
                        </Flex>
                        <Text textAlign={"center"} mt={5} marginBottom={"7rem"} fontWeight="500">Finalizing payment.. Hang Tight</Text>
                    </ModalBody>
                    </ModalContent>
                </Modal>
            </Box>
    )
}

export default PayStack;