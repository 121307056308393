import { Box, Image, useToast } from "@chakra-ui/react";
import { useState } from "react";
import axiosInstance from "../../services/api";
import TrueHeart from './heart.svg';
import FalseHeart from './hearticon.svg';

const SaveAction2 = (id, from) => {
    const [action, setAction] = useState("like");

    const toast = useToast()

    const saveItem = async () => {
        // console.log(id.id)
        try {
            const {data} = await axiosInstance.post(`/sale-items/${id.id}/saved-items`)
            // console.log(data)
            setAction("like")
            toast({
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } catch(error) {
            console.log(error?.response?.data?.message)
        }
    }

    const deleteSaveItem = async () => {
        // console.log(id?.id?._id)
        console.log(id?.id)

        const reqId = from === "main" ? id?.id?._id : id?.id


        try {
            const {data} = await axiosInstance.delete(`/saved-items/${reqId}`)
            // console.log(data)
            setAction("")
            toast({
                description: "Item removed successfully",
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        }
    }

    return (
        <Box onClick={deleteSaveItem} position={"absolute"} right="5%" top="5%">
            {action === "like" ?
                <Image src={TrueHeart} />
            :
                <Image src={FalseHeart} />
            }
        </Box>
    )
}

export default SaveAction2;