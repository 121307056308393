import { Badge, Box, Button, Flex, Image, Skeleton, Stack, Text } from "@chakra-ui/react";

import One from '../../assets/trending/one.svg';
import Two from '../../assets/trending/two.svg';
import Three from '../../assets/trending/three.svg';
import Four from '../../assets/trending/four.svg';
import Five from '../../assets/trending/five.svg';
import Six from '../../assets/trending/six.svg';
import Seven from '../../assets/trending/seven.svg';
import Eight from '../../assets/trending/eight.svg';
import Nine from '../../assets/trending/nine.svg';
import Ten from '../../assets/trending/ten.svg';

import Location from '../../assets/location.svg';
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import Navbar from "../../components/navbar/navbar";
import axiosInstance from "../../services/api";
import { useNavigate } from "react-router-dom";
import SaveAction2 from "../../components/saveAction/saveAction2";
import SaveAction from "../../components/saveAction/saveAction";
import Header from "../../components/header/header";


const ClearanceSales = () => {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0,0)
    })

    const navigate = useNavigate()

    useEffect(() => {
        // axiosInstance.get("sale-items")
        axiosInstance.get("/sale-items-clearance-groups")
        // .then(res => console.log("vvv", res?.data?.data))
        .then(res => setItems(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, [loading]);

    return (
        <>
        <Header />
        <Box  px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={[".8rem", ".9rem", "1rem", "1rem"]}>
            <Text mb={6} mt={4} color="#1A1A1A" fontWeight={"700"} fontFamily="Clash Display" fontSize={"1.2rem"}>Clearance Sales</Text>

            <Flex wrap={"wrap"} gap="1rem" rowGap={"3rem"}>
                {loading ? 
                 <>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton width="100%" height={"230"} borderRadius="12px" />
                     <Skeleton width="100%" height={"25px"} />
                     <Skeleton width="100%" height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton width="100%" height={"230"} borderRadius="12px" />
                     <Skeleton width="100%" height={"25px"} />
                     <Skeleton width="100%" height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 </>
                :
                items?.map(({imageURL, name, items, _id}) => {
                    return (
                        <Box onClick={() => navigate(`/clearance-sales/${_id}`, {state: "paid"})} cursor={"pointer"} overflow={"hidden"} minWidth={["150px", "250px" ]} flex={["1", "1", "0", "1"]} key={_id}>
                            <Box position={"relative"} overflow={"hidden"} borderRadius="12px" height="230px" width={"100%"} backgroundImage={imageURL} backgroundSize="cover">
                                {/* {savedItems.map(i => i?.item?._id).includes(_id) ? <SaveAction2 id={idx[0]} from="main" /> : <SaveAction id={_id} />} */}
                            </Box>
                            <Box padding={"0 .3rem"}>
                                {/* <Text mt={1} fontWeight="600" fontSize={".9rem"}>{createdAt?.slice(0,10)}</Text> */}
                                <Flex mt={1} alignItems={"center"} justifyContent={"space-between"}>
                                    <Text textTransform={"capitalize"} fontWeight="600" fontSize={".9rem"}>{name}</Text>
                                    <Badge fontSize={".65rem"} colorScheme="green">{items?.length} items</Badge>
                                </Flex>
                               
                            </Box>
                        </Box>
                    )
                })}
            </Flex> 

            <Flex marginBottom={"4rem"} justifyContent={"center"}>
                <Flex margin={"3rem auto 0 auto"} alignItems={"center"} gap=".6rem">
                    <Text cursor="pointer" color={"#1A1A1A"} padding=".1rem .7rem" borderRadius={"50%"} border="1px solid #D1FB00">1</Text>
                    <Text cursor="pointer" color={"#1A1A1A"} padding=".1rem .6rem" borderRadius={"50%"} border="1px solid #D1FB00">2</Text>
                    <Text cursor="pointer" color={"#1A1A1A"} padding=".1rem .6rem" borderRadius={"50%"} border="1px solid #D1FB00">3</Text>
                    <ArrowForwardIcon cursor="pointer" color={"#D1FB00"} />
                </Flex>
            </Flex>
        </Box>
        <br /><br /><br /><br /><br />
        <br /><br /><br /><br /><br />
        <br /><br /><br /><br /><br />
        <br /><br /><br /><br /><br />
        </>
    )
}

export default ClearanceSales;