import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../../components/header/header";
import Navbar from "../../../components/navbar/navbar";
import PrivacyPolicy from "./privacyPolicy";
import Terms from "./termsOfService";

const Help = () => {
    const [page, setPage] = useState("one");
    return (
        <Box>
            <Header />
            <Box marginBottom={"20rem"} borderTop={"1px solid #EAEAEA"} px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={"2rem"}>
                <Flex alignItems={"center"}>
                    <Link style={{display: "flex", flex: "1"}} to="/settings"><Text cursor={"pointer"} flex={"1"} fontWeight={"600"} color={"#1A1A1A"}><ArrowBackIcon transform={"translate(-4px,-1px)"} />Back</Text></Link>
                    <Text flex={"4"} color="#1A1A1A" fontWeight={"700"} fontSize="1.4rem" fontFamily={"Clash Display"}>Help</Text>
                </Flex>
                <Flex display={["flex", "none"]} gap="2rem" mt={10} color="#666666" flexWrap={"wrap"} justifyContent="center">
                    <Text onClick={() => setPage("one")} cursor="pointer" color={page === "one" ? "#1A1A1A" : null} fontWeight={page === "one" ? "700" : null} transition="250ms ease">Privacy Policy</Text>
                    <Text onClick={() => setPage("two")} cursor="pointer" color={page === "two" ? "#1A1A1A" : null} fontWeight={page === "two" ? "700" : null} transition="250ms ease">Terms of Service</Text>
                </Flex>
                <Flex marginTop="4rem">
                    <Text display={["none", "block"]} flex={"1"}></Text>
                    <Flex flex={"4"} gap="10rem">
                        <Box display={["none", "block"]} color="#666666">
                            <Text onClick={() => setPage("one")} cursor="pointer" mb={10} color={page === "one" ? "#1A1A1A" : null} fontWeight={page === "one" ? "500" : null} transition="250ms ease">Privacy Policy</Text>
                            <Text onClick={() => setPage("two")} cursor="pointer" mb={10} color={page === "two" ? "#1A1A1A" : null} fontWeight={page === "two" ? "500" : null} transition="250ms ease">Terms of Service</Text>
                        </Box>
                        <Box maxWidth={"650px"} padding={"0 .5rem"}>
                            {page === "one" && <PrivacyPolicy />}
                            {page === "two" && <Terms />}
                            
                        </Box>
                    </Flex>
                </Flex>
            </Box>
        </Box>
    )
}

export default Help;