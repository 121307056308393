import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Box, Button, Checkbox, FormControl, FormLabel, Input, Text, Spinner, Flex, Switch, InputGroup, InputRightElement, useToast } from "@chakra-ui/react"
import { useState } from "react"
import axiosInstance from "../../../services/api";


const Privacy = () => {
    const [currPassword, setCurrPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const toast = useToast()

    function Resolver() {
        if(currPassword && newPassword) {
            return <Button type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={8} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Save Changes" }</Button>
        } else {
            return <Button disabled type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={8} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Save Changes" }</Button>
        }
    }

    const datax = {
        currentPassword: currPassword,
        newPassword: newPassword
    }

    const changePassword = async (e) => {
        e.preventDefault()
        setLoading(true);
            try {
                const {data} = await axiosInstance.patch("auth/update-password", datax)
                toast({
                    // title: "",
                    description: data?.message,
                    status: "success",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                });
            } catch(error) {
                toast({
                    title: "An Error Occurred",
                    description: error?.response?.data?.message,
                    status: "error",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setLoading(false)
            }
    }

    return (
        <Box>
            <Flex mb={3} alignItems={"center"} justifyContent="space-between" color="#1A1A1A" fontWeight="600">
                <Text>Personalized Ads</Text>
                <Switch colorScheme={"orange"} />
            </Flex>
            <Text mb={6}>Allow Declutters to personalize my ads based on my data</Text>
            <Text mb={2} color="#1A1A1A" fontWeight="600">Change Password</Text>
            <Text color="#1A1A1A">Enter a new password</Text>
            <br />
            <form onSubmit={changePassword}>
                <FormControl mb={5}>
                    <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">Current Password</FormLabel>
                    <InputGroup>
                        <Input onChange={e => setCurrPassword(e.target.value)} type={showPassword ? "text" : "password"} padding={"1.3rem 1rem"} placeholder="Enter a password" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" />
                        <InputRightElement children={showPassword ? <ViewIcon cursor={"pointer"} onClick={() => setShowPassword(!showPassword)} /> : <ViewOffIcon cursor={"pointer"} onClick={() => setShowPassword(!showPassword)} />} />
                    </InputGroup>
                </FormControl>
                <FormControl>
                    <FormLabel transform={"translate(7px,3px)"} color="#030303" fontWeight="600" fontFamily='Creato Display' fontSize=".85rem">New Password</FormLabel>
                    <InputGroup>
                        <Input onChange={e => setNewPassword(e.target.value)} type={showPassword ? "text" : "password"} padding={"1.3rem 1rem"} placeholder="Enter a password" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" />
                        <InputRightElement children={showPassword ? <ViewIcon cursor={"pointer"} onClick={() => setShowPassword(!showPassword)} /> : <ViewOffIcon cursor={"pointer"} onClick={() => setShowPassword(!showPassword)} />} />
                    </InputGroup>
                </FormControl>
                <Resolver />
            </form>
        </Box>
    )
}

export default Privacy;