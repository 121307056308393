import { ArrowBackIcon, ChevronDownIcon, ChevronRightIcon, SearchIcon } from "@chakra-ui/icons"
import { Box, Button, Checkbox, Divider, Flex, FormControl, Image, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Progress, ProgressLabel, Radio, RadioGroup, Spinner, Stack, Text, Textarea, useDisclosure } from "@chakra-ui/react"
import statesLgaJson from "../../../utils/stateLGAs"
import { Fragment, useEffect, useState } from "react";
import axiosInstance from "../../../services/api";
import AutoComplete from "../../../components/googlePlacesAutocomplete";
import Upload from '../assets/media.svg';
import axios from "axios";
import Illustration from './illustration.svg';

const datay = [
    {
        name: "Brand New",
    },
    {
        name: "Almost New",
    },
    {
        name: "Fairly Used",
    },
    {
        name: "Used",
    }
]


const EditListing = ({item, setUpdateTrigger}) => {
    const [category, setCategory] = useState(item?.category);
    const [subcategory, setSubCategory] = useState(item?.subcategory);
    const [name, setName] = useState(item?.name);
    const [desc, setDesc] = useState(item?.description);
    const [condition, setCondition] = useState(item?.condition);
    const [location, setLocation] = useState(item?.pickUpLocation?.landmarkArea + ", " + item?.pickUpLocation?.state);
    const [defect, setDefect] = useState(item?.defectDesc)
    const [address, setAddress] = useState(item?.pickUpAddress);
    const [price, setPrice] = useState(item?.sellerPrice);
    const [negotiable, setNegotiable] = useState(item?.isNegotiable);
    const [media, setMedia] = useState(item?.imageURLs);
    const [tags, setTags] = useState(item?.tags);

    const [loading, setLoading] = useState(false);
    const [loadingf, setLoadingf] = useState(false);
    const [loadingc, setLoadingc] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
    const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure();
    const { isOpen: isOpen4, onOpen: onOpen4, onClose: onClose4 } = useDisclosure();
    const { isOpen: isOpen5, onOpen: onOpen5, onClose: onClose5 } = useDisclosure();
    const { isOpen: isOpen6, onOpen: onOpen6, onClose: onClose6 } = useDisclosure();

    const [view, setView] = useState("category");
    const [stateView, setStateView] = useState(true);
    const [data, setData] = useState(statesLgaJson);

    const [categories, setCategories] = useState([]);

    const [tempCat, setTempCat] = useState("")
    const [tempCats, setTempCats] = useState("")
    const [tempLoc, setTempLoc] = useState("")

    const [selected, setSelected] = useState("");
    const [selectedx, setSelectedx] = useState("");

    const [selectedImages, setSelectedImages] = useState(item?.imageURLs);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [chunkArray, setChunkArray] = useState([]);
    const [progressValue, setProgressValue] = useState(0);
    const [showUpload, setShowUpload] = useState(false);

    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);


    useEffect(() => {
        axiosInstance.get("/item-categories")
        // .then(res => console.log("xxxxx", res?.data?.data))
        .then(res => setCategories(res?.data?.data))
        .then(res => setLoadingc(false))
        .catch(error => console.log(error))
    }, [])

    const handleSelect = (obj) => {
        setCategory(JSON.parse(obj)?.name)
        setTempCat(JSON.parse(obj))
        setView("sub-category")
        // onClose()
    }
    const handleSelect2 = (obj) => {
        setSubCategory(JSON.parse(obj)?.name)
        setTempCats(JSON.parse(obj))
        onClose2()
    }

    const handleStateSelect = (name, alias) => {
        setSelected(name)
        setTempLoc(alias)
        setStateView(false)
    }

    const handleStateSelectx = (name) => {
        setSelectedx(name)
        setLocation(name + ", " + selected)
        onClose3()
    }

    const handleSelectC = (name) => {
        setCondition(name)
        onClose6()
    }

    const addTag = (item) => {
        if(!tags.includes(item)) {
            tags.push(item)
        } else {
            // const newArr = tagsx.filter(i => i !== item)
            setTags(tags.filter(i => i !== item))
        }
        console.log(tags)
    }

    const handleSelectImages = e => {
        if(e.target.files) {
          const chunk = Array.from(e.target.files).map(file => file)
          setChunkArray(chunk)
          const fileArray = Array.from(e.target.files).map(file => URL.createObjectURL(file))
        //   console.log(fileArray)
  
          setSelectedImages(prevImages => prevImages.concat(fileArray))
          Array.from(e.target.files).map(
            (file) => URL.revokeObjectURL(file)
          )
        }
        setShowUpload(true)
        console.log(chunkArray)
    }

    const renderPhotos = source => {
        return source?.map((photo, index) => {
          return <Box key={photo} height="93px" borderRadius={"12px"} flex="1" backgroundImage={photo} backgroundSize="cover"></Box>
        //     <Text>{`loading${index + 1}` == true ? "done" : null}</Text>
        //   </>
        })
    }


    const datax = {
        name: name,
        category: category,
        subcategory: subcategory,
        minDeliveryPrice: tempCats?.minDeliveryPrice || item?.minDeliveryPrice,
        maxDeliveryPrice: tempCats?.maxDeliveryPrice || item?.maxDeliveryPrice,
        description: desc,
        condition: condition,
        defectDesc: defect,
        pickUpAddress: address,
        pickUpLocation: {
            state: location.split(" ")[1], 
            landmarkArea: location.split(" ")[0]
        },
        sellerPrice: typeof(price) === "number" ? price : parseInt(price),
        isNegotiable: negotiable,
        tags: tags,
        imageURLs: media,
        "videoURL": "https://wallpaperaccess.com/full/676550.jpg"
    }


    const uploadImages = async () => {
        

        const formData = new FormData()
        formData.append("file", chunkArray[0])
        formData.append("upload_preset", "jmrkiyu0")
        formData.append("cloud_name", "declutters")
        

        if(showUpload) {
        setProgressValue(1)
        setLoading(true)
        try {
            const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
            // console.log(data)
            media.push(data.url)
            setProgressValue(25)
            console.log(datax)
            onOpen4()
            setLoading1(true)

            try {
                const formData = new FormData()
                formData.append("file", chunkArray[1])
                formData.append("upload_preset", "jmrkiyu0")
                formData.append("cloud_name", "declutters")

                const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
                // console.log(data)
                media.push(data.url)
                setProgressValue(50)
                console.log(datax)
                onOpen4()
                setLoading2(true)

                if(chunkArray.length === 2) {
               
                try {
                    const formData = new FormData()
                    formData.append("file", chunkArray[2])
                    formData.append("upload_preset", "jmrkiyu0")
                    formData.append("cloud_name", "declutters")
    
                    const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
                    // console.log(data)
                    media.push(data.url)
                    setProgressValue(75)
                    console.log(datax)
                    onOpen4()
                    setLoading3(true)

                    if(chunkArray.length === 3) {

                    try {
                        const formData = new FormData()
                        formData.append("file", chunkArray[3])
                        formData.append("upload_preset", "jmrkiyu0")
                        formData.append("cloud_name", "declutters")
        
                        const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
                        // console.log(data)
                        media.push(data.url)
                        setProgressValue(100)
                        console.log(datax)
                        // console.log("final", uploadedImages)
                        onOpen4()
                        setLoading4(true)
            
                        
                    } catch(error) {
                        console.log(error?.response?.data?.message)
                        setLoading4(false)
                    }
                }
                    
                } catch(error) {
                    console.log(error?.response?.data?.message)
                    setLoading3(false)
                }
            }
                
            } catch(error) {
                console.log(error?.response?.data?.message)
                setLoading2(false)
            }
        } catch(error) {
            console.log(error?.response?.data?.message)
            setLoading1(false)
        } finally {
            setLoading(false)
        }
     } else {
        onOpen4()
     }
    }

    

    const editListing = async() => {
        // console.log(datax)
        setLoadingf(true)

        try {
            const {data} = await axiosInstance.patch(`/sale-items/${item?._id}`, datax)
            console.log(data)
            onOpen5()
        } catch (error) {
            console.log(error?.response?.data?.message)
        } finally {
            setLoadingf(false)
        }
    }

    const handleNewList = () => {
        window.location.href = "/list-item"
    }

    const handleFinish = () => {
        setUpdateTrigger(Math.random())
        onClose5()
        onClose4()
        onClose()
    }

  return (
    <Box>
        <Button mb={3} onClick={onOpen} padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #E66F15" bg="#FFF" mt={2} width={"100%"} color="#E66F15" fontSize=".9rem" fontWeight={"700"}>Edit Listing</Button>

        <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Edit Listing</ModalHeader>
                <Text bg="#F6FECC" padding={".8rem"} borderRadius="6px" mb=".7rem" fontSize={".9rem"}>Please note that editing a listed item reverts it back to pending to allow declutters check that all listing criterias are met. We’ll review and approve if all checks out!</Text>
                <ModalBody maxH={"470px"} overflowY={"scroll"}>
                    {/* <Text fontSize={["1.05rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>Which category would you like to list this item under?</Text> */}
                    <Text fontWeight={"600"} fontSize={".9rem"} color="#667085">Select the category that best describes it.</Text>

                    <Flex onClick={onOpen2} cursor={"pointer"} mt={1} alignItems={"center"} justifyContent="space-between" padding={".7rem 1rem"} borderRadius="24px" border="1px solid #B0B4B0">
                        <Text color={category ? "#1A1A1A" : "#B0B4B0"} fontSize={".9rem"}>{category ? category + " " + `(${subcategory})` : "Select a category"}</Text>
                        <ChevronDownIcon color="#B0B4B0" fontSize={"1.2rem"} />
                    </Flex>
                    <br />
                    {/* <Text fontSize={["1.05rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>What is the name of the item you’re listing?</Text> */}
                    <Text fontWeight={"600"} fontSize={".9rem"} color="#667085">Name it in a way that makes it easy to find.</Text>

                    <FormControl mt={1}>
                        <Input value={name} textTransform={"capitalize"} onChange={e => setName(e.target.value)} type="text" _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"18px"} padding={"1.3rem 1rem"} placeholder="Enter item name" _placeholder={{color: "#8C8C8C"}} fontSize=".9rem" />
                    </FormControl>
                    <br />
                    {/* <Text fontSize={["1.05rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>How would you describe this item?</Text> */}
                    <Text fontWeight={"600"} fontSize={".9rem"} color="#667085">Provide your buyer with information on the features and appearance of this item.</Text>

                    <FormControl mt={1}>
                        <Textarea value={desc} onChange={e => setDesc(e.target.value)} type="text" _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"8px"} rows="6" padding={"1rem"} placeholder="Enter full description" _placeholder={{color: "#8C8C8C"}} fontSize=".9rem" />
                    </FormControl>
                    <br />
                    {/* <Text fontSize={["1.05rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>What is the condition of this item?</Text> */}
                    <Text fontWeight={"600"} fontSize={".9rem"} color="#667085">What is the length of time this item has been used for?</Text>

                    <Flex onClick={onOpen6} cursor={"pointer"} mt={1} alignItems={"center"} justifyContent="space-between" padding={".7rem 1rem"} borderRadius="20px" border="1px solid #B0B4B0">
                        <Text color={condition ? "#1A1A1A" : "#B0B4B0"} fontSize={".9rem"}>{condition ? condition : "Select a condition"}</Text>
                        <ChevronDownIcon color="#B0B4B0" fontSize={"1.2rem"} />
                    </Flex>
                    <br />

                    {/* <Text fontSize={["1.05rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>Does this item have any defects?</Text> */}
                    <Text fontWeight={"600"} fontSize={".9rem"} color="#667085">Is there anything wrong with this item that the buyer should know? (Optional)</Text>
                    <FormControl mt={1}>
                        <Textarea value={defect} onChange={e => setDefect(e.target.value)} placeholder="Describe defect" borderRadius="16px" border="1px solid #B0B4B0" _placeholder={{color: "#999", fontSize: ".9rem"}} />
                    </FormControl>

                    <br />
                    {/* <Text fontSize={["1.05rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>Select a pick up location</Text> */}
                    <Text fontWeight={"600"} color="#667085" fontSize={".9rem"}>Select the location the item is being sold from</Text>

                    <Flex onClick={onOpen3} cursor={"pointer"} mt={1} alignItems={"center"} justifyContent="space-between" padding={".7rem 1rem"} borderRadius="20px" border="1px solid #B0B4B0">
                        <Text color={location ? "#1A1A1A" : "#B0B4B0"} fontSize={".9rem"}>{location ? location : "Select location"}</Text>
                        <ChevronDownIcon color="#B0B4B0" fontSize={"1.2rem"} />
                    </Flex>
                    <br />
                    {/* <Text fontSize={["1.05rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>Enter your full pick up address</Text> */}
                    <Text fontWeight={"600"} color="#667085" fontSize={".9rem"}>Please provide us with the exact address where we can pick up the item, or choose the location on the map.</Text>

                    <FormControl mt={1}>
                        <AutoComplete setAddress={setAddress} address={address} value={address} />
                    </FormControl>
                    <br />
                    {/* <Text fontSize={["1.1rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>Enter a price</Text> */}
                    <Text fontWeight={"600"} color="#667085" fontSize={".9rem"}>The full amount stated here will be credited to you after sale as a seller. The Declutters commission is paid by the buyer</Text>

                    <FormControl mt={1} mb={3}>
                        <InputGroup>
                            <Input value={price} onChange={e => setPrice(e.target.value)} type="tel" outline={"none"} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15", outline: "none"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"10px"} padding={"1.3rem 1rem"} placeholder="0" _placeholder={{color: "#8C8C8C"}} fontSize=".9rem" />
                            <InputRightElement children={<Text transform={"translateY(1px)"} color="#B3B3B3" fontSize={".9rem"} marginLeft="-3rem">Amount (₦)</Text>} />
                        </InputGroup>
                    </FormControl>
                    <Checkbox colorScheme={"orange"} defaultChecked={negotiable} onChange={e => setNegotiable(e.target.checked)} fontWeight={"500"} fontSize=".9rem" mb={2}>Negotiable</Checkbox><br />
                    {/* <Checkbox colorScheme={"orange"} defaultChecked={free} onChange={e => setFreex(e.target.checked)} fontWeight={"500"} fontSize=".9rem">Give away for free</Checkbox> */}
                    
                    {/* <Text fontSize={["1.1rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>Add Tags (Optional)</Text> */}
                    <Text mt={2} fontWeight={"600"} color="#667085" fontSize={".9rem"}>Add tags to allow your item be easily found by potential buyers.</Text>
                    <Flex mt={3} flexWrap="wrap" gap=".5rem">
                        {categories?.find(a => a.name === category)?.tags?.map((i, index) => {
                            return (
                                <Radio defaultChecked={tags?.includes(i)} size={"md"} onChange={() => addTag(i)} colorScheme="orange" fontSize={".8rem"} borderRadius=".7rem" border="1px solid #E66F15" key={index} value={i}>{i}</Radio>
                                // <Text fontSize={".9rem"} onClick={() => addTag(i)} cursor={"pointer"} bg={tagsx.includes(i) ? "#E66F15" : "#fff"} color={tagsx.includes(i) ? "#fff" : "#000"} padding={".5rem .7rem"} transition="250ms ease" borderRadius=".7rem" border="1px solid #E66F15" key={index}>{i}</Text>
                            )
                        })}
                    </Flex>
                    <br />
                    {/* <Text fontSize={["1.1rem", "1.25rem"]} fontWeight="500" fontFamily={"Clash Display"}>Add images</Text> */}
                    <Text mt={2} fontWeight={"600"} color="#667085" fontSize={".9rem"}>Upload a minimun of 2 and up to 4 high-quality images of the exact item you are listing. Must be the same item buyer will get.</Text>
                    <Flex mt={5} alignItems={"center"} gap="1rem">
                        <Box display={selectedImages?.length == 4 ? "none" : null} bg="#fff" overflow={"hidden"} position={"relative"} cursor={"pointer"} height="93px" borderRadius={"12px"} flex="1" border={"1px dashed #8C8C8C"} textAlign="center">
                            <Image src={Upload} margin="1.5rem auto 0 auto" />
                            <Text fontSize={".8rem"} fontFamily={"Clash Display"} color="#667085">Add image</Text>
                            <Input bg="red" width="100%" marginTop="-68%" cursor={"pointer"} opacity="0" marginLeft="-50%" height="100%" position={"absolute"} 
                            type="file" multiple maxLength={4} 
                            // accept="image/*" capture="environment"
                            onChange={handleSelectImages} />
                        </Box>
                        {renderPhotos(selectedImages)}
                        {selectedImages?.length === 0 &&
                        <>
                        <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                        <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                        <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                        </>}
                        {selectedImages?.length === 1 &&
                        <>
                        <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                        <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                        </>}
                        {selectedImages?.length === 2 &&
                        <>
                        <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                        </>}
                    </Flex>
                    {selectedImages?.length !== 0 && selectedImages?.length < 2 ? <Text mt="1rem" color="crimson">Please add a minimum of two(2) images.</Text> : null}
                    <br />
                    {loading &&
                    <Progress colorScheme={"orange"} hasStripe value={progressValue} transition="250ms ease" borderRadius="6px" isAnimated>
                        <ProgressLabel>Uploading Media ({progressValue}%)</ProgressLabel>
                    </Progress>}

                </ModalBody>

                <ModalFooter>
                    <Button mr={4} variant='ghost' onClick={onClose}>Cancel</Button>
                    <Button bg="#E66F15" color={"#fff"} mr={3} onClick={uploadImages}>
                    {loading ? <Spinner /> : "Edit Listing"}
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>



        <Modal isCentered isOpen={isOpen2} onClose={onClose2}>
            <ModalOverlay />
            <ModalContent maxHeight={"600px"} overflow="scroll" padding={".8rem"} margin=".5rem">
            <ModalHeader mb={6}>
            <Text cursor={"pointer"} onClick={view === "category" ? () => onClose2 : () => setView("category")} fontSize="1.1rem" fontFamily="Clash Display" fontWeight={"700"} color={"#1A1A1A"}><ArrowBackIcon transform={"translate(-6px,-1px)"} />Categories</Text>
            </ModalHeader>
            {view === "category" &&
            <ModalBody>
                {categories?.map((i, index) => {
                    return (
                        <Fragment key={i?._id}>
                        <Flex onClick={() => handleSelect(JSON.stringify(i))} position={"relative"} pb={4} alignItems={"center"} cursor={"pointer"}>
                            <Box mr={2} width={"34px"} height="34px" border="2px solid #F7CFB1" borderRadius={"50%"} backgroundImage={i?.imageURL} backgroundSize="cover"></Box>
                            <Box>
                                <Text fontFamily={"Clash Display"} fontWeight="500">{i?.name}</Text>
                                {/* <Flex maxW="260px" overflow={"hidden"} alignItems={"center"}>
                                    {tags.map((i, index) => ( */}
                                        <Text whiteSpace={"nowrap"} mt={1} maxWidth="270px" overflow={"hidden"} fontWeight="500" color="#8C8C8C">{i?.description}</Text>
                                    {/* ))}
                                </Flex> */}
                            </Box>
                            <Checkbox size={"lg"} colorScheme={"orange"} position="absolute" right="3%" top="8%" />
                        </Flex>
                        <Divider />
                        <br />
                        </Fragment>
                    )
                })}
            </ModalBody>}
            {view === "sub-category" &&
            <ModalBody>
                {tempCat?.subcategories?.map((i, index) => {
                    return (
                        <Fragment key={i?._id}>
                        <Flex onClick={() => handleSelect2(JSON.stringify(i))} position={"relative"} pb={4} alignItems={"center"} cursor={"pointer"}>
                            <Box mr={2} width={"34px"} height="34px" border="2px solid #F7CFB1" borderRadius={"50%"} backgroundImage={i?.imageURL} backgroundSize="cover"></Box>
                            <Box>
                                <Text fontFamily={"Clash Display"} fontWeight="500">{i?.name}</Text>
                                {/* <Flex maxW="260px" overflow={"hidden"} alignItems={"center"}>
                                    {tags.map((i, index) => ( */}
                                        <Text whiteSpace={"nowrap"} mt={1} maxWidth="270px" overflow={"hidden"} fontWeight="500" color="#8C8C8C">{i?.description}</Text>
                                    {/* ))}
                                </Flex> */}
                            </Box>
                            <Checkbox size={"lg"} colorScheme={"orange"} position="absolute" right="3%" top="8%" />
                        </Flex>
                        <Divider />
                        <br />
                        </Fragment>
                    )
                })}
            </ModalBody>}
            </ModalContent>
        </Modal>

        <Modal isCentered isOpen={isOpen3} onClose={onClose3}>
            <ModalOverlay />
            <ModalContent maxHeight={"600px"} overflowY="scroll" padding={".8rem"}>
            <ModalHeader mb={6}>
            <Text cursor={"pointer"} onClick={() => setStateView(true)} fontSize="1.1rem" fontFamily="Clash Display" fontWeight={"700"} color={"#1A1A1A"}><ArrowBackIcon transform={"translate(-6px,-1px)"} />{stateView ? "State" : "Local Government"}</Text>
            </ModalHeader>
            <ModalBody>
                {stateView ?
                <>
                    {data?.sort((a, b) => a.state?.localeCompare(b.state))?.map(({id, state, alias}, index) => {
                        return (
                            <Box key={index}>
                            <Flex onClick={() => handleStateSelect(state, alias)} position={"relative"} pb={4} justifyContent="space-between" alignItems={"center"} cursor={"pointer"}>
                                <Text fontFamily={"Clash Display"} fontWeight="500">{state}</Text>
                                <ChevronRightIcon fontSize={"1.3rem"} color="#D1FB00" />
                                {/* <Checkbox size={"lg"} colorScheme={"orange"} position="absolute" right="3%" top="8%" /> */}
                            </Flex>
                            <Divider />
                            <br />
                            </Box>
                        )
                    })}
                </>
                :
                <>
                    {data?.find(i => i.state === selected)?.lgas?.map((i, index) => {
                        return (
                            <Box key={index}>
                            <Flex onClick={() => handleStateSelectx(i)} position={"relative"} pb={4} justifyContent="space-between" alignItems={"center"} cursor={"pointer"}>
                                <Text fontFamily={"Clash Display"} fontWeight="500">{i}</Text>
                                {/* <ChevronRightIcon fontSize={"1.2rem"} color="#D1FB00" /> */}
                                <Checkbox size={"lg"} colorScheme={"orange"} position="absolute" right="3%" top="8%" />
                            </Flex>
                            <Divider />
                            <br />
                            </Box>
                        )
                    })}
                </>
                }
            </ModalBody>

            
            </ModalContent>
        </Modal>

        <Modal isCentered isOpen={isOpen6} onClose={onClose6}>
                <ModalOverlay />
                <ModalContent maxHeight={"600px"} overflow="scroll" padding={".8rem"}>
                <ModalHeader mb={6}>
                <Text cursor={"pointer"} onClick={onClose2} fontSize="1.1rem" fontFamily="Clash Display" fontWeight={"700"} color={"#1A1A1A"}><ArrowBackIcon transform={"translate(-6px,-1px)"} />Condition</Text>
                </ModalHeader>
                <ModalBody>
                    {datay?.map(({img, name, egs}, index) => {
                        return (
                            <Fragment key={index}>
                            <Flex onClick={() => handleSelectC(name)} position={"relative"} pb={4} alignItems={"center"} cursor={"pointer"}>
                                <Text fontFamily={"Clash Display"} fontWeight="500">{name}</Text>
                                <Checkbox size={"lg"} colorScheme={"orange"} position="absolute" right="3%" top="8%" />
                            </Flex>
                            <Divider />
                            <br />
                            </Fragment>
                        )
                    })}
                </ModalBody>

                
                </ModalContent>
            </Modal>

        <Modal isCentered size={"xxl"} isOpen={isOpen4} onClose={onClose4}>
                <ModalOverlay />
                <ModalContent mx={[".5rem", "15rem"]} overflow="scroll" padding={".8rem"}>
                <ModalCloseButton />
                <ModalHeader fontFamily="Clash Display">Edit Listing Preview</ModalHeader>
                <ModalBody lineHeight={"40px"}>

                    <Flex justifyContent={"space-between"} gap="1.5rem" flexWrap={"wrap"}>
                        <Box minWidth={"350px"} backgroundImage={media?.length ? media[0] : null} flex="1" height={["200px", "350px"]} backgroundSize={["contain", "cover"]} backgroundRepeat="no-repeat" borderRadius={"10px"}></Box>
                        <Box flex="1">
                            <Text fontWeight={"500"} color="#8C8C8C">Description</Text>
                            <Text fontWeight={"500"} color="#1A1A1A">{desc}</Text>
                            <Text fontWeight={"500"} color="#8C8C8C">Condition</Text>
                            <Text fontWeight={"500"} color="#1A1A1A">{condition}</Text>
                            <Text fontWeight={"500"} color="#8C8C8C">Location</Text>
                            <Text fontWeight={"500"} color="#1A1A1A">{location}</Text>
                            <Text fontWeight={"500"} color="#8C8C8C">Category</Text>
                            <Text fontWeight={"500"} color="#1A1A1A">{category}</Text>
                        </Box>
                    </Flex>
                    <Text mt={3} fontFamily={"Clash Display"} fontWeight="700" fontSize={"1.3rem"}>{name}</Text>
                    <Text mt={1} fontSize="1.1rem" fontWeight="600">₦{parseInt(price)?.toLocaleString()}</Text>

                    <Button mb={3} type="submit" onClick={editListing} fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={6} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loadingf ? <Spinner /> : "Update Listing"}</Button>
                    <br />
                </ModalBody>
                </ModalContent>
            </Modal>

            <Modal size={"xl"} isCentered isOpen={isOpen5} onClose={onClose5}>
                <ModalOverlay />
                <ModalContent margin={"0 .5rem"}>
                <ModalCloseButton onClick={() => onClose3()} />
                <ModalBody>
                    <Image width={"30%"} margin="0 auto" src={Illustration} />
                    <Text mt={3} fontFamily='Clash Display' color="#030303" fontWeight={"700"} fontSize="1.2rem" textAlign={"center"}>Item Updated successfully</Text>
                    <Text mt={2} color="#908888" fontFamily='Clash Display' textAlign={"center"}>Your listed item has been updated on declutters.  We’ll review and approve if all listing criterias are met.</Text>
                    <Flex mt={6} justifyContent={"center"} gap="2rem">
                    <Button onClick={handleNewList} fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #E66F15" bg="#fff" mt={2} width={400} color="#E66F15" fontSize=".9rem" fontWeight={"700"}>List new item</Button>
                    <Button onClick={handleFinish} fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width={400} color="#fff" fontSize=".9rem" fontWeight={"700"}>Finish</Button>
                    </Flex>
                    <br />
                </ModalBody>
                </ModalContent>
            </Modal>
    </Box>
  )
}

export default EditListing