import { Box, Divider, Flex, Image, Skeleton, Stack, Text } from "@chakra-ui/react"
import Header from "../../../components/header/header"
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../../../services/api";
import SaveAction2 from "../../../components/saveAction/saveAction2";
import SaveAction from "../../../components/saveAction/saveAction";
import Location from '../../../assets/location.svg'

const ClearanceSaleDetails = () => {
    const {id} = useParams()
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);

    const [savedItems, setSavedItems] = useState([]);
    const [loadingx, setLoadingx] = useState(true);

    const navigate = useNavigate()

    useEffect(() => {
        axiosInstance.get(`/sale-items-clearance-groups/${id}`)
        // .then(res => console.log("vvv", res?.data?.data))
        .then(res => setItem(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, [loading]);

    useEffect(() => {
        axiosInstance.get("/saved-items")
        // .then(res => console.log("vvv", res?.data?.data))
        .then(res => setSavedItems(res?.data?.data))
        .then(res => setLoadingx(false))
        .catch(error => console.log(error))
    }, []);

  return (
    <>
     <Header />
        <Box  px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={[".8rem", ".9rem", "1rem", "1rem"]}>
            {/* <Text mb={6} mt={4} color="#1A1A1A" fontWeight={"700"} fontFamily="Clash Display" fontSize={"1.2rem"}>Newly Listed Items</Text> */}
            
            {loading ?
                <>
            <Stack width="250px" mx="auto">
                <Skeleton height={"230"} borderRadius="12px" />
                <Skeleton height={"25px"} />
                <Skeleton height={"25px"} />
            </Stack>
            <br /><br />
            <Flex wrap={"wrap"} gap="1rem" rowGap={"3rem"}>
            <>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton width="100%" height={"230"} borderRadius="12px" />
                     <Skeleton width="100%" height={"25px"} />
                     <Skeleton width="100%" height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton width="100%" height={"230"} borderRadius="12px" />
                     <Skeleton width="100%" height={"25px"} />
                     <Skeleton width="100%" height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 <Stack minWidth="250px" flex="1">
                     <Skeleton height={"230"} borderRadius="12px" />
                     <Skeleton height={"25px"} />
                     <Skeleton height={"25px"} />
                 </Stack>
                 </>
                 </Flex>
                 </>
                 :
                 <>
                 <Box width={"280px"} mx="auto">
                    <Box height={"220px"} width={"100%"} borderRadius={"8px"} backgroundImage={item?.imageURL} backgroundSize={"cover"} backgroundPosition={"center"}></Box>
                    <Text textAlign={"center"} mb={6} mt={4} color="#1A1A1A" fontWeight={"700"} fontFamily="Clash Display" fontSize={"1.2rem"}>{item?.name}</Text>
                 </Box>
                 <Divider />
                 <br />
                 <Flex wrap={"wrap"} gap="1rem" rowGap={"3rem"}>
                 {item?.items?.map(({imageURLs, name, listPrice, pickUpLocation, _id, createdAt}) => {
                        const idx = savedItems.filter(i => i?.item?._id === _id)
                        return (
                            // <Link to={`item-details/${_id}`} style={{flex: "0"}}>
                            <Box onClick={() => navigate(`/item-details/${_id}`, {state: item?.items})} cursor={"pointer"} overflow={"hidden"} minWidth={["150px", "250px" ]} maxWidth={"300px"} flex="1" key={_id}>
                                <Box position={"relative"} overflow={"hidden"} borderRadius="12px" height="230px" width={"100%"} backgroundImage={imageURLs[0]} backgroundSize="cover">
                                    {savedItems.map(i => i?.item?._id).includes(_id) ? <SaveAction2 id={idx[0]} from="main" /> : <SaveAction id={_id} />}
                                </Box>
                                <Box padding={"0 .3rem"}>
                                    {/* <Text mt={1} fontWeight="600" fontSize={".9rem"}>{createdAt?.slice(0,10)}</Text> */}
                                    <Text textTransform={"capitalize"} mt={1} fontWeight="600" fontSize={".9rem"}>{name}</Text>
                                    <Flex mt={2} justifyContent={"space-between"}>
                                        <Flex alignItems={"center"}>
                                            <Image mr={1} transform={"translateY(1.5px)"} width={"20%"} src={Location} />
                                            <Text mt={1} color="#908888" fontSize={".9rem"}>{pickUpLocation?.landmarkArea}</Text>
                                        </Flex>
                                        <Text mt={1} fontSize=".9rem" fontWeight="600">₦{listPrice?.toLocaleString()}</Text>
                                    </Flex>
                                </Box>
                            </Box>
                            // </Link>
                        )
                    })}
                 </Flex>
                 </>
                 }
        </Box>
        <br /><br /><br /><br /><br />
        <br /><br /><br /><br /><br />
    </>
  )
}

export default ClearanceSaleDetails