import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, FormControl, FormLabel, HStack, Image, Input, Spinner, Text, Textarea, useToast } from "@chakra-ui/react"
import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/header/header";
import Navbar from "../../../components/navbar/navbar";
import axiosInstance from "../../../services/api";
import Upload from './upload.svg';


const CreateDispute = () => {
    const navigate = useNavigate();
    const { id, issue } = useParams();    
    const { state } = useLocation()

    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [attachment, setAttachment] = useState("");

    const toast = useToast();

    const createDispute = async(e) => {
        e.preventDefault()
        setLoading(true)

        try {
            const formData = new FormData()
            formData.append("file", attachment)
            formData.append("upload_preset", "jmrkiyu0")
            formData.append("cloud_name", "declutters")

            const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
            // console.log(data.url)

            const dataxy = {
                title: issue,
                description: desc,
                attachment: data?.url
            }

            try {
                const { data } = await axiosInstance.post(state === "approved" ? `/transactions/${id}/disputes/feedback` : `/transactions/${id}/disputes`, dataxy)
                console.log(data)
                navigate("/success", {state: {text: "Submitted!", para: "We have received your dispute and will get back to you as soon as possible", link: "/orders"}})
            } catch(error) {
                console.log(error?.response?.data?.message)
                toast({
                    title: "An Error Occurred",
                    description: error?.response?.data?.message,
                    status: "error",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setLoading(false)
            }

        } catch(error) {
            console.log(error?.response?.data?.message)
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        }

    }

    return (
        <Box>
            <Header />
            <Box px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={"2rem"}>
                <HStack alignItems={"center"} gap="2rem">
                    <Text onClick={() => navigate(-1)} cursor={"pointer"} fontWeight={"600"} color={"#1A1A1A"}><ArrowBackIcon transform={"translate(-4px,-1px)"} />Back</Text>
                    <Text mb={6} fontSize={"1.25rem"} fontWeight="700" fontFamily={"Clash Display"}>Dispute Order</Text>
                </HStack>
                <br />
                <HStack maxW={"700px"} alignItems={"center"} gap="5.3rem">
                    <Box display={["none", "flex"]}></Box>
                    {state === "approved" && <Text maxW={"510px"} bg="#F6FECC" padding={".8rem"} borderRadius="6px">Please note that there are <strong>NO GUARANTEED REFUNDS</strong> at this stage as the seller has been fully paid after pickup information. But, we will appeal to the seller to still resolve any issue raised in good faith and fairness.</Text>}
                </HStack>
                <HStack maxW={"700px"} marginTop="2.5rem" alignItems={"center"} gap="5.3rem">
                    <Box display={["none", "flex"]}></Box>
                    <Box>
                        <Text color={"#1A1A1A"} mb={3} fontSize={"1.1rem"} fontWeight="600">{issue}</Text>
                        <Text mt={3} color={"#2E1604"} maxW="500px" mb={6} fontSize={".9rem"}>Fill up the form with information supporting your issue. We will send instructions on how to return the incorrect item and receive the correct one.</Text>
                        <form onSubmit={createDispute}>
                            <FormControl>
                                <FormLabel fontSize={".85rem"}>Tell us more about the issue</FormLabel>
                                <Textarea required onChange={e => setDesc(e.target.value)} placeholder="Enter full description" rows="6" _placeholder={{color: "#B3B3B3", fontSize: ".8rem"}} />
                            </FormControl>
                            <br />
                            {attachment ? 
                            <Flex alignItems={"center"}>
                                <Image src={Upload} mr={3} />
                                <Text fontSize={".75rem"} color="#E63F07">{attachment?.name}</Text>
                            </Flex>
                            :
                            <FormControl>
                                <FormLabel fontSize={".85rem"}>Upload an image/video (Optional)</FormLabel>
                                <Text fontSize={".8rem"} color="#667085">Images/Videos help support your complaint and can also aid faster dispute resolution</Text>
                                <Flex position={"relative"} mt={5} cursor="pointer" padding={"1rem"} border="1px dashed #D1D1D1" borderRadius={"12px"} justifyContent="center">
                                    <Flex alignItems={"center"}>
                                        <Image src={Upload} mr={3} />
                                        <Box textAlign={"center"}>
                                            <Text fontSize={".75rem"} color="#E63F07">Click to upload</Text>
                                            <Text fontSize={".75rem"} color="#D1D1D1">PNG or JPG (max 5mb)</Text>
                                        </Box>
                                        <Input onChange={e => setAttachment(e.target.files[0])} opacity={"0"} cursor={"pointer"} position={"absolute"} height="100%" top="0%" bottom={"0%"} left="0%" right={"0%"} type="file" />
                                    </Flex>
                                </Flex>
                            </FormControl>}
                            <br />
                            <Button type="submit" mb={3} mt={2} padding={"1.1rem 1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"100%"} color="#fff" fontSize=".85rem" fontWeight={"700"}>{loading ? <Spinner /> : "Submit"}</Button>
                        </form>
                    </Box>
                </HStack>
            </Box>
        </Box>
    )
}

export default CreateDispute;