import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, FormControl, FormHelperText, FormLabel, Heading, Image, Input, InputGroup, InputRightElement, Spinner, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Google from '../../assets/google.svg';
import Instagram from '../../assets/instagram.svg';
import Navbar2 from "../../components/navbar2/navbar2";
import { userActionTypes } from "../../redux/constants/userActionTypes";
import axiosInstance from "../../services/api";
import { GoogleLogin } from '@react-oauth/google';

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const toast = useToast()

    const navigate = useNavigate();
    const dispatch = useDispatch()

    function Resolver() {
        if(email && password) {
            return <Button type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Log In" }</Button>
        } else {
            return <Button disabled type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Log In" }</Button>
        }
    }

    const datax = {
        email: email,
        password: password
    }

    const login = async(e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const {data} = await axiosInstance.post("auth/login", datax)
            console.log("test",data)
            localStorage.setItem("DCV1TK", data?.data?.token);
            dispatch({type: userActionTypes.SIGNIN_SUCCESS, payload: data?.data?.user})
            navigate("/")
            // toast({
            //     description: data?.message,
            //     status: "success",
            //     position: "top-right",
            //     duration: 5000,
            //     isClosable: true,
            //   });
        } catch(error) {
            console.log(error?.response?.data?.message)
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoading(false)
        }
    }


    const googleResponse = async (response) => {
        // console.log(response?.credential);
        const datax = {
          "idToken": response?.credential,
        }
        try {
          const {data} = await axiosInstance.post("auth/google", datax)
        //   console.log("test",data)
            localStorage.setItem("DCV1TK", data?.data?.token);
            dispatch({type: userActionTypes.SIGNIN_SUCCESS, payload: data?.data?.user})
            navigate("/")
        //   window.location.reload();
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
        //   console.log(error?.response?.data?.message)
        }
    }

    return (
        <Box>
            <Navbar2 />
            <Box maxWidth={"500px"} padding="3rem .8rem" margin="0 auto 20rem auto">
                <Heading mt={6} fontWeight={700} fontSize="1.4rem" color="#030303" fontFamily='Clash Display'>Log In</Heading>
                <Text mt={2} color={"#908888"} fontWeight="500" fontFamily='Creato Display'>Continue buying and selling household items easily.</Text>
                <br />
                <form onSubmit={login}>
                    <FormControl mt={3}>
                        <FormLabel transform={"translateY(3px)"} fontWeight="600" fontFamily='Creato Display' fontSize=".9rem">Email Address</FormLabel>
                        <Input type={"email"} onChange={e => setEmail(e.target.value)} padding={"1.3rem 1rem"} placeholder="Enter a valid email address" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" />
                    </FormControl>
                    <FormControl mt={3} mb={6}>
                        <FormLabel transform={"translateY(3px)"} fontWeight="600" fontFamily='Creato Display' fontSize=".9rem">Password</FormLabel>
                        <InputGroup>
                            <Input onChange={e => setPassword(e.target.value)} type={showPassword ? "text" : "password"} padding={"1.3rem 1rem"} placeholder="Enter a password" _placeholder={{color: "#B0B4B0"}} fontSize=".9rem" />
                            <InputRightElement children={showPassword ? <ViewIcon cursor={"pointer"} onClick={() => setShowPassword(!showPassword)} /> : <ViewOffIcon cursor={"pointer"} onClick={() => setShowPassword(!showPassword)} />} />
                        </InputGroup>
                        <Link to="/reset-password"><FormHelperText cursor={"pointer"} _hover={{color: "#E66F15"}} color="#030303">Forgot Password?</FormHelperText></Link>
                    </FormControl>

                    <Resolver />
                    
                    <Flex justifyContent={"center"} mt={6} mb={3}>
                        <Flex gap=".9rem" width={"60%"} alignItems="center">
                            <Box color={"#EFEDED"} borderTop="1px solid #EFEDED" width={"150px"}></Box>
                            <Text fontWeight={"500"}>or</Text>
                            <Box color={"#EFEDED"} borderTop="1px solid #EFEDED" width={"150px"}></Box>
                        </Flex>
                    </Flex>
                    <Flex justifyContent={"center"}>
                        <GoogleLogin
                            width="350px"
                            onSuccess={googleResponse}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                        />
                     </Flex>
                    {/* <Button mb={2} padding={"1.3rem 1rem"} border="1px solid #EFEDED" bg="#fff" mt={2} width="100%" color="#030303" fontSize=".9rem" fontWeight={"400"}><Image mr={2} src={Google} />Sign In with Google</Button> */}
                    {/* <Button padding={"1.3rem 1rem"} border="1px solid #EFEDED" bg="#fff" mt={2} width="100%" color="#030303" fontSize=".9rem" fontWeight={"400"}><Image mr={2} src={Instagram} />Sign In with Instagram</Button> */}
                    
                    <Link to="/signup"><Text mt={3} textAlign={"center"} fontFamily='Clash Display'>Not a registered user? <strong style={{cursor: "pointer"}}>Create an account</strong></Text></Link>
                </form>
            </Box>
        </Box>
    )
}

export default Login;

