import { ArrowBackIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Divider, Checkbox, FormControl, FormLabel, Input, InputGroup, InputRightElement, Image, Spinner, Progress, ProgressLabel } from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import Upload from '../assets/media.svg';

const Media = ({setLogger, setMedia, setPrice, setNegotiable, setFree, onOpen, setAnon}) => {
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const [chunkArray, setChunkArray] = useState([]);

    const [progressValue, setProgressValue] = useState(0)
    const [anonx, setAnonx] = useState(false);

    const [free, setFreex] = useState(false);

 

    function Resolver() {
        if(selectedImages.length >= 2) {
            return <Button type="submit" onClick={uploadImages} fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>{loading ? <Spinner /> : "Continue"}</Button>
        } else {
            return <Button disabled type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="16px" border="1px solid #EFEDED" bg="#E66F15" mt={2} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>Continue</Button>
        }
    }

    const handleSelectImages = e => {
        if(e.target.files) {
          const chunk = Array.from(e.target.files).map(file => file)
          setChunkArray(chunk)
          const fileArray = Array.from(e.target.files).map(file => URL.createObjectURL(file))
        //   console.log(fileArray)
  
          setSelectedImages(prevImages => prevImages.concat(fileArray))
          Array.from(e.target.files).map(
            (file) => URL.revokeObjectURL(file)
          )
        }
    }

    const renderPhotos = source => {
        return source.map((photo, index) => {
          return <Box key={photo} height="93px" borderRadius={"12px"} flex="1" backgroundImage={photo} backgroundSize="cover"></Box>
        //     <Text>{`loading${index + 1}` == true ? "done" : null}</Text>
        //   </>
        })
    }

    const handleContinue = () => {
        setFree(free)
        setMedia(selectedImages)
        setAnon(anonx)
        setLogger(true)
        onOpen()
    }

    const uploadImages = async () => {
        setFree(free)
        setAnon(anonx)
        setMedia(uploadedImages)
        setLogger(true)


        const formData = new FormData()
        formData.append("file", chunkArray[0])
        formData.append("upload_preset", "jmrkiyu0")
        formData.append("cloud_name", "declutters")
        

        if(!uploadedImages?.length) {
        setProgressValue(1)
        setLoading(true)
        try {
            const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
            // console.log(data)
            uploadedImages.push(data.url)
            setProgressValue(25)
            setLoading1(true)

            try {
                const formData = new FormData()
                formData.append("file", chunkArray[1])
                formData.append("upload_preset", "jmrkiyu0")
                formData.append("cloud_name", "declutters")

                const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
                // console.log(data)
                uploadedImages.push(data.url)
                setProgressValue(50)
                setLoading2(true)

                try {
                    const formData = new FormData()
                    formData.append("file", chunkArray[2])
                    formData.append("upload_preset", "jmrkiyu0")
                    formData.append("cloud_name", "declutters")
    
                    const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
                    // console.log(data)
                    uploadedImages.push(data.url)
                    setProgressValue(75)
                    setLoading3(true)

                    try {
                        const formData = new FormData()
                        formData.append("file", chunkArray[3])
                        formData.append("upload_preset", "jmrkiyu0")
                        formData.append("cloud_name", "declutters")
        
                        const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
                        // console.log(data)
                        uploadedImages.push(data.url)
                        setProgressValue(100)
                        // console.log("final", uploadedImages)
                        onOpen()
                        setLoading4(true)
            
                        
                    } catch(error) {
                        console.log(error?.response?.data?.message)
                        setLoading4(false)
                    }
        
                    
                } catch(error) {
                    console.log(error?.response?.data?.message)
                    setLoading3(false)
                }
    
                
            } catch(error) {
                console.log(error?.response?.data?.message)
                setLoading2(false)
            }
        } catch(error) {
            console.log(error?.response?.data?.message)
            setLoading1(false)
        } finally {
            setLoading(false)
        }
     } else {
        onOpen()
     }
    }


    return (
        <Box>
            <br /><br />
            <Text fontSize={"1.25rem"} fontWeight="500" fontFamily={"Clash Display"}>Visibility</Text>
            <Text mt={2} mb={3} fontWeight={"600"} color="#667085" fontSize={".9rem"}>Being anonymous protects your privacy and means giveaway winners won't see your phone number, name or pickup address.</Text>
            <Checkbox size={"lg"} colorScheme={"orange"} defaultChecked={anonx} onChange={e => setAnonx(e.target.checked)} fontWeight={"500"} fontSize=".9rem" mb={2}>{!anonx ? "Make Anonymous" : "Make Visible"}</Checkbox>
            <br />
            <br />
            <br />
            <Text fontSize={"1.25rem"} fontWeight="500" fontFamily={"Clash Display"}>Add images</Text>
            <Text mt={2} fontWeight={"600"} color="#667085" fontSize={".9rem"}>Upload a minimun of 2 and up to 4 high-quality images of the exact item you are listing. Must be the same item buyer will get.</Text>
            <Flex mt={5} alignItems={"center"} gap="1rem">
                <Box display={selectedImages.length == 4 ? "none" : null} bg="#fff" overflow={"hidden"} position={"relative"} cursor={"pointer"} height="93px" borderRadius={"12px"} flex="1" border={"1px dashed #8C8C8C"} textAlign="center">
                    <Image src={Upload} margin="1.5rem auto 0 auto" />
                    <Text fontSize={".8rem"} fontFamily={"Clash Display"} color="#667085">Add image</Text>
                    <Input bg="red" width="100%" marginTop="-68%" cursor={"pointer"} opacity="0" marginLeft="-50%" height="100%" position={"absolute"} 
                    type="file" multiple maxLength={4} 
                    // accept="image/*" capture="environment"
                    onChange={handleSelectImages} />
                </Box>
                {renderPhotos(selectedImages)}
                {selectedImages.length == 0 &&
                <>
                <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                </>}
                {selectedImages.length == 1 &&
                <>
                <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                </>}
                {selectedImages.length == 2 &&
                <>
                <Box height="93px" borderRadius={"12px"} flex="1" bg="#F6F6F6"></Box>
                </>}
            </Flex>
            {selectedImages?.length !== 0 && selectedImages?.length < 2 ? <Text mt="1rem" color="crimson">Please add a minimum of two(2) images.</Text> : null}
            <br />
            {loading &&
            <Progress colorScheme={"orange"} hasStripe value={progressValue} transition="250ms ease" borderRadius="6px" isAnimated>
                <ProgressLabel>Uploading Media ({progressValue}%)</ProgressLabel>
            </Progress>}
            <br />
            <Resolver />

            
        </Box>
    )
}

export default Media;