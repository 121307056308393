import { ArrowBackIcon } from "@chakra-ui/icons";
import { Badge, Box, Flex, Text, Spinner, TabPanel, Tabs, TabPanels, Tab, TabList } from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/header/header";
import Navbar from "../../components/navbar/navbar";
import axiosInstance from "../../services/api";


const Orders = () => {
    const [orders, setOrders] = useState();
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const currentUser = useSelector(({userData})=>  userData?.currentUser);

    useEffect(() => {
        axiosInstance.get("/transactions/user")
        // .then(res => console.log("vvvxx", res?.data?.data))
        .then(res => setOrders(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, [])

    let today = new Date();
    let mmx;
    let dd = today.getDate();
    let mm = today.getMonth();
    let xx = today.getFullYear()

    if(dd.length < 2) {
        dd = "0" + dd
    }
    
    today = dd;
    // if(mm === 0) {
    //     mm = 1
    // }
    if(mm === 1) {
        mm = 2
    }
    // if(mm === 2) {
    //     mm = 3
    // }
    // if(mm === 3) {
    //     mm = 4
    // }
    // if(mm === 4) {
    //     mm = 5
    // }
    // if(mm === 5) {
    //     mm = 6
    // }
    // if(mm === 6) {
    //     mm = 7
    // }
    mmx = (mm)

    console.log(today, mmx, mm, today + mm, xx + "-" + today + "-" + mm)

    return (
        <Box>
            <Header />
            <Box px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={"1rem"}>
                <Text color="#1A1A1A" marginLeft={"1rem"} fontWeight={"700"} fontSize="1.4rem" fontFamily={"Clash Display"}>Orders</Text>
                <Tabs>
                    <TabList maxW="800px" margin={"0 auto"} borderBottom={"none"} display={"flex"} justifyContent="space-around">
                        <Tab _selected={{borderBottom: "2px solid #E66F15", color: "#1A1A1A"}} fontWeight="500" fontSize={"1.1rem"} color="#666666">Buy</Tab>
                        <Tab _selected={{borderBottom: "2px solid #E66F15", color: "#1A1A1A"}} fontWeight="500" fontSize={"1.1rem"} color="#666666">Sell</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel padding={"1rem 0"}>
                            <Box maxW={"530px"} margin="4rem auto 3rem auto" borderTop="1px solid #F6F6F6">
                                <Text borderRadius={"20px"} bg={"#F6FECC"} color="#2E1604" margin={"-1.2rem auto 0 auto"} width={"fit-content"} fontWeight={"500"} fontSize=".85rem" padding={".5rem 1.4rem"}>Today</Text>
                            </Box>
                            <Box maxW={"700px"} margin="0 auto">
                                {orders?.filter(i => moment(i?.createdAt).calendar().toLowerCase().includes("today") && i.seller !== currentUser?._id).map(({_id, totalAmount, item, status, createdAt, isItemDelivered}) => {
                                    return (
                                        <Link to={`/orders/${_id}`} style={{flex: "1"}} key={_id}>
                                            <Flex justifyContent={"space-between"} cursor={"pointer"} mt={5} marginBottom="1rem" padding={".7rem .5rem"} borderRadius={"12px"} border="1px solid #EAEAEA" bg="#FFF" gap="1rem" alignItems={"center"}>
                                                <Flex gap=".8rem">
                                                    <Box height={"60px"} width="60px" backgroundSize={"cover"} backgroundImage={item?.imageURLs[0]} borderRadius="12px"></Box>
                                                    <Box>
                                                        <Text mb={2} fontWeight="600" color="#4D2507" fontSize={[".9rem",".95rem","1rem","1rem",]}>{item?.name}</Text>
                                                        <Text fontSize={".8rem"} color={"#8C8C8C"} width={"170px"}>{moment(createdAt).calendar()}</Text>
                                                    </Box>
                                                </Flex>
                                                <Flex justifyContent={"flex-end"} flexWrap={"wrap"} alignItems={"center"} gap={[".5rem", ".5rem", "1rem", "1rem"]}>
                                                    <Text textAlign={"left"} fontWeight={"600"}>₦{totalAmount?.toLocaleString()}</Text>
                                                    <Text whiteSpace={"nowrap"} borderRadius={"20px"} bg={status === "pending" ? "#EE9F63" : status === "cancelled" ? "#F04438" : status === "disputed" ? "purple" : "#12B76A"} color="#FFF" fontWeight={"600"} fontSize=".85rem" padding={".5rem .8rem"}>{status === "pending" ? "Pickup" : status === "cancelled" ? "Cancelled" : status === "disputed" ? "Under Dispute" : "Delivered"}</Text>
                                                </Flex>
                                            </Flex>
                                        </Link>
                                    )
                                })}
                            </Box>

                            <Box maxW={"530px"} margin="2rem auto 3rem auto" borderTop="1px solid #F6F6F6">
                                <Text borderRadius={"20px"} bg={"#F6FECC"} color="#2E1604" margin={"-1.2rem auto 0 auto"} width={"fit-content"} fontWeight={"500"} fontSize=".85rem" padding={".5rem 1.4rem"}>Older</Text>
                            </Box>

                            <Box maxW={"700px"} margin="0 auto">
                            {orders?.filter(i => !moment(i?.createdAt).calendar().toLowerCase().includes("today") && i.seller !== currentUser?._id).map(({_id, totalAmount, item, status, createdAt, isItemDelivered}) => {
                                    return (
                                        <Link to={`/orders/${_id}`} style={{flex: "1"}} key={_id}>
                                            <Flex justifyContent={"space-between"} cursor={"pointer"} mt={5} marginBottom="1rem" padding={".7rem .5rem"} borderRadius={"12px"} border="1px solid #EAEAEA" bg="#FFF" gap="1rem" alignItems={"center"}>
                                                <Flex gap=".8rem">
                                                    <Box height={"60px"} width="60px" backgroundSize={"cover"} backgroundImage={item?.imageURLs[0]} borderRadius="12px"></Box>
                                                    <Box>
                                                        <Text mb={2} fontWeight="600" color="#4D2507" fontSize={[".9rem",".95rem","1rem","1rem",]}>{item?.name}</Text>
                                                        <Text fontSize={".8rem"} color={"#8C8C8C"} width={"170px"}>{moment(createdAt).calendar()}</Text>
                                                    </Box>
                                                </Flex>
                                                <Flex justifyContent={"flex-end"} flexWrap={"wrap"} alignItems={"center"} gap={[".5rem", ".5rem", "1rem", "1rem"]}>
                                                    <Text textAlign={"left"} fontWeight={"600"}>₦{totalAmount?.toLocaleString()}</Text>
                                                    <Text whiteSpace={"nowrap"} borderRadius={"20px"} bg={status === "pending" ? "#EE9F63" : status === "cancelled" ? "#F04438" : status === "disputed" ? "purple" : "#12B76A"} color="#FFF" fontWeight={"600"} fontSize=".85rem" padding={".5rem .8rem"}>{status === "pending" ? "Pickup" : status === "cancelled" ? "Cancelled" : status === "disputed" ? "Under Dispute" : "Delivered"}</Text>
                                                </Flex>
                                            </Flex>
                                        </Link>
                                    )
                                })}
                            </Box>
                        </TabPanel>

                        <TabPanel padding={"1rem 0"}>
                            <Box maxW={"530px"} margin="4rem auto 3rem auto" borderTop="1px solid #F6F6F6">
                                <Text borderRadius={"20px"} bg={"#F6FECC"} color="#2E1604" margin={"-1.2rem auto 0 auto"} width={"fit-content"} fontWeight={"500"} fontSize=".85rem" padding={".5rem 1.4rem"}>Today</Text>
                            </Box>
                            <Box maxW={"700px"} margin="0 auto">
                                {orders?.filter(i => moment(i?.createdAt).calendar().toLowerCase().includes("today") && i.seller === currentUser?._id).map(({_id, totalAmount, item, status, createdAt, isItemDelivered}) => {
                                    return (
                                        <Link to={`/orders/${_id}`} style={{flex: "1"}} key={_id}>
                                            <Flex justifyContent={"space-between"} cursor={"pointer"} mt={5} marginBottom="1rem" padding={".7rem .5rem"} borderRadius={"12px"} border="1px solid #EAEAEA" bg="#FFF" gap="1rem" alignItems={"center"}>
                                                <Flex gap=".8rem">
                                                    <Box height={"60px"} width="60px" backgroundSize={"cover"} backgroundImage={item?.imageURLs[0]} borderRadius="12px"></Box>
                                                    <Box>
                                                        <Text mb={2} fontWeight="600" color="#4D2507" fontSize={[".9rem",".95rem","1rem","1rem",]}>{item?.name}</Text>
                                                        <Text fontSize={".8rem"} color={"#8C8C8C"} width={"170px"}>{moment(createdAt).calendar()}</Text>
                                                    </Box>
                                                </Flex>
                                                <Flex justifyContent={"flex-end"} flexWrap={"wrap"} alignItems={"center"} gap={[".5rem", ".5rem", "1rem", "1rem"]}>
                                                    <Text textAlign={"left"} fontWeight={"600"}>₦{totalAmount?.toLocaleString()}</Text>
                                                    <Text whiteSpace={"nowrap"} borderRadius={"20px"} bg={status === "pending" ? "#EE9F63" : status === "cancelled" ? "#F04438" : status === "disputed" ? "purple" : "#12B76A"} color="#FFF" fontWeight={"600"} fontSize=".85rem" padding={".5rem .8rem"}>{status === "pending" ? "Pickup" : status === "cancelled" ? "Cancelled" : status === "disputed" ? "Under Dispute" : "Delivered"}</Text>
                                                </Flex>
                                            </Flex>
                                        </Link>
                                    )
                                })}
                            </Box>

                            <Box maxW={"530px"} margin="2rem auto 3rem auto" borderTop="1px solid #F6F6F6">
                                <Text borderRadius={"20px"} bg={"#F6FECC"} color="#2E1604" margin={"-1.2rem auto 0 auto"} width={"fit-content"} fontWeight={"500"} fontSize=".85rem" padding={".5rem 1.4rem"}>Older</Text>
                            </Box>

                            <Box maxW={"700px"} margin="0 auto">
                            {orders?.filter(i => !moment(i?.createdAt).calendar().toLowerCase().includes("today") && i.seller === currentUser?._id).map(({_id, totalAmount, item, status, createdAt, isItemDelivered}) => {
                                    return (
                                        <Link to={`/orders/${_id}`} style={{flex: "1"}} key={_id}>
                                            <Flex justifyContent={"space-between"} cursor={"pointer"} mt={5} marginBottom="1rem" padding={".7rem .5rem"} borderRadius={"12px"} border="1px solid #EAEAEA" bg="#FFF" gap="1rem" alignItems={"center"}>
                                                <Flex gap=".8rem">
                                                    <Box height={"60px"} width="60px" backgroundSize={"cover"} backgroundImage={item?.imageURLs[0]} borderRadius="12px"></Box>
                                                    <Box>
                                                        <Text mb={2} fontWeight="600" color="#4D2507" fontSize={[".9rem",".95rem","1rem","1rem",]}>{item?.name}</Text>
                                                        <Text fontSize={".8rem"} color={"#8C8C8C"} width={"170px"}>{moment(createdAt).calendar()}</Text>
                                                    </Box>
                                                </Flex>
                                                <Flex justifyContent={"flex-end"} flexWrap={"wrap"} alignItems={"center"} gap={[".5rem", ".5rem", "1rem", "1rem"]}>
                                                    <Text textAlign={"left"} fontWeight={"600"}>₦{totalAmount?.toLocaleString()}</Text>
                                                    <Text whiteSpace={"nowrap"} borderRadius={"20px"} bg={status === "pending" ? "#EE9F63" : status === "cancelled" ? "#F04438" : status === "disputed" ? "purple" : "#12B76A"} color="#FFF" fontWeight={"600"} fontSize=".85rem" padding={".5rem .8rem"}>{status === "pending" ? "Pickup" : status === "cancelled" ? "Cancelled" : status === "disputed" ? "Under Dispute" : "Delivered"}</Text>
                                                </Flex>
                                            </Flex>
                                        </Link>
                                    )
                                })}
                            </Box>
                        </TabPanel>
                    </TabPanels>
                </Tabs>


                
            </Box>
        </Box>
    )
}

export default Orders;