import { Box, Button, Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Navbar from "../../components/navbar/navbar";
import Mainicon from './assets/mainicon.svg';
import ListingForm from "./listingForm";

const ListItem = () => {
    const [listings, setListings] = useState(["a"]);
    const [loading, setLoading] = useState(false);

    return (
        <Box marginBottom={"20rem"}>
            <Header />
            <Box borderTop={"1px solid #EAEAEA"}>
            <br />
            {loading ? 
            <Flex marginTop={"20rem"} justifyContent={"center"}>
                <Spinner size={"lg"} color="#E66F15" />
            </Flex>
            :
            listings.length ?
                <ListingForm />
                :
                <Box maxWidth={"550px"} margin="0 auto">
                <Box textAlign={"center"}>
                    <Image margin={"0 auto"} src={Mainicon} mb={2} />
                    <Text mb={2} fontFamily={"Clash Display"} fontWeight="700" color="#101828" fontSize={"1.3rem"}>Welcome to the Sellers section!</Text>
                    <Text fontWeight={"500"} margin="0 auto" maxW="500px" color="#666666">We’re glad to have you onboard. Here are some quick tips to get you up and running.</Text>
                </Box>
                <br />
                <Box padding={"2rem 3rem"}>
                    <Text mb={1} fontFamily={"Clash Display"} fontWeight="700" color="#101828" fontSize={"1.1rem"}>List an Item</Text>
                    <Text mb={2} fontWeight={"500"} color="#666666">List your products, add all the necessary information, and submit for approval!</Text>
                    <br />
                    <Text mb={1} fontFamily={"Clash Display"} fontWeight="700" color="#101828" fontSize={"1.1rem"}>Receive orders and price offers</Text>
                    <Text mb={2} fontWeight={"500"} color="#666666">Once your listed items goes live, you'll begin receiving orders and price offers. Each item sold will cost you a fixed commission.</Text>
                    <br />
                    <Text mb={1} fontFamily={"Clash Display"} fontWeight="700" color="#101828" fontSize={"1.1rem"}>Streamlined delivery process</Text>
                    <Text fontWeight={"500"} color="#666666">Relax while we handle your deliveries. Pick up & Delivery will handled by us.</Text>

                    <Button type="submit" fontFamily='Clash Display' padding={"1.3rem 1rem"} borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" mt={9} width="100%" color="#fff" fontSize=".9rem" fontWeight={"700"}>List your first item</Button>
                </Box>
            </Box>}
            </Box>
        </Box>
    )
}

export default ListItem