import { Box, Button, Flex, FormControl, FormLabel, Image, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Spinner, useDisclosure, Checkbox, Divider, InputGroup, InputLeftElement, RadioGroup, Stack, Radio, InputRightElement, useToast } from "@chakra-ui/react"
import Header from "../../components/header/header";
import { Fragment, useEffect, useState } from "react";
import axiosInstance from "../../services/api";
import Empty from './empty.svg';
import { AddIcon, ArrowBackIcon, ChevronDownIcon, DeleteIcon, MinusIcon, PlusSquareIcon, SearchIcon } from "@chakra-ui/icons";
import states from "../../utils/states";
import capitalizeFirstLetter from "../../utils/capitalize";
import statesLgaJson from "../../utils/stateLGAs";


  const datay = [
    {
        name: "Brand New",
    },
    {
        name: "Almost New",
    },
    {
        name: "Fairly Used",
    },
    {
        name: "Used",
    }
]


const AlertMe = () => {
    const [loadingc, setLoadingc] = useState(false);
    const [loadingd, setLoadingd] = useState(false);
    const [loading, setLoading] = useState(true);
    const [alerts, setAlerts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [view, setView] = useState("category");
    const [value4, setValue4] = useState("");

    const [idx, setIdx] = useState("")

    const [selected, setSelected] = useState("");
    const [selected2, setSelected2] = useState("");
    const [selectedy, setSelectedy] = useState("");
    const [showMore, setShowMore] = useState(false);

    const [locationSearch, setLocationSearch] = useState("");
    
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
    const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure();
    const { isOpen: isOpen4, onOpen: onOpen4, onClose: onClose4 } = useDisclosure();
    const { isOpen: isOpen5, onOpen: onOpen5, onClose: onClose5 } = useDisclosure();

    const [category, setCategory] = useState("")
    const [subcategory, setSubcategory] = useState("")
    const [minPrice, setMinPrice] = useState("")
    const [maxPrice, setMaxPrice] = useState("")
    const [condition, setCondition] = useState("")
    const [location, setLocation] = useState("")
    const [locationx, setLocationx] = useState("")

    useEffect(() => {
        axiosInstance.get("/item-alerts/user")
        .then(res => setAlerts(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    }, [loadingc, loadingd])

    const toast = useToast()

    useEffect(() => {
        axiosInstance.get("/item-categories")
        // .then(res => console.log("xxxxx", res?.data?.data))
        .then(res => setCategories(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error))
    })

    const handleSelecty = (name) => {
        setSelectedy(name)
        onClose3()
    }

    const createAlert = async() => {
        setLoadingc(true)

        const myObj = {}

        const datax = {
            category: selected?.name,
            subcategory: selected2?.name,
            minPrice,
            maxPrice,
            condition: selectedy,
            location,
            landmarkArea: locationx
        }

        for (const key in datax) {
            const value = datax[key];
            if (value !== "" && value !== undefined && value !== null) {
              myObj[key] = value;
            }
          }

        //   console.log(myObj)

        try {
            const {data} = await axiosInstance.post("/item-alerts", myObj)
            // console.log(data)
            onClose()
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoadingc(false)
        }
        
    }

    const deleteAlert = async(id) => {
        setLoadingd(true)

        try {
            const {data} = await axiosInstance.delete(`/item-alerts/${id}`)
            console.log(data)
            onClose4()
            toast({
                description: "Alert removed successfully",
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoadingd(false)
        }
        
    }

    const handleSelect = (obj) => {
        setSelected(JSON.parse(obj))
        // setView("sub-category")
        onClose2()
    }
    const handleSelect2 = (obj) => {
        setSelected2(JSON.parse(obj))
        onClose2()
    }

    const handleDelete = (id) => {
        setIdx(id)
        onOpen4()
    }

    const handleCat = (type) => {
        setView(type)
        onOpen2()
    }

    const handleStateSelectx = (val) => {
        setLocationx(val)
        onClose5()
    }

    return (
        <Box>
            <Header />
            <Box px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={"2rem"} height="77vh">
                <Flex alignItems={"center"} justifyContent={"space-between"}>
                    <Text flex={"6"} color="#1A1A1A" fontWeight={"700"} fontSize="1.25rem" fontFamily={"Clash Display"}>My Alerts</Text>
                    {alerts?.length > 0 && <Button onClick={() => onOpen()} padding={".9rem 1.5rem"} fontFamily="Clash Display" borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"fit-content"} color="#fff" fontSize={[".9rem", ".9rem"]} fontWeight={"600"}><PlusSquareIcon mr={2} />Alert</Button>}
                </Flex>
                {alerts?.length > 0 && <Text>You will receive alerts if items that match these criteria are listed on the platform</Text>}
                <br />
                <br />
                {loading ? 
                <Flex marginTop={"20rem"} justifyContent={"center"}>
                    <Spinner size={"lg"} color="#E66F15" />
                </Flex>
                :
                alerts?.length < 1 ? 
                <Box textAlign={"center"} marginTop="1rem">
                    <Image src={Empty} transform="scale(.6) translateY(20px)" width={"auto"} margin="0 auto" />
                    <Text color="#030303" fontFamily={"Clash Display"} fontWeight="600">Nothing to see here yet!</Text>
                    <Text color="#666666" fontSize={".9rem"} maxW="500px" mx="auto" mt={2}>You can receive notifications & alerts for specific items you have set and be the first to know when your choice item hits the market!</Text>
                    <Flex justifyContent={"center"} mt="3rem">
                        <Button onClick={() => onOpen()} padding={".9rem 1.5rem"} fontFamily="Clash Display" borderRadius="20px" border="1px solid #EFEDED" bg="#E66F15" width={"fit-content"} color="#fff" fontSize={[".9rem", ".9rem"]} fontWeight={"600"}>Create Alert</Button>
                    </Flex>
                </Box>
                :
                <Box maxW={"700px"} mx="auto">
                    {alerts?.map(({_id, category, subcategory, minPrice, maxPrice, condition, location, landmarkArea}) => {
                        return (
                            <Flex position={"relative"} mb="1rem" bg="#FAE2D0" key={_id} padding={"1.2rem"} borderRadius={"8px"} border="1px solid #E66F15" justifyContent={"space-between"}>
                                <Box>
                                    <Text mb="1rem"><strong>{subcategory}</strong> in <strong>{category}</strong></Text>
                                    <Text>Price range: <strong>₦{minPrice?.toLocaleString()}</strong> - <strong>₦{maxPrice?.toLocaleString()}</strong></Text>
                                </Box>
                                <Box textAlign={"right"}>
                                    <Text mb="1rem"><strong>{condition}</strong></Text>
                                    <Text textAlign={"right"}>In <strong style={{textTransform: "capitalize"}}>{landmarkArea}{landmarkArea?.length > 0 ? "," : null} {location}</strong></Text>
                                </Box>
                                <Box position={"absolute"} right={"-4%"} top="0">
                                    <DeleteIcon onClick={() => handleDelete(_id)} cursor={"pointer"} />
                                </Box>
                            </Flex>
                        )
                    })}
                </Box>
                }
            </Box>

            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Create New Alert</ModalHeader>
                <ModalBody>
                    
                    <FormControl mt={3}>
                            <InputGroup>
                                <InputLeftElement children={<SearchIcon color={"gray"} />} />
                                <Input onChange={e => setLocationSearch(e.target.value)} type="text" outline={"none"} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15", outline: "none"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"12px"} padding={"1rem .4rem 1rem 2rem"} placeholder="Search Location (State)" _placeholder={{color: "#8C8C8C", fontSize: ".8rem"}} fontSize=".9rem" height={"45px"} />
                            </InputGroup>
                        </FormControl>
                    <RadioGroup margin={"1rem 0 0 0"} onChange={setLocation} value={location}>
                        <Stack direction='column' lineHeight={"30px"} height={"100px"} overflowY={"scroll"}>
                        <Radio size={"sm"} colorScheme="orange" fontSize={".8rem"} value={""}>Anywhere</Radio>
                        {statesLgaJson.sort((a, b) => a.state?.localeCompare(b.state))?.filter(i => i.state.toLowerCase().includes(locationSearch.toLowerCase())).slice(0, showMore == true ? states.length : 5)?.map((i, index) => {
                            return (
                                <Radio size={"sm"} colorScheme="orange" fontSize={".8rem"} key={index} value={i.state}>{capitalizeFirstLetter(i.state)}</Radio>
                            )
                        })}
                        </Stack>
                    </RadioGroup>

                    <Flex onClick={() => onOpen5()} cursor={"pointer"} mt={4} alignItems={"center"} justifyContent="space-between" padding={".7rem 1rem"} borderRadius="12px" border="1px solid #B0B4B0">
                        <Text color={selected ? "#1A1A1A" : "#B0B4B0"} fontSize={".9rem"}>{locationx ? locationx : "Select an area"}</Text>
                        <ChevronDownIcon color="#B0B4B0" fontSize={"1.2rem"} />
                    </Flex>
                    <Flex onClick={() => handleCat("category")} cursor={"pointer"} mt={4} alignItems={"center"} justifyContent="space-between" padding={".7rem 1rem"} borderRadius="12px" border="1px solid #B0B4B0">
                        <Text color={selected ? "#1A1A1A" : "#B0B4B0"} fontSize={".9rem"}>{selected ? selected?.name : "Select a category"}</Text>
                        <ChevronDownIcon color="#B0B4B0" fontSize={"1.2rem"} />
                    </Flex>
                    <Flex onClick={() => handleCat("sub-category")} cursor={"pointer"} mt={4} alignItems={"center"} justifyContent="space-between" padding={".7rem 1rem"} borderRadius="12px" border="1px solid #B0B4B0">
                        <Text color={selected ? "#1A1A1A" : "#B0B4B0"} fontSize={".9rem"}>{selected2 ? selected2?.name : "Select a subcategory"}</Text>
                        <ChevronDownIcon color="#B0B4B0" fontSize={"1.2rem"} />
                    </Flex>
                    <Flex onClick={onOpen3} cursor={"pointer"} mt={4} alignItems={"center"} justifyContent="space-between" padding={".7rem 1rem"} borderRadius="12px" border="1px solid #B0B4B0">
                        <Text color={selectedy ? "#1A1A1A" : "#B0B4B0"} fontSize={".9rem"}>{selectedy ? selectedy : "Select a condition"}</Text>
                        <ChevronDownIcon color="#B0B4B0" fontSize={"1.2rem"} />
                    </Flex>
                    <Flex mb={8} mt={4} gap=".7rem">
                        <FormControl>
                            <InputGroup>
                                <Input onChange={e => setMinPrice(parseInt(e.target.value))} type="tel" outline={"none"} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15", outline: "none"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"10px"} padding={".7rem .4rem"} placeholder="0" _placeholder={{color: "#8C8C8C"}} fontSize=".9rem" height={"45px"} />
                                <InputRightElement children={<Text transform={"translateY(1px)"} color="#B3B3B3" fontSize={".75rem"} marginLeft="-1rem">Min (₦)</Text>} />
                            </InputGroup>
                        </FormControl>
                        <FormControl>
                            <InputGroup>
                                <Input onChange={e => setMaxPrice(parseInt(e.target.value))} type="tel" outline={"none"} _focus={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15", outline: "none"}}  _hover={{border: "1px solid #E66F15", boxShadow: "1px solid #E66F15"}} border="1px solid #B0B4B0 !important" borderRadius={"10px"} padding={".7rem .4rem"} placeholder="0" _placeholder={{color: "#8C8C8C"}} fontSize=".9rem" height={"45px"} />
                                <InputRightElement children={<Text transform={"translateY(1px)"} color="#B3B3B3" fontSize={".75rem"} marginLeft="-1rem">Max (₦)</Text>} />
                            </InputGroup>
                        </FormControl>
                    </Flex>
                </ModalBody>

                <ModalFooter>
                    <Button mr={4} variant='ghost' onClick={onClose}>Cancel</Button>
                    <Button bg="#E66F15" color={"#fff"} mr={3} onClick={createAlert}>
                    {loadingc ? <Spinner /> : "Create Alert"}
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isCentered isOpen={isOpen2} onClose={onClose2}>
                <ModalOverlay />
                <ModalContent maxHeight={"600px"} overflow="scroll" padding={".8rem"} margin=".5rem">
                <ModalHeader mb={6}>
                <Text cursor={"pointer"} onClick={view === "category" ? () => onClose : () => setView("category")} fontSize="1.1rem" fontFamily="Clash Display" fontWeight={"700"} color={"#1A1A1A"}><ArrowBackIcon transform={"translate(-6px,-1px)"} />{view === "category" ? "Categories" : "Sub-Categories"}</Text>
                </ModalHeader>
                {view === "category" &&
                <ModalBody>
                    {categories?.map((i, index) => {
                        return (
                            <Fragment key={i?._id}>
                            <Flex onClick={() => handleSelect(JSON.stringify(i))} position={"relative"} pb={4} alignItems={"center"} cursor={"pointer"}>
                                <Box mr={2} width={"34px"} height="34px" border="2px solid #F7CFB1" borderRadius={"50%"} backgroundImage={i?.imageURL} backgroundSize="cover"></Box>
                                <Box>
                                    <Text fontFamily={"Clash Display"} fontWeight="500">{i?.name}</Text>
                                    {/* <Flex maxW="260px" overflow={"hidden"} alignItems={"center"}>
                                        {tags.map((i, index) => ( */}
                                            <Text whiteSpace={"nowrap"} mt={1} maxWidth="270px" overflow={"hidden"} fontWeight="500" color="#8C8C8C">{i?.description}</Text>
                                        {/* ))}
                                    </Flex> */}
                                </Box>
                                <Checkbox size={"lg"} colorScheme={"orange"} position="absolute" right="3%" top="8%" />
                            </Flex>
                            <Divider />
                            <br />
                            </Fragment>
                        )
                    })}
                </ModalBody>}
                {view === "sub-category" &&
                <ModalBody>
                    {selected ?
                    selected?.subcategories?.map((i, index) => {
                        return (
                            <Fragment key={i?._id}>
                            <Flex onClick={() => handleSelect2(JSON.stringify(i))} position={"relative"} pb={4} alignItems={"center"} cursor={"pointer"}>
                                <Box mr={2} width={"34px"} height="34px" border="2px solid #F7CFB1" borderRadius={"50%"} backgroundImage={i?.imageURL} backgroundSize="cover"></Box>
                                <Box>
                                    <Text fontFamily={"Clash Display"} fontWeight="500">{i?.name}</Text>
                                    {/* <Flex maxW="260px" overflow={"hidden"} alignItems={"center"}>
                                        {tags.map((i, index) => ( */}
                                            <Text whiteSpace={"nowrap"} mt={1} maxWidth="270px" overflow={"hidden"} fontWeight="500" color="#8C8C8C">{i?.description}</Text>
                                        {/* ))}
                                    </Flex> */}
                                </Box>
                                <Checkbox size={"lg"} colorScheme={"orange"} position="absolute" right="3%" top="8%" />
                            </Flex>
                            <Divider />
                            <br />
                            </Fragment>
                        )
                    })
                    :
                    <Text>You need to select a category first.</Text>
                    }
                </ModalBody>}

                
                </ModalContent>
            </Modal>

            <Modal isCentered isOpen={isOpen3} onClose={onClose3}>
                <ModalOverlay />
                <ModalContent maxHeight={"600px"} overflow="scroll" padding={".8rem"}>
                <ModalHeader mb={6}>
                <Text cursor={"pointer"} onClick={onClose2} fontSize="1.1rem" fontFamily="Clash Display" fontWeight={"700"} color={"#1A1A1A"}><ArrowBackIcon transform={"translate(-6px,-1px)"} />Condition</Text>
                </ModalHeader>
                <ModalBody>
                    {datay?.map(({img, name, egs}, index) => {
                        return (
                            <Fragment key={index}>
                            <Flex onClick={() => handleSelecty(name)} position={"relative"} pb={4} alignItems={"center"} cursor={"pointer"}>
                                <Text fontFamily={"Clash Display"} fontWeight="500">{name}</Text>
                                <Checkbox size={"lg"} colorScheme={"orange"} position="absolute" right="3%" top="8%" />
                            </Flex>
                            <Divider />
                            <br />
                            </Fragment>
                        )
                    })}
                </ModalBody>

                
                </ModalContent>
            </Modal>

            <Modal isCentered isOpen={isOpen4} onClose={onClose4}>
                <ModalOverlay />
                <ModalContent maxHeight={"600px"} overflow="scroll" padding={".8rem"}>
                <ModalHeader mb={6}>
                <Text cursor={"pointer"} fontSize="1.1rem" fontFamily="Clash Display" fontWeight={"700"} color={"#1A1A1A"}>Delete Alert?</Text>
                </ModalHeader>
                <ModalBody>
                    <Flex gap="1.2rem" justifyContent={"flex-end"}>
                        <Button onClick={onClose4} padding={".9rem 1.5rem"} fontFamily="Clash Display" borderRadius="8px" border="1px solid #E66F15" bg="#FFF" width={"fit-content"} color="#E66F15" fontSize={[".9rem", ".9rem"]} fontWeight={"600"}>Cancel</Button>
                        <Button onClick={() => deleteAlert(idx)} padding={".9rem 1.5rem"} fontFamily="Clash Display" borderRadius="8px" border="1px solid #EFEDED" bg="#E66F15" width={"fit-content"} color="#fff" fontSize={[".9rem", ".9rem"]} fontWeight={"600"}>{loadingd ? <Spinner /> : "Delete"}</Button>
                    </Flex>
                </ModalBody>
                </ModalContent>
            </Modal>

            <Modal isCentered isOpen={isOpen5} onClose={onClose5}>
                <ModalOverlay />
                <ModalContent maxHeight={"600px"} overflowY="scroll" padding={".8rem"}>
                <ModalHeader mb={6}>
                <Text cursor={"pointer"}  fontSize="1.1rem" fontFamily="Clash Display" fontWeight={"700"} color={"#1A1A1A"}>Local Govt. Area</Text>
                </ModalHeader>
                <ModalBody>
                        {location ?
                        statesLgaJson?.find(i => i.state === location)?.lgas?.map((i, index) => {
                            return (
                                <Box key={index}>
                                <Flex onClick={() => handleStateSelectx(i)} position={"relative"} pb={4} justifyContent="space-between" alignItems={"center"} cursor={"pointer"}>
                                    <Text fontFamily={"Clash Display"} fontWeight="500">{i}</Text>
                                    {/* <ChevronRightIcon fontSize={"1.2rem"} color="#D1FB00" /> */}
                                    <Checkbox size={"lg"} colorScheme={"orange"} position="absolute" right="3%" top="8%" />
                                </Flex>
                                <Divider />
                                <br />
                                </Box>
                            )
                        })
                        :
                        <Text>You need to select a location(state) first.</Text>
                        }
                </ModalBody>

                
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default AlertMe;