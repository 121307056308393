import "./navbar2.scss";
import Logo from "../../assets/Declutterslogo.png";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  Input,
  FormLabel,
  InputGroup,
  InputRightElement,
  Text,
  HStack,
  Spinner,
  useToast,
  Avatar,
  Flex,
  WrapItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Box,
  Badge,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { useState } from "react";
import { userActionTypes } from "../../redux/constants/userActionTypes";
import { useDispatch } from "react-redux";
import axios from "axios";
import baseUrl from "../../config";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Navbar2 = () => {
  const dispatch = useDispatch()
  const toast = useToast()

  const currentUser = useSelector(({userData})=>  userData?.currentUser);
  // console.log("currentUser", currentUser);


 

  const navigate = useNavigate()

  return (
    <>
      <Box borderBottom={"1px solid #EAEAEA"} px={[".8rem", "1.5rem", "2.5rem", "3rem"]} py={["1rem", "1.2rem", "1.35rem", "1.5rem"]} className="navbar">
        <Image width={["30%", "17%", "12%"]} cursor={"pointer"} onClick={() => navigate("/")} src={Logo} alt="declutters_logo" />
      </Box>
    </>
  );
};

export default Navbar2;
